import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import { getPointsCalculationsByKeys } from 'luxLoyalty/selectors/pointsCalculation'
import submitLuxLoyaltyPointsEarnCalculationRequest from 'actions/luxLoyalty/submitLuxLoyaltyPointsEarnCalculationRequest'

interface TotalPointsCalculation {
  totalPointsCalculation: App.StatefulData<App.LuxLoyaltyPointsEarnCalculation, boolean>;
  /** @dev primarily used for debugging individual points calculations */
  pointsCalculations: Array<App.StatefulData<App.LuxLoyaltyPointsEarnCalculation> | undefined>;
}

interface Options {
  skipAll?: boolean
}

const INITIAL_LUX_LOYALTY_POINTS_EARN_CALCULATION_STATE: App.StatefulData<App.LuxLoyaltyPointsEarnCalculation, boolean> = {
  initial: true,
}

function useLuxLoyaltyPointsEarnCalculator(
  calculationRequests: Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>,
  options?: Options,
): TotalPointsCalculation {
  const dispatch = useAppDispatch()
  const customerId = useAppSelector(state => state.auth.account.memberId ?? '')
  const { skipAll } = options ?? {}

  const requestsToCalculate = useMemo(() => {
    if (skipAll) return []

    return nonNullable(calculationRequests)
  }, [calculationRequests, skipAll])

  useEffect(() => {
    requestsToCalculate.forEach((calculationRequest) => {
      dispatch(submitLuxLoyaltyPointsEarnCalculationRequest(calculationRequest))
    })
  }, [dispatch, requestsToCalculate])

  const pointsKeys = useMemo(() => requestsToCalculate.map((calculationRequest) => buildPointsKey(calculationRequest, customerId)), [requestsToCalculate, customerId])
  const pointsCalculations = useAppSelector(state => getPointsCalculationsByKeys(state, pointsKeys))

  return useMemo<TotalPointsCalculation>(() => {
    const anyError = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.error)
    const anyFetching = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.fetching)

    if (!pointsCalculations.length) {
      return {
        totalPointsCalculation: INITIAL_LUX_LOYALTY_POINTS_EARN_CALCULATION_STATE,
        pointsCalculations,
      }
    }

    if (anyFetching) {
      return {
        totalPointsCalculation: {
          fetching: true,
        },
        pointsCalculations,
      }
    }

    if (anyError) {
      return {
        totalPointsCalculation: {
          error: true,
          fetching: false,
        },
        pointsCalculations,
      }
    }

    return {
      totalPointsCalculation: {
        fetching: false,
        data: {
          points: sum(pointsCalculations, calculation => calculation?.data?.points ?? 0),
          statusCredits: sum(pointsCalculations, calculation => calculation?.data?.statusCredits ?? 0),
        },
      },
      pointsCalculations,
    }
  }, [pointsCalculations])
}

export default useLuxLoyaltyPointsEarnCalculator
