import React, { FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { forgotPassword, login, sendOTPVerificationCode } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PasswordInput from 'components/Common/Form/Input/PasswordInput'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import noop from 'lib/function/noop'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import HelpPane from 'components/Common/HelpPane'
import ContactContext from 'contexts/contactContext'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'

const Or = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${rem(12)};

  &::after,
  &::before {
    content: '';
    flex: 1;
    border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
    display: block;
  }
`

interface LoginForm {
  email: string;
  password: string;
}

interface Props {
  error?: any;
  processing: boolean;
  user: App.User;
  isStoreMode: boolean;
  onModeChange?: (mode: App.UiAccountModalMode) => void;
  otpEnabled?: boolean;
  dismissable?: boolean;
}

function AccountAccessLoginPassword(props: Props) {
  const {
    error,
    processing,
    user,
    isStoreMode,
    otpEnabled,
    onModeChange = noop,
    dismissable,
  } = props

  const [sendingCode, setSendingCode] = useState<boolean>(false)
  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const nativeInputRef = useRef<HTMLInputElement>(null)
  const loggedIn = useAppSelector(selectLoggedIn)
  const isMobile = useScreenSizeOnly('mobile')

  const currentContact = useContext(ContactContext)
  const contactNumber = currentContact.defaultContact

  const currentPathName = useAppSelector(state => state.router.location.pathname)
  const currentSearch = useAppSelector(state => state.router.location.search)
  const callbackPath = currentPathName + currentSearch

  useEffect(() => {
    if (sendingCode && !processing && !error) {
      onModeChange('loginOTP')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingCode, processing, error])

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  useEffect(() => {
    const loginType = user.email ? 'email' : 'phone'
    if (user.maskedPhone) {
      Analytics.trackClientEvent({
        subject: 'sign_in_password_or_otp',
        action: 'impression',
        category: loginType,
        type: 'operational',
      })
    } else {
      Analytics.trackClientEvent({
        subject: 'sign_in_password',
        action: 'impression',
        category: loginType,
        type: 'operational',
      })
    }

    if (!isMobile || !user.maskedPhone) {
      nativeInputRef.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useAppDispatch()

  const reRef = useRef<ReCAPTCHA>(null)

  const onForgotPassword = useCallback(() => {
    if (user.email) {
      // forgot password only works with an email, not phone number (for now?)
      dispatch(forgotPassword(user.email, callbackPath))
      onModeChange('forgotPassword')
    }
  }, [dispatch, onModeChange, user, callbackPath])

  const onLogin = useCallback<FormEventHandler<HTMLFormElement>>(async(event) => {
    event.preventDefault()
    const loginData = formToObject<LoginForm>(event.currentTarget)
    let recaptchaData
    if (reRef.current) {
      recaptchaData = await reRef.current.executeAsync()
    }
    dispatch(login(user, loginData.password, recaptchaData))
  }, [dispatch, user])

  const onSendVerificationCode = useCallback(() => {
    dispatch(sendOTPVerificationCode(user))
    setSendingCode(true)
  }, [dispatch, user])

  const onBack = useCallback(() => onModeChange('login'), [onModeChange])

  return (<>
    <ModalHeader
      title="Welcome back"
      onBackButtonClick={onBack}
      dismissible={dismissable}
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24} as="form" onSubmit={onLogin} name="loginForm">
          {error?.status === 500 && <MessageBanner kind="critical" description="Something went wrong. Please try again." />}
          {error?.message && error.status !== 500 && <MessageBanner kind="critical" description={error.message} />}
          <VerticalSpacer gap={16}>
            <fieldset>
              <HiddenInput
                id="email"
                name="email"
                value={user.email}
              />
              <PasswordInput
                label="Password"
                id="password"
                required
                name="password"
                placeholder="Password"
                requiredErrorMessage="Password is required"
                ref={nativeInputRef}
              />
              <Group direction="vertical" gap={16} horizontalAlign="center">
                <TextButton
                  kind="primary"
                  size="large"
                  type="submit"
                  fit="flex">
                  {user.maskedPhone ? 'Log in with password' : 'Log in'}
                </TextButton>
                {!isStoreMode && <>
                  {user.email && <TextLink
                    onClick={onForgotPassword}
                    size="medium"
                  >
                    Forgot your password?
                  </TextLink>}
                  {!user.email && <TextLink
                    onClick={() => onModeChange('login', '')}
                    size="medium"
                  >
                    Log in with email
                  </TextLink>}
                </>}
                {otpEnabled && user.maskedPhone && (
                  <Group direction="vertical" gap={16} horizontalAlign="center" fullWidth>
                    <Or>
                      <BodyText colour="neutral-two" variant="medium">or</BodyText>
                    </Or>
                    <TextButton
                      kind="secondary"
                      size="large"
                      fit="flex"
                      onClick={onSendVerificationCode}
                    >
                      Send me a verification code
                    </TextButton>
                    <BodyText variant="medium">SMS will be sent to <strong>{user.maskedPhone}</strong></BodyText>
                  </Group>
                )}
              </Group>
              {error?.status === 500 && (
                <HelpPane contactNumber={contactNumber} />
              )}
            </fieldset>
            {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
          </VerticalSpacer>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    processing: state.auth.processing,
    isStoreMode: state.system.storeMode,
  }
}

export default connect(mapStateToProps)(AccountAccessLoginPassword)
