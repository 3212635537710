import { getHotelChangeDatesConfig, isExtendDatesAllowed } from 'components/Common/OrderView/OrderItemSummaries/HotelItemSummary/HotelSummaryModifyBooking/HotelSummaryModifyBookingUtils'
import { bookingsPriority, canBeUpgraded } from 'components/Common/OrderView/OrderItemSummaries/HotelItemSummary/HotelItemSummaryUtils'
import { hideDowngrades, hideBedbankDowngrades } from 'lib/order/isDowngraded'
import { isBalancePending } from 'lib/payment/depositsUtils'
import { isInstalmentFullyPaidOff } from 'lib/payment/payInInstalmentsUtils'
import isBedbankDateChangeAllowed from 'lib/order/isBedbankDateChangeAllowed'
import { dateIsAfter } from 'lib/datetime/dateUtils'

export function isChangeDatesAllowed(payload?: App.ChatMessageMetaPayload, order?: App.Order, offer?: App.Offer) {
  if (!order) {
    return false
  }

  // If the customer has multiple orders,
  // The canChangeDates property gets added to the payload when they select the order
  if (typeof payload?.canChangeDates !== 'undefined') {
    return payload.canChangeDates
  }

  // We will only hit this code if the customer has 1 order
  // and it is automatically selected when they start the SELECT_ORDER flow
  if (order.items.length > 0) {
    if (offer) {
      return canChangeHotelDates(order, offer)
    }
  } else if (order.bedbankItems.length > 0) {
    return canChangeBedbankDates(order)
  }

  return false
}

export function getBedbankItems(order: App.Order) {
  let bedbankItems = hideBedbankDowngrades(order.bedbankItems)

  // In case all items are cancelled, show the latest one
  if (bedbankItems.length === 0) {
    bedbankItems = order.bedbankItems.slice(-1)
  }
  return bedbankItems
}

export function getBedbankItem(order: App.Order) {
  const bedbankItems = getBedbankItems(order)
  return bedbankItems[0]
}

export function canChangeBedbankDates(order: App.Order): boolean {
  const item = getBedbankItem(order)
  return isBedbankDateChangeAllowed(order, item)
}

export function getHotelItems(order: App.Order): Array<App.OrderItem> {
  let hotelItems = hideDowngrades(order.items)

  // In case all items are cancelled, show the latest one
  if (hotelItems.length === 0) {
    hotelItems = order.items.slice(-1)
  }

  return hotelItems
}

export function getHotelItem(order: App.Order, itemId?: string) {
  const hotelItems = getHotelItems(order)

  // if the customer has selected a room in a multi room booking
  if (itemId) {
    const selectedHotelItem = hotelItems.find((item) => item.id === itemId)
    if (selectedHotelItem) {
      return selectedHotelItem
    }
  }

  return hotelItems[0]
}

export function canChangeHotelDates(order: App.Order, offer: App.Offer): boolean {
  const item = getHotelItem(order)

  return getHotelChangeDatesConfig(
    false,
    item,
    order,
    offer,
  ).canDateChange
}

export function canRequestChangeHotelDates(order?: App.Order, offer?: App.Offer) {
  if (!order || !offer) {
    return false
  }

  const item = getHotelItem(order)

  return getHotelChangeDatesConfig(
    false,
    item,
    order,
    offer,
  ).canRequestDateChange
}

export function canChangeHotelRooms(order: App.Order, offer: App.Offer, items: Array<App.OrderItem>): boolean {
  const activeItems = items.filter(item => item.status !== 'cancelled').sort(bookingsPriority)
  const roomCount = activeItems.length
  const isSingleItem = roomCount <= 1

  const firstItem = activeItems[0] || items[0]

  const hasUnpaidDepositAmount = order.depositDetails && isBalancePending(order.depositDetails.deposit_status)
  const hasUnpaidInstalment = order.instalmentDetails && !isInstalmentFullyPaidOff(order.instalmentDetails)

  return isSingleItem && !!offer.packageUpgradesAllowed && canBeUpgraded(firstItem) && !hasUnpaidDepositAmount && !hasUnpaidInstalment && !order.reserveForZeroDetails?.isActive && !items[0].isCompedPackage
}

export function canExtendHotelDates(order?: App.Order, offer?: App.Offer, itemId?: string): boolean {
  // This function is called from different Components where order is required or optional :sadFace
  if (!order || !offer) {
    return false
  }

  const item = getHotelItem(order, itemId)

  return isExtendDatesAllowed(
    item,
    offer,
  )
}

export function canCancelOrderItem(order?: App.Order, item?: App.OrderItem): boolean {
  if (!order || !item) {
    return false
  }

  const { policyType, canRefund } = item.cancellationConfig

  return policyType !== 'non-refundable' && canRefund
}

export function getFreeCancellationPeriodEndDate(order: App.Order, item: App.OrderItem) {
  const reservation = item.reservation
  if (!reservation) {
    return undefined
  }

  const isAfterCheckIn = dateIsAfter(new Date(), new Date(reservation.startDate))
  const hasRefundRequest = !!order.refundRequests[item.id]

  if (hasRefundRequest || isAfterCheckIn) {
    return undefined
  }

  const { policyDeadline } = item.cancellationConfig
  return policyDeadline
}
