import React, { useCallback } from 'react'
import { enrolLuxLoyaltyAccount } from 'actions/LuxLoyaltyActions'
import Pane from 'components/Common/Pane'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import Pill from 'components/Luxkit/Pill'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { rem } from 'polished'
import { connect } from 'react-redux'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { capitalise, capitaliseAll } from 'lib/string/stringUtils'
import cn from 'clsx'
import { themeClassName } from 'lib/theme/themeUtils'
import { getPlural } from 'lib/string/pluralize'
import LuxLoyaltyCopyListItem from './LuxLoyaltyCopyListItem'
import { getLuxLoyaltyProgramConfigDetails } from 'luxLoyalty/selectors/utils'
import LuxLoyaltyProductLogo from './LuxLoyaltyProductLogo'

const LuxLoyaltyBannerSheet = styled(Pane)`
  padding: ${rem(32)} ${rem(24)};

  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
  }

  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
  }

  &.tier-platinum {
    background: ${props => props.theme.palette.product.luxLoyalty.platinum.gradient};
  }
`

interface MappedProps {
  givenName?: string;
  accountEligibilityTier?: App.LuxLoyaltyTier;
  programConfigDetails: App.LuxLoyaltyProgramConfig;
}

interface Props extends MappedProps {}

function LuxLoyaltyJoinModal(props: Props) {
  const {
    givenName,
    accountEligibilityTier = 'silver',
    programConfigDetails,
  } = props
  const { resolve } = useModalElementContext<boolean>()
  const dispatch = useAppDispatch()

  const closeModal = useCallback(() => resolve(true), [resolve])

  const dismissModal = useCallback(() => {
    setLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY, 1)
    closeModal()
  }, [closeModal])

  const handleOnClickJoinLuxLoyalty = useCallback(() => {
    dismissModal()
    dispatch(enrolLuxLoyaltyAccount({ enableSnackbar: true }))
  }, [dismissModal, dispatch])

  const formattedAccountEligibilityTier = capitalise(accountEligibilityTier)
  const formattedTierLabel = capitaliseAll(programConfigDetails.tierLabel)
  const themeClassNameForTier = themeClassName(accountEligibilityTier === 'platinum' ? 'inverse' : 'default')

  return <ModalBase dismissible={false}>
    <ModalBody>
      <LuxLoyaltyBannerSheet className={cn(`tier-${accountEligibilityTier}`)}>
        <Group direction="vertical" horizontalAlign="center" gap={12}>
          <Pill kind="primary" variant="success">
            NEW
          </Pill>
          <LuxLoyaltyProductLogo className={themeClassNameForTier} height={24} />
          <Heading variant="heading3" format="propercase" align="center" className={themeClassNameForTier}>
            {givenName ? `${givenName}, unlock` : 'Unlock'} your{' '}{formattedAccountEligibilityTier} {formattedTierLabel}
          </Heading>
        </Group>
      </LuxLoyaltyBannerSheet>
      <ModalContent>
        <VerticalSpacer gap={8}>
          <BodyText variant="large" weight="bold">
            Join our FREE Loyalty Program to:
          </BodyText>
          {!!accountEligibilityTier && <VerticalSpacer gap={8}>
            <LuxLoyaltyCopyListItem size="M" tier={accountEligibilityTier} type="earn-redeem" />
            {accountEligibilityTier !== 'platinum' && <LuxLoyaltyCopyListItem size="M" tier={accountEligibilityTier} type="unlock-status" />}
          </VerticalSpacer>}
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter
      primaryActionProps={{
        children: `Activate my FREE ${formattedAccountEligibilityTier} Status`,
        onClick: handleOnClickJoinLuxLoyalty,
      }}
      secondaryActionProps={{
        children: `I don't want free ${getPlural(programConfigDetails.currencyName)}`,
        onClick: dismissModal,
      }}
    />
  </ModalBase>
}

const mapStateToProps = (state: App.State) => ({
  givenName: state.auth.account.givenName,
  accountEligibilityTier: state.luxLoyalty.eligibilityTier?.data,
  programConfigDetails: getLuxLoyaltyProgramConfigDetails(state),
})

export default connect(mapStateToProps)(LuxLoyaltyJoinModal)
