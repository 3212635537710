import Clickable from 'components/Common/Clickable'
import FormatCurrency from 'components/Common/FormatCurrency'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import Label from 'components/Luxkit/Label/Label'
import Caption from 'components/Luxkit/Typography/Caption'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import React, { ComponentProps, useCallback } from 'react'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import LuxPlusBookingProtectionLabelLabel from './LuxPlusBookingProtectionLabel'
import LuxPlusInsuranceLabel from './LuxPlusInsuranceLabel'

const LOGO_HEIGHT = 10

interface Props extends Pick<ComponentProps<typeof Clickable>, 'to'>, Utils.PropsWithDataAttributes {
  onClick?: () => void;
  type: App.LuxPlusLabelType;
  saveAmount?: number;
  hideSaveAmountPlus?: boolean;
  currency?: string;
  description?: string;
}

function LuxPlusLabel(props: Props) {
  const {
    currency,
    description,
    type,
    onClick,
    saveAmount,
    hideSaveAmountPlus,
    to,
    ...rest
  } = props

  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }, [onClick])

  if (!luxPlusEnabled) return null

  const saveAmountSuffix = hideSaveAmountPlus ? '' : '+'

  const label = <Label
    data-testid="lux-plus-label"
    {...rest}
    kind="primary"
    variant="lux-plus"
    endIcon={onClick ? <LineInfoCircleIcon /> : undefined}
  >
    {type === 'bonus-inclusions' && !description && <><Caption variant="medium" weight="bold">Bonus inclusions with</Caption><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /></>}
    {type === 'bonus-inclusions' && !!description && <><Caption variant="medium" weight="bold">{description} with</Caption> <LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /></>}
    {type === 'member' && <><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /> <Caption variant="medium" weight="bold">member</Caption></>}
    {type === 'only-logo' && <LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} />}
    {type === 'early-access-offer' && <><Caption variant="medium" weight="bold">Early access with</Caption><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT}/></>}
    {type === 'member-only-offer' && <><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /><Caption variant="medium" weight="bold">member-only offer</Caption></>}
    {type === 'member-price-offer' && !!saveAmount && <><Caption variant="medium" weight="bold">Save <FormatCurrency format="roundedDollar" value={saveAmount} />{saveAmountSuffix} with</Caption><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /></>}
    {type === 'member-price-offer-lpp' && !!saveAmount && <><Caption variant="medium" weight="bold">Save <FormatCurrency format="roundedDollar" value={saveAmount} />{saveAmountSuffix} with</Caption><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /></>}
    {type === 'member-price-offer-tour' && !!saveAmount && <><Caption variant="medium" weight="bold">Save <FormatCurrency format="roundedDollar" value={saveAmount} />{saveAmountSuffix}/person with</Caption><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /></>}
    {type === 'options-available-tour' && <><LuxPlusLogo colour="inherit" height={LOGO_HEIGHT} /><Caption variant="medium" weight="bold">options available</Caption></>}
    {type === 'plus' && <LuxPlusLogo colour="inherit" type="plus-only" height={LOGO_HEIGHT} />}
    {type === 'plus-preview' && <><LuxPlusLogo colour="inherit" type="plus-only" height={LOGO_HEIGHT} /><Caption variant="medium" weight="bold">preview</Caption></>}
    {type === 'insurance' && !!saveAmount && <LuxPlusInsuranceLabel saveAmount={saveAmount} logoHeight={LOGO_HEIGHT} />}
    {type === 'booking-protection' && !!saveAmount && <LuxPlusBookingProtectionLabelLabel saveAmount={saveAmount} logoHeight={LOGO_HEIGHT} />}
  </Label>

  if (onClick || to) return <Clickable onClick={clickHandler} to={to} data-testid="lux-plus-label-clickable">{label}</Clickable>

  return label
}

export default LuxPlusLabel
