import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import React, { MouseEventHandler, useCallback, useContext, useMemo } from 'react'
import GeoContext from 'contexts/geoContext'
import ModalContext from 'contexts/ModalContext'
import { PROMO_SPEND_EARN_REGION } from 'constants/tours'
import TourSpendEarnDetailModal from 'components/Tours/TourSpendEarnDetailModal'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import Clickable from 'components/Common/Clickable'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'

function TourPromoSpendEarnLabel() {
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const showModal = useContext(ModalContext)
  const creditEarnListRegion = PROMO_SPEND_EARN_REGION[currentRegionCode]

  const bestCreditEarn = useMemo<number>(() => {
    const creditEarnList = creditEarnListRegion ?? []
    if (creditEarnList.length) {
      return Math.max(...creditEarnList.map(creditEarn => creditEarn.earn))
    }
    return 0
  }, [creditEarnListRegion])

  const showSpendEarnDetail = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    showModal(<TourSpendEarnDetailModal spendEarnList={creditEarnListRegion} maxCredit={bestCreditEarn} />)
  }, [bestCreditEarn, creditEarnListRegion, showModal])

  return <Clickable onClick={showSpendEarnDetail}>
    <PromotionLabel variant="default">
      <BodyTextBlock endIcon={<LineInfoCircleIcon />} variant="small" weight="semi-bold"> Up to <FormatCurrency value={bestCreditEarn} currency={currentCurrency} /> credit. Code: TOURDEAL</BodyTextBlock>
    </PromotionLabel>
  </Clickable>
}
export default TourPromoSpendEarnLabel
