import config from 'constants/config'
import { deepOmitKeys, mapObject } from 'lib/object/objectUtils'

export function getSentryServerSideReleaseName(defaultName: string) {
  // REACT_APP_COMMIT_SHA is from environment variable: i.e. 1234567890abcdef
  const commitSha = process.env.REACT_APP_COMMIT_SHA || undefined
  return commitSha ? `${process.env.REACT_APP_SENTRY_ENV}-${process.env.BRAND}-${commitSha?.substring(0, 7)}` : defaultName
}

export function getSentryClientSideReleaseName(defaultName: string) {
  // config should have COMMIT_SHA value from REACT_APP_COMMIT_SHA
  return config.COMMIT_SHA ? `${config.SENTRY_ENV}-${config.BRAND}-${config.COMMIT_SHA?.substring(0, 7)}` : defaultName
}

let sentry: any
export function initSentryServerSide(Sentry: any) {
  sentry = Sentry
  sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: getSentryServerSideReleaseName('unknown'),
  })
}

export function initSentryClientSide(Sentry: any) {
  sentry = Sentry
  sentry.init({
    dsn: config.SENTRY_ID,
    environment: config.SENTRY_ENV,
    release: getSentryClientSideReleaseName('unknown'),
    integrations: [
      /**
       * We must use `drop-error-if-exclusively-contains-third-party-frames` to avoid sending errors from third party libraries.
       * If use `drop-error-if-contains-third-party-frames`, it will drop all errors that contain at least one third-party stack frame.
       *
       * "drop-error-if-contains-third-party-frames": Drop error events that contain at least one third-party stack frame.
       * "drop-error-if-exclusively-contains-third-party-frames": Drop error events that exclusively contain third-party stack frames.
       * "apply-tag-if-contains-third-party-frames": Keep all error events, but apply a third_party_code: true tag in case the error contains at least one third-party stack frame.
       * "apply-tag-if-exclusively-contains-third-party-frames": Keep all error events, but apply a third_party_code: true tag in case the error contains exclusively third-party stack frames.
       */
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['luxgroup-customer-portal'],
        behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
      }),
    ],
  })
}

// Shortest string that could be contained in props that would be PII
// eg `email`, `first_name` would all be matched by these
const piiKeys = [
  'mail',
  'name',
  'phone',
  'number',
  'post_code',
  'birth',
  'gender',
  'title',
  'signature',
]
export async function sendErrorToSentry(error: Error | any, user?: App.AuthAccount, extras?: any) {
  try {
    // We also need to remove any PII from the extras to avoid the whole data being hidden on Sentry
    const obfuscatedExtras = deepOmitKeys(extras || {}, piiKeys)
    const stringifiedObfuscatedExtras = mapObject(obfuscatedExtras, (extra) => {
      if (typeof extra !== 'object' && !Array.isArray(extra)) return extra
      return JSON.stringify(extra, null, 2)
    })

    if (user?.memberId) {
      sentry.setUser({ id: user.memberId })
    }

    sentry.withScope((scope: any) => {
      const exception = error instanceof Error ? error : new Error(JSON.stringify(error))
      scope.setFingerprint(exception.message)
      scope.setExtras(stringifiedObfuscatedExtras)
      sentry.captureException(exception)
    })
  } catch {}
}
