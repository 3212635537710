import SolidCheckCircleIcon from 'components/Luxkit/Icons/solid/SolidCheckCircleIcon'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import React from 'react'

function showAddToUserCartSnackbar(description: string, heading?: string) {
  showSnackbar(description, 'success', {
    heading,
    icon: <SolidCheckCircleIcon />,
    action: {
      label: 'View cart',
      to: '/user-cart',
    },
  })
}

export default showAddToUserCartSnackbar
