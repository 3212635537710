import config from 'constants/config'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { createSelector } from 'reselect'

const isInsuranceBookingProtectionEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (currentRegionCode) => {
    return isEnabledForFeature(config.INSURANCE_BOOKING_PROTECTION_ENABLED_REGIONS, currentRegionCode) && config.INSURANCE_BOOKING_PROTECTION_ENABLED
  },
)

export default isInsuranceBookingProtectionEnabled
