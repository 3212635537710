import React, { useContext, useMemo } from 'react'
import LunarSiteTakeoverBackground from 'components/Common/SiteTakeover/LunarSiteTakeoverBackground'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LunarSiteTakeoverOfferUSPs from 'components/Common/SiteTakeover/LunarSiteTakeoverOfferUSPs'
import moment, { Moment } from 'moment'
import GeoContext from 'contexts/geoContext'
import config from 'constants/config'
import ValentineSiteTakeoverBackground from 'components/Common/SiteTakeover/ValentineSiteTakeoverBackground'
import Image from 'components/Common/Image'
import AccountAccessPromptIcon from 'components/Account/AccountAccessPrompt/AccountAccessPromptIcon'

export type SiteTakeoverBackground = 'default' |'hero' | 'search'

/**
 * Note: This interface has been hastile made to work with one type of site takeover
 * As we get more site takovers, hopefully the general structure will reveal itself better
 * Do not take this setup as gospel.
 */
interface SiteTakeover {
  /**
   * ID uniquely identifying the take over
   */
  id: string;
  /**
   * Date/time the take over starts
   */
  startDate: Moment;
  /**
   * Date/time the take over ends
   */
  endDate: Moment;
  /**
   * An image id to use as a base background
   */
  imageId: string;
  /**
   * The primary colour for the takeover
   * Will be used in a few places
   */
  primaryColour: string;
  /**
   * A background element that accepts a background type
   */
  backgroundElement: React.FunctionComponent<{ background: SiteTakeoverBackground, className?: string }>;
  /**
   * The general palette this takeover requires
   */
  palette: App.Design.PaletteType;
  heroCarousel: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
  };
  pageBannerContent: React.ReactNode;
  offerPage: {
    content: React.ReactNode;
      /**
   * Additional USP's for the offer page
   */
    usps?: React.ReactNode;
    /**
     * List of offer types the offer page banner applies to
     * Note: Perhaps expand to campaign tags later
     */
    offerTypes: Set<App.OfferType>;
  }
  searchPageBannerContent: React.ReactNode;
  /**
   * List of regions this take over will run on
   */
  regions: Set<string>;
  /**
   * List of brands this take over will run on
   */
  brands: Set<App.BrandName>;
}

const LunarNewYearTakeover: SiteTakeover = {
  id: 'lunarnewyear',
  startDate: moment('2025-01-22T00:00:00+1100'),
  endDate: moment('2025-02-06T23:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'CN', 'KR', 'MO', 'MY', 'TW', 'VN']),
  imageId: 'gidua9dchhephstwu3jp',
  primaryColour: '#F84916',
  palette: 'default',
  heroCarousel: {
    title: 'Happy Lunar New Year',
    subtitle: 'Enjoy exclusive offers! Valid Jan 22 - 6 Feb only',
  },
  offerPage: {
    content: <b>Enjoy Lunar New Year exclusive offers! Only valid Jan 22 - 6 Feb</b>,
    usps: <LunarSiteTakeoverOfferUSPs />,
    offerTypes: new Set(['hotel']),
  },
  backgroundElement: LunarSiteTakeoverBackground,
  pageBannerContent: <b>Enjoy Lunar New Year exclusive offers! Only valid Jan 22 - 6 Feb</b>,
  searchPageBannerContent: <VerticalSpacer gap={2}>
    <Heading variant="heading2">Happy Lunar New Year</Heading>
    <BodyText variant="large">Enjoy exclusive offers! Only valid Jan 22 - 6 Feb</BodyText>
  </VerticalSpacer>,

}

export const ValentineTakeover: SiteTakeover = {
  id: 'valentine',
  startDate: moment('2025-02-12T08:00:00+1100'),
  endDate: moment('2025-02-15T02:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'AE', 'ZA', 'IN']),
  imageId: 'gidua9dchhephstwu3jp',
  primaryColour: '#F84916',
  palette: 'default',
  heroCarousel: {
    title: 'Happy Valentine\'s Day',
    subtitle: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
  },
  offerPage: {
    content: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
    offerTypes: new Set(['hotel']),
  },
  backgroundElement: ValentineSiteTakeoverBackground,
  pageBannerContent: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
  searchPageBannerContent: <VerticalSpacer gap={2}>
    <Heading variant="heading2">Happy Valentine's Day</Heading>
    <BodyText variant="large">Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</BodyText>
  </VerticalSpacer>,

}

const takeovers = [
  LunarNewYearTakeover,
  ValentineTakeover,
]

function useCurrentSiteTakeover(): SiteTakeover | undefined {
  const { currentRegionCode } = useContext(GeoContext)

  const currentTakeover = useMemo(() => {
    const now = moment()
    return takeovers.find(take => {
      if (typeof window !== 'undefined' && !!window.__DEBUG_TAKEOVER_ID__) {
        return take.id.toLowerCase() === window.__DEBUG_TAKEOVER_ID__.toLowerCase()
      }
      return take.brands.has(config.BRAND) &&
      take.regions.has(currentRegionCode) &&
      now.isBetween(take.startDate, take.endDate)
    })
  }, [currentRegionCode])

  return currentTakeover
}

export function useHeaderIcon(currentRegion: string, promoDisplayConfig?: App.PromoDisplayConfig): React.ReactNode {
  const HeaderIcon = useMemo(() => {
    if (promoDisplayConfig?.codeName === 'LOVEISINTHEAIR' && ValentineTakeover.regions.has(currentRegion)) {
      return <Image width={90} id="ravf7iouyaoeqloomvue" enhancedFormats={false}/>
    } else {
      return <AccountAccessPromptIcon/>
    } }, [promoDisplayConfig, currentRegion])

  return HeaderIcon
}

export default useCurrentSiteTakeover
