import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import getHotelEncryptedMargin from 'luxLoyalty/lib/margin/getHotelEncryptedMargin'

interface BaseAdditionalOptions {
  skip?: boolean;
}

interface BasePricingOptions {
  price?: number;
  memberPrice?: number;
}

interface GenerateLuxLoyaltyPointsCalculatorHotelAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {
  packageView?: OfferPackageOptionView;
  pkg?: App.HotelPackage;
}

export function generateLuxLoyaltyPointsCalculatorHotelOptions(
  offer: App.Offer | App.OfferSummary | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorHotelAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    packageView,
    pkg,
    skip,
    price,
    memberPrice,
  } = options
  const luxLoyaltyProductType = getLuxLoyaltyProductType(offer)

  if (
    !offer ||
    (!packageView && !pkg) ||
    (!luxLoyaltyProductType || (luxLoyaltyProductType !== 'hotel_flash' && luxLoyaltyProductType !== 'hotel_lpc')) ||
    skip
  ) return

  return {
    price: price ?? packageView?.pricing?.price ?? 0,
    memberPrice: memberPrice ?? packageView?.memberPricing?.price ?? 0,
    luxLoyaltyProductType,
    mx: getHotelEncryptedMargin(offer.type, packageView?.package ?? pkg),
    offerId: offer.id,
    packageId: packageView?.package.id ?? pkg?.id ?? '',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorBedbankAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorBedbankOptions(
  rate: App.BedbankRate | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorBedbankAdditionalOptions | undefined,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'hotel_lpp_eps',
    mx: rate?.mx,
  }
}

// This is temporary until we have a villa product type in the backend
interface GenerateLuxLoyaltyPointsCalculatorVillasAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

// This is temporary until we have a villa product type in the backend
export function generateLuxLoyaltyPointsCalculatorVillasOptions(
  pkg: App.Package | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorVillasAdditionalOptions | undefined,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'rental',
    mx: pkg?.roomRate?.mx,
  }
}

interface GenerateLuxLoyaltyPointsCalculatorInsuranceAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorInsuranceOptions(
  quote: App.InsuranceQuote | undefined,
  options?: GenerateLuxLoyaltyPointsCalculatorInsuranceAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: price ?? quote?.total ?? 0,
    memberPrice: memberPrice ?? quote?.luxPlusTotal ?? 0,
    luxLoyaltyProductType: 'insurance',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorExperienceAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorExperienceOptions(
  offer: App.ExperienceOffer | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorExperienceAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: offer?.price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'experience',
  }
}
