import { UpdateAirportTransferPayload } from 'api/experiences'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { UpdateAirportTransferFormFields } from '../MyEscapesAirportTransfersPage'
import moment from 'moment'

export function formatTransferTravellers(
  travellers: App.OrderTransferItemTraveller,
) {
  let travellerText = formatOccupantsShort({ adults: travellers.adults, children: travellers.children })
  if (travellers.children > 0 && (travellers.childSeats > 0 || travellers.boosterSeats > 0)) {
    const seats = [
      travellers.childSeats > 0 ? pluralizeToString('child seat', travellers.childSeats) : undefined,
      travellers.boosterSeats > 0 ? pluralizeToString('booster seat', travellers.boosterSeats) : undefined,
    ].filter(Boolean)
    travellerText += ` (${seats.join(', ')})`
  }
  return travellerText
}

export function buildAirportTransferPayload(fields: UpdateAirportTransferFormFields) {
  const { flightNumber, date, time, specialRequests, travellers, baggage } = fields
  const { adults, children, childSeats, boosterSeats } = travellers
  const { bags, oversizedBags } = baggage

  const payload: UpdateAirportTransferPayload = {
    op: 'update_airport_transfer',
    data: {
      flightNumber,
      day: date,
      time,
      specialRequests,
      adults,
      children,
      childSeats,
      boosterSeats,
      bags,
      oversizedBags,
    },
  }
  return payload
}

export function airportTransfersUpdateRequired(airportTransfers: Array<App.OrderTransferItem>) {
  return airportTransfers.some(airportTransferUpdateRequired)
}

export function airportTransferUpdateRequired(airportTransfer: App.OrderTransferItem) {
  // because I have seen instances of all of these.
  return (
    airportTransfer.flightNumber == null ||
    airportTransfer.flightNumber === '' ||
    airportTransfer.date == null ||
    airportTransfer.date === '' ||
    airportTransfer.time == null ||
    airportTransfer.time === ''
  )
}

// For the new Complimentary Experiences, we do not want to show cancelled items.
// But we do for want to show them for standalone purchased experiences.
export function filterAirportTransfers(airportTransfers: Array<App.OrderTransferItem>, includeComplimentary = true, includeCancelled = false): Array<App.OrderTransferItem> {
  return airportTransfers.filter(item =>
    item.accommodation_parent_item_id === null ||
    item.accommodation_parent_item_id === undefined ||
    (includeComplimentary && item.accommodation_parent_item_id && (includeCancelled || item.status !== 'cancelled')),
  )
}

export function isRoundTripAirportTransfer(airportTransfers: Array<App.OrderTransferItem>): boolean {
  const filteredTransferItems = filterAirportTransfers(airportTransfers)

  // First iteration of airport transfer updates only handles one way or return transfer.
  // I.e. the ones with the form on the experience page.
  if (filteredTransferItems.length > 2) {
    return false
  }

  const toHotelItems = airportTransfers.filter(item => item.type === 'AIRPORT-TO-HOTEL')
  const toAirportItems = airportTransfers.filter(item => item.type === 'HOTEL-TO-AIRPORT')

  return !(toHotelItems.length > 2 || toAirportItems.length > 2)
}

export function isAirportTransferDateInNextTwoWeeks(transferDate: string) {
  const currentDate = moment()
  const targetDate = moment().add(14, 'days')

  return moment(transferDate) >= currentDate && moment(transferDate) <= targetDate
}

// ICS vendor IDs are the same in test and prod.
export const ICS_VENDOR_IDS = [
  '0012y00000W105xAAB',
  '0012y00000dbTjvAAE',
  '0010I000026fcwcQAA',
  '0010I00002J0Bb0QAF',
  '0012y00000jypSJAAY',
  '0012y00000W0foQAAR',
  '0012y00000ddqE4AAI',
]

export const ICS_CONTACT_NUMBERS: Record<string, { number: string, humanReadable: string }> = {
  Thailand: {
    number: '6281139603800',
    humanReadable: '+62 811 3960 3800',
  },
  General: {
    number: '6281139606800',
    humanReadable: '+62 811 3960 6800',
  },
}
