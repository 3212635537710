import { fetchFlights } from 'actions/FlightActions'
import CurrencyContext from 'contexts/currencyContext'
import { getFlightSearchKey } from 'lib/flights/flightUtils'
import { useContext, useEffect, useMemo } from 'react'
import { EmptyObject } from 'lib/object/objectUtils'
import { arrayToMap, nonNullable } from 'lib/array/arrayUtils'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { FlightViewTypes } from 'constants/flight'
import { FlightSearchItinerary } from 'api/flights'

export interface FlightSearchParams {
  itinerary: Array<FlightSearchItinerary>;
  occupancies: Array<App.Occupants>;
  fareClass: string;
  maxArrivalTime?: string;
  minReturningDepartureTime?: string;
  carriers?: Array<string>;
  carrier?: string;
  fareType?: string;
  viewType?: string;
  flightsCredit?: boolean;
  forceBundleId?: string;
  source?: string;
  filterAirlines?: Array<string>;
  key?: string | number;
}

function useSearchFlights(
  params: FlightSearchParams,
  options: {
    disabled?: boolean,
    segments?: Array<string>,
    activeSegmentKey?: string,
    selectedFlights?: Record<string, App.JourneyV2>,
    selectedFareFamilies?: Record<string, App.FlightFareFamily>,
    offerId?: string,
    offerTilePrice?: number,
    offerCalendarPrice?: number,
    sendCredentials?: boolean,
  } = EmptyObject,
): {
  journeys: Array<App.JourneyV2>,
  fetching: boolean,
  error: any,
  providerSearchId: string | undefined,
  viewType: string | undefined,
  key: string,
  metadata: App.FlightSearchMetadata | undefined,
  searchResultsTimestamp: number | undefined,
} {
  const currency = useContext(CurrencyContext)
  const { disabled, activeSegmentKey, segments = [], selectedFareFamilies = {}, selectedFlights = {}, offerTilePrice, offerId, offerCalendarPrice, sendCredentials } = options

  const paramsWithCurrency = {
    ...params,
    currency,
  }
  const key = getFlightSearchKey(paramsWithCurrency)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!disabled) {
      dispatch(fetchFlights(paramsWithCurrency, { offerTilePrice, offerId, offerCalendarPrice, sendCredentials }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, disabled])

  const flightsV2 = useAppSelector(state => state.flights.searchV2Flights[key])

  const v2Journeys = useMemo((): Array<App.JourneyV2> => {
    if (flightsV2 && segments && activeSegmentKey) {
      const segmentIndex = segments.findIndex(segment => segment === activeSegmentKey)

      if (segmentIndex === 0) {
        return flightsV2.fares[segmentIndex]
      } else {
        const currentFares = flightsV2.fares[segmentIndex] || []
        const prevFares = flightsV2.fares[segmentIndex - 1]
        const prevSegment = segments[segmentIndex - 1]
        const prevFlight = selectedFlights[prevSegment]
        const prevFareFamily = selectedFareFamilies[prevSegment]

        if ([FlightViewTypes.RETURN, FlightViewTypes.MULTI_CITY].includes(prevFlight?.fareType as FlightViewTypes)) {
          let currentPrevFlight = prevFlight

          // if a departing fare family is selected, we need to find the corresponding departing flight to get the correct returning flights
          if (prevFareFamily) {
            const correspondingFares = prevFares.filter((fare) => {
              const isSameCarrier = fare.carrier === prevFlight.carrier
              const isSameFareType = fare.fareType === prevFlight.fareType
              const sameFlightsLength = fare.flightGroup.flights.length === prevFlight.flightGroup.flights.length
              const isSameBookingClass = fare.bookingInfo.bookingClass === prevFareFamily.bookingClass
              const isSameFlight = fare.flightGroup.flights.every((flight, index) => {
                return flight.departingTime === prevFlight.flightGroup.flights[index].departingTime &&
                  flight.arrivalTime === prevFlight.flightGroup.flights[index].arrivalTime
              })

              return isSameCarrier && isSameFareType && sameFlightsLength && isSameFlight && isSameBookingClass
            })

            currentPrevFlight = correspondingFares[0] || prevFlight
          }

          // We have a departing flight that expects a return leg, find all return flights that pair with this one
          const currentFairsById = arrayToMap(currentFares, fare => fare.bookingInfo.boookingKey)
          return nonNullable(currentPrevFlight.pairedFareId.map(id => currentFairsById.get(id)))
        } else {
          // Doesn't have a paired departing flight, so we must be looking for one ways only
          return currentFares?.filter(fare => fare.fareType !== FlightViewTypes.RETURN)
        }
      }
    }

    return []
  }, [flightsV2, segments, activeSegmentKey, selectedFlights, selectedFareFamilies])

  return {
    journeys: v2Journeys || [],
    fetching: Boolean(flightsV2?.fetching),
    error: flightsV2?.error,
    providerSearchId: flightsV2?.searchId,
    viewType: flightsV2?.viewType,
    key,
    metadata: flightsV2?.metadata,
    searchResultsTimestamp: flightsV2?.timestamp,
  }
}

export default useSearchFlights
