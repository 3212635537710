import { getPlaceById, getPlaceBySlug, getPlaceByLatLong, getPlaceAncestorsById, getSpecificPlaces, getDestinationImageId } from 'api/search'
import { PlacesByPlaceId } from 'constants/places'
import { API_CALL } from './actionConstants'
import { FETCH_PLACE_BY_NAME, FETCH_PLACE_BY_COORDS, FETCH_PLACE, FETCH_PLACE_ANCESTORS, FETCH_SPECIFC_PLACES, FETCH_DESTINATION_IMAGE } from './apiActionConstants'
import { AppAction } from './ActionTypes'

export function fetchPlaceById(id: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.destination.placesLoading[id] || state.destination.places[id]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_PLACE,
      request: (): Promise<App.Place | null> => {
        const place = PlacesByPlaceId[id]
        if (place) {
          return Promise.resolve(place)
        } else {
          return getPlaceById(id).then(result => {
            if (!result.id) {
              // We use null here to explicitly set if we looked for it and didn't get any results
              return null
            }
            return result
          }).catch((): null => null)
        }
      },
      id,
    })
  }
}

export function fetchPlaceAncestorsById(id: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.destination.placeAncestors[id]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_PLACE_ANCESTORS,
      request: () => getPlaceAncestorsById(id),
      id,
    })
  }
}

export function fetchPlaceByName(location: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.destination.placesLoading[location] || state.destination.locationToPlace[location]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_PLACE_BY_NAME,
      // We use null here to explicitly set if we looked for it and didn't get any results
      request: () => getPlaceBySlug(location).then(result => {
        if (!result.id) {
          return null
        }
        return result
      }).catch((): null => null),
      location,
    })
  }
}

export function fetchDestinationImageId(placeId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.destination.fetchingDestinationImage[placeId] || state.destination.places[placeId]?.imageId) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_DESTINATION_IMAGE,
      request: () => getDestinationImageId(placeId).then(result => result),
      placeId,
    })
  }
}

interface PlaceByGeoCoordsOptions {
  level?: App.PlaceType;
  levels?: Array<App.PlaceType>;
}

export function fetchPlaceByGeoCoords(latitude: number, longitude: number, options?: PlaceByGeoCoordsOptions): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = `${latitude}-${longitude}`
    if (
      state.destination.placesLoading[key] ||
      state.destination.coordsToPlace[key] ||
      state.destination.coordsToPlace[key] === null
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_PLACE_BY_COORDS,
      request: async() => {
        if (options?.levels?.length) {
          for (const level of options.levels) {
            try {
              const result = await getPlaceByLatLong(latitude, longitude, level)
              return result
            } catch {
              // do nothing
            }
          }
        } else {
          return getPlaceByLatLong(latitude, longitude, options?.level).then(result => {
            if (!result.id) {
              return null
            }
            return result
          }).catch((): null => null)
        }
      },
      key,
    })
  }
}

export function fetchSpecificPlaces(placeId: string): AppAction {
  return async(dispatch, getState) => {
    const state = getState()

    const specificPlaces = state.destination.specificPlaces[placeId]
    const fetching = state.destination.fetchingSpecificPlaces[placeId]
    if (specificPlaces || fetching) {
      return
    }

    dispatch(({
      type: API_CALL,
      api: FETCH_SPECIFC_PLACES,
      request: async() => getSpecificPlaces(placeId),
      placeId,
    }))
  }
}
