import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import moment from 'moment'
import qs from 'qs'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'
import { pushWithRegion } from 'actions/NavigationActions'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import flightSearchWidgetStateReducer, { initialState, FlightSearchWidgetState, FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateProvider from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateProvider'
import { DISABLED_MULTI_CITY_REGION_CODES, FlightsClassTypes, FlightsFareTypes, fareCabins, fareTypes } from 'constants/flight'
import { FlightsCreditDetails } from 'components/Flights/types'
import { isFlightsCredit } from 'selectors/flightsSelectors'
import config from 'constants/config'
import FlightSearchWidgetDesktop from './FlightSearchWidgetDesktop'
import FlightSearchWidgetMobile from './FlightSearchWidgetMobile'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { fireInteractionEvent } from 'api/googleTagManager'
import { flightsFlightSearchClick } from 'analytics/eventDefinitions'
import * as Analytics from 'analytics/analytics'
import ModalProvider from 'contexts/ModalProvider'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import BusinessTravellerFlightSearchApprovalSearchModal from 'businessTraveller/components/checkout/flight-search-approval-modal/BusinessTravellerFlightSearchApprovalSearchModal'
import { getFlightSearchQueryparams, LastSearchSessionData, setFlightSessionRecentSearch } from 'lib/flights/flightUtils'
import { mapFlightSearchDataToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { SEARCH_VERTICALS } from 'constants/search'
import uuidV4 from 'lib/string/uuidV4Utils'
import GeoContext from 'contexts/geoContext'

export enum StandaloneFlightMenu {
  departAirport = 'departAirport',
  arrivalAirport = 'arrivalAirport',
  date = 'date',
  occupants = 'occupants'
}

export interface FlightSearchWidgetSearchData extends Partial<FlightSearchWidgetState>{
  filterAirlines?: Array<string>;
  lastSearch?: LastSearchSessionData;
  disableCalendarPricing?: boolean;
}

interface Props {
  className?: string;
  searchData?: FlightSearchWidgetSearchData;
  creditDetails?: FlightsCreditDetails;
  isFlightsCredit: boolean;
  pathname: string;
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
  showMultiCity?: boolean;
}

export function getFlightSearchWidgetRecentStateData(lastSearch?: LastSearchSessionData): Partial<FlightSearchWidgetState> {
  if (lastSearch?.fareType === FlightsFareTypes.MULTI_CITY) {
    return {}
  }

  return {
    ...(lastSearch?.fareType && { fareType: fareTypes.find(f => f.value === lastSearch.fareType)! }),
    ...(lastSearch?.fareClass && { fareCabin: fareCabins.find(f => f.value === lastSearch.fareClass)! }),
    ...(lastSearch?.occupants && { occupants: lastSearch.occupants }),
    ...((lastSearch?.flights?.length || 0) > 0 && {
      flights: lastSearch?.flights?.map(flight => {
        const checkinDate = flight?.departingDate ? moment(flight.departingDate) : undefined
        const checkoutDate = flight?.returningDate ? moment(flight.returningDate) : undefined
        const isValidDepartingDate = checkinDate?.isAfter(moment())

        return {
          id: uuidV4(),
          ...(checkinDate && isValidDepartingDate ? { checkinDate } : {}),
          ...(checkoutDate && isValidDepartingDate ? { checkoutDate } : {}),
          ...(flight?.departureAirport && { departureAirport: flight.departureAirport }),
          ...(flight?.arrivalAirport && { arrivalAirport: flight.arrivalAirport }),
        }
      }),
    }),
  }
}

function FlightSearchWidget(props: Props) {
  const dispatch = useAppDispatch()
  const {
    searchData,
    isFlightsCredit,
    creditDetails,
    className,
    pathname,
    readOnlyAirports,
    showMultiCity,
  } = props

  const loadPrefilledData = !pathname.includes('flights-search-results')

  const query = useQueryParams()
  const [showBusinessApprovalModal, setShowBusinessApprovalModal] = useState(false)
  const departingQuotedPrice = query.get('departingQuotedPrice')
  const returningQuotedPrice = query.get('returningQuotedPrice')
  const { currentRegionCode } = useContext(GeoContext)

  const [state, stateDispatch] = useReducer(flightSearchWidgetStateReducer, {
    ...initialState,
    ...searchData,
    ...(loadPrefilledData ? getFlightSearchWidgetRecentStateData(searchData?.lastSearch) : {}),
  })

  useEffect(() => {
    if (searchData) {
      stateDispatch({
        type: FlightSearchWidgetActions.UPDATE_FLIGHT_SEARCH_WIDGET_DATA,
        payload: {
          ...state,
          ...searchData,
          ...(loadPrefilledData ? getFlightSearchWidgetRecentStateData(searchData?.lastSearch) : {}),
        },
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData, loadPrefilledData])

  const {
    flights,
    occupants,
    fareType,
    fareCabin,
  } = state

  const proceedWithSearch = useCallback(() => {
    const transformedOccupants = countOccupantsForFlights([occupants])
    const isReturnFare = fareType?.value === FlightsFareTypes.RETURN
    let nextFareType = fareType?.value as FlightsFareTypes

    // If there is only one multi city flight it's one way
    if (nextFareType === FlightsFareTypes.MULTI_CITY && flights.length === 1) {
      nextFareType = FlightsFareTypes.ONE_WAY
    }

    flights.forEach(flight => {
      const departDate = moment(flight.checkinDate).format(ISO_DATE_FORMAT)
      const returnDate = isReturnFare ? moment(flight.checkoutDate).format(ISO_DATE_FORMAT) : null

      Analytics.trackEvent(searchEventWithContext(

        mapFlightSearchDataToSnowplowSearchEvent({
          searchVerticals: new Set([SEARCH_VERTICALS.FLIGHTS]),
          originAirportCode: flight.departureAirport?.airportCode,
          originAirportName: flight.departureAirport?.airportName,
          destinationAirportCode: flight.arrivalAirport?.airportCode,
          destinationAirportName: flight.arrivalAirport?.airportName,
          departDate,
          returnDate,
          occupancies: transformedOccupants,
          fareClass: fareCabin?.value,
          fareType: nextFareType,
        },
        ),
      ))
    })

    fireInteractionEvent(flightsFlightSearchClick())

    setFlightSessionRecentSearch({
      fareType: nextFareType,
      fareClass: fareCabin?.value,
      occupants,
      flights: flights.map(flight => {
        const departDate = moment(flight.checkinDate).format(ISO_DATE_FORMAT)
        const returnDate = isReturnFare ? moment(flight.checkoutDate).format(ISO_DATE_FORMAT) : null

        return {
          departingDate: departDate,
          returningDate: returnDate,
          departureAirport: flight.departureAirport,
          arrivalAirport: flight.arrivalAirport,
        }
      }),
    })

    const airportsChanged = flights.some((flight, index) => {
      const isDifferentDepartureAirport = flight.departureAirport !== searchData?.flights?.[index]?.departureAirport
      const isDifferentArrivalAirport = flight.arrivalAirport !== searchData?.flights?.[index]?.arrivalAirport
      return isDifferentDepartureAirport || isDifferentArrivalAirport
    })

    const params = {
      adults: transformedOccupants.adults,
      children: transformedOccupants.children,
      infants: transformedOccupants.infants,
      childrenAge: transformedOccupants.childrenAge.join(','),
      fareClass: fareCabin?.value,
      fareType: nextFareType,
      searchId: moment().unix(),
      flightsCredit: isFlightsCredit || undefined,
      carrier: isFlightsCredit ? creditDetails?.carrier : undefined,
      ...(!airportsChanged ? { filterAirlines: searchData?.filterAirlines?.join(',') } : {}),
    }

    const flightParams = getFlightSearchQueryparams(flights, nextFareType)

    if (nextFareType === FlightsFareTypes.MULTI_CITY) {
      dispatch(pushWithRegion('/flights-search-results-multi-city', qs.stringify({
        ...params,
        ...flightParams,
      })))

      return
    }

    dispatch(pushWithRegion('/flights-search-results', qs.stringify({
      ...params,
      ...flightParams,
      ...(fareCabin?.value === FlightsClassTypes.ECONOMY ? {
        departingQuotedPrice: airportsChanged ? flightParams.departingQuotedPrice : flightParams.departingQuotedPrice ?? departingQuotedPrice,
        returningQuotedPrice: airportsChanged ? flightParams.returningQuotedPrice : flightParams.returningQuotedPrice ?? returningQuotedPrice,
      } : {
        departingQuotedPrice: 0,
        returningQuotedPrice: 0,
      }),
    })))
  }, [occupants, fareType?.value, flights, fareCabin, isFlightsCredit, creditDetails?.carrier, searchData?.filterAirlines, searchData?.flights, dispatch, departingQuotedPrice, returningQuotedPrice])

  const onSearchAirport = useCallback((e?: any) => {
    e?.preventDefault()

    // Business traveller has confirmation modal before searching, non-LEBT still has normal behaviour
    if (config.businessTraveller.currentAccountMode === 'business' && config.BUSINESS_TRAVELLER_FLIGHTS_APPROVAL_ENABLED && query.get('approvalRequest')) {
      setShowBusinessApprovalModal(true)
    } else {
      proceedWithSearch()
    }
  }, [proceedWithSearch, query])

  const isMultiCityRegionEnabled = useMemo(() => {
    return !DISABLED_MULTI_CITY_REGION_CODES.includes(currentRegionCode)
  }, [currentRegionCode])

  const multiCityEnabled = showMultiCity && isMultiCityRegionEnabled

  return (
    <FlightSearchWidgetStateProvider state={state} dispatch={stateDispatch}>
      {config.businessTraveller.currentAccountMode === 'business' &&
      config.BUSINESS_TRAVELLER_FLIGHTS_APPROVAL_ENABLED &&
        <BusinessTravellerFlightSearchApprovalSearchModal isOpen={showBusinessApprovalModal} setIsOpen={setShowBusinessApprovalModal} proceed={proceedWithSearch}/>}
      <ModalProvider>
        <div className={className}>
          <CSSBreakpoint min="tablet">
            <FlightSearchWidgetDesktop
              onSearchAirport={onSearchAirport}
              readOnlyAirports={readOnlyAirports}
              showMultiCity={multiCityEnabled}
            />
          </CSSBreakpoint>
          <CSSBreakpoint max="mobile">
            <VerticalSpacer gap={12} as="form" onSubmit={onSearchAirport}>
              <FlightSearchWidgetMobile
                readOnlyAirports={readOnlyAirports}
                showMultiCity={multiCityEnabled}
              />
              <TextButton kind="primary" size="large" type="submit" fit="full-width">
                Search
              </TextButton>
            </VerticalSpacer>
          </CSSBreakpoint>
        </div>
      </ModalProvider>
    </FlightSearchWidgetStateProvider>
  )
}

function mapStateToProps(state: App.State) {
  return {
    creditDetails: state.flights.creditDetails,
    isFlightsCredit: isFlightsCredit(state),
    pathname: state.router.location.pathname,
  }
}

export default connect(mapStateToProps)(FlightSearchWidget)
