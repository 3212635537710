import config from 'constants/config'
import { LESCAPES_SIGNUP_DOMAIN } from 'constants/auth'
import request, { ObjectOptions } from 'api/requestUtils'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import * as Analytics from 'analytics/analytics'
import { baseContext } from 'analytics/snowplow/events'
import { reportClientError } from 'services/errorReportingService'
import { getMemberSubscription } from './luxPlus'
import { AppleLoginData, FacebookLoginData, GoogleLoginData } from 'actions/AuthActions'

interface PostAuthResponse {
  access_token: string;
  id_member: string;
  is_sign_up: boolean;
}

function mapAccount(serverAccount: AccountResponseResult, luxPlusMemberSubscription: App.MemberSubscription): App.AuthAccount {
  return {
    balance: 0,
    memberId: serverAccount.memberId,
    referralProgramEnabled: serverAccount.referral_program_enabled,
    givenName: serverAccount.givenName,
    surname: serverAccount.surname,
    title: serverAccount.title as App.Title ?? undefined,
    email: serverAccount.email,
    dob: serverAccount.dob,
    status: serverAccount.status,
    roles: serverAccount.roles,
    vendors: serverAccount.vendors,
    postcode: serverAccount.postcode,
    phonePrefix: serverAccount.phone_prefix,
    phone: serverAccount.phone,
    partnerships: serverAccount.partnerships ?? {},
    toggles: serverAccount.toggles ?? {},
    lastLogin: serverAccount.lastLogin ? {
      ipAddress: serverAccount.lastLogin.ip_address,
      loginDate: serverAccount.lastLogin.login_date,
    } : {},
    isSpoofed: !!serverAccount.isSpoofed,
    fullName: serverAccount.fullName,
    legacyId: serverAccount.legacy_id ?? undefined,
    legacyPlatform: serverAccount.legacy_platform ?? undefined,
    countryCode: serverAccount.country_code,
    signupDomain: serverAccount.signup_domain,
    personContactId: serverAccount.person_contact_id,
    tenant: serverAccount.tenant,
    numberOfPurchases: serverAccount.number_of_purchases,
    customerSupportCode: serverAccount.customer_support_code,
    recentlyUsedAirportCode: serverAccount.recently_used_airport_code ?? undefined,
    emailVerified: !!serverAccount.email_verified,
    shadowBanUser: serverAccount.shadow_ban_user,
    utmSource: serverAccount.utm_source,
    utmMedium: serverAccount.utm_medium,
    utmCampaign: serverAccount.utm_campaign,
    utmTerm: serverAccount.utm_term,
    utmContent: serverAccount.utm_content,
    utmAdgroup: serverAccount.utm_adgroup,
    creditsByCurrency: {},
    subscriberTier: serverAccount.subscriber_tier,
    // @ts-expect-error `add_phone_prompted_at` doesn't exist in the contract!
    addPhonePromptedAt: serverAccount.add_phone_prompted_at,
    luxPlus: {
      member: {
        /** This is used so spoofed customers can be toggled between buying as a member or as a non member */
        disableMembership: false,
        subscription: luxPlusMemberSubscription,
      },
    },
  }
}

function parseTrackAuthResponse(res: any) {
  const idMember = res?.result?.id_member
  const status = res?.status
  return { idMember, status }
}

async function authPost(
  path: string,
  data: any,
  options: ObjectOptions = {},
  trackingProvider: App.AuthSource,
  regionCode?: string,
  luxPlusEnabled?: boolean,
) {
  const res = await request.post<App.ApiResponse<PostAuthResponse>, any>(path, data, options)

  try {
    const { idMember, status } = parseTrackAuthResponse(res)

    if (path === '/register' || res.result?.is_sign_up) {
      Analytics.trackClientEvent({
        action: `${idMember}_${status}_succeeded`,
        subject: `${trackingProvider}_registration`,
        category: 'logging',
        type: 'operational',
      })
    } else {
      Analytics.trackClientEvent({
        action: `${idMember}_${status}_succeeded`,
        subject: `${trackingProvider}_auth`,
        category: 'logging',
        type: 'operational',
      })
    }
  } catch (e) {
    reportClientError(e)
  }

  const accessToken = res.result.access_token
  const isSignUp = !!res.result.is_sign_up
  const isSignIn = !isSignUp && path !== '/register'
  const accountResponse = await me(accessToken, regionCode, luxPlusEnabled)

  return {
    accessToken,
    account: accountResponse,
    isSignUp: path === '/register' || isSignUp,
    isSignIn,
  }
}

interface AccountResponseResult {
  givenName: string;
  title: string | null;
  surname: string;
  email: string;
  fullName: string;
  dob: string;
  id_member: string;
  memberId: string;
  legacy_id: string | null;
  legacy_platform: string | null;
  status: string;
  email_verified: boolean;
  roles: Array<string>;
  vendors: Array<string>;
  country_code: string;
  postcode: string;
  phone_prefix: string;
  phone: string;
  signup_domain: string;
  customer_support_code: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  utm_adgroup: string;
  updated_at: string;
  qff: string;
  qff_last_name: string;
  partnerships: App.PartnershipsMap;
  gdpr: string;
  created_date: string;
  recently_used_airport_code: string | null;
  person_contact_id: string;
  flights_enabled: boolean;
  toggles: Record<string, boolean | string | null>;
  referral_program_enabled: boolean;
  lastLogin: {
    ip_address: string;
    login_date: string
  };
  tenant: string;
  number_of_purchases: number;
  vip_client: boolean;
  le_platinum: boolean | null;
  subscriber_tier: 'base' | 'free_preview' | null;
  maskedPhone: string;
  isSpoofed?: boolean;
  shadow_ban_user?: boolean;
}

export function me(accessToken?: string, regionCode?: string, luxPlusEnabled?: boolean) {
  let options: ObjectOptions = { credentials: 'include' }
  if (accessToken) {
    options = { headers: { authorization: `Bearer ${accessToken}` } }
  }

  return request.get<App.ApiResponse<AccountResponseResult>>('/me', options).then(async response => {
    const luxPlusMemberSubscription: App.MemberSubscription = {
      item: undefined,
      error: undefined,
      fetching: false,
    }
    if (luxPlusEnabled && regionCode && response.result.memberId && response.result.subscriber_tier) {
      try {
        luxPlusMemberSubscription.item = await getMemberSubscription(response.result.memberId, regionCode, accessToken)
      }
      catch (e) {
        luxPlusMemberSubscription.error = e
      }
    }
    return mapAccount(response.result, luxPlusMemberSubscription)
  })
}

interface CheckEmailPayloadStructure {
  email?: string,
  phone_prefix?: string;
  phone?: string;
  recaptchaResponse: string,
}

interface CheckUserResult {
  isExist: boolean;
  maskedPhone?: string;
}

export async function checkUserExists(user:{ email?: string, phoneNumber?: App.PhoneNumber}, recaptchaData?: string | null) {
  return request.post<App.ApiResponse<CheckUserResult>, CheckEmailPayloadStructure>(
    '/api/users/exists',
    {
      email: user.email,
      // prefer email if it's provided
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      recaptchaResponse: recaptchaData ?? '',
    },
  )
}

export interface AuthAccountLoginResponse {
  accessToken: string;
  account: App.AuthAccount;
  business?: App.BusinessTraveller.Business;
  employee?: App.BusinessTraveller.Employee;
}

export function login(
  user: App.User,
  password: string,
  recaptchaData: string,
  regionCode?: string,
  luxPlusEnabled?: boolean,
): Promise<AuthAccountLoginResponse> {
  return authPost(
    '/login/recaptcha',
    {
      login: user.email,
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      password,
      recaptchaResponse: recaptchaData,
    },
    { credentials: 'include' },
    'email',
    regionCode,
    luxPlusEnabled,
  )
}

export function loginFacebook(data: FacebookLoginData, regionCode: string, luxPlusEnabled?: boolean) {
  return authPost(
    '/login/facebook',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'facebook',
    regionCode,
    luxPlusEnabled,
  )
}

export function loginGoogle(data: GoogleLoginData, regionCode: string, luxPlusEnabled?: boolean) {
  return authPost(
    '/login/google',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'google',
    regionCode,
    luxPlusEnabled,
  )
}

export function loginApple(data: AppleLoginData, regionCode: string, luxPlusEnabled?: boolean) {
  return authPost(
    '/login/apple',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'apple',
    regionCode,
    luxPlusEnabled,
  )
}

export function logout() {
  return request.post('/logout', {}, { credentials: 'include' })
}

export function getSingleUseToken() {
  return request.post<any, any>('/api/single-use-token', {}, { credentials: 'include' })
}

export interface AuthAccountRegistrationResponse {
  accessToken: string;
  account: App.AuthAccount;
}

interface RegisterData extends Partial<App.AuthAccount> {
  password: string;
  utmAdgroup?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
}

export function register(data: RegisterData, regionCode: string, includeCredentials = true): Promise<AuthAccountRegistrationResponse> {
  const { email, password, surname, givenName, utmSource, utmMedium, utmCampaign, utmTerm, utmAdgroup, utmContent, phone, phonePrefix, postcode } = data
  const signUpDomain = config.BRAND === 'luxuryescapes' ? LESCAPES_SIGNUP_DOMAIN : null
  const payload = {
    email,
    password,
    surname,
    givenName,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_adgroup: utmAdgroup,
    utm_content: utmContent,
    country_code: regionCode,
    ...(signUpDomain && { signup_domain: signUpDomain }),
    ...(phone && {
      phone_prefix: phonePrefix,
      phone,
    }),
    postcode,
  }
  return authPost(
    '/register',
    payload,
    includeCredentials ? { credentials: 'include' } : undefined,
    'email',
    regionCode,
  )
}

export function sendResetPasswordEmail(email: string, callbackPath?: string) {
  return request.post('/api/reset-password-email', { email, callbackPath }, { credentials: 'include' })
}

interface ResetPasswordParams {
  token: string;
  password: string;
  luxPlusEnabled?: boolean;
  regionCode?: string;
}

export function resetPassword({ token, password, luxPlusEnabled, regionCode }: ResetPasswordParams) {
  return request
    .put<App.ApiResponse<{ access_token: string }>, any>('/api/reset-token', { token, password }, { credentials: 'include' })
    .then(async response => ({
      accessToken: response.result.access_token,
      account: await me(response.result.access_token, regionCode, luxPlusEnabled),
    }))
}

function mapUserDetails(serverAccount: AccountResponseResult) {
  return {
    givenName: serverAccount.givenName,
    surname: serverAccount.surname,
    title: serverAccount.title,
    email: serverAccount.email,
    dob: serverAccount.dob,
    postcode: serverAccount.postcode,
    phonePrefix: serverAccount.phone_prefix,
    phone: serverAccount.phone,
    countryCode: serverAccount.country_code,
    recentlyUsedAirportCode: serverAccount.recently_used_airport_code,
  }
}

export function updateUserDetails(account: Partial<App.AuthAccount>, supportPhoneNumber: string | null = null) {
  const serverAccount = {
    givenName: account.givenName ?? undefined,
    surname: account.surname ?? undefined,
    title: account.title ?? undefined,
    dob: account.dob ?? undefined,
    country_code: account.countryCode ?? undefined,
    phone_prefix: account.phonePrefix ?? undefined,
    phone: account.phone ?? undefined,
    recently_used_airport_code: account.recentlyUsedAirportCode ?? undefined,
    postcode: account.postcode ?? undefined,
    support_phone_number: supportPhoneNumber ?? undefined,
  }

  return request.put<App.ApiResponse<AccountResponseResult>, unknown>('/api/users/current', serverAccount, { credentials: 'include' })
    .then(response => mapUserDetails(response.result))
}

export async function updateAddPhonePromptedAt(addPhonePromptedAt: Date) {
  const response = await request.post<App.ApiResponse<string>, unknown>('/api/users/toggles',
    {
      toggle_name: 'add_phone_prompted_at',
      toggle_value: addPhonePromptedAt.toISOString(),
    }, { credentials: 'include' })
  return response.result
}

export function updatePartnershipDetails(partnershipDetails: App.PartnershipsMap) {
  return request.put('/api/partnerships/current', partnershipDetails, { credentials: 'include' })
}

export function resendEmailConfirmation() {
  return request.get('/api/resend-email-confirmation', {
    credentials: 'include',
  })
}

const DO_NATIVE_AUTHENTICATE = 'doNativeAuthenticate'
export async function webViewAccountAccess(regionCode: string, luxPlusEnabled: boolean) {
  const authPromise = new Promise<string>((resolve, reject) => {
    window.userAuthenticated = (token: string) => {
      if (token) {
        resolve(token)
      } else {
        reject('Authentication cancelled')
      }
    }

    // Must remove region because mobile client already sets it
    const analyticsContext = baseContext().filter(c => {
      return !c.schema.includes('region')
    })
    appViewMessageHandler('authenticateUser', DO_NATIVE_AUTHENTICATE, analyticsContext)
  })
  try {
    const accessToken = await authPromise

    return {
      accessToken,
      account: await me(accessToken, regionCode, luxPlusEnabled),
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    // re-throw to indicate that the login failed
    throw error
  }
}

export interface ItemDetails {
  check_in?: string; // Standard hotel reservations
  start_date?: string; // Tours v1
  type?: string;
  journey?: { id: string } // Flights
  ticket?: { date?: string } // Experiences
  departure_date?: string; // Cruises
  session_id?: string; // Bedbank
  departure_id?: string; // Tours v2
}
export interface CheckRequireVerificationRequest {
  brand: string;
  regionCode: string;
  currencyCode: string;
  payments: {
    payments: Array<{ type: string }>
  };
  items: Array<ItemDetails>;
}

export interface CheckRequireVerificationResponse {
  verificationRequired: boolean;
}

export function checkRequireVerification(checkRequireVerificationRequest: CheckRequireVerificationRequest): Promise<CheckRequireVerificationResponse> {
  // Not all order creation need otp, use this to check if otp header is required for order creation.
  const { brand, regionCode, currencyCode, payments, items } = checkRequireVerificationRequest
  return request.post('/api/orders/require_verification', {
    brand,
    region_code: regionCode,
    payments,
    items,
    currency_code: currencyCode,
  }, { credentials: 'include' })
}

export function sendVerificationCode(): Promise<void> {
  // Send OTP via email
  return request.post('/api/verify/send-auth-code?without_brand=1', {}, { credentials: 'include' })
}

export interface VerifyAuthCodeResponse {
  result: { message: string, token: string }
}

export function verifyAuthCode(code: string): Promise<string> {
  // Use to check if OTP is expired or invalid
  return request.post('/api/verify/verify-auth-code?without_brand=1', { code }, { credentials: 'include' })
    .then(res => (res as VerifyAuthCodeResponse).result.token)
}

export function sendOTPVerificationCode(user: App.User) {
  return request.post('/login/send-sms-otp', {
    email: user.email,
    phone: user.email ? undefined : user.phoneNumber?.phone,
    phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
    brand: config.BRAND,
  }, { credentials: 'include' })
}

export function validateOTPVerificationCode(user: App.User, code: string, regionCode?: string, luxPlusEnabled?: boolean) {
  return authPost(
    '/login/login-sms-otp',
    {
      email: user.email,
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      code,
      brand: config.BRAND,
    },
    { credentials: 'include' },
    'otp',
    regionCode,
    luxPlusEnabled,
  )
}

interface PhoneNumberResponse {
  phone: string | null;
  prefix: string | null;
}

export function resendEmailChangeConfirmationCode(email: string, password?: string): Promise<{status: number, message: string, error: any}> {
  return request.post<{status: number, message: string, error: string}, {email: string, password?: string}>('/api/auth/email-change/resend-email-change-confirmation-code', { email, password }, { credentials: 'include' })
}

export function validateEmailChangeConfirmationPhoneCode(code: string, email: string): Promise<{status: number, message: string, error: any}> {
  return request.post<{status: number, message: string, error: string}, {code: string, email: string}>('/api/auth/email-change/validate-email-change-confirmation-phone', { code, email }, { credentials: 'include' })
}

export function checkEmailChangePossibility(): Promise<{ email_changeable: boolean, step: string, new_email: string, error?: any }> {
  return request.post<{ step: string, status: number, email_changeable: boolean, new_email: string, error?: any }, {}>('/api/auth/email-change/initialize', {}, { credentials: 'include' })
}

export function sendEmailChangeConfirmationCode(email: string, password?: string): Promise<{status: number, message: string, error: any}> {
  return request.post<{status: number, message: string, error: string}, {email: string, password?: string}>('/api/auth/email-change/send-email-change-confirmation-code', { email, password }, { credentials: 'include' })
}

export function validateEmailChangeConfirmationCode(code: string, email: string, password?: string, recaptchaResponse?: string): Promise<{status?: number, message?: string, loginData?: any, result?: any, account?: any}> {
  return request.post<{status?: number, message?: string, loginData?: any, result?: any, account?: App.User}, {code: string, email: string, password?: string, recaptchaResponse?: string}>('/api/auth/email-change/validate-email-change-confirmation', { code, email, password, recaptchaResponse }, { credentials: 'include' })
}

export function initializePhoneChange(): Promise<{ step: string, error: string, phone_number: PhoneNumberResponse }> {
  return request.post<{ step: string, error: string, phone_number: PhoneNumberResponse }, {} >('/api/auth/phone-change/initialize', {}, { credentials: 'include' })
}

export function sendPhoneChangeConfirmationCode(phoneNumber: App.PhoneNumber, password?: string, resend?: boolean): Promise<{status: number, message: string, error: any}> {
  return request.post<{status: number; message: string; error: string;}, {phone_number: App.PhoneNumber, password?: string, resend?: boolean}>('/api/auth/phone-change/send-confirmation-code', { phone_number: phoneNumber, password, resend }, { credentials: 'include' })
}

export function validatePhoneChangeConfirmationCode(code: string, phoneNumber: App.PhoneNumber): Promise<{status?: number, message?: string, phone_number: App.PhoneNumber}> {
  return request.post<{status: number, message: string, phone_number: App.PhoneNumber}, {code: string, phone_number: App.PhoneNumber}>('/api/auth/phone-change/validate-confirmation-code', { code, phone_number: phoneNumber }, { credentials: 'include' })
}
