import { MessageSenders } from 'api/supportAssistant'
import { ITEM_STATUS_CANCELLED, ITEM_STATUS_AWAITING_DATES } from 'constants/cart'
import { isBedbankOffer, isLEHotel } from 'lib/offer/offerTypes'
import { canAddInsuranceToOrder } from 'lib/order/orderUtils'
import { canCancelOrderItem, getFreeCancellationPeriodEndDate, canChangeHotelDates, canChangeHotelRooms, getHotelItem, getBedbankItem } from './orderUtils'

export const isSenderSystem = (sender: App.ChatMessageSender) => sender === 'system'

export const isSenderUser = (sender: App.ChatMessageSender) => sender === 'user'

export const isSenderLE = (sender: App.ChatMessageSender) => sender === 'agent' || sender === 'chatbot'

export interface BuildChatMessageParams {
  chatMessageMeta?: App.ChatMessageMeta;
  currentChatId: string | undefined;
  account?: App.AuthAccount,
  customerId?: string;
  hasUpcomingBooking: boolean;
  canViewLuxPlusBenefits: boolean;
  luxPlusContact: {
    number: string | undefined;
    numberHumanReadable: string | undefined;
  }
  message: string;
  isCurrentOrder?: boolean;
  orderBookingNumbers?: Array<App.OrderBookingNumber>,
}

// TODO: WRITE UNIT TESTS
export function buildChatMessagePayload(params: BuildChatMessageParams): Partial<App.ChatMessage> {
  const {
    chatMessageMeta,
    currentChatId,
    account,
    customerId,
    hasUpcomingBooking,
    canViewLuxPlusBenefits,
    luxPlusContact,
    message,
    isCurrentOrder,
    orderBookingNumbers,
  } = params

  const trimmedMessage = message.trim()

  const messageMeta = chatMessageMeta ? {
    action: chatMessageMeta.action,
    payload: {
      ...chatMessageMeta.payload,
      chatId: currentChatId,
      customerId,
      email: chatMessageMeta.payload?.email ?? account?.email,
      phone: chatMessageMeta.payload?.phone ?? account?.phone,
      firstName: chatMessageMeta.payload?.firstName ?? account?.givenName,
      lastName: chatMessageMeta.payload?.lastName ?? account?.surname,
      hasUpcomingBooking,
      isLuxPlusMember: canViewLuxPlusBenefits,
      luxPlusContact,
      isCurrentOrder: isCurrentOrder || chatMessageMeta.payload?.isCurrentOrder,
      orderBookingNumbers,
    },
  } : {
    payload: { customerId },
  }

  return {
    text: trimmedMessage,
    chatId: currentChatId,
    customer: {
      countryCode: account?.phonePrefix,
      email: account?.email,
      phone: account?.phone,
      firstName: account?.givenName,
      lastName: account?.surname,
    },
    meta: messageMeta,
    sender: MessageSenders.USER,
  }
}

export const buildChatMessageMetaPayload = (order: App.Order, item: App.OrderItem | App.OrderBedbankItem, offer: App.BedbankOffer | App.Offer, options: Partial<App.ChatMessageMetaPayload>): App.ChatMessageMetaPayload => {
  const hasFlight = !!order.hasFlight
  const hasTransfers = !!order.transferItems.filter(transfer => transfer.status !== ITEM_STATUS_CANCELLED).length
  const hasCarHire = !!order.carHireItems.filter(transfer => transfer.status !== ITEM_STATUS_CANCELLED).length
  const canUpsellInsurance = !order.insuranceItems.length && canAddInsuranceToOrder(order, order.regionCode, offer)

  const awaitingDates = item.status === ITEM_STATUS_AWAITING_DATES

  const payload: App.ChatMessageMetaPayload = {
    awaitingDates,
    bookingNumber: item.bookingNumber,
    canBeCancelled: false,
    canCarHireBeCancelled: false,
    canChangeDates: false,
    canRequestChangeDates: false,
    canChangeRooms: false,
    canExtendDates: false,
    customerId: order.customerId,
    daysBeforeCheckInChangesDisallowed: 0,
    hasFlight,
    hasTransfers,
    hasCarHire,
    canUpsellInsurance,
    id_orders: order.id,
    itemId: item.id,
    orderType: undefined,
    ...options,
  }

  if (isLEHotel(offer)) {
    const hotelItem = getHotelItem(order, item.id)
    payload.canBeCancelled = canCancelOrderItem(order, hotelItem)
    payload.freeCancellationPeriodEndDate = getFreeCancellationPeriodEndDate(order, hotelItem) ?? ''
    payload.canChangeDates = canChangeHotelDates(order, offer)
    payload.canChangeRooms = canChangeHotelRooms(order, offer, order.items)
    payload.daysBeforeCheckInChangesDisallowed = offer?.daysBeforeCheckInChangesDisallowed ?? 0
    payload.orderType = 'hotel'
  } else if (isBedbankOffer(offer)) {
    const bedbankItem = getBedbankItem(order)
    const cancellationConfig = bedbankItem.rooms[0]?.cancellationConfig
    payload.canBeCancelled = cancellationConfig.policyType !== 'non-refundable' && cancellationConfig.canRefund
    payload.orderType = 'bedbank'
  }

  return payload
}
