import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'
import { capitaliseAll, capitalise } from 'lib/string/stringUtils'
import { OFFER_TYPE_CRUISE } from 'constants/offer'
import { filterDataByHighestPercentage } from 'lib/cruises/cruiseUtils'
import { dateDifference } from 'lib/datetime/dateUtils'
import { formatRoundedAmount } from 'lib/format/formatCurrencyIntl'
import { cruiseInclusionsMap, cruiseBookingLuxPlusInclusionsMap } from './cruiseInclusionMap'
import { CRUISE_LINE_CUSTOM_INFO } from 'constants/cruise'
import { CruiseAPI } from 'api/cruises'
import moment from 'moment'
import config from 'constants/config'
import uuidV4 from 'lib/string/uuidV4Utils'
import { arrayToObject, min } from 'lib/array/arrayUtils'

export function formatOfferName(name: string, displayName?: string): string {
  // It must be formatted because the name came from the provider
  if (!displayName) return capitaliseAll(name.toLowerCase())
  // Must be formatted because displayName is the same as name
  else if (displayName === name) return capitaliseAll(displayName.toLowerCase())

  // Do not format because this value comes from the Admin-Portal and is already formatted
  return displayName
}

export function cruisesMap(cruises: Array<CruisesContract.OfferSummary>): Array<App.CruiseOfferSummary> {
  return cruises.map((cruise) => {
    const ship = cruisesMapShip(cruise.ship)
    const cruiseLineCustomInfo = CRUISE_LINE_CUSTOM_INFO.find((info) => info.code === cruise.cruiseLine.code)

    return {
      id: cruise.id,
      type: OFFER_TYPE_CRUISE,
      cruiseLine: {
        name: cruiseLineCustomInfo?.name ?? cruise.cruiseLine.name,
        imageId: cruise.cruiseLine.imageId,
        code: cruise.cruiseLine.code,
      },
      disableDeposits: !config.DEPOSITS_ENABLED || cruise.disableDeposits,
      duration: cruise.duration,
      leExclusive: cruise.leExclusive,
      heroImageId: cruise.heroImageId,
      name: formatOfferName(cruise.name, cruise.displayName),
      departureDates: cruise.departureDates,
      departurePort: cruise.departurePort.name,
      returnPort: cruise.returnPort.name,
      ship,
      images: ship.images,
      lowestDeparturePrice: cruise.lowestDeparturePrice,
      description: cruise.leVisibleDescription,
      destinationDescription: cruise.destinationDescription,
      itinerary: cruise.itineraries?.map(cruiseItineraryItemMap).sort((a, b) => a.startDay - b.startDay),
    }
  })
}

export function cruiseLineMap(cruiseLine: CruisesContract.CruiseLine): App.CruiseLine {
  const customInfo = CRUISE_LINE_CUSTOM_INFO.find(({ code }) => code === cruiseLine.code)

  return {
    id: cruiseLine.id,
    name: customInfo?.name ?? cruiseLine.name,
    code: cruiseLine.code,
    imageId: cruiseLine.imageId,
    description: cruiseLine.description,
    lpHeroImageId: cruiseLine.lpHeroImageId,
    lpMobileHeroImageId: cruiseLine.lpMobileHeroImageId,
    lpEnabled: cruiseLine.lpEnabled,
    slug: cruiseLine.slug,
  }
}

export function getCruiseProductType(
  cruise: CruisesContract.Offer | CruisesContract.OfferSummaryResponse,
  mainDepartureDetails: App.CruiseDepartureDetails,
): App.CruiseProductType {
  const hasLuxExclusiveInclusions = mainDepartureDetails.standardInclusions?.some(({ type }) => type === 'LUX_EXCLUSIVE')

  if (cruise.leExclusive || hasLuxExclusiveInclusions) {
    return 'cruise-exclusive'
  }
  else if (cruise.luxPremiumCollection) {
    return 'cruise-tactical'
  }
  else {
    return 'cruise'
  }
}

export function buildPromotionDetails(
  promotion: CruisesContract.Promotion,
  regionCode: string,
): App.CruisePromotionDetails {
  const formatDeposit = (deposit: CruisesContract.PromotionDeposit): string => {
    if (deposit.type === 'PERCENTAGE') return `${deposit.amount}%`
    return formatRoundedAmount(deposit.amount, deposit.currencyCode, regionCode)
  }

  const formatOBC = (obc: CruisesContract.PromotionOnBoardCredit): string => {
    return formatRoundedAmount(obc.amount, obc.currencyCode, regionCode)
  }

  return {
    leExclusive: promotion.leExclusive,
    sellingPoints: promotion.sellingPoints,
    startDate: promotion.startDate,
    endDate: promotion.endDate,
    isNew: dateDifference(new Date(), new Date(promotion.startDate)).days < 7,
    isEndingIn: dateDifference(new Date(promotion.endDate), new Date()).days,
    deposit: (config.DEPOSITS_ENABLED && promotion.deposit) ? {
      type: promotion.deposit.type,
      amount: promotion.deposit.amount,
      currencyCode: promotion.deposit.currencyCode,
      formattedValue: formatDeposit(promotion.deposit),
    } : null,
    onBoardCredit: promotion.onBoardCredit ? {
      amount: promotion.onBoardCredit.amount,
      currencyCode: promotion.onBoardCredit.currencyCode,
      formattedValue: formatOBC(promotion.onBoardCredit),
    } : null,
  }
}

type PriceDetailsParams = {
  currencyCode?: string
  isTaxIncluded?: boolean,
  isNccfIncluded?: boolean,
} & CruisesContract.LowestPriceWithCategory

export function buildPriceDetails(
  price: PriceDetailsParams,
  regionCode: string,
  promotionsByRateCode?: CruisesContract.PromotionByRateCode,
): App.CruisePriceDetails {
  let promotion: App.CruisePromotionDetails | null = null

  if (promotionsByRateCode && price.rateCode && promotionsByRateCode[price.rateCode]) {
    promotion = buildPromotionDetails(promotionsByRateCode[price.rateCode], regionCode)
  }

  return {
    total: Math.round(price.total),
    value: price.value ?? Math.round(price.total * 2),
    adultFare: Math.round(price.adultFare),
    cabinCategory: price.cabinCategory,
    isTaxIncluded: !!price.isTaxIncluded,
    isNccfIncluded: !!price.isNccfIncluded,
    rateCode: price.rateCode ?? null,
    currencyCode: price.currencyCode ?? null,
    promotion,
    // WE JUST HAVE DISCOUNT PILLS FOR VALUES COMING FROM CACHE
    discountPills: price.brochurePrice && price.brochureDiscountPrice && price.brochureDiscountPercentage ? {
      price: Math.round(price.brochurePrice),
      discountPrice: Math.round(price.brochureDiscountPrice),
      discountPercentage: price.brochureDiscountPercentage,
    } : null,
  }
}

export function buildLowestDeparturePricesByCategory(
  lowestDeparturePricesByCategory: CruisesContract.LowestDeparturePricesByCategory,
  regionCode: string,
  promotionsByRateCode?: CruisesContract.PromotionByRateCode,
): App.LowestCabinDetailsByDepartureId {
  const lowestCabinDetailsByDeparture: App.LowestCabinDetailsByDepartureId = {}

  Object.entries(lowestDeparturePricesByCategory).forEach(([id, lowestPricesByCategory]) => {
    Object.entries(lowestPricesByCategory).forEach(([_, price]) => {
      const category = price.cabinCategory
      const priceDetails = buildPriceDetails(price, regionCode, promotionsByRateCode)
      lowestCabinDetailsByDeparture[id] = {
        ...lowestCabinDetailsByDeparture[id],
        [category]: priceDetails,
      }
    })
  })

  return lowestCabinDetailsByDeparture
}

type LowestCabinsDetailsByDepartureWithId = {
  lowestDepartureId: string
  lowestPriceDetailsByCategory: App.LowestPriceDetailsByCategory
}

export function getLowestCabinsDetailsByDeparture(
  lowestCabinDetailsByDeparture: App.LowestCabinDetailsByDepartureId,
): LowestCabinsDetailsByDepartureWithId {
  let lowestDepartureId: string = ''
  const lowestPriceDetailsByCategory: App.LowestPriceDetailsByCategory = {}

  Object.entries(lowestCabinDetailsByDeparture).forEach(([id, lowestPricesByCategory]) => {
    Object.values(lowestPricesByCategory).forEach((price: App.CruisePriceDetails) => {
      const category = price.cabinCategory
      const lowestPrice = lowestPriceDetailsByCategory[category]

      if (!lowestPrice) {
        lowestDepartureId = id
        lowestPriceDetailsByCategory[category] = price
      }
      else if (price.total < lowestPrice.total) {
        lowestDepartureId = id
        lowestPriceDetailsByCategory[category] = price
      }
    })
  })

  return { lowestDepartureId, lowestPriceDetailsByCategory }
}

export function getLowestOverallPriceDetails(
  lowestPriceDetailsByCategory: App.LowestPriceDetailsByCategory,
): App.CruisePriceDetails {
  return Object.values(lowestPriceDetailsByCategory).reduce(
    (acc: App.CruisePriceDetails, cur: App.CruisePriceDetails) => {
      if (!acc) return cur
      return acc.total < cur.total ? acc : cur
    }, {} as App.CruisePriceDetails)
}

type CruiseDepartureDetailsParams = {
  duration: number
  lowestDeparturePricesByCategory: CruisesContract.LowestDeparturePricesByCategory
  mainDepartureId?: string
  departuresByMonth?: CruisesContract.DeparturesByMonth
  promotionsByRateCode?: CruisesContract.PromotionByRateCode
  inclusions?: Array<App.Cruises.OfferInclusion>
}

export function buildMainDepartureDetails(params: CruiseDepartureDetailsParams, regionCode: string): App.CruiseDepartureDetails {
  const { mainDepartureId, lowestDeparturePricesByCategory, departuresByMonth } = params

  const lowestCabinDetailsByDeparture = buildLowestDeparturePricesByCategory(
    lowestDeparturePricesByCategory, regionCode, params.promotionsByRateCode)

  let departureId: string | null = mainDepartureId ?? null
  let lowestPriceDetailsByCategory: App.LowestPriceDetailsByCategory = {}

  /** IF NOT DEPARTURE ID, USE THE
   * LOWEST PRICE FROM ALL DEPARTURES */
  if (!departureId || !lowestCabinDetailsByDeparture[departureId]) {
    const result = getLowestCabinsDetailsByDeparture(lowestCabinDetailsByDeparture)
    departureId = result.lowestDepartureId
    lowestPriceDetailsByCategory = result.lowestPriceDetailsByCategory
  } else lowestPriceDetailsByCategory = lowestCabinDetailsByDeparture[departureId]

  const lowestOverallPriceDetails = getLowestOverallPriceDetails(lowestPriceDetailsByCategory)

  const departureExtraInfo: CruisesContract.Departure | null = Object.entries(departuresByMonth || {})
    .flatMap(([_, departures]) => departures).find((departure) => departure.id === departureId) ?? null

  const { luxPlusInclusionsByTier, standardInclusions, inclusionDetails } = cruiseInclusionsMap(
    params.inclusions ?? [],
    departureId,
    lowestOverallPriceDetails.rateCode!,
    lowestOverallPriceDetails.total,
    regionCode,
    true,
  )

  return {
    id: departureId,
    lowestOverallPriceDetails,
    lowestPriceDetailsByCategory,
    departureDate: departureExtraInfo?.departureDate ?? null,
    externalId: departureExtraInfo?.externalId ?? null,
    arrivalDate: departureExtraInfo?.arrivalDate ?? null,
    luxPlusInclusionsByTier,
    standardInclusions,
    inclusionDetails,
  }
}

export function convertDepartureToLowestDeparturePricesByCategory(
  departure: CruisesContract.Departure,
): CruisesContract.LowestDeparturePricesByCategory | null {
  return departure.prices?.reduce((acc, cur) => ({
    ...acc,
    [cur.cabinCategory]: {
      ...cur,
      total: cur.total,
      rateCode: cur.rateCode,
      adultFare: cur.adultFare,
      currencyCode: cur.currencyCode,
      cabinCategory: cur.cabinCategory,
      isTaxIncluded: cur.isTaxIncluded,
      isNccfIncluded: cur.isNccfIncluded,
      value: Math.round(cur.total * 2),
    },
  }), {}) ?? null
}

type LowestDepartureDetailsByIdParams = {
  duration: number
  departure: CruisesContract.Departure
  lowestDeparturePricesByCategory: CruisesContract.LowestDeparturePricesByCategory
  promotionsByRateCode?: CruisesContract.PromotionByRateCode
  inclusions?: Array<App.Cruises.OfferInclusion>
}

export function buildLowestDepartureDetailsById(
  params: LowestDepartureDetailsByIdParams,
  regionCode: string,
): App.CruiseDepartureDetails {
  const { departure, lowestDeparturePricesByCategory } = params
  let lowestPriceDetailsByCategory: App.LowestPriceDetailsByCategory = {}

  if (!lowestDeparturePricesByCategory[departure.id]) {
    const departureByCategories = convertDepartureToLowestDeparturePricesByCategory(departure)
    const lowestCabinDetailsByDeparture = buildLowestDeparturePricesByCategory(departureByCategories ?? {}, regionCode, params.promotionsByRateCode)
    lowestPriceDetailsByCategory = getLowestCabinsDetailsByDeparture(lowestCabinDetailsByDeparture).lowestPriceDetailsByCategory
  } else {
    const lowestCabinDetailsByDeparture = buildLowestDeparturePricesByCategory(lowestDeparturePricesByCategory, regionCode, params.promotionsByRateCode)
    lowestPriceDetailsByCategory = lowestCabinDetailsByDeparture[departure.id]
  }

  const lowestOverallPriceDetails = getLowestOverallPriceDetails(lowestPriceDetailsByCategory)

  const { luxPlusInclusionsByTier, standardInclusions, inclusionDetails } = cruiseInclusionsMap(
    params.inclusions ?? [],
    departure.id,
    lowestOverallPriceDetails.rateCode!,
    lowestOverallPriceDetails.total,
    regionCode,
  )

  return {
    id: departure.id,
    lowestOverallPriceDetails,
    lowestPriceDetailsByCategory,
    departureDate: departure.departureDate ?? null,
    externalId: departure.externalId ?? null,
    arrivalDate: departure.arrivalDate ?? null,
    luxPlusInclusionsByTier,
    standardInclusions,
    inclusionDetails,
  }
}

export function getLowestOverallPriceWithPromotion(
  lowestDeparturePricesByCategory: CruisesContract.LowestDeparturePricesByCategory,
): CruisesContract.Promotion | undefined {
  const allPrices: Array<CruisesContract.LowestPriceWithCategory> = Object.values(lowestDeparturePricesByCategory)
    .map(pricesByCategory => Object.values(pricesByCategory))
    .flat()

  const lowestPriceWithPromotion = min(
    allPrices,
    (price) => price.promotion ? price.total : Infinity,
  )

  return lowestPriceWithPromotion?.promotion ?? undefined
}

export function getDepartureWithLowestPriceByRateCode(
  departuresByMonth: CruisesContract.DeparturesByMonth,
  targetRateCode: string,
): CruisesContract.Departure | null {
  const filteredDepartures = Object.values(departuresByMonth)
    .flatMap(month => month)
    .filter(departure => departure.prices?.some(price => price.rateCode === targetRateCode))

  if (filteredDepartures.length === 0) {
    return null
  }
  const lowestDeparture = filteredDepartures.reduce((minDeparture, departure) => {
    const lowestPriceInDeparture = (departure.prices || []).reduce(
      (minPrice, price) => (price.total < minPrice ? price.total : minPrice),
      Infinity,
    )

    if (lowestPriceInDeparture < minDeparture.price) {
      return { price: lowestPriceInDeparture, departure }
    }

    return minDeparture
  }, { price: Infinity, departure: null as CruisesContract.Departure | null })
  return lowestDeparture.departure
}

export function mapUrgencyTags() {
  // While this does nothing now, there are times we need to manaully add urgency tags to the
  // offer - such as the Best of the Decade 2023 campaign. Keep this method around to support
  // future extra mappings
  const tags: Array<string> = []
  return tags
}

export function cruiseOfferMap(cruise: CruisesContract.Offer, regionCode: string): App.CruiseOffer {
  const ship = cruisesMapShip(cruise.ship)
  const { leadDepartureId, lowestDeparturePricesByCategory, departures, promotionsByRateCode } = cruise
  const offerInclusions = cruise.inclusions as Array<App.Cruises.OfferInclusion>

  const departuresByMonth = Object.keys(departures ?? {})
    .reduce((acc, cur) => ({
      ...acc,
      [cur]: departures?.[cur]?.map(
        (item) => {
          return buildLowestDepartureDetailsById({
            departure: item,
            duration: cruise.duration,
            lowestDeparturePricesByCategory,
            ...(promotionsByRateCode && { promotionsByRateCode }),
            ...(offerInclusions && { inclusions: offerInclusions }),
          }, regionCode)
        }),
    }), {})

  const mainDepartureDetails: App.CruiseDepartureDetails = buildMainDepartureDetails({
    duration: cruise.duration,
    lowestDeparturePricesByCategory,
    ...(departures && { departuresByMonth: departures }),
    ...(promotionsByRateCode && { promotionsByRateCode }),
    ...(leadDepartureId && { mainDepartureId: leadDepartureId }),
    ...(offerInclusions && { inclusions: offerInclusions }),
  }, regionCode)

  const hasPromotions = !!Object.keys(promotionsByRateCode).length
  let fallbackPromotion: App.CruisePromotionDetails | null = null
  const lowestPriceWithPromotion = getLowestOverallPriceWithPromotion(lowestDeparturePricesByCategory)
  if (hasPromotions) {
    if (!lowestPriceWithPromotion) {
      const rateCodesWithPromotions = Object.keys(promotionsByRateCode)
      if (departures) {
        const lowestRateCode = rateCodesWithPromotions.reduce((lowestRate, rateCode) => {
          const departure = getDepartureWithLowestPriceByRateCode(departures, rateCode)
          if (!departure) return lowestRate

          const lowestPriceInDeparture = (departure.prices || []).reduce(
            (minPrice, price) => Math.min(minPrice, price.total),
            Infinity,
          )

          if (lowestPriceInDeparture < lowestRate.lowestPrice) {
            return { rateCode, lowestPrice: lowestPriceInDeparture }
          }

          return lowestRate
        }, { rateCode: rateCodesWithPromotions[0], lowestPrice: Infinity })
        fallbackPromotion = buildPromotionDetails(promotionsByRateCode[lowestRateCode.rateCode], regionCode)
      }
    } else {
      fallbackPromotion = buildPromotionDetails(lowestPriceWithPromotion, regionCode)
    }
  }

  const cruiseLineInfo = CRUISE_LINE_CUSTOM_INFO.find((info) => info.code === cruise.cruiseLine.code)
  const hasMemberInclusions = !!mainDepartureDetails.luxPlusInclusionsByTier

  return {
    id: cruise.id,
    productType: getCruiseProductType(cruise, mainDepartureDetails),
    cruiseLine: {
      id: cruise.cruiseLine.id,
      name: cruiseLineInfo?.name ?? cruise.cruiseLine.name,
      code: cruise.cruiseLine.code,
      imageId: cruise.cruiseLine.imageId,
      vendorInfo: cruise.cruiseLine.vendorInfo?.map<App.CruiseVendorInfo>((serverVendorInfo) => {
        return {
          dataType: serverVendorInfo.dataType,
          vendorName: serverVendorInfo.vendorName ?? '',
          title: serverVendorInfo.title ?? '',
          text: serverVendorInfo.text ?? '',
          vendorId: serverVendorInfo.vendorId,
        }
      }),
    },

    duration: cruise.duration,
    disableDeposits: !config.DEPOSITS_ENABLED || cruise.disableDeposits,
    heroImageId: cruise.heroImageId,
    name: formatOfferName(cruise.name, cruise.displayName),
    departureDates: cruise.departureDates,
    departurePort: cruise.departurePort.name,
    showPricePerNight: cruise.showPricePerNight,
    returnPort: cruise.returnPort.name,
    ship,
    slug: cruise.id,
    type: OFFER_TYPE_CRUISE,
    parentType: OFFER_TYPE_CRUISE,
    analyticsType: OFFER_TYPE_CRUISE,
    images: cruise?.images?.map(cruisesMapOfferImages) ?? [],
    faq: [].map(cruiseFaqMap), // TODO: Implement faq for cruises
    finePrint: cruiseFinePrintMap(), // TODO: Implement finePrint for cruises
    departures: departuresByMonth,
    itinerary: cruise.itineraries?.map(cruiseItineraryItemMap).sort((a, b) => a.startDay - b.startDay),
    destinationDescription: cruise.destinationDescription,
    description: cruise.leVisibleDescription,
    // these types are not correct from server, cannot work for now. Any'ing them
    lowestDeparturePricesByCategory: cruise.lowestDeparturePricesByCategory as any,
    lowestDeparturePrice: cruise.lowestDeparturePrice as any, /** @deprecated - use mainDepartureDetails.lowestOverallPriceDetails instead */
    mainDepartureDetails,
    hasPromotions,
    fallbackPromotion,
    evergreenInclusions: cruise.evergreenInclusions?.map<App.EvergreenInclusion>((serverEvergreenInclusion) => {
      return {
        id: uuidV4(),
        description: serverEvergreenInclusion.inclusion,
        cabinCategories: serverEvergreenInclusion.cabinCategories,
        cabinTypes: serverEvergreenInclusion.cabinTypes,
        shipId: serverEvergreenInclusion.shipId,
      }
    }) ?? [],
    urgencyTags: mapUrgencyTags(),
    luxPlus: {
      hasMemberInclusions,
    },
    offerInclusions,
  }
}

export function cruiseLowestPriceByMonthMap(
  prices: Array<CruisesContract.LowestPriceByMonth>,
  fromDate: string,
): Array<App.CruiseLowestPriceByMonth> {
  return prices.map((price): App.CruiseLowestPriceByMonth => ({
    year: moment(fromDate).year(),
    month: price.month,
    totalFare: Math.ceil(price.totalFare),
  }))
}

export function cruiseOrderItemMap(orderItem: any): App.CruiseOrderItem {
  return {
    sessionId: orderItem.session_id,
    bookingId: orderItem.booking_id,
    departureId: orderItem.departure_id,
    departureDate: orderItem.departure_date,
    arrivalDate: orderItem.arrival_date,
    itemId: orderItem.id,
    orderId: orderItem.order_id,
    reservationId: orderItem.reservation_id,
    status: orderItem.status,
    total: orderItem.total,
    cruiseOfferId: orderItem.cruiseOffer_id || orderItem.offer_id,
    transactionKey: orderItem.transactionKey,
    passengers: (orderItem.passengers ?? []).map(cruiseOrderItemTravellerMap),
    offerName: orderItem.offer_name,
  }
}

export function cruiseOrderItemTravellerMap(traveller) {
  return {
    firstName: traveller.first_name,
    lastName: traveller.last_name,
    type: traveller.type,
    isLeadPassenger: traveller.is_lead_passenger,
  }
}

const cruisesMapShip = (ship: CruisesContract.Ship): App.CruiseShip => ({
  id: ship.id,
  name: ship.name,
  images: ship.images ? ship.images.map(cruisesMapShipImages) : [],
  shipInfo: ship.shipInfo?.map(cruisesMapShipInfo) ?? [],
  ...(ship.decks?.length && { decks: ship.decks?.map(cruisesShipDeckMap) }),
  ...(ship.cabinCategories?.length && { cabinCategories: ship.cabinCategories?.map(cruisesMapCabinCategories) }),
  occupancies: {
    ...(!!ship.occupancies?.maxOccupancy && { maxOccupancy: ship.occupancies.maxOccupancy }),
    ...(!!ship.occupancies?.maxAdults && { maxAdults: ship.occupancies.maxAdults }),
    ...(!!ship.occupancies?.maxChildren && { maxChildren: ship.occupancies.maxChildren }),
    ...(!!ship.occupancies?.maxInfants && { maxInfants: ship.occupancies.maxInfants }),
    ...(!!ship.occupancies?.adultMinimumAge && { adultMinimumAge: ship.occupancies.adultMinimumAge }),
    ...(!!ship.occupancies?.childMaximumAge && { childMaximumAge: ship.occupancies.childMaximumAge }),
    ...(!!ship.occupancies?.childMinimumAge && { childMinimumAge: ship.occupancies.childMinimumAge }),
    ...(!!ship.occupancies?.infantMaximumAge && { infantMaximumAge: ship.occupancies.infantMaximumAge }),
    ...(!!ship.occupancies?.infantMinimumAge && { infantMinimumAge: ship.occupancies.infantMinimumAge }),
    ...(!!ship.occupancies?.minimumSupervisorAge && { minimumSupervisorAge: ship.occupancies.minimumSupervisorAge }),
  },
})

const cruisesMapCabinCategories = (cabinCategory: CruisesContract.ShipCabinCategory): App.CruiseShipCabinCategory => ({
  id: cabinCategory.id,
  cabinType: cabinCategory.cabinType,
  code: cabinCategory.code,
  ...(cabinCategory.color && { color: cabinCategory.color }),
  ...(cabinCategory.imageId && { imageId: cabinCategory.imageId }),
  ...(cabinCategory.cabinCategoryInfo?.length && {
    cabinCategoryInfo: cabinCategory.cabinCategoryInfo?.map(cruisesMapCabinInfo),
  }),
})

export const cruisesShipDeckMap = (deck: CruisesContract.ShipDeck): App.CruiseShipDeck => ({
  id: deck.id,
  name: deck.name,
  deckNumber: deck.deckNumber,
  externalId: deck.externalId,
  ...(deck.imageId && { imageId: deck.imageId }),
  ...(deck.shipCabinPosition && { shipCabinPosition: deck.shipCabinPosition }),
})

const cruisesMapCabinInfo = (cabinInfo: CruisesContract.ShipCabinCategoryInfo): App.CruiseShipCabinCategoryInfo => ({
  id: cabinInfo.id,
  name: cabinInfo.name,
  description: cabinInfo.description ?? '',
  languageCode: cabinInfo.languageCode,
})

const cruisesMapShipInfo = (shipInfo: CruisesContract.ShipInfo): App.CruiseShipInfo => {
  const shipMappedData = {
    title: shipInfo.title,
    description: shipInfo.description,
    dataTypeName: shipInfo.dataTypeName,
  }

  if (shipInfo.dataTypeName === 'General') {
    return {
      ...shipMappedData,
      description: shipInfo.description.replaceAll(/<\/?br\/?>/g, ''),
    }
  }

  return shipMappedData
}

const cruisesMapShipImages = (image: CruisesContract.ShipImage): App.CruiseShipImage => ({
  id: (image.idImage || image.imageId) as string,
  order: image.order,
})

const cruisesMapOfferImages = (image: CruisesContract.OfferImage): App.CruiseShipImage => ({
  id: image.imageId,
  order: image.order,
  title: image.description ?? '',
})

const cruiseFaqMap = (faq: any): App.CruiseFAQ => ({ // CruisesContract.Faq
  title: faq.title,
  content: faq.content,
  key: faq.key,
})

const cruiseFinePrintMap = (finePrint?: any): App.CruiseFinePrint => ({ // CruisesContract.FinePrint
  finalParagraph: finePrint?.finalParagraph,
  sections: finePrint?.sections?.map(cruiseFinePrintSectionMap) ?? [],
})

const cruiseFinePrintSectionMap = (finePrintSection: any): App.CruiseFinePrintSection => ({ // CruisesContract.FinePrintSection
  description: finePrintSection.introductoryText,
  items: finePrintSection.items ?? [],
  subSections: finePrintSection.subSections?.map(cruiseFinePrintSubSectionMap) ?? [],
  title: finePrintSection.topic,
})

const cruiseFinePrintSubSectionMap = (finePrintSubSection: any): App.CruiseFinePrintSection => ({ // CruisesContract.FinePrintSection
  items: finePrintSubSection.subSectionItems ?? [],
  title: finePrintSubSection.title,
})

const cruiseItineraryItemMap = (itineraryItem: CruisesContract.Itinerary): App.CruiseItineraryItem => {
  const mappedPort = cruiseItineraryItemPortMap(itineraryItem.port)

  const originalTitle = itineraryItem.description && itineraryItem.description.toLowerCase() !== 'at sea' ?
    itineraryItem.description :
    null

  return {
    startDay: itineraryItem.dayNumber,
    title: originalTitle || (mappedPort?.portInfo?.map(item => item.title).join(', ') ?? ''),
    description: mappedPort?.portInfo?.map(item => item.description).join() ?? '',
    startTime: itineraryItem.arrivalTime,
    endTime: itineraryItem.departureTime,
    port: mappedPort,
  }
}

const cruiseItineraryItemPortMap = (port?: CruisesContract.FullPort): App.CruisePort | null => {
  if (!port) return null

  return {
    name: port.name,
    code: port.code,
    nearestAirportCode: port.nearestAirportCode,
    address: port.address,
    city: port.city,
    stateProvince: port.stateProvince,
    countryCode: port.countryCode,
    latitude: port.latitude,
    longitude: port.longitude,
    portInfo: port.portInfo?.map(cruiseItineraryItemPortInfoMap) ?? [],
  }
}

const cruiseItineraryItemPortInfoMap = (portInfo: CruisesContract.PortInfo): App.CruiseItineraryItemPortInfo => ({
  title: portInfo.title,
  description: portInfo.description ?? '',
  dataTypeName: portInfo.dataTypeName,
})

// BOOKING FLOW MAPPERS

export const cabinViewMap = (
  cruiseOffer: App.CruiseOffer,
): Array<App.CruiseCabinView> => {
  const { cabinCategories } = cruiseOffer.ship
  const categories: Array<App.CruiseShipCabinCategory> = Object.assign(cabinCategories || [])
  const cabinView: Array<App.CruiseCabinView> = categories.map((category, i) => {
    const categoryInfo = category.cabinCategoryInfo?.[0] ?? null

    return {
      id: category.id,
      cabin: {
        code: category.code,
        color: category.color ?? '',
        imageId: category.imageId ?? '',
        typeName: category.cabinType,
        categoryInfo: {
          id: categoryInfo?.id ?? i,
          name: categoryInfo?.name ?? category.cabinType,
          description: categoryInfo?.description ?? '',
          languageCode: categoryInfo?.languageCode ?? '',
        },
      },
    }
  })

  return cabinView
}

export const cruiseCabinRateDetailsMap = (
  details: string,
): App.CruiseCabinRateDetailsResponse => {
  const list = details?.match(/<li>.*?<\/li>/g) ?? []

  const tempElement = document.createElement('div')
  tempElement.innerHTML = details
  const paragraphs = tempElement.getElementsByTagName('p')
  let termsAndConditions = ''
  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i]
    if (paragraph.textContent) {
      termsAndConditions += paragraph.textContent.trim() + ' '
    }
  }
  termsAndConditions = `<ul>${termsAndConditions
    .split('. ')
    .filter(Boolean)
    .map((item) => `<li>${item}.</li>`)
    .join('')}</ul>`

  const FilteredList = list.map(
    item => {
      if ((item.toLocaleLowerCase().includes('terms') && item.toLocaleLowerCase().includes('conditions')) && termsAndConditions) {
        return `<li><button>${item.match(/<li>(.*?)<\/li>/s)?.[1]}</button></li>`
      }
      return item
    },
  )

  const preview = `<ul>${(FilteredList.slice(0, 6).join(''))}</ul>`
  const fullList = `<ul>${(FilteredList.join(''))}</ul>`

  return {
    rawRateDetails: details,
    rateDetails: fullList,
    detailsPreview: preview,
    hasMoreDetails: list.length > 6,
    termsAndConditions,
  }
}

export const cruiseBookingInfoMap = (
  booking: CruisesContract.BookingOrderResponse,
  regionCode: string,
): App.CruiseBookingInfo => {
  return {
    id: booking.id,
    departureId: booking.departureId,
    orderId: booking.orderId,
    totalAmount: booking.totalAmount,
    categoryId: booking.categoryId,
    cabinNumber: booking.cabinNumber,
    departureDate: booking.departureDate,
    arrivalDate: booking.arrivalDate,
    status: booking.status,
    cabinCode: booking.cabinCode,
    sessionId: booking.sessionId,
    categoryCode: booking.berthedCategoryCode,
    confirmationNumber: booking.confirmationNumber,
    cabinTypeName: booking.cabinType,
    dining: booking.dining,
    alternateDining: booking.alternateDining,
    tableSize: booking.tableSize,
    bedConfiguration: booking.bedConfiguration,
    prepaidGratuities: booking.prepaidGratuities,
    insurance: booking.insurance,
    onboardCredit: booking.onboardCredit,
    ...(!!booking.rateCodeDetails && {
      rateCodeDetails: cruiseCabinRateDetailsMap(booking.rateCodeDetails),
    }),
    ...(!!booking.travellersInfo && {
      travelersInfo: getTravellersInfoFromBooking(booking.travellersInfo),
    }),
    isNonRefundable: booking.isNonRefundable,
    ...(!!booking.cancellationSchedule && {
      cancellationSchedule: booking.cancellationSchedule,
    }),
    ...(!!booking.inclusions && {
      luxPlusInclusions: cruiseBookingLuxPlusInclusionsMap(booking.inclusions, regionCode),
    }),
  }
}

const getTravellersInfoFromBooking = (
  travellers: Array<CruisesContract.TravellerRepositoryInfo>,
): Array<App.TravelerInfo> => {
  return travellers.map((item) => ({
    type: item.type,
    firstName: item.firstName,
    lastName: item.lastName,
    dateOfBirth: item.dateOfBirth,
    email: item.email,
    phone: item.phone,
  }))
}

export const cruiseDepartureByIdMap = (
  departure: CruisesContract.DepartureByIdResponse,
): App.CruiseDepartureView => ({
  id: departure.id,
  duration: departure.duration,
  returnDate: departure.returnDate,
  departureDate: departure.departureDate,
  offer: {
    id: departure.offer.id,
    name: departure.offer.displayName ?? departure.offer.name,
    samePorts: departure.offer.samePorts,
    returnPortName: departure.offer.returnPortName,
    departurePortName: departure.offer.departurePortName,
    numberOfCities: departure.offer.numberOfCities,
    numberOfCountries: departure.offer.numberOfCountries,
  },
  vendor: {
    id: departure.vendor.id,
    name: departure.vendor.name,
    imageId: departure.vendor.imageId ?? '',
  },
  ship: {
    id: departure.ship.id,
    name: departure.ship.name,
    imageId: departure.ship.imageId ?? '',
  },
})

export const cruisePromoBannersMap = (cruisePromoBanners: Array<CruisesContract.PromoBanner>): Array<App.CruisePromoBanner> => {
  return cruisePromoBanners.map(cruisePromoBanner => ({
    id: cruisePromoBanner.id,
    description: cruisePromoBanner.description,
    startDate: cruisePromoBanner.startDate,
    endDate: cruisePromoBanner.endDate,
    redirectType: cruisePromoBanner.redirectType,
    redirectData: cruisePromoBanner.redirectData,
    isFixed: cruisePromoBanner.isFixed,
    imageId: cruisePromoBanner.imageId,
    mobileImageId: cruisePromoBanner.mobileImageId,
    vendorId: cruisePromoBanner.vendorId,
    isVisibleOnVendorPage: cruisePromoBanner.isVisibleOnVendorPage,
  }))
}

export function cruiseFacetsMap(facets: Array<App.CruiseSearchFacet>): Array<App.CruiseSearchFacet> {
  return facets.map((facet) => {
    // TODO: Soon we will be able to customize the name and add imageIdSm in the admin portal
    if (facet.category === 'cruise_lines') {
      const customInfo = CRUISE_LINE_CUSTOM_INFO.find(({ code }) => code === facet.value?.trim())

      return {
        ...facet,
        name: customInfo?.name ?? facet.name,
        originalName: facet.name,
        imageIdSm: customInfo?.imageIdSm,
      }
    }

    return {
      ...facet,
      originalName: facet.name,
      order: facet.count,
    }
  })
}

// BOOKING FLOW V2
export function cruiseBookingRateListMap(
  rateList: CruiseAPI.CruiseRateListResponse,
  offer: App.CruiseOffer,
  departureId: string,
  regionCode: string,
): App.Cruises.BookingRateListResponse {
  return {
    sessionId: rateList.sessionId,
    bookingId: rateList.bookingId,
    cabinHoldTimeout: rateList.cabinHoldTimeout,
    cabinRates: rateList.cabinRates.map((cabinRate) => cruiseBookingCabinRateMap(cabinRate, offer, departureId, regionCode)),
    personTitles: rateList.personTitles.map((title) => ({
      ...title,
      applicableGender: title.applicableGender as App.Cruises.ApplicableGender,
    })),
  }
}

export function cruiseBookingCabinRateMap(
  cabinRate: CruiseAPI.CruiseCabinRate,
  offer: App.CruiseOffer,
  departureId: string,
  regionCode: string,
): App.Cruises.BookingCabinRate {
  const { luxPlusInclusionsByTier } = cruiseInclusionsMap(
    offer.offerInclusions ?? [],
    departureId,
    cabinRate.rateCode,
    cabinRate.priceDetails.price,
    regionCode,
  )

  return {
    rateCode: cabinRate.rateCode,
    cabinType: capitalise(cabinRate.cabinType),
    componentId: cabinRate.componentId,
    categoryName: cabinRate.categoryName,
    pricedCategoryCode: cabinRate.pricedCategoryCode,
    rateCodeDescription: cabinRate.rateCodeDescription,
    priceDetails: {
      price: cabinRate.priceDetails.price,
      ...(!!cabinRate.priceDetails.discountDetails && {
        discountDetails: {
          amount: cabinRate.priceDetails.discountDetails.amount,
          discountPrice: cabinRate.priceDetails.discountDetails.discountPrice,
          discountPercentage: cabinRate.priceDetails.discountDetails.discountPercentage,
        },
      }),
      ...(!!cabinRate.priceDetails.onboardCreditDetails && {
        onboardCreditDetails: {
          amount: cabinRate.priceDetails.onboardCreditDetails.amount,
          currencyCode: cabinRate.priceDetails.onboardCreditDetails.currencyCode,
        },
      }),
    },
    luxPlusInclusionsByTier,
  }
}

export function cruiseBookingCabinListMap(cabinList: CruiseAPI.CruiseCabinListResponse): Array<App.Cruises.CruiseBookingCabin> {
  return cabinList.cabins.map<App.Cruises.CruiseBookingCabin>((cabin) => {
    return {
      categoryId: cabin.categoryId,
      cabinNumber: cabin.cabinNumber,
      minimumPassengers: cabin.minimumPassengers,
      maximumPassengers: cabin.maximumPassengers,
      isAccessibleCabin: cabin.isAccessibleCabin,
      locationDescription: cabin.locationDescription ?? '',
      isCabinInfoAvailable: cabin.isCabinInfoAvailable,
      deckId: cabin.deckId,
      bedType: cabin.bedType,
      deckName: cabin.deckName,
      connectingCabinNumber: cabin.connectingCabinNumber,
      componentIds: [],
    }
  })
}

export function cruiseBookingCabinSelectionMap(cabinSelection: CruiseAPI.CruiseCabinSelectionResponse): App.Cruises.CruiseBookingCabinSelection {
  const details = cabinSelection.details
  const isNonRefundable = details.pricing.isNonRefundable || false

  return {
    alternateDiningRequired: details.alternateDiningRequired,
    pricing: {
      ...details.pricing,
      cancellationPolicy: filterDataByHighestPercentage(details.pricing.cancellationSchedule || [], isNonRefundable),
    },
    tableSizes: details.tableSizes,
    diningOptions: details.diningOptions,
    bedConfigurations: details.bedConfigurations,
    prepaidGratuities: details.prepaidGratuities,
    insurance: details.insurance,
  }
}

export function cruiseBookingCabinPricingMap(cabinPricing: CruiseAPI.CruiseCabinPricingResponse): App.Cruises.CruiseBookingCabinPricing {
  const isNonRefundable = cabinPricing.details.pricing.isNonRefundable || false

  return {
    pricing: {
      ...cabinPricing.details.pricing,
      cancellationPolicy: filterDataByHighestPercentage(
        cabinPricing.details.pricing.cancellationSchedule || [],
        isNonRefundable,
      ),
    },
    paymentConfiguration: cabinPricing.details.paymentConfiguration,
  }
}

function cabinDetailsMap(cabinDetails: Array<string>) {
  // TODO: temporary implementation, we will move this to the backend
  const features = cabinDetails
    .filter((item) => item.includes(':') || item.toLowerCase().includes(' bed') || item.toLowerCase().includes(' beds'))
    .reduce((acc, item) => {
      let [key, value] = item.split(':')
      const isBedData = item.toLowerCase().includes('beds') && !item.includes(':')
      let formattedKey = isBedData ? 'bed_size' : key.trim().toLowerCase().replace(/\s/g, '_')

      if (formattedKey === 'cabin_dimensions') {
        value = value.replace('X', '-')
          .replace('FEET', 'ft')
          .replace('FT ft', 'ft')
          .replaceAll('"', '')
      }

      if (formattedKey === 'position_in_ship') {
        formattedKey = 'cabin_location'
      }

      if (formattedKey === 'cabin_location') {
        value = value.trim().toLowerCase()
      }

      if (formattedKey === 'bed_size') {
        if (item.toLowerCase().includes('unk')) {
          return acc
        }

        value = item.toLowerCase()
      }

      return {
        ...acc,
        [formattedKey]: value,
      }
    }, {})

  const details = cabinDetails.filter((item) => {
    const value = item.toLowerCase()

    return !value
      .match(/:|capacity|bed|inside|suite|balcony|oceanview/g)
  })

  return {
    features,
    details,
  }
}

export function cruiseBookingCabinDetailsListMap(
  cabinDetailsList: CruiseAPI.CruiseCabinDetailsListResponse,
): App.Cruises.BookingCabinDetailsList {
  return arrayToObject(
    cabinDetailsList.cabins,
    cabin => cabin.cabinNumber,
    cabin => cabinDetailsMap(cabin.cabinDetails || []),
  )
}

export const cruiseBookingRateDetailsListMap = (
  // TODO: use type when it's available
  rateDetailsList: any,
  // rateDetailsList: CruiseAPI.CruiseRateDetailsListResponse,
): Record<string, App.Cruises.BookingRateDetails> => {
  return rateDetailsList.rates.reduce((acc: Record<string, App.Cruises.BookingRateDetails>, rate: any) => {
    const details = rate.rateDetails?.[0] || ''
    const list: Array<string> = details?.match(/<li>.*?<\/li>/g) ?? []

    const tempElement = document.createElement('div')
    tempElement.innerHTML = details
    const paragraphs = tempElement.getElementsByTagName('p')
    let termsAndConditions = ''
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i]
      if (paragraph.textContent) {
        termsAndConditions += paragraph.textContent.trim() + ' '
      }
    }
    termsAndConditions = `<ul>${termsAndConditions
      .split('. ')
      .filter(Boolean)
      .map((item) => `<li>${item}.</li>`)
      .join('')}</ul>`

    const FilteredList = list.map(
      item => {
        if ((item.toLocaleLowerCase().includes('terms') && item.toLocaleLowerCase().includes('conditions')) && termsAndConditions) {
          return `<li><button>${item.match(/<li>(.*?)<\/li>/s)?.[1]}</button></li>`
        }
        return item
      },
    )

    const preview = `<ul>${(FilteredList.slice(0, 6).join(''))}</ul>`
    const fullList = `<ul>${(FilteredList.join(''))}</ul>`

    acc[rate.componentId] = {
      rawRateDetails: details,
      rateDetails: fullList,
      detailsPreview: preview,
      hasMoreDetails: list.length > 6,
      termsAndConditions,
    }

    return acc
  }, {})
}

export const cruiseShipMap = (ship: CruiseAPI.CruiseShipResponse): App.Cruises.Ship => {
  return {
    id: ship.id,
    name: ship.name,
    criticId: ship.criticId,
    captions: ship.captions,
    cabinCategoriesGroups: ship.cabinCategoriesGroups?.map((group) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      cabinCategories: group.cabinCategories ?? [],
      images: group.images,
    })) ?? [],
  }
}

export const lowestPriceByCabinTypeMap = (
  cabinRates: Array<App.Cruises.BookingCabinRate>,
): Array<App.CruiseLowestPriceCabin> => {
  const cabinKey: { [k: string] : App.CruiseLowestPriceCabin } = {}

  const cabins = cabinRates.reduce((
    acc: Array<App.CruiseLowestPriceCabin>,
    cur: App.Cruises.BookingCabinRate) => {
    const hasItem = acc.find(item => item.typeName === cur.cabinType)

    hasItem ?
      cabinKey[cur.cabinType] = {
        typeName: cur.cabinType,
        lowestPrice: Math.min(hasItem.lowestPrice, cur.priceDetails.price),
      } :
      cabinKey[cur.cabinType] = {
        typeName: cur.cabinType,
        lowestPrice: cur.priceDetails.price,
      }

    return Object.values(cabinKey).map(item => item)
  }, []).sort((a, b) => a.lowestPrice - b.lowestPrice)

  return cabins
}

export const cruiseOfferSnapshotMap = (
  offer: CruiseAPI.CruiseOfferSnapshot,
): App.Cruises.CruiseOfferSnapshot => {
  return {
    ...offer,
    leVisibleDescription: offer.leVisibleDescription!,
    heroImageId: offer.heroImageId!,
    vendorImageId: offer.vendorImageId!,
    shipImageId: offer.shipImageId!,
    destinationDescription: offer.destinationDescription!,
    cabinImageId: offer.cabinImageId!,
    isSamePort: offer?.departurePortCode === offer?.returnPortCode,
    numberOfCountries: getNumberOfCountries(offer?.itineraries),
    numberOfCities: getNumberOfCities(offer?.itineraries),
    itineraries: offer.itineraries.map((itinerary) => {
      const port = itinerary.port ? {
        name: itinerary.port.name,
        city: itinerary.port.city!,
        countryCode: itinerary.port.countryCode!,
      } : undefined
      return {
        ...itinerary,
        port,
      }
    }),
  }
}

export const cruiseBookingMap = (
  booking: CruiseAPI.CruiseBooking,
): App.Cruises.CruiseBooking => {
  return {
    ...booking,
    orderId: booking.orderId!,
    cabinCode: booking.cabinCode!,
    totalAmount: booking.totalAmount!,
    categoryId: booking.categoryId!,
    cabinNumber: booking.cabinNumber!,
    cabinType: booking.cabinType!,
    departureDate: booking.departureDate!,
    arrivalDate: booking.arrivalDate!,
    confirmationNumber: booking.confirmationNumber!,
    dining: booking.dining!,
    alternateDining: booking.alternateDining!,
    tableSize: booking.tableSize!,
    bedConfiguration: booking.bedConfiguration!,
    prepaidGratuities: booking.prepaidGratuities!,
    insurance: booking.insurance!,
    onboardCredit: booking.onboardCredit!,
    customerId: booking.customerId!,
    vendorCode: booking.vendorCode!,
    rateCodeDetails: booking.rateCodeDetails!,
    rateCodeDescription: booking.rateCodeDescription!,
    baseCurrency: booking.baseCurrency!,
    convertedCurrency: booking.convertedCurrency!,
    baseFxRate: booking.baseFxRate!,
    requiredFxRate: booking.requiredFxRate!,
    isNccfIncluded: booking.isNccfIncluded!,
    originalNccfAmount: booking.originalNccfAmount!,
    nccfAmount: booking.nccfAmount!,
    isTaxIncluded: booking.isTaxIncluded!,
    originalTaxAmount: booking.originalTaxAmount!,
    taxAmount: booking.taxAmount!,
    originalCommissionAmount: booking.originalCommissionAmount!,
    commissionAmount: booking.commissionAmount!,
    originalTotalAmount: booking.originalTotalAmount!,
    originalNetFareAmount: booking.originalNetFareAmount!,
    netFareAmount: booking.netFareAmount!,
    failureReason: booking.failureReason!,
    vendorBookingStatus: booking.vendorBookingStatus!,
    travellersDetails: booking.travellersDetails.map((traveller) => {
      return {
        ...traveller,
        dateOfBirth: traveller.dateOfBirth!,
        middleName: traveller.middleName!,
      }
    }),
    providerPaymentSchedules: booking.providerPaymentSchedules.map((schedule) => {
      return {
        ...schedule,
        providerDueDate: schedule.providerDueDate!,
      }
    }),
    inclusions: booking.inclusions?.map((inclusion) => {
      return {
        ...inclusion,
        description: inclusion.description!,
        amount: inclusion.amount!,
        currency: inclusion.currency!,
      }
    }),
  }
}

const getNumberOfCountries = (
  itineraries: Array<CruiseAPI.CruiseItinerarySnapshot>,
): number => {
  const countries = new Set()
  itineraries?.forEach((itinerary) => {
    if (itinerary.port?.countryCode) {
      countries.add(itinerary.port.countryCode)
    }
  })
  return countries.size
}

const getNumberOfCities = (
  itineraries: Array<CruiseAPI.CruiseItinerarySnapshot>,
): number => {
  const cities = new Set()
  itineraries?.forEach((itinerary) => {
    if (itinerary.port?.city) {
      cities.add(itinerary.port.city.toLocaleLowerCase())
    } else if (itinerary.port?.name) {
      cities.add(itinerary.port.name.split(',')[0].toLocaleLowerCase())
    } else if (itinerary.description !== 'At Sea') {
      cities.add(itinerary.description.split(',')[0].toLocaleLowerCase())
    }
  })
  return cities.size
}
