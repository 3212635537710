import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import {
  USER_LOGOUT,
  ENROL_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
  FETCH_LUX_LOYALTY_PROGRAM_CONFIG,
  FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION,
  SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION,
} from 'actions/apiActionConstants'

export const initialLuxLoyaltyState: App.LuxLoyaltyState = {
  account: { initial: true },
  eligibilityTier: { initial: true },
  programConfig: { initial: true },
  pointsEarnCalculations: {},
  pointsBurnCalculations: {},
}

const apiRequests = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: () => ({
    eligibilityTier: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: () => ({
    programConfig: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { fetching: true },
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { fetching: true },
      },
    }
  },
})

const apiSuccesses = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      data: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      data: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [USER_LOGOUT]: () => initialLuxLoyaltyState,
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibilityTier: {
      data: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: (_state, action) => ({
    programConfig: {
      data: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { data: action.data },
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { data: action.data },
      },
    }
  },
})

const apiFailures = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibilityTier: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: (_state, action) => ({
    programConfig: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { error: action.error },
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { error: action.error },
      },
    }
  },
})

export default createReducer<App.LuxLoyaltyState>(initialLuxLoyaltyState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})
