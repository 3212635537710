export enum CheckoutPageId {
  DepartingFlights = 'departing',
  ReturningFlights = 'returning',
  Experiences = 'experiences',
  Purchase = 'purchase',
  Dates = 'dates',
  LuxPlus = 'lux-plus',
  AddFlights = 'flights',
  TourV1Capacity = 'tour-capacity',
  TourV1Departure = 'tour-departure',
  TourV2Upgrade = 'tours-package',
  TourV2OptionalExperience = 'tours-optional-extras',
  TourV2Departures = 'tours-departures',
  TourV2Accommodation = 'tours-accommodation',
  ChangeDates = 'change-dates',
  ChangePackage = 'change-package',
  InsuranceSelectDates = 'insurance-select-dates',
  BedbankChangeDates = 'partner-change-dates',
  // cruises
  CruiseTravelers = 'cruise-travellers',
  CruiseCabinType = 'cruise-cabin-type',
  CruiseCabinCategory = 'cruise-cabin-category',
  CruiseCabinLocation = 'cruise-cabin-location',
  CruiseCabinPackage = 'cruise-cabin-package',
}
