import React, { PropsWithChildren, useCallback } from 'react'
import styled from 'styled-components'
import cx from 'clsx'

import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { accountAccessShowLogin } from 'actions/UiActions'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import TextButton from 'components/Luxkit/Button/TextButton'
import { hasPassedWalledGarden } from 'selectors/accountSelectors'
import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const BottomWrapper = styled.div`
  display: flex;
  margin-top: ${rem(12)};
  justify-content: flex-end;

  ${mediaQueryUp.desktop} {
    margin-top: ${rem(16)};
  }

  > * + * {
    margin-left: ${rem(8)};
  }

  &.align-left:not(.full-width) {
    justify-content: flex-start;
  }

  &.align-center:not(.full-width) {
    justify-content: center;
  }

  &.align-right:not(.full-width) {
    justify-content: flex-end;
  }
`

const BtnSignUp = styled(TextButton)`
  min-width: ${rem(100)};

  &.full-width {
    flex: 1;
  }

  &.md-full-width {
    ${mediaQueryUp.desktop} {
      flex: 1;
    }
  }
`

interface Props {
  showContent?: boolean;
  loginText?: string;
  signUpText?: string;
  discountPercentage?: number;
  align?: 'left' | 'center' | 'right' | 'none';
  fullWidth?: boolean;
  discountLabelAlign?: 'start' | 'end';
  className?: string;
  mdFullWidth?: boolean;
}

function OfferTileLoggedInButtons({
  showContent,
  children,
  signUpText,
  discountPercentage,
  align = 'center',
  fullWidth = true,
  discountLabelAlign,
  className,
  mdFullWidth,
}: PropsWithChildren<Props>) {
  const dispatch = useAppDispatch()

  const showLogin = useCallback<React.MouseEventHandler<HTMLButtonElement>>(e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(accountAccessShowLogin())
  }, [dispatch])

  if (showContent) {
    return <>{children}</>
  }

  const alignClass = `align-${align}`
  const discountLabel = discountPercentage ? `Get ${discountPercentage}% off with member prices` : 'Sign up to access exclusive rates'
  return <Root className={className}>
    <BodyTextBlock
      colour="neutral-one"
      variant="medium"
      weight="semi-bold"
      startIcon={<LineTagAltIcon />}
      align={discountLabelAlign}
    >
      {discountLabel}
    </BodyTextBlock>
    <BottomWrapper className={cx({ 'full-width': fullWidth, [alignClass]: !fullWidth })}>
      <BtnSignUp
        kind="secondary"
        className={cx({ 'full-width': fullWidth, 'md-full-width': mdFullWidth })}
        onClick={showLogin}>
        {signUpText ?? 'Sign up for free'}
      </BtnSignUp>
    </BottomWrapper>
  </Root >
}

const mapStateToProps = (state: App.State, props: Props) => ({
  showContent: hasPassedWalledGarden(state) || props.showContent,
})

export default connect(mapStateToProps)(OfferTileLoggedInButtons)
