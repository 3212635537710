import { paths } from '@luxuryescapes/contract-svc-lux-loyalty'
import requestUtils from 'api/requestUtils'

type Payload = paths['/api/lux-loyalty/points/calculate-earn']['post']['parameters']['body']['payload']
type Response = paths['/api/lux-loyalty/points/calculate-earn']['post']['responses']['200']['content']['application/json']

// /**
//  * TODO - this is a temp implementation until the product types have been finalised
//  */
function isLoyaltyProductTypeWithAdditionalCalculationOptions(options: App.LuxLoyaltyPointsEarnCalculationRequest): options is App.LuxLoyaltyHotelPointsEarnCalculationRequest {
  return options.luxLoyaltyProductType === 'hotel_flash' || options.luxLoyaltyProductType === 'hotel_lpc'
}

function luxLoyaltyServerProductDetailsMap(options: App.LuxLoyaltyPointsEarnCalculationRequest): Payload['productDetails'] {
  if (isLoyaltyProductTypeWithAdditionalCalculationOptions(options)) {
    return {
      loyaltyProductType: options.luxLoyaltyProductType,
      offerId: options.offerId,
      packageId: options.packageId,
    }
  }

  return {
    loyaltyProductType: options.luxLoyaltyProductType,
  }
}

interface Options {
  customerId: string;
  currency: string;
  luxPlusMember: boolean;
}

async function requestLuxLoyaltyEarnPointsCalculation(request: App.LuxLoyaltyPointsEarnCalculationRequest, options: Options) {
  const payload: Payload = {
    publicPrice: request.price,
    luxPlusPrice: request.memberPrice,
    luxPlusMember: options.luxPlusMember,
    currency: options.currency,
    mx: request.mx,
    customerId: options.customerId,
    productDetails: luxLoyaltyServerProductDetailsMap(request),
  }

  const response = await requestUtils.post<Response, Payload>('/api/lux-loyalty/points/calculate-earn',
    payload,
    { credentials: 'include' },
  )

  const mapped: App.LuxLoyaltyPointsEarnCalculation = {
    points: response.result.totalBasePoints,
    statusCredits: response.result.totalStatusCredits,
  }

  return mapped
}

export default requestLuxLoyaltyEarnPointsCalculation
