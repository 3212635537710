import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { addMonths, endOfMonth, startOfMonth } from 'lib/datetime/dateUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import GeoContext from 'contexts/geoContext'
import moment from 'moment'
import FlightsDatePickerDay from './FlightsDatePickerDay'
import { FlightsClassTypes, FlightsFareTypes } from 'constants/flight'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchFlightCalendar } from 'actions/FlightActions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { flightsArrivalDateSelect, flightsDepartureDateSelect } from 'analytics/eventDefinitions'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions, FlightSearchWidgetFlightItem } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import { DatePickerMonthChangeHandler } from 'components/Common/Calendar/DatePicker'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import { getFlightCalendarKey } from 'lib/flights/flightUtils'
import { arrayToMap, groupBy, min } from 'lib/array/arrayUtils'
import VerticalDatePicker from 'components/Common/Calendar/VerticalDatePicker'
import truncateText from 'lib/string/truncateText'

interface Props {
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  id: string;
  onDateChange?: (date: Date) => void
  featuredRanges?: Array<{
    start: Date,
    end: Date
  }>
  required?: boolean;
  flightDeals?: Array<App.FlightDeal>;
}

function getFlightName(airport?: App.AirportLocation) {
  return truncateText(airport?.cityDesc || airport?.airportName || '', 6, '', '..')
}

function FlightsVerticalDatePicker(props: Props) {
  const {
    minDate,
    maxDate,
    className,
    id,
    onDateChange,
    featuredRanges,
    required,
    flightDeals,
  } = props

  const state = useContext(FlightSearchWidgetStateContext)

  const prevFlight = useMemo(() => {
    const prevFlightIndex = state.flights.findIndex((flight) => flight.id === id)

    if (prevFlightIndex !== -1) {
      return state.flights[prevFlightIndex - 1]
    }
  }, [state.flights, id])

  const currentFlight = useMemo(() => {
    return state.flights.find((flight) => flight.id === id) || state.flights[0]
  }, [state.flights, id])

  const { fareType, fareCabin } = state

  const {
    checkinDate: startDate,
    checkoutDate: endDate,
    departureAirport,
    arrivalAirport,
  } = currentFlight

  const searchDispatch = useContext(FlightSearchWidgetDispatchContext)

  const { currentRegionCode, currentCurrency } = useContext(GeoContext)

  const [date, setDate] = useState<Date>(startDate?.toDate() ?? new Date())

  const defaultStartDate: Date = useMemo(() => {
    return minDate ?? new Date()
  }, [minDate])

  const defaultEndDate = useMemo(() => {
    return maxDate || addMonths(defaultStartDate, 12)
  }, [maxDate, defaultStartDate])

  const startDateQuery = useMemo(() => moment(startOfMonth(defaultStartDate)).format(ISO_DATE_FORMAT), [defaultStartDate])
  const endDateQuery = useMemo(() => moment(endOfMonth(defaultEndDate)).format(ISO_DATE_FORMAT), [defaultEndDate])

  const cacheKey = getFlightCalendarKey({
    startDate: startDateQuery,
    endDate: endDateQuery,
    origin: departureAirport?.airportCode ?? '',
    destination: arrivalAirport?.airportCode ?? '',
    region: currentRegionCode,
    currency: currentCurrency,
  })

  const flightCalendar = useAppSelector(state => state.flights.flightCalendar[cacheKey])
  const loading = useAppSelector(state => state.flights.flightCalendarLoading[cacheKey])

  const earliestBookingDate = !loading && flightCalendar?.earliestBookingDate ? new Date(flightCalendar.earliestBookingDate + 'T00:00:00') : minDate || defaultStartDate
  const finalBookingDate = !loading && flightCalendar?.finalBookingDate ? new Date(flightCalendar.finalBookingDate) : maxDate || defaultEndDate

  const dispatch = useAppDispatch()

  const isMultiCity = fareType?.value === FlightsFareTypes.MULTI_CITY
  const isEconomy = fareCabin?.value === FlightsClassTypes.ECONOMY
  const isReturn = fareType?.value === FlightsFareTypes.RETURN

  /* Adding the displayCachedPrice flag to hide the cached price only when
   * there is a deal available for the same origin and destination
   */
  const [displayCachedPrice, setDisplayCachedPrice] = useState(true)

  useEffect(() => {
    if (departureAirport && arrivalAirport && flightDeals?.length > 0) {
      const flightDeal = flightDeals.find(deal => deal.originAirportCode === departureAirport.airportCode && deal.destinationAirportCode === arrivalAirport.airportCode)
      if (flightDeal) {
        setDisplayCachedPrice(false)
      } else {
        setDisplayCachedPrice(true)
      }
    } else {
      setDisplayCachedPrice(true)
    }
  }, [departureAirport, arrivalAirport, flightDeals])

  useEffect(() => {
    if (departureAirport && arrivalAirport && !isMultiCity && isEconomy && !state.disableCalendarPricing && displayCachedPrice) {
      dispatch(fetchFlightCalendar({
        startDate: startDateQuery,
        endDate: endDateQuery,
        origin: departureAirport.airportCode,
        destination: arrivalAirport.airportCode,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey, isEconomy, state.disableCalendarPricing, displayCachedPrice])

  const onDayClick = useCallback((selectedDay: Date) => {
    const day = moment(selectedDay)

    if ([FlightsFareTypes.ONE_WAY, FlightsFareTypes.MULTI_CITY].includes(fareType?.value as FlightsFareTypes)) {
      searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKIN_DATE, id, date: day })
      fireInteractionEvent(flightsDepartureDateSelect())
      searchDispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu: undefined })

      // Clear next flights dates
      const currentFlightIndex = state.flights.findIndex(flight => flight.id === id)
      for (let i = currentFlightIndex + 1; i < state.flights.length; i++) {
        const flight = state.flights[i]
        searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKIN_DATE, id: flight.id, date: null })
      }
    } else {
      const departingCachedPrice = startDate ? flightCalendar?.cachedPrices?.[startDate.format(ISO_DATE_FORMAT)]?.cost ?? 0 : 0
      const returningCachedPrice = day ? flightCalendar?.cachedPrices?.[day.format(ISO_DATE_FORMAT)]?.cost ?? 0 : 0

      const departingPrice = Math.floor(departingCachedPrice / 2)
      const returningPrice = Math.floor(returningCachedPrice / 2)

      if (startDate && !endDate && day.isSameOrAfter(startDate)) {
        searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKIN_DATE, id, date: startDate, quotedPrice: departingPrice })
        searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKOUT_DATE, id, date: day, quotedPrice: returningPrice })
        fireInteractionEvent(flightsArrivalDateSelect())
        searchDispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, id, activeMenu: undefined })
      } else {
        searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKIN_DATE, id, date: day, quotedPrice: departingPrice })
        searchDispatch({ type: FlightSearchWidgetActions.SET_CHECKOUT_DATE, id, date: null, quotedPrice: null })
        fireInteractionEvent(flightsDepartureDateSelect())
      }
    }

    onDateChange?.(date)
  }, [fareType?.value, onDateChange, date, searchDispatch, id, startDate, endDate, state.flights, flightCalendar])

  const onMonthChange = useCallback<DatePickerMonthChangeHandler>((direction, date) => {
    setDate(date)
  }, [])

  const cheapestPricesByMonth = useMemo(() => {
    const pricesByMonth = groupBy(
      Object.entries(flightCalendar?.cachedPrices ?? {}),
      ([date]) => moment(date).format('YYYY-MM'),
      ([, value]) => value,
    )

    // now narrow it down to the cheapest for said month
    return arrayToMap(
      Array.from(pricesByMonth.entries()),
      ([monthYear]) => monthYear,
      ([, values]) => min(values, val => val.cost)?.cost,
    )
  }, [flightCalendar])

  const defaultMonth = useMemo(() => {
    if (prevFlight?.checkinDate) {
      return moment(prevFlight.checkinDate).get('month')
    }

    return date.getMonth()
  }, [date, prevFlight])

  const defaultYear = useMemo(() => {
    if (prevFlight?.checkinDate) {
      return moment(prevFlight.checkinDate).get('year')
    }

    return date.getFullYear()
  }, [date, prevFlight])

  const selectedFlightsByDate: Record<number, FlightSearchWidgetFlightItem> = useMemo(() => {
    return state.flights.reduce((dates, flight) => {
      if (flight.checkinDate) {
        return {
          ...dates,
          [flight.checkinDate.valueOf()]: flight,
        }
      }
      return dates
    }, {})
  }, [state.flights])

  return (
    <div className={className}>
      <HiddenInput
        name="startDate"
        value={startDate?.format(ISO_DATE_FORMAT)}
        required={required}
        invalidMessage="Please select a date"
      />

      <HiddenInput
        name="endDate"
        value={endDate?.format(ISO_DATE_FORMAT)}
        required={isReturn && required}
        invalidMessage="Please select a date"
      />

      <VerticalDatePicker
        min={earliestBookingDate}
        max={finalBookingDate}
        onMonthChange={onMonthChange}
        defaultMonth={defaultMonth}
        defaultYear={defaultYear}
        dayRender={(day: Date, params) => {
          const { disabled } = params
          const dayTime = day.getTime()
          const dateOfDay = moment(day)
          const monthKey = dateOfDay.format('YYYY-MM')
          const selected = (startDate?.valueOf() === dayTime || endDate?.valueOf() === dayTime) || (isMultiCity && Boolean(selectedFlightsByDate[dayTime]))
          const isDeparting = startDate?.valueOf() === dayTime
          const cachedPrice = !isMultiCity && displayCachedPrice ? flightCalendar?.cachedPrices?.[dateOfDay.format(ISO_DATE_FORMAT)]?.cost ?? 0 : 0
          const isInRange = (!selected && startDate && endDate) ? dateOfDay.isBetween(startDate, endDate) : false
          const perPersonPrice = Math.floor(cachedPrice / 2)
          const shouldCheckDate = prevFlight && fareType?.value === FlightsFareTypes.MULTI_CITY
          const isAfterLastFlight = shouldCheckDate ? dateOfDay.isAfter(prevFlight.checkinDate) : true
          const isFeatured = featuredRanges?.some(range => dateOfDay.isBetween(range.start, range.end, 'days', '[]'))
          const departingAirportName = getFlightName(currentFlight.departureAirport)
          const arrivalAirportName = getFlightName(currentFlight.arrivalAirport)
          const multiCityLabel = getFlightName(selectedFlightsByDate[dayTime]?.departureAirport)
          const defaultLabel = isDeparting ? departingAirportName : arrivalAirportName
          const label = isMultiCity ? multiCityLabel : defaultLabel

          return <FlightsDatePickerDay
            key={dayTime}
            day={day}
            dataTestId={`day-${dateOfDay.format('YYYY-MM-DD')}`}
            selected={selected}
            disabled={disabled || !isAfterLastFlight}
            price={isEconomy && !state.disableCalendarPricing ? perPersonPrice : undefined}
            loading={loading}
            isInRange={isInRange}
            onClick={onDayClick}
            cheapest={cheapestPricesByMonth.get(monthKey) === cachedPrice}
            featured={isFeatured}
            label={selected ? label : undefined}
          />
        }}
      />
    </div>
  )
}

export default FlightsVerticalDatePicker
