import { API_CALL, SET_CUSTOMER_VIEW_TOGGLE } from 'actions/actionConstants'
import requestGetAccountAgent from 'agentHub/api/requestGetAccountAgent'
import {
  FETCH_ACCOUNT_AGENT_HUB,
  FETCH_AGENT_STATUS,
  ONBOARD_AGENCY,
  FETCH_AGENT_AFFILIATION_OPTIONS,
  FETCH_OFFER_COMMISSION,
  CLEAR_OFFER_COMMISSION_DATA,
} from './apiActionConstants'
import requestGetAgentAccountById from 'agentHub/api/requestGetAgentAccountById'
import requestGetAgentStatus from 'agentHub/api/requestGetAgentStatus'
import requestPostSetAgencyDetails, { SetAgencyDetailsParams } from 'agentHub/api/requestPostSetAgencyDetails'
import requestPostMarkAgentAsOnboarded from 'agentHub/api/requestPostMarkAgentAsOnboarded'
import { AgentHubGetOffersCommissionBody, getAccumulatedOfferCommissionById } from 'agentHub/api/agentHubCommission'
import { getAgentAffiliationOptions } from 'agentHub/api/agentHubAffiliationOptions'
import { AppAction } from './ActionTypes'
import requestGetAgentStatusByToken from 'agentHub/api/requestGetAgentStatusByToken'

/**
 * Fetches the agent hub account for the current user
 *
 * Note: Will only work for onboarded users
 */
export function fetchAccountAgentHub(accessToken?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.agentHub.agentHubAccount.account?.status === 'onboarded') {
      // already have the onboarded version, no need to refetch
      // (we might have the pending version, so if that's the case, refetch it)
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ACCOUNT_AGENT_HUB,
      request: () => requestGetAccountAgent(accessToken),
    })
  }
}

/**
 * Fetches the agent hub account for the agent given.
 *
 * Note: Will work for accounts that are still pending onboarding
 * @param agentId The ID of the agent to fetch the account for
 */
export function fetchAgentAccountByAgentId(agentId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.agentHub.agentHubAccount.account?.agentId === agentId) {
      // already have the current agents data, no need to refetch
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ACCOUNT_AGENT_HUB,
      request: () => requestGetAgentAccountById(agentId),
    })
  }
}

export function fetchAgentAffiliationOptions(region: App.AgentHubEnabledRegions): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.agentHub?.affiliationOptions?.options[region]?.length > 0) {
      // already have the affiliation options, no need to refetch
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_AFFILIATION_OPTIONS,
      key: region,
      request: () => getAgentAffiliationOptions(region),
    })
  }
}

export function checkAgentHubStatus(agentEmail: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.agentHub.agentStatus[agentEmail] !== undefined) {
      // already checked it
      return
    }
    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_STATUS,
      key: agentEmail,
      request: () => requestGetAgentStatus(agentEmail),
    })
  }
}

export function checkAgentHubStatusByAgentToken(token: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.agentHub.agentStatus[token] !== undefined) {
      // already checked it
      return
    }
    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_STATUS,
      key: token,
      request: () => requestGetAgentStatusByToken(token),
    })
  }
}

export function toggleCustomerView(customerView?: boolean): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: SET_CUSTOMER_VIEW_TOGGLE,
      value: customerView ?? !state.agentHub.customerView,
    })
  }
}

export function onboardAgency(agencyDetails: SetAgencyDetailsParams): AppAction {
  return {
    type: API_CALL,
    api: ONBOARD_AGENCY,
    request: async() => {
      await requestPostSetAgencyDetails(agencyDetails.agentId, agencyDetails)
      const updatedAccount = await requestPostMarkAgentAsOnboarded(agencyDetails.agentId)
      return updatedAccount
    },
  }
}

export function fetchAccumulatedOfferCommissionById(offerId: string, offerCommission: AgentHubGetOffersCommissionBody): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const region = state.geo.currentRegionCode

    if (state.agentHub.offersCommissionLoading[offerId] || state.agentHub.offersCommissionMap[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_COMMISSION,
      request: () => getAccumulatedOfferCommissionById(offerId, offerCommission, region),
      key: offerId,
    })
  }
}

export const clearOfferCommissionData = (offerId: string) => {
  return {
    type: CLEAR_OFFER_COMMISSION_DATA,
    key: offerId,
  }
}
