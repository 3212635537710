import moment from 'moment'
import chunk from 'lodash/chunk'
import getObjectKey from 'lib/object/getObjectKey'
import { sum, sortBy, without, arrayToObject, unique, groupBy, EmptyArray, split } from 'lib/array/arrayUtils'
import { CheckoutPageId } from 'checkout/constants/pages'
import { getAvailableLuxPlusInclusionsForMembers } from 'lib/cruises/cruiseInclusionsUtils'
import { CruiseAPI } from 'api/cruises'
import { CABIN_CATEGORIES } from 'constants/cruise'
import { US_DATE_FORMAT } from 'constants/dateFormats'

const GUARANTEED_CODES = ['GUAR', 'GTY', 'G00000']

export function generateCabinTravelerKey(cruiseItem: App.Checkout.CruiseItem) {
  return getObjectKey(cruiseItem.occupancy)
}

const emptyData: App.Cruises.BookingSessionData = {
  bookingId: undefined,
  sessionId: undefined,
  sharedSessionId: undefined,
  cabinHoldTimeout: undefined,
}

export function getSessionByCruiseItem(
  selectedCruiseItem: App.Checkout.CruiseItem | undefined,
  session: App.CruiseMultiBookingState['session'],
): App.Cruises.BookingSessionData {
  if (!selectedCruiseItem) return emptyData

  return session[selectedCruiseItem.itemId || ''] ?? emptyData
}

export function getMultiCabinStatus(
  multiCabinDataMap: App.CruiseMultiBookingState['rateList']
    | App.CruiseMultiBookingState['cabinList']
    | App.CruiseMultiBookingState['cabinSelection']
    | App.CruiseMultiBookingState['cabinPricing'],
) {
  // Check if there is an error in the data of any cabin
  const cabinDataItems = Object.values(multiCabinDataMap).filter(Boolean)
  const cabinDataWithError = cabinDataItems.find(data => data.error)
  const multiCabinStatus = cabinDataWithError ? {
    isError: true,
    errorMessage: cabinDataWithError.errorMessage,
    errorToRender: cabinDataWithError.errorToRender,
  } : { isError: false }

  return multiCabinStatus
}

export function getRateListByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  rateList: App.CruiseMultiBookingState['rateList'],
): App.Cruises.CruiseBookingRateListDataWithStatus {
  const basicData: App.Cruises.CruiseBookingRateListDataWithStatus = {
    rates: [],
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(rateList),
  }

  const rateListData = rateList[cruiseItem?.itemId!]
  if (!cruiseItem || !rateListData) return basicData

  return {
    ...basicData,
    ...rateListData,
    rates: sortBy(rateListData.rates, rate => rate.priceDetails.price, 'asc'),
  }
}

export function getCabinListByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinList: App.CruiseMultiBookingState['cabinList'],
): App.Cruises.BookingCabinListDataWithStatus {
  const basicData: App.Cruises.BookingCabinListDataWithStatus = {
    cabins: [],
    cabinByNumber: {},
    loading: false,
    erroredCabinComponentIds: [],
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinList),
  }

  const travelerKey = cruiseItem ? generateCabinTravelerKey(cruiseItem) : undefined
  const cabinListData = cabinList[travelerKey!]

  if (!cruiseItem || !cabinListData) return basicData

  return { ...basicData, ...cabinListData }
}

export function getCabinDetailsByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinDetails: App.CruiseMultiBookingState['cabinDetails'],
): App.Cruises.BookingCabinDetailsListData {
  const emptyData = { detailsByCabin: {}, errorByCabin: {}, loading: false }
  if (!cruiseItem) return emptyData

  const travelerKey = generateCabinTravelerKey(cruiseItem)
  return cabinDetails[travelerKey] ?? emptyData
}

export function getRateDetailsByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  rateDetails: App.CruiseMultiBookingState['rateDetails'],
): App.Cruises.BookingRateDetailsData {
  const emptyData = { detailsByRate: {}, errorByRate: {}, loadingByRate: {} }
  if (!cruiseItem) return emptyData

  return rateDetails[cruiseItem.itemId] ?? emptyData
}

export function getCabinSelectionByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinSelection: App.CruiseMultiBookingState['cabinSelection'],
): App.Cruises.BookingCabinSelectionDataWithStatus {
  const basicData: App.Cruises.BookingCabinSelectionDataWithStatus = {
    cabinSelection: undefined,
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinSelection),
  }

  const cabinSelectionData = cabinSelection[cruiseItem?.itemId!]
  if (!cruiseItem || !cabinSelectionData) return basicData

  return { ...basicData, ...cabinSelectionData }
}

export function getCabinPricingByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinPricing: App.CruiseMultiBookingState['cabinPricing'],
): App.Cruises.BookingCabinPricingDataWithStatus {
  const basicData: App.Cruises.BookingCabinPricingDataWithStatus = {
    cabinPricing: undefined,
    hasCabinPricing: false,
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinPricing),
  }

  const cabinPricingData = cabinPricing[cruiseItem?.itemId!]
  if (!cruiseItem || !cabinPricingData) return basicData

  return {
    ...basicData,
    ...cabinPricingData,
    hasCabinPricing: !!cabinPricingData.cabinPricing,
  }
}

export function getCabinReleaseByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinRelease: App.CruiseMultiBookingState['cabinRelease'],
): App.Cruises.BookingCabinReleaseData {
  const emptyData = { released: false, error: false, loading: false }
  if (!cruiseItem) return emptyData

  return cabinRelease[cruiseItem.itemId] ?? emptyData
}

export function getMultiCabinError(
  bookingDataItems: Array<
    App.Cruises.CruiseBookingRateListDataWithStatus
    | App.Cruises.BookingCabinListDataWithStatus
    | App.Cruises.BookingCabinSelectionDataWithStatus
    | App.Cruises.BookingCabinPricingDataWithStatus
  >,
): { isError: boolean, errorMessage?: string, errorToRender?: string, errors?: Array<string> } {
  const bookingDataWithError = bookingDataItems.find(data => data.multiCabinStatus.isError)
  if (!bookingDataWithError) return { isError: false }
  return {
    isError: true,
    errorMessage: bookingDataWithError.multiCabinStatus.errorMessage,
    errorToRender: bookingDataWithError.multiCabinStatus.errorToRender,
    errors: bookingDataWithError.errors,
  }
}

export const getBookingCabinTypes = (rateListData: App.Cruises.CruiseBookingRateListDataWithStatus, offer: App.CruiseOffer) => {
  const cabinRates = rateListData.rates
  const cabinCategories: Array<App.CruiseShipCabinCategory> = offer.ship.cabinCategories ?? EmptyArray
  const offerEvergreenInclusions = offer.evergreenInclusions
  const ratesByType = groupBy(cabinRates, rate => rate.cabinType)

  const cabinTypes = CABIN_CATEGORIES
    .map((cabinType) => {
      const categoryRates = ratesByType.get(cabinType.category) ?? []
      const [lowestRate] = sortBy(categoryRates, rate => rate.priceDetails.price, 'asc')
      const shipCategory = cabinCategories.find(category => category.cabinType === cabinType.category)

      const evergreenInclusions = offerEvergreenInclusions.filter((inclusion) => inclusion.cabinTypes.includes(cabinType.category))
      const luxPlusInclusionsByTier = categoryRates.find(rate => rate.luxPlusInclusionsByTier)?.luxPlusInclusionsByTier

      return {
        name: cabinType.category,
        imageId: shipCategory?.imageId || cabinType.defaultImageId,
        lowestRatePrice: lowestRate?.priceDetails,
        evergreenInclusions,
        luxPlusInclusionsByTier,
      }
    })

  return cabinTypes
}

export function buildCabinsWithPrice(
  cruiseItem: App.Checkout.CruiseItem,
  cruiseItems: Array<App.Checkout.CruiseItem>,
  cabinLisData: App.Cruises.BookingCabinListData,
  cabinDetails: App.Cruises.BookingCabinDetailsListData,
  selectedRates: Array<App.Cruises.BookingCabinRate>,
  evergreenInclusions: Array<App.EvergreenInclusion>,
): Array<App.Cruises.BookingCabinWithPrice> {
  const { cabins, cabinByNumber } = cabinLisData
  const { detailsByCabin } = cabinDetails

  const cabinsWithPrice = cabins
    .map((cabin) => {
      const { cabinNumber, deckId, componentIds, connectingCabinNumber } = cabin
      const rates = selectedRates.filter(rate => componentIds.includes(rate.componentId))
      const categoryCodes = unique(rates.map(rate => rate.pricedCategoryCode))

      const lowestRate = rates[0]
      const lowestPrice = lowestRate?.priceDetails
      const lowestCategoryCode = lowestRate?.pricedCategoryCode
      const isGuaranteed = GUARANTEED_CODES.includes(cabinNumber) || !deckId
      const details = detailsByCabin[cabinNumber]

      const isByronBeachClub = evergreenInclusions.some((inclusion) => (
        inclusion.cabinTypes.includes(cruiseItem.cabinType!) &&
        inclusion.cabinCategories.some(category => categoryCodes.includes(category)) &&
        inclusion.description === 'Access to Byron Beach Club'
      ))

      const connectingCabinAvailable = (
        !!connectingCabinNumber &&
        connectingCabinNumber !== '*' &&
        !!cabinByNumber[connectingCabinNumber]
      )

      const cabinItemIndex = cruiseItems.findIndex(item => item.cabinNumber === cabinNumber)
      const selectedByItemPosition = cabinItemIndex !== -1 && cruiseItems.length > 1 ? cabinItemIndex + 1 : undefined

      return {
        ...cabin,
        details,
        categoryCodes,
        lowestCategoryCode,
        isGuaranteed,
        selectedByItemPosition,
        isByronBeachClub,
        connectingCabinAvailable,
        lowestPrice,
      }
    })
    .filter(cabin => cabin.lowestPrice)

  return sortBy(cabinsWithPrice, cabin => cabin.lowestPrice.price, 'asc')
}

function getSelectedCategoriesCount(
  cabinCategories: Array<App.Cruises.BookingCabinCategory>,
  cruiseItems: Array<App.Checkout.CruiseItem>): number {
  const cabinCategoriesCode = cabinCategories.map(({ code }) => code).filter(Boolean)
  const selectedCategories = cruiseItems.map(({ cabinCodes }) => cabinCodes ?? []).filter(Boolean)
  return selectedCategories.reduce((acc, selectedCategory) => {
    if (selectedCategory.some(category => cabinCategoriesCode.includes(category))) {
      return acc + 1
    }
    return acc
  }, 0)
}

export function buildCabinCategoriesGroup(
  groupName: string,
  cabinCategories: Array<App.Cruises.BookingCabinCategory>,
  cruiseItems: Array<App.Checkout.CruiseItem>,
  offer: App.CruiseOffer,
): App.Cruises.BookingCabinCategoryGroupView {
  const name = groupName

  const { lowestRatePrice } = sortBy(cabinCategories, (category) => category.lowestRatePrice.price, 'asc')[0]
  const categories = cabinCategories.map(category => ({
    code: category.code,
    color: category.color ?? '',
  }))
  const selectedCategoriesCount = getSelectedCategoriesCount(cabinCategories, cruiseItems)
  const { cabin, description, luxPlusInclusionsByTier, images, name: cabinName } = cabinCategories[0]
  const hasAccessibleCabin = cabinCategories.some(category => category.hasAccessibleCabin)
  const hasConnectingCabin = cabinCategories.some(category => category.hasConnectingCabin)
  const hasGuaranteedCabin = cabinCategories.some(category => category.hasGuaranteedCabin)

  /* multiples categories can have guaranteed cabin,
  * so we need to count them separately to avoid duplicates */
  const normalCabinsCount = sum(cabinCategories, category => category.cabinsCount)
  const totalCabinsCount = normalCabinsCount + (hasGuaranteedCabin ? 1 : 0)
  const availableCabinsCount = totalCabinsCount > 0 ? totalCabinsCount - selectedCategoriesCount : 0
  const evergreenInclusions = offer.evergreenInclusions.filter((inclusion) =>
    inclusion.cabinTypes.includes(cabinName),
  )

  return {
    name,
    images,
    cabinsCount: availableCabinsCount,
    description,
    categories,
    lowestRatePrice,
    evergreenInclusions,
    luxPlusInclusionsByTier,
    hasAccessibleCabin,
    hasConnectingCabin,
    cabin,
  }
}

export function getBookingCabinsByCategory(
  cabinsWithPrice: Array<App.Cruises.BookingCabinWithPrice>,
): Record<string, Array<App.Cruises.BookingCabinWithPrice>> {
  return cabinsWithPrice.reduce<Record<string, Array<App.Cruises.BookingCabinWithPrice>>>((acc, cabin) => {
    for (const categoryCode of cabin.categoryCodes) {
      acc[categoryCode] = [...(acc[categoryCode] || []), cabin]
    }

    return acc
  }, {})
}

export function getCruiseDeposits(
  cabinPricingData: App.Cruises.BookingCabinPricingData,
): App.CruisePaymentScheduleDetails | undefined {
  const { cabinPricing } = cabinPricingData
  const paymentSchedule = cabinPricing?.pricing.paymentSchedule

  if (paymentSchedule?.firstPayment?.amount) {
    return {
      type: '',
      schedule: {
        firstPayment: paymentSchedule.firstPayment,
        finalPayment: paymentSchedule.finalPayment,
      },
    } as App.CruisePaymentScheduleDetails
  }
}

// Group cabins by cabin number and merge the componentIds
export function groupCabinListByNumber(
  cabins: Array<App.Cruises.CruiseBookingCabin>,
  newCabins: Array<App.Cruises.CruiseBookingCabin>,
): Record<string, App.Cruises.CruiseBookingCabin> {
  const cabinByNumber = arrayToObject(cabins, (cabin) => cabin.cabinNumber)

  newCabins.forEach((newCabin) => {
    const cabin = cabinByNumber[newCabin.cabinNumber]

    if (cabin) {
      cabinByNumber[newCabin.cabinNumber] = {
        ...cabin,
        componentIds: unique([...cabin.componentIds, newCabin.categoryId]),
      }
    } else {
      cabinByNumber[newCabin.cabinNumber] = {
        ...newCabin,
        componentIds: [newCabin.categoryId],
      }
    }
  })

  return cabinByNumber
}

export function checkIfNeedsToReleaseCabin(cruiseItem: App.Checkout.CruiseItem): boolean {
  const sessionIds = JSON.parse(sessionStorage.getItem('cruiseSessionIDs') || '[]')

  return (
    !!cruiseItem.sessionId &&
    !!cruiseItem.bookingId &&
    // This prevents releasing a cabin from a sessionID from another browser tab
    sessionIds.includes(cruiseItem.sessionId)
  )
}

// BOOKING DATA FETCH PARAMS
export function buildCabinListFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: App.Cruises.BookingSessionData,
  rateListData: App.Cruises.CruiseBookingRateListData,
  cabinListData: App.Cruises.BookingCabinListData,
  currentCheckoutStepId: string | undefined,
):Array<Array<string>> | undefined {
  const { sessionId } = sessionData
  const { rates } = rateListData
  const { cabins, loading, erroredCabinComponentIds, error } = cabinListData
  const cabinType = cruiseItem.cabinType
  const cabinCodes = cruiseItem.cabinCodes ?? []
  const isV2 = cruiseItem.version === 'v2'

  if (isV2 && cabinType && rates.length && sessionId && !loading && !error) {
    const isCabinCategoryStep = currentCheckoutStepId === CheckoutPageId.CruiseCabinCategory
    const isPaymentStep = currentCheckoutStepId === CheckoutPageId.Purchase

    const categoryRates = rates
      .filter((rate) => {
        /* If there are categories selected in the item and it is not in the categories step.
         * it only loads the cabins of the selected categories */
        if (!isCabinCategoryStep && !cabinCodes?.includes(rate.pricedCategoryCode)) {
          return false
        }

        /* If the user is in the payment step, only load cabins from the componentId of the selected rate,
         * This avoids loading unnecessary data when refreshing the page */
        if (isPaymentStep && rate.componentId !== cruiseItem.componentId) {
          return false
        }

        return rate.cabinType === cabinType
      })
      .map((rate) => rate.componentId)

    const categoryRatesLoaded = [...new Set(cabins.flatMap((cabin: App.Cruises.CruiseBookingCabin) => cabin.componentIds))]
    const categoryRatesToLoad = without(categoryRates, ...categoryRatesLoaded, ...erroredCabinComponentIds)

    const RATES_PER_REQUEST = 5
    const cabinGroupsToLoad = split(categoryRatesToLoad, RATES_PER_REQUEST)

    if (cabinGroupsToLoad.length) {
      return cabinGroupsToLoad
    }
  }

  return undefined
}

export function buildCabinDetailsFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: App.Cruises.BookingSessionData,
  cabinDetailsData: App.Cruises.BookingCabinDetailsListData,
  cabinListData: App.Cruises.BookingCabinListData,
  currentCheckoutStepId: CheckoutPageId,
) {
  const { sessionId } = sessionData
  const { detailsByCabin, errorByCabin, loading } = cabinDetailsData
  const { cabins } = cabinListData
  const isV2 = cruiseItem.version === 'v2'
  const cabinCodes = cruiseItem.cabinCodes ?? []
  const stepsToLoad = [CheckoutPageId.CruiseCabinCategory, CheckoutPageId.CruiseCabinLocation]

  if (isV2 && sessionId && cabinCodes.length && !loading && stepsToLoad.includes(currentCheckoutStepId)) {
    const cabinsWithUnavailableDetails = cabins
      .filter(({ isCabinInfoAvailable }) => !isCabinInfoAvailable)
      .map(({ cabinNumber }) => cabinNumber)

    const cabinsWithError = Object.keys(errorByCabin)
    const cabinNumbers = cabins.map(({ cabinNumber }) => cabinNumber)
    const cabinDetailsLoaded = Object.keys(detailsByCabin)

    const cabinDetailsToLoad = without(
      cabinNumbers,
      ...cabinDetailsLoaded,
      ...cabinsWithUnavailableDetails,
      ...cabinsWithError,
    )

    const PARALLEL_REQUESTS = 3
    const CABINS_PER_REQUEST = 10
    const cabinDetailGroupsToLoad = chunk(cabinDetailsToLoad, CABINS_PER_REQUEST).slice(0, PARALLEL_REQUESTS)
    if (cabinDetailGroupsToLoad.length) {
      return cabinDetailGroupsToLoad
    }
  }

  return undefined
}

export function buildCabinSelectionFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: App.Cruises.BookingSessionData,
  rateListData: App.Cruises.CruiseBookingRateListData,
  cabinSelectionData: App.Cruises.BookingCabinSelectionData,
  rateDetailsData: App.Cruises.BookingRateDetailsData,
  departures: Array<App.CruiseDepartureDetails>,
  currentStepId: string,
  deckName?: string,
) {
  const { sessionId, bookingId } = sessionData
  const { rates } = rateListData
  const { cabinSelection, loading, error } = cabinSelectionData
  const { detailsByRate } = rateDetailsData
  const details = detailsByRate[cruiseItem.componentId!]
  const cabinRate = rates.find(rate => rate.componentId === cruiseItem.componentId)
  const departure = departures.find(departure => departure.id === cruiseItem.departureId)
  const isV2 = cruiseItem.version === 'v2'

  if (
    isV2 &&
    !cabinSelection &&
    !loading &&
    !error &&
    rates.length &&
    sessionId &&
    bookingId &&
    cruiseItem.cabinNumber &&
    cruiseItem.componentId &&
    currentStepId === CheckoutPageId.Purchase &&
    cabinRate &&
    departure
  ) {
    const departurePrices = departure.lowestOverallPriceDetails
    return {
      cabinType: cruiseItem.cabinType,
      categoryId: cruiseItem.componentId,
      cabinNumber: cruiseItem.cabinNumber,
      departureId: cruiseItem.departureId,
      cabinCode: cruiseItem.cabinCode,
      sessionId,
      bookingId,
      deckName,
      isNccfIncluded: departurePrices?.isNccfIncluded,
      isTaxIncluded: departurePrices?.isTaxIncluded,
      rateCodeDescription: cabinRate.rateCodeDescription,
      ...(details?.rawRateDetails && { rateCodeDetails: details.rawRateDetails }),
    }
  }

  return undefined
}

export function buildBookingCabinPricingParams(
  cruiseItem: App.Checkout.CruiseItem,
  preferences: App.CruiseCabinPreferences | undefined,
  travellerForms: Array<App.Checkout.TravellerForm>,
  personTitles: Array<App.Cruises.BookingPersonTitles>,
  inclusionsViewableLuxPlusTier: App.MembershipSubscriptionTier,
  hasLuxPlusOrSubscriptionInCart: boolean,
): Omit<CruiseAPI.CruiseCabinPricingBody, 'region' | 'currencyCode'> {
  const bookingId = cruiseItem.bookingId as string
  const departureId = cruiseItem.departureId
  const sessionId = cruiseItem.sessionId as string

  const vendorOptions = {
    ...(preferences?.diningOption && { dining: preferences.diningOption }),
    ...(preferences?.bedConfiguration && { bedConfiguration: preferences.bedConfiguration }),
    ...(preferences?.insurance && { insurance: preferences.insurance }),
    ...(preferences?.prepaidGratuity && { prepaidGratuities: preferences.prepaidGratuity }),
    ...(preferences?.tableSize && { tableSize: preferences.tableSize }),
  } as App.Cruises.BookingCabinPreferences

  const passengers = buildBookingCabinPricingPassengers(travellerForms, personTitles, cruiseItem)

  const availableInclusions = getAvailableLuxPlusInclusionsForMembers(
    cruiseItem.luxPlusInclusionsByTier,
    inclusionsViewableLuxPlusTier,
    hasLuxPlusOrSubscriptionInCart,
  )

  const inclusionIds = availableInclusions.map(({ inclusionGroupId }) => inclusionGroupId!).filter(Boolean)

  return {
    bookingId,
    departureId,
    sessionId,
    passengers,
    vendorOptions,
    inclusionIds,
  }
}

export function buildBookingCabinPricingPassengers(
  travellerForms: Array<App.Checkout.TravellerForm>,
  personTitles: Array<App.Cruises.BookingPersonTitles>,
  cruiseItem: App.Checkout.CruiseItem,
): CruiseAPI.CruiseCabinPricingBody['passengers'] {
  const titleGenderMap = buildBookingTitleGenderMap(personTitles)
  const travellers = getTravellerFormByItem(cruiseItem, travellerForms)

  // We only need the first traveller to get the phone number and email
  const phoneNumber = travellers[0].phonePrefix ?? `${travellers[0].prefix}${travellers[0].phone}`
  const emailAddress = travellers[0].email

  const passengers = travellers.reduce((acc, travellerForm, index) => {
    const passenger = {
      type: buildBookingTravellerType(travellerForm.id),
      gender: titleGenderMap[travellerForm.title],
      firstName: travellerForm.firstName,
      middleName: travellerForm.middleName,
      lastName: travellerForm.lastName,
      dateOfBirth: moment(travellerForm.dateOfBirth).format(US_DATE_FORMAT),
      citizenshipCountry: travellerForm.countryOfResidence,
      title: travellerForm.title,
      pastPassengerNumber: travellerForm.pastPassengerNumber,
      contactInfo: {
        emailAddress,
        phoneNumber,
        ...(travellerForm.address && {
          address: {
            address1: travellerForm.address,
            city: travellerForm.city,
            state: travellerForm.state,
            postalCode: travellerForm.postcode,
            country: travellerForm.countryOfResidence,
          },
        }),
      },
    }
    return {
      ...acc,
      [index + 1]: passenger,
    }
  }, {} as CruiseAPI.CruiseCabinPricingBody['passengers'])

  return passengers
}

function getTravellerFormByItem(
  cruiseItem: App.Checkout.CruiseItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
): Array<App.Checkout.TravellerForm> {
  const cruiseItemForms = travellerForms.filter(travellerForm => travellerForm.relatedItemId === cruiseItem.itemId)
  // if there are no specific forms for the cruise item, use all forms
  return cruiseItemForms.length ? cruiseItemForms : travellerForms
}

function buildBookingTitleGenderMap(personTitles: Array<App.CruisePersonTitle>): App.Cruises.BookingTitleGender {
  const titleGenderMap = {
    Sr: 'M',
    Mstr: 'M',
    Mr: 'M',
    Ms: 'F',
    Mrs: 'F',
    Miss: 'F',
  } as App.Cruises.BookingTitleGender

  const personTitleGenders = (personTitles ?? []).reduce((acc, curr) => {
    acc[curr.code] = titleGenderMap[curr.code] ?? 'M'
    return acc
  }, {} as App.Cruises.BookingTitleGender)

  return personTitles ? personTitleGenders : titleGenderMap
}

export function buildBookingTravellerType(key: string): 'ADT' | 'CHD' {
  return key.includes('adult') ? 'ADT' : 'CHD'
}

type CabinLowestPrice = {
  price: number;
  cabinCategory: string;
}

function getLowerCabinPrice(
  currentLowestPrice: CabinLowestPrice | undefined,
  cabin: App.Cruises.BookingCabinWithPrice,
) {
  if (
    !currentLowestPrice ||
    cabin.lowestPrice.price < currentLowestPrice.price
  ) {
    return {
      price: cabin.lowestPrice.price,
      cabinCategory: cabin.lowestCategoryCode,
    }
  }

  return currentLowestPrice
}

export function getCabinLowestPrice(
  cabins: Array<App.Cruises.BookingCabinWithPrice>,
): CabinLowestPrice | undefined {
  if (!cabins.length) return

  return cabins.reduce(getLowerCabinPrice, undefined)
}

export function setDeckLowestPrice(decks: Array<App.CruiseDeck>): Array<App.CruiseDeck> {
  const lowestPriceDeck: App.CruiseDeck = decks
    .filter((deck) => !!deck.cabinLowestPrice)
    .reduce((acc, deck) => {
      if (!deck.cabinLowestPrice || !acc.cabinLowestPrice) return deck
      return deck.cabinLowestPrice < acc.cabinLowestPrice ? deck : acc
    }, decks[0])

  if (!lowestPriceDeck.cabinLowestPrice) return decks

  return decks.map((deck) => ({
    ...deck,
    isDeckWithLowestPrice: deck.deckId === lowestPriceDeck.deckId,
  }))
}

export function buildCruiseCancellationPolicies(
  cancellationPolicies: Array<App.PricingCancellationSchedule>,
  isNonRefundable: boolean = true,
): App.Cruises.CruiseCancellationPolicy {
  if (!cancellationPolicies?.length) {
    return {
      isRefundable: !isNonRefundable,
      cancellationPolicies: [],
      soonerPoliceHasValidDate: false,
    }
  }

  const policies = cancellationPolicies.map(policy => ({
    amount: policy.amount,
    date: new Date(policy.date),
    isPercentage: policy.isPercentage,
    isNonRefundable: 'isNonRefundable' in policy ? !!policy.isNonRefundable : isNonRefundable,
  }))

  // ordering by date (asc)
  const orderedPolicies = policies.sort((firstItem, secondItem) => {
    return moment(firstItem.date).diff(moment(secondItem.date))
  })

  // check if sooner date has at least 10 days from now
  const soonerPoliceHasValidDate = moment(orderedPolicies[0].date).diff(moment(), 'days') >= 10

  return {
    soonerPoliceHasValidDate,
    cancellationPolicies: orderedPolicies,
    isRefundable: !orderedPolicies.some(policy => policy.isNonRefundable),
  }
}

export function buildCabinPreferenceOptions(cabinSelection?: App.Cruises.CruiseBookingCabinSelection) {
  const prepaidGratuities = cabinSelection?.prepaidGratuities ?? []
  const allDiningOptions = cabinSelection?.diningOptions ?? []
  const isAllWaitlisted = !!allDiningOptions.length && allDiningOptions?.every((diningOption) => diningOption.status === 'Waitlisted')

  const diningOptions = isAllWaitlisted ? [
    {
      code: 'U',
      name: 'Unassigned - choose closer to sailing',
      status: 'Waitlisted',
      isMandatoryGratuities: false,
    },
  ] : allDiningOptions?.filter((diningOption) => diningOption.status !== 'Waitlisted')

  const isMandatoryPrepaidGratuity = prepaidGratuities.some(({ price }) => price !== 0)

  return {
    bedConfigurations: cabinSelection?.bedConfigurations ?? [],
    diningOptions,
    prepaidGratuities,
    tableSizes: cabinSelection?.tableSizes ?? [],
    insurance: cabinSelection?.insurance ?? [],
    isMandatoryPrepaidGratuity,
  }
}

export function getOverallCruiseConsolidatedPaymentSchedule(
  paymentSchedule: App.Cruises.CruiseConsolidatedPaymentSchedule | undefined,
): App.Cruises.CruisePaymentSchedule | undefined {
  if (!paymentSchedule) return
  const { isDepositSupported, firstPayment, finalPayment } = paymentSchedule
  if (!isDepositSupported || !firstPayment || !finalPayment) return

  return { firstPayment, finalPayment }
}

export function getCruiseConsolidatedPaymentScheduleByBooking(
  paymentSchedule: App.Cruises.CruiseConsolidatedPaymentSchedule | undefined,
  bookingId?: string,
): App.Cruises.CruisePaymentSchedule | undefined {
  if (!paymentSchedule) return
  const { consolidatedBookingSchedules } = paymentSchedule
  const schedulesLength = Object.keys(consolidatedBookingSchedules).length

  if (
    !bookingId ||
    schedulesLength === 0 ||
    !consolidatedBookingSchedules[bookingId]?.firstPayment
  ) return

  return {
    firstPayment: consolidatedBookingSchedules[bookingId].firstPayment,
    finalPayment: consolidatedBookingSchedules[bookingId].finalPayment,
  }
}

export function getCruiseBalanceDueDate(
  isMultiCabinEnabled: boolean,
  consolidatedPaymentSchedule?: App.Cruises.CruisePaymentSchedule,
  cabinPricingData?: App.Cruises.BookingCabinPricingDataWithStatus,
): Date | undefined {
  if (isMultiCabinEnabled && consolidatedPaymentSchedule?.finalPayment) {
    return new Date(consolidatedPaymentSchedule.finalPayment.dueDate)
  }

  if (!isMultiCabinEnabled && cabinPricingData?.hasCabinPricing) {
    const paymentSchedule = getCruiseDeposits(cabinPricingData)

    if (paymentSchedule?.schedule.finalPayment) {
      return new Date(paymentSchedule.schedule.finalPayment.dueDate)
    }
  }

  return
}

export function getCruiseDepositAmount(
  isMultiCabinEnabled: boolean,
  bookingId?: string,
  cabinPricingData?: App.Cruises.BookingCabinPricingData,
  consolidatedPaymentSchedule?: App.Cruises.CruiseConsolidatedPaymentSchedule,
): number {
  if (isMultiCabinEnabled && bookingId && consolidatedPaymentSchedule) {
    const schedule = getCruiseConsolidatedPaymentScheduleByBooking(consolidatedPaymentSchedule, bookingId)
    return schedule?.firstPayment?.amount || 0
  }

  if (!isMultiCabinEnabled && cabinPricingData) {
    const depositDetails = getCruiseDeposits(cabinPricingData)
    return depositDetails?.schedule?.firstPayment?.amount || 0
  }

  return 0
}

export function getLastSelectedCabinByDeck(
  cruiseItems: Array<App.Checkout.CruiseItem>,
  selectedCruiseItem: App.Checkout.CruiseItem,
  deckId: number,
): App.Checkout.CruiseItem | undefined {
  const cruiseItemsByDeckId = cruiseItems.filter(item =>
    item.itemId !== selectedCruiseItem.itemId &&
    item.cabinType === selectedCruiseItem.cabinType &&
    item.deckId === Number(deckId),
  )
  if (!cruiseItemsByDeckId.length) return

  return cruiseItemsByDeckId[cruiseItemsByDeckId.length - 1]
}

export function getPreviousSelectedCabin(
  cruiseItems: Array<App.Checkout.CruiseItem>,
  selectedCruiseItem: App.Checkout.CruiseItem,
): App.Checkout.CruiseItem | undefined {
  const cruiseItemsByCabinType = cruiseItems.filter(item =>
    item.itemId !== selectedCruiseItem.itemId &&
    item.cabinType === selectedCruiseItem.cabinType &&
    !!item.cabinNumber &&
    !!item.deckId,
  )
  if (!cruiseItemsByCabinType.length) return

  return cruiseItemsByCabinType[cruiseItemsByCabinType.length - 1]
}
