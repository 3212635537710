import { getChannelMarkupByFilters, getChannelMarkupById, getPromoDisplayConfig } from 'api/promo'
import {
  API_CALL,
  API_CALL_SUCCESS,
  FETCH_CHANNEL_MARKUP,
} from './actionConstants'
import {
  FETCH_BEST_BACK_PRIZE,
  FETCH_BEST_BACK_OBJECT,
  FETCH_BEST_BACK_USER_STATUS,
  FETCH_NBL_COMPETITION_PRIZE,
  FETCH_NBL_COMPETITION_OBJECT,
  FETCH_NBL_COMPETITION_USER_STATUS,
  FETCH_UK_COMPETITION_PRIZE,
  FETCH_UK_COMPETITION_OBJECT,
  FETCH_UK_COMPETITION_USER_STATUS,
  FETCH_AU_COMPETITION_PRIZE,
  FETCH_AU_COMPETITION_OBJECT,
  FETCH_AU_COMPETITION_USER_STATUS,
  FETCH_INTL_COMPETITION_PRIZE,
  FETCH_INTL_COMPETITION_OBJECT,
  FETCH_INTL_COMPETITION_USER_STATUS,
  FETCH_PROMO_DISPLAY_CONFIGS,
  REMOVE_PROMO_DISPLAY_CONFIG,
} from './apiActionConstants'
import {
  getBestBackPrize,
  getBestBackObject,
  getBestBackUserStatus,
  getNblCompetitionPrize,
  getNblCompetitionObject,
  getNblCompetitionUserStatus,
  getUkCompetitionPrize,
  getUkCompetitionObject,
  getUkCompetitionUserStatus,
  getAuCompetitionPrize,
  getAuCompetitionObject,
  getAuCompetitionUserStatus,
  getIntlCompetitionPrize,
  getIntlCompetitionObject,
  getIntlCompetitionUserStatus,
  getCompetitionPrize,
  getCompetitionUserStatus,
  getCompetitionObject,
} from 'marketing/api'
import { paths } from '@luxuryescapes/contract-svc-promo'

export function fetchBestBackPrize() {
  return {
    type: API_CALL,
    api: FETCH_BEST_BACK_PRIZE,
    request: () => getBestBackPrize(),
  }
}

export function fetchBestBackObject() {
  return {
    type: API_CALL,
    api: FETCH_BEST_BACK_OBJECT,
    request: () => getBestBackObject(),
  }
}

export function fetchBestBackUserStatus(accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_BEST_BACK_USER_STATUS,
    request: () => getBestBackUserStatus(accessToken),
  }
}

export function fetchNblCompetitionPrize() {
  return {
    type: API_CALL,
    api: FETCH_NBL_COMPETITION_PRIZE,
    request: () => getNblCompetitionPrize(),
  }
}

export function fetchNblCompetitionObject() {
  return {
    type: API_CALL,
    api: FETCH_NBL_COMPETITION_OBJECT,
    request: () => getNblCompetitionObject(),
  }
}

export function fetchNblCompetitionUserStatus(accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_NBL_COMPETITION_USER_STATUS,
    request: () => getNblCompetitionUserStatus(accessToken),
  }
}

export function fetchUkCompetitionPrize() {
  return {
    type: API_CALL,
    api: FETCH_UK_COMPETITION_PRIZE,
    request: () => getUkCompetitionPrize(),
  }
}

export function fetchUkCompetitionObject() {
  return {
    type: API_CALL,
    api: FETCH_UK_COMPETITION_OBJECT,
    request: () => getUkCompetitionObject(),
  }
}

export function fetchUkCompetitionUserStatus(accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_UK_COMPETITION_USER_STATUS,
    request: () => getUkCompetitionUserStatus(accessToken),
  }
}

export function fetchAuCompetitionPrize() {
  return {
    type: API_CALL,
    api: FETCH_AU_COMPETITION_PRIZE,
    request: () => getAuCompetitionPrize(),
  }
}

export function fetchAuCompetitionObject() {
  return {
    type: API_CALL,
    api: FETCH_AU_COMPETITION_OBJECT,
    request: () => getAuCompetitionObject(),
  }
}

export function fetchAuCompetitionUserStatus(region?: string, accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_AU_COMPETITION_USER_STATUS,
    request: () => getAuCompetitionUserStatus(region, accessToken),
  }
}

export function fetchIntlCompetitionPrize(region?:string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_PRIZE,
    request: () => getIntlCompetitionPrize(region),
  }
}

export function fetchIntlCompetitionObject(region:string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_OBJECT,
    request: () => getIntlCompetitionObject(region),
  }
}

export function fetchIntlCompetitionUserStatus(region?: string, accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_USER_STATUS,
    request: () => getIntlCompetitionUserStatus(region, accessToken),
  }
}

export function fetchLETVS6CompetitionPrize(region?:string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_PRIZE,
    request: () => getCompetitionPrize(region, 'letvS6Competition'),
  }
}

export function fetchLETVS6CompetitionObject(region:string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_OBJECT,
    request: () => getCompetitionObject(region, 'letvS6Competition'),
  }
}

export function fetchLETVS6CompetitionUserStatus(region?: string, accessToken?: string) {
  return {
    type: API_CALL,
    api: FETCH_INTL_COMPETITION_USER_STATUS,
    request: () => getCompetitionUserStatus(region, 'letvS6Competition', accessToken),
  }
}

export function fetchChannelMarkupAction(channelMarkupId: string, filters: App.ChannelMarkupFilters) {
  return {
    type: API_CALL,
    api: FETCH_CHANNEL_MARKUP,
    request: async() => {
      let channelMarkup: App.ChannelMarkup | undefined
      if (channelMarkupId) {
        channelMarkup = await getChannelMarkupById(channelMarkupId)
      } else if (!channelMarkupId && filters.source.length && filters.medium.length && filters.campaign.length) {
        channelMarkup = await getChannelMarkupByFilters(filters)
      }
      return channelMarkup
    },
  }
}

export function fetchPromoDisplay(params: Omit<paths['/api/promo/promo-display-config']['get']['parameters']['query'], 'brand'>, blacklistPromoDisplayIds: Array<string>) {
  return {
    type: API_CALL,
    api: FETCH_PROMO_DISPLAY_CONFIGS,
    request: () => getPromoDisplayConfig(params, blacklistPromoDisplayIds),
  }
}

export function removePromoDisplay(promoDisplayId: string) {
  return {
    type: API_CALL_SUCCESS,
    api: REMOVE_PROMO_DISPLAY_CONFIG,
    data: { promoDisplayId },
  }
}
