import React, { useCallback, useMemo, MouseEventHandler, useEffect } from 'react'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import ListItem from 'components/Luxkit/List/ListItem'
import Group from 'components/utils/Group'
import { connect } from 'react-redux'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import useBusinessTravellerEmployeeQuery from 'businessTraveller/hooks/useEmployeeQuery'
import { EmptyArray, sortBy } from 'lib/array/arrayUtils'
import Divider from 'components/Luxkit/Divider'
import CounterInput from 'components/Common/Form/Input/CounterInput'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import { maxFlightPassengerCount } from 'constants/flight'
import Pill from 'components/Luxkit/Pill'
import { BUSINESS_TRAVELLER_ACTIONS } from 'reducers/businessTravellerActionReducer'
import { useAppDispatch } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'

interface EmployeeOption {
  id: string
  customerId: string | undefined
  firstName: string
  lastName: string
  email: string
  isSelected: boolean
}

interface MappedStateProps {
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount
  approvalRequests?: Array<App.BusinessTraveller.ApprovalRequest>
}

interface Props {
  searchTerm?: string
  travellerSelectState: {
    selectedTravellers: Array<string>
    guestCount: number
  }
  setTravellerSelectState: (state: { selectedTravellers: Array<string>, guestCount: number }) => void
}

function BusinessTravellerMultiSelectContent(props: Props & MappedStateProps) {
  const { businessAccount, searchTerm = '', travellerSelectState, setTravellerSelectState, approvalRequests } = props
  const { employees } = useBusinessTravellerEmployeeQuery()

  const appDispatch = useAppDispatch()
  const queryParams = useQueryParams()

  const approvalRequest = approvalRequests?.[0]?.type === 'flight' ? approvalRequests?.[0] : undefined

  const employeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (!employees) {
      return []
    }

    const selectedIds = new Set(travellerSelectState.selectedTravellers)
    const options: Array<EmployeeOption> = Object.values(employees).map<EmployeeOption>((employee) => {
      return {
        id: employee.id,
        customerId: employee.customerId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        isSelected: selectedIds.has(employee.id),
      }
    })

    return sortBy(options, (employee) => employee.firstName + employee.lastName, 'asc')
  }, [employees, travellerSelectState.selectedTravellers])

  const searchActive = !!searchTerm

  const filteredEmployeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (employeeOptions.length && searchTerm) {
      return employeeOptions.filter((employee) => {
        return employee.firstName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) ||
          employee.lastName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase())
      })
    }
    return EmptyArray
  }, [employeeOptions, searchTerm])

  const handleEmployeeClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    const employeeId = event.currentTarget.dataset.employeeid

    if (employeeId) {
      if (!travellerSelectState.selectedTravellers.includes(employeeId) && travellerSelectState.selectedTravellers.length + travellerSelectState.guestCount >= maxFlightPassengerCount) {
        showSnackbar(
          'You can only select up to 9 travellers and guests.',
          'warning',
        )
        return
      }

      if (approvalRequest) {
        showSnackbar(
          'If you need to change the travellers, please create a new approval request.',
          'warning',
        )
        return
      }

      const updatedSelectedTravellers = travellerSelectState.selectedTravellers.includes(employeeId) ?
        travellerSelectState.selectedTravellers.filter(id => id !== employeeId) : [...travellerSelectState.selectedTravellers, employeeId]
      setTravellerSelectState({
        ...travellerSelectState,
        selectedTravellers: updatedSelectedTravellers,
      })
    }
  }, [travellerSelectState, setTravellerSelectState, approvalRequest])

  const handleGuestCountChange = useCallback<(value: number, inputElement: HTMLInputElement | null) => void>((value) => {
    if (travellerSelectState.selectedTravellers.length + value > 9) {
      showSnackbar(
        'You can only select up to 9 travellers and guests.',
        'warning',
      )
      return
    }
    setTravellerSelectState({
      ...travellerSelectState,
      guestCount: value,
    })
  }, [travellerSelectState, setTravellerSelectState])

  const isBusinessAccountSelected = useMemo<boolean>(() => {
    return !!travellerSelectState.selectedTravellers.find(id => id === businessAccount?.employee?.id)
  }, [businessAccount?.employee?.id, travellerSelectState.selectedTravellers])

  const showEmployees = employeeOptions.length > 1
  const showFilteredEmployees = filteredEmployeeOptions.length > 0
  const showNoResults = searchActive && !showFilteredEmployees

  useEffect(() => {
    if (!employees) return

    const validEmployeeIds = new Set(Object.keys(employees))
    const hasInvalidSelection = travellerSelectState.selectedTravellers.some(
      id => !validEmployeeIds.has(id),
    )

    if (hasInvalidSelection) {
      const validSelectedTravellers = travellerSelectState.selectedTravellers.filter(
        id => validEmployeeIds.has(id),
      )
      setTravellerSelectState({
        ...travellerSelectState,
        selectedTravellers: validSelectedTravellers,
      })
    }

    if (approvalRequest) {
      // Clear approval request if param not in URL, which brings back into normal flow
      if (!queryParams.get('approvalRequest') || approvalRequest.status === 'DENIED' || approvalRequest.status === 'REQUESTED') {
        appDispatch({
          type: BUSINESS_TRAVELLER_ACTIONS.CLEAR_CART_RESTORE_REQUEST_ID,
          force: true, // always force for flights
        })
        appDispatch({
          type: BUSINESS_TRAVELLER_ACTIONS.CLEAR_APPROVAL_REQUESTS,
          force: true,
        })
      } else {
        setTravellerSelectState({
          selectedTravellers: approvalRequest.employeeIds ?? [],
          guestCount: approvalRequest.guestCount ?? 0,
        })
      }
    }
  }, [employees, setTravellerSelectState, travellerSelectState, approvalRequest, appDispatch, queryParams])

  return (
    <Group direction="vertical" gap={12}>
      {!showFilteredEmployees && !showNoResults && (
        <>
          {!!businessAccount?.employee?.id && (
            <ListItem
              startIcon={
                <AvatarCircle
                  firstName={businessAccount?.employee?.firstName}
                  lastName={businessAccount?.employee?.lastName}
                  nonInteractable
                />
              }
              title={<Group direction="horizontal" gap={8}>
                {businessAccount?.employee?.firstName} {businessAccount?.employee?.lastName}
                {businessAccount?.employee?.id === travellerSelectState.selectedTravellers[0] && <Pill kind="tertiary" variant="primary">
                  Primary
                </Pill>}
              </Group>}
              subtitle={businessAccount?.employee?.email}
              data-employeeid={businessAccount.employee.id}
              onClick={handleEmployeeClick}
              endIcon={<CheckboxButton checked={isBusinessAccountSelected} />}
            />
          )}
          <ListItem
            startIcon={
              <AvatarCircle
                nonInteractable
              />
            }
            title="Guest traveller"
            subtitle="For one-off bookings"
          >
            <CounterInput
              tabletDirection="horizontal"
              min={0}
              defaultValue={0}
              max={9}
              onChange={handleGuestCountChange}
              value={travellerSelectState.guestCount}
              disabled={!!approvalRequest}
            />
          </ListItem>
          {showEmployees && <>
            <Divider kind="primary" />
            <ListSubheader>
              {businessAccount?.business?.name}'s team members ({employeeOptions.length})
            </ListSubheader>
            <div>
              {employeeOptions
                ?.filter((employeeOption) => employeeOption.id !== businessAccount?.employee?.id)
                .map((employeeOption) => (
                  <ListItem
                    key={employeeOption.id}
                    startIcon={
                      <AvatarCircle
                        firstName={employeeOption.firstName}
                        lastName={employeeOption.lastName}
                        nonInteractable
                      />
                    }
                    title={<Group direction="horizontal" gap={8}>
                      {employeeOption.firstName} {employeeOption.lastName}
                      {employeeOption.id === travellerSelectState.selectedTravellers[0] && <Pill kind="tertiary" variant="primary">
                        Primary
                      </Pill>}
                    </Group>}
                    subtitle={employeeOption.email}
                    data-employeeid={employeeOption.id}
                    onClick={handleEmployeeClick}
                    endIcon={<CheckboxButton checked={employeeOption.isSelected} />}
                  />
                ))}
            </div>
          </>}
        </>
      )}
      {showFilteredEmployees && (
        <div>
          {filteredEmployeeOptions.map((employeeOption) => <ListItem
            key={employeeOption.id}
            startIcon={<AvatarCircle
              firstName={employeeOption.firstName}
              lastName={employeeOption.lastName}
              nonInteractable
            />}
            title={`${employeeOption.firstName} ${employeeOption.lastName}`}
            subtitle={employeeOption.email}
            data-employeeid={employeeOption.id}
            onClick={handleEmployeeClick}
            endIcon={<CheckboxButton checked={employeeOption.isSelected} />}
          />)}
        </div>
      )}
      {showNoResults && <ListSubheader>No results</ListSubheader>}
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    businessAccount: state.businessTraveller.currentBusinessAccount,
    approvalRequests: state.businessTraveller.approvalRequests?.request,
  }
})(BusinessTravellerMultiSelectContent)
