import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import React, { ChangeEventHandler, useCallback, useContext, useMemo, useState } from 'react'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import Group from 'components/utils/Group'
import TextInputList from 'components/Common/Form/Input/TextInputList'
import { connect } from 'react-redux'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { GlobalSearchStateContext, GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import InputText from 'components/Luxkit/Typography/InputText'
import Counter from 'components/Luxkit/Counter'
import { getMaxOccupancies } from 'lib/offer/occupancyUtils'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

function BusinessTravellerSelectModal(props: MappedStateProps) {
  const { travellerEmployees } = props

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { occupancies } = useContext(GlobalSearchStateContext)

  const maxCapacities = useMemo(() => {
    return getMaxOccupancies(undefined, occupancies[0])
  }, [occupancies])

  // Exclude the primary traveller from the count
  const adultsCount = occupancies[0]?.adults - 1
  const maxAdults = maxCapacities.adults - 1

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const handleOccupancyChange = useCallback((value: number) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies: [{ ...occupancies[0], adults: value + 1 }] })
  }, [globalSearchDispatch, occupancies])

  const modalElementContext = useModalElementContext()

  const dismiss = useCallback(() => {
    modalElementContext.resolve()
  }, [modalElementContext])

  const [selectedRoom, setSelectedRoom] = useState(false)

  const selectedTravellerString = travellerEmployees.length ? `${travellerEmployees[0]?.firstName} ${travellerEmployees[0]?.lastName}` : undefined

  return <Modal
    mode="drawer"
    title="Who is going?"
    primaryActionText="Continue"
    onPrimaryActionClick={dismiss}
    headerExtension={selectedRoom && <TextInputList
      value={searchTerm}
      type="text"
      placeholder="Search traveller name"
      onChange={handleSearchTermChange}
      endIcon={<LineSearchIcon />}
      noValidationSpacing
      autoFocus
      displayValue={selectedTravellerString}
    />}
  >
    {selectedRoom &&
      <BusinessTravellerSelectContent
        searchTerm={searchTerm}
        onTravellerSelect={() => setSelectedRoom(false)}
      />}
    {!selectedRoom &&
      <Group direction="vertical" gap={12}>
        <TextInputList
          type="text"
          placeholder="Search traveller name"
          label="Primary traveller"
          endIcon={<LineSearchIcon />}
          noValidationSpacing
          contentEditable={false}
          onFocus={() => setSelectedRoom(true)}
          required
          displayValue={selectedTravellerString}
        />
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
          <label htmlFor="occupancy-item-adults">
            <InputText variant="label">Other travellers in room</InputText>
          </label>
          <Counter
            data-testid="occupancy-item-adults"
            value={adultsCount}
            onChange={handleOccupancyChange}
            max={maxAdults}
            name="room.adults"
          />
        </Group>
      </Group>
    }
  </Modal>
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectModal)
