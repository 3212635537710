import { isAccommodationItem, isBedbankItem, isExperienceItem, isFlightItem, isInstantBookingLEHotelItem, isLEHotelItem, isTransferItem } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { getAllOffers } from './offerSelectors'
import { last, sortBy } from 'lib/array/arrayUtils'

export const getUserCartItemsArray = createSelector(
  (state: App.State) => state.auth.userCart.items,
  (items) => Object.values(items),
)

export const getNumberOfActiveUserCartItems = createSelector(
  (state: App.State) => state.auth.userCart.itemStates || {},
  getUserCartItemsArray,
  (states, items) => {
    return items.filter(item => !states[item.itemId]?.deleted && !states[item.itemId]?.saveForLater).length
  },
)

export const getUserCartAccommodationItems = createSelector(
  (state: App.State) => getUserCartItemsArray(state),
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),
)

export const getUserCartFlightItems = createSelector(
  (state: App.State) => getUserCartItemsArray(state),
  (items) => items.filter(isFlightItem),
)

export const getUserCartFlightOrigin = createSelector(
  (state: App.State) => getUserCartFlightItems(state),
  (items) => items.filter(isFlightItem).filter(item => !!item.bundledItemIds?.length)[0]?.originAirportCode,
)

export const getUserCartExperienceItems = createSelector(
  (state: App.State) => getUserCartItemsArray(state),
  (items): Array<App.Checkout.ExperienceItem> => items.filter(isExperienceItem),
)

export const getUserCartTransferItems = createSelector(
  (state: App.State) => getUserCartItemsArray(state),
  (items): Array<App.Checkout.TransferItem> => items.filter(isTransferItem),
)

export const selectCartOffers = createSelector(
  (state: App.State) => state.auth.userCart.items,
  getAllOffers,
  (cartItems, offers) => {
    return Object.values(cartItems).reduce((acc: Record<string, App.Offer | App.ExperienceOffer>, item: App.Checkout.AnyItem) => {
      if (isExperienceItem(item) && offers?.[item?.experienceId]) {
        acc[item.experienceId] = offers[item.experienceId] as App.ExperienceOffer
      } else if ((isLEHotelItem(item) || isBedbankItem(item)) && offers?.[item?.offerId]) {
        acc[item.offerId] = offers[item.offerId] as App.Offer
      }
      return acc
    }, {})
  },
)

interface SnackbarData {
  heading?: string
  description: string
}

type ItemTypeHandler = (items: Array<App.Checkout.AnyItem>, offers: Record<string, App.AnyOffer>, experiences: Record<string, App.ExperienceOffer>) => SnackbarData | undefined

function getFlightAirportMessage(items: Array<App.Checkout.FlightItem>) {
  const flights = sortBy(items.map(item => item.flights.map(flight => ({
    fareType: item.fareType,
    timestamp: new Date(flight.departingDate).getTime(),
    departingAirportName: flight.departingAirportName,
    arrivalAirportName: flight.arrivalAirportName,
  }))).flat(), item => item.timestamp, 'asc')
  const firstFlightAirport = flights[0].departingAirportName
  const lastFlight = last(flights)
  // Show destination airport for return and arrival for one-way or multi-city
  const isReturnFlight = flights.length === 2 && lastFlight.fareType !== 'multiCity'
  const lastFlightAirport = isReturnFlight ? lastFlight.departingAirportName : lastFlight.arrivalAirportName
  return `${firstFlightAirport} to ${lastFlightAirport}`
}

const flightSnackbarHandler: ItemTypeHandler = (items) => {
  if (!items.every(isFlightItem)) return

  const isSingleItem = items.length === 1
  const firstItem = items[0]

  const headingMap: Record<App.Checkout.FlightItem['fareType'], string> = {
    return: 'Return flights added to cart',
    oneWay: isSingleItem ? 'One-way flight added to cart' : 'Return flights added to cart',
    multiCity: 'Multi-city flights added to cart',
  }
  return {
    heading: headingMap[firstItem.fareType] || 'Flight(s) added to cart',
    description: getFlightAirportMessage(items),
  }
}

const experienceSnackbarHandler: ItemTypeHandler = (items, _, experiences) => {
  if (!items.every(isExperienceItem)) return
  return {
    heading: 'Experience added to cart',
    description: experiences[items[0].experienceId].name,
  }
}

const transferSnackbarHandler: ItemTypeHandler = (items) => {
  if (!items.every(isTransferItem)) return
  const typeLookup: Record<App.ExperienceTransferView['type'], string> = {
    'AIRPORT-TO-HOTEL': 'Airport to hotel',
    'HOTEL-TO-AIRPORT': 'Hotel to airport',
  }
  const isSingleItem = items.length === 1
  return {
    heading: 'Airport transfers added to cart',
    description: isSingleItem ? typeLookup[items[0].transfer.type] : 'Round trip',
  }
}

const hotelSnackbarHandler: ItemTypeHandler = (items, offers) => {
  if (!items.every(isInstantBookingLEHotelItem) && !items.every(isBedbankItem)) return
  const description = offers[items[0].offerId].name
  const heading = items.length > 1 ? `${items.length} rooms added to cart` : 'Hotel room added to cart'
  return { heading, description }
}

const flightHotelSnackbarHandler: ItemTypeHandler = (items, offers) => {
  if (!(items.some(isFlightItem) && (items.some(isInstantBookingLEHotelItem) || items.some(isBedbankItem)))) return
  const hotelItems = items.filter(item => isInstantBookingLEHotelItem(item) || isBedbankItem(item))
  const headingBase = hotelItems.length > 1 ? `${hotelItems.length} rooms` : 'Hotel'
  const description = `${offers[hotelItems[0].offerId].name ?? 'Hotel'} + Return flights`
  return {
    heading: `${headingBase} + flights added to cart`,
    description,
  }
}

const itemTypeHandlers: Array<ItemTypeHandler> = [
  flightSnackbarHandler,
  experienceSnackbarHandler,
  transferSnackbarHandler,
  hotelSnackbarHandler,
  flightHotelSnackbarHandler,
]

export const getAddToCartSnackbarData = createSelector(
  getAllOffers,
  (state: App.State) => state.experience.experiences,
  (_: App.State, addedItems: Array<App.Checkout.AnyItem>) => addedItems,
  (offers, experiences, addedItems): SnackbarData => {
    for (const handler of itemTypeHandlers) {
      const result = handler(addedItems, offers, experiences)
      if (result) return result
    }
    return { description: 'Item(s) added to cart' }
  },
)
