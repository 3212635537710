import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { matchPath } from 'react-router'
import * as Analytics from 'analytics/analytics'
import { useAppSelector } from 'hooks/reduxHooks'
import useClickOutside from 'hooks/useClickOutside'
import MasterModalContext from 'contexts/MasterModalContext'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import TextButton from 'components/Luxkit/Button/TextButton'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import BodyText from 'components/Luxkit/Typography/BodyText'
import StickyPrompt from 'components/Common/StickyPromptCard/StickyPrompt'
import EnablePushNotificationsPromptIcon from './EnablePushNotificationsPromptIcon'

const PushNotificationsPrompt = styled(StickyPrompt)`
  padding: ${rem(24)};

  ${mediaQueryUp.tablet} {
    width: ${rem(360)};
  }
`

const EnablePushNotificationsPromptRoutes = [
  '/:regionCode/hotels',
  '/:regionCode/tours',
  '/:regionCode/cruises',
  '/:regionCode/car-hire',
  '/:regionCode/experiences',
  '/:regionCode/flights',
  '/:regionCode/homes-and-villas',
  '/:regionCode/search',
  '/:regionCode/lux-plus',
  '/:regionCode/ultra-lux',
]

function EnablePushNotificationsPrompt() {
  const promptRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const loggedIn = useAppSelector(selectLoggedIn)
  const modalState = useContext(MasterModalContext)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isMobileDevice = useIsMobileDevice()

  const isRouteValid = useMemo(() => (
    matchPath(currentPath, { path: '/:regionCode', exact: true }) || matchPath(currentPath, { path: EnablePushNotificationsPromptRoutes, exact: false })
  ), [currentPath])

  // TODO: change it to a 90-day expiration flag (cookies or local storage)
  useEffect(() => {
    if (loggedIn && !modalState.open) {
      setShow(true)

      Analytics.trackClientEvent({
        subject: 'push_primer',
        action: 'impression',
        category: 'web_push_notifications',
        type: 'nonInteraction',
      })
    }
  }, [loggedIn, modalState.open])

  useEffect(() => {
    if (!loggedIn || !isRouteValid) {
      setShow(false)
    }
  }, [loggedIn, isRouteValid])

  const dismissPrompt = useCallback(() => {
    setShow(false)
  }, [])

  const onEnablePushNotifications = useCallback(() => {
    dismissPrompt()
    // TODO: add requestBrowserPushPermission() call when ready
  }, [dismissPrompt])

  useClickOutside(promptRef, dismissPrompt, show)

  if (isMobileDevice) {
    return null
  }

  return <PushNotificationsPrompt promptPosition="left" show={show} ref={promptRef}>
    <Group direction="vertical" horizontalAlign="center" gap={16}>
      <Group direction="horizontal" gap={12}>
        <EnablePushNotificationsPromptIcon />
        <Group direction="vertical" gap={4}>
          <Heading variant="heading6">Enable notifications to get the latest updates, special offers, and more</Heading>
          <BodyText variant="medium" colour="neutral-two">You can disable these anytime from your browser settings.</BodyText>
        </Group>
      </Group>
      <Group direction="horizontal" gap={12}>
        <TextButton size="large" kind="tertiary" onClick={dismissPrompt}>
          Maybe later
        </TextButton>
        <TextButton size="large" kind="primary" onClick={onEnablePushNotifications}>
          Yes, notify me
        </TextButton>
      </Group>
    </Group>
  </PushNotificationsPrompt>
}

export default React.memo(EnablePushNotificationsPrompt)
