import { useQuery } from '@tanstack/react-query'
import request, { authOptions } from 'api/requestUtils'
import { AnswersParam } from 'marketing/pages/LETVPage'
import { definitions } from '@luxuryescapes/contract-svc-promo'
import { mapCompetitionConfig, mapCompetitionMinorPrize, mapCompetitionUserStatus, mapContentfulWebPageData } from './mapping'

export async function getReferralValue(region: string) {
  return request.get<App.ApiResponse<App.ReferralValue>>(`/api/referral/value?regionCode=${region}`)
}

export async function getReferralLogs(accessToken?: string) {
  return request.get<App.ApiResponse<App.ReferralLogs>>('/api/referral/logs', authOptions(accessToken))
}

export async function getEarnOptions(region: string) {
  return request.get<App.ApiResponse<Array<App.EarnOption>>>(`/api/referral/earn-options?region=${region}`)
}

export async function getLETVQuestions() {
  return request.get('/api/promo/competition/le-tv/questions')
}

export async function postLETVAnswers(answers: AnswersParam) {
  return request.post('/api/promo/competition/le-tv/answers', answers, { credentials: 'include' })
}

export async function getLETVPrize(debugNoSave: boolean) {
  return request.get(`/api/promo/competition/le-tv/game${debugNoSave ? '?debug_no_save=true' : ''}`, { credentials: 'include' })
}

export async function getBestBackPrize() {
  return request.get('/api/promo/competition/bestOfAllTime/entry', { credentials: 'include' })
}

export async function getBestBackObject() {
  return request.get('/api/promo/competition/bestOfAllTime/config')
}

export async function getBestBackUserStatus(accessToken?: string) {
  return request.get('/api/promo/competition/bestOfAllTime/check', authOptions(accessToken))
}

export async function getNblCompetitionPrize() {
  return request.get('/api/promo/competition/nblCompetition/entry', { credentials: 'include' })
}

export async function getNblCompetitionObject() {
  return request.get('/api/promo/competition/nblCompetition/config')
}

export async function getNblCompetitionUserStatus(accessToken?: string) {
  return request.get('/api/promo/competition/nblCompetition/check?', authOptions(accessToken))
}

export async function getAuCompetitionPrize() {
  return request.get('/api/promo/competition/auCompetition/entry?', { credentials: 'include' })
}

export async function getAuCompetitionObject() {
  return request.get('/api/promo/competition/auCompetition/config')
}

export async function getAuCompetitionUserStatus(region?: string, accessToken?: string) {
  return request.get(`/api/promo/competition/auCompetition/check?region=${region}`, authOptions(accessToken))
}

export async function getLETVUserStatus(accessToken?: string) {
  return request.get('/api/promo/competition/le-tv/user-status', authOptions(accessToken))
}

export async function getUkCompetitionPrize() {
  return request.get('/api/promo/competition/ukCompetition/entry', { credentials: 'include' })
}

export async function getUkCompetitionObject() {
  return request.get('/api/promo/competition/ukCompetition/config')
}

export async function getUkCompetitionUserStatus(accessToken?: string) {
  return request.get('/api/promo/competition/ukCompetition/check', authOptions(accessToken))
}

export async function getIntlCompetitionPrize(region?: string) {
  return request.get(`/api/promo/competition/intlCompetition/entry?region=${region}`, { credentials: 'include' })
}

export async function getIntlCompetitionObject(region?: string) {
  return request.get(`/api/promo/competition/intlCompetition/config?region=${region}`)
}

export async function getIntlCompetitionUserStatus(region?: string, accessToken?: string) {
  return request.get(`/api/promo/competition/intlCompetition/check?region=${region}`, authOptions(accessToken))
}

export async function getCompetitionPrize(region?: string, competitionName?: string) {
  return request.get(`/api/promo/competition/${competitionName}/entry?region=${region}`, { credentials: 'include' })
}

export async function getCompetitionObject(region?: string, competitionName?: string) {
  return request.get(`/api/promo/competition/${competitionName}/config?region=${region}`)
}

export async function getCompetitionUserStatus(region?: string, competitionName?: string, accessToken?: string) {
  return request.get(`/api/promo/competition/${competitionName}/check?region=${region}`, authOptions(accessToken))
}

export async function getDestinationPage(destination: string) {
  return request.get(`/api/content/pageDestinationDiscover/${destination}`)
}

export async function getContentfulWebPage(path: string, regionCode: string) {
  return request.get(`/api/content/web-page?slug=${path}&regionCode=${regionCode}`)
}

export function useCompetitionConfig(slug: string, region?: string) {
  return useQuery<any, {errors: Array<string>}>({
    queryKey: [`competition-config-${slug}`],
    queryFn: () => getCompetitionObject(region, slug),
    select: (response: { result: definitions['CompetitionConfigV2'] }): App.MarketingCompetitionConfig => {
      return mapCompetitionConfig(response.result)
    },
  })
}

export function useCompetitionPrize(competitionName?: string, episodeId = 1, region?: string) {
  return useQuery({
    queryKey: [`competition-prize-${competitionName}-${episodeId}`],
    queryFn: () => getCompetitionPrize(region, competitionName),
    enabled: false,
    select: (response: any): App.MarketingCompetitionMinorPrize => {
      return mapCompetitionMinorPrize(response)
    },
  })
}

export function useCompetitionStatus(competitionName?: string, episode = 1, region?: string) {
  return useQuery({
    queryKey: [`competition-status-${competitionName}-${episode}`],
    queryFn: () => getCompetitionUserStatus(region, competitionName),
    select: (response: any): App.MarketingCompetitionUserStatus => {
      return mapCompetitionUserStatus(response)
    },
  })
}

export function useContentfulWebPage(path: string, regionCode: string) {
  return useQuery({
    queryKey: ['contentful-web-page', path],
    queryFn: () => getContentfulWebPage(path, regionCode),
    select: (response: any): App.ContentfulWebPageData => {
      return mapContentfulWebPageData(response.result[0].fields)
    },
  })
}
