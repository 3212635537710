import React from 'react'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { getIsLuxLoyaltyEnabled, getIsLuxLoyaltyLoadTestEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { connect } from 'react-redux'
import PriceRowLuxLoyaltyPoints from 'components/Luxkit/PricePoints/LuxLoyalty/PriceRowLuxLoyaltyPoints'
import { getLuxLoyaltyDefaultTier } from 'luxLoyalty/selectors/utils'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import useLuxLoyaltyPointsEarnCalculator from 'luxLoyalty/hooks/useLuxLoyaltyPointsEarnCalculator'

/**
 * This is needed to load the element in the DOM
 * without it being visible or interactive i.e. hidden
 * from the user.
 */
const HiddenElement = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
`

interface MappedProps {
  luxLoyaltyEnabled: boolean;
  loyaltyAccount?: App.LuxLoyaltyAccount;
  defaultTier: App.LuxLoyaltyTier;
  isLuxLoyaltyLoadTestEnabled: boolean;
}

interface Props extends MappedProps {
  calculationRequests: Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>;
  /**
   * @default the base or starting program tier.
   * The user's LuxLoyalty account tier will be applied if it exits.
   * If needed, the tier can be overridden by passing a different value (e.g. for tier upgrades).
   */
  tier?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['tier'];
  /**
   * @default 'S'
   */
  size?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['size'];
  /**
   * @default false
   */
  hideStatusCredits?: boolean;
  calculationType: 'estimate' | 'exact';
}

function LuxLoyaltyPoints(props: Props) {
  const {
    luxLoyaltyEnabled,
    loyaltyAccount,
    calculationRequests,
    size = 'S',
    tier,
    defaultTier,
    hideStatusCredits = false,
    calculationType,
    isLuxLoyaltyLoadTestEnabled,
  } = props

  const [luxLoyaltyPointsRef, luxLoyaltyPointsInView] = useInView({
    triggerOnce: true,
  })

  const { totalPointsCalculation } = useLuxLoyaltyPointsEarnCalculator(calculationRequests, {
    skipAll: !luxLoyaltyPointsInView,
  })

  const initial = totalPointsCalculation?.initial
  const fetching = !!totalPointsCalculation?.fetching
  const err = totalPointsCalculation?.error
  const points = totalPointsCalculation?.data?.points ?? 0
  const statusCredits = totalPointsCalculation?.data?.statusCredits ?? 0

  /** Temp for load test */
  if (isLuxLoyaltyLoadTestEnabled) {
    if (!initial && !fetching) return null
    return <HiddenElement ref={luxLoyaltyPointsRef} />
  }

  if (!luxLoyaltyEnabled || err || (!initial && !fetching && !points)) return null

  return <div ref={luxLoyaltyPointsRef}>
    {fetching && <TextLoadingBox
      width="20ch"
      lines={1}
      typography="body-large"
    />}
    {!initial && !fetching && <PriceRowLuxLoyaltyPoints
      tier={tier ?? loyaltyAccount?.tier ?? defaultTier}
      earnablePoints={points}
      size={size}
      calculationType={calculationType}
      statusCredits={hideStatusCredits ? undefined : statusCredits}
    />}
  </div>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isLuxLoyaltyLoadTestEnabled: getIsLuxLoyaltyLoadTestEnabled(state),
  luxLoyaltyEnabled: getIsLuxLoyaltyEnabled(state),
  loyaltyAccount: state.luxLoyalty.account.data,
  defaultTier: getLuxLoyaltyDefaultTier(state),
})

export default connect(mapStateToProps)(LuxLoyaltyPoints)
