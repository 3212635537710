import {
  API_CALL,
  API_CALL_SUCCESS,
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  OFFER_PUSH_TO_LIST_BOTTOM,
  OFFER_SET_SEARCH_RESULT_METADATA,
  OFFER_SET_TOUR_SEARCH_RESULT_METADATA,
  OFFER_SHARE_COMPLETE,
  OFFER_SHARE_START,
  OFFER_UPDATE_AVAILABLE_RATES,
  OFFER_UPDATE_PRICING_DATA,
  OFFER_VIEWED,
  OFFERS_CLEAR,
  REMOVE_OFFER,
  ADD_OFFER_LIST_EXTRA,
  END_OFFER_LIST_STREAM,
  SET_OFFER_LIST_ERROR,
} from './actionConstants'
import {
  FETCH_ALTERNATIVE_OFFERS,
  FETCH_AVAILABILITY_RATES_FOR_OFFER,
  FETCH_BEST_OFFER_FOR_PROPERTY,
  FETCH_BEST_PRICE_FOR_OFFER,
  FETCH_OFFER,
  FETCH_OFFER_FLIGHT_PRICE,
  FETCH_OFFER_LIST,
  FETCH_OFFER_LIST_FILTERS,
  FETCH_OFFER_LOCATION_BREADCRUMBS,
  FETCH_OFFERS,
  FETCH_RELATED_TRAVEL_ITEMS,
  FETCH_TOUR_SEARCH_FACETS,
  FETCH_TRADER_INFORMATION,
  FETCH_POPULAR_FILTERS,
  FETCH_SURCHARGE_MARGIN,
  FETCH_OFFER_LIST_STREAM_SCROLL,
  FETCH_PROPERTY_OFFER_MAPPING,
} from './apiActionConstants'

import * as CalendarV2Service from 'api/calendarV2'
import * as OfferService from 'api/offer'
import { OfferListResult, streamRequest } from 'api/offer'
import * as SearchService from 'api/search'
import * as RecommendationService from 'api/recommendations'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_HOTEL_SLUG,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_SLUG,
  OFFER_TYPE_TOUR_V2,
  PRODUCT_TYPE_ULTRALUX_SLUG,
} from 'constants/offer'
import placesTree from 'constants/placesTree'
import { arrayToObject, groupBy, skip, sortBy, take, unique, uniqueBy } from 'lib/array/arrayUtils'
import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import getOfferListKey from 'lib/offer/offerListKey'
import { isBedbankOffer, isBundleOffer, isCruiseOffer, isLEOffer, isTourV1Offer } from 'lib/offer/offerTypes'
import { nameToSearchFriendlyQueryParamsTransform, urlTransform } from 'lib/string/removeSpaceUtils'
import {
  buildDestinationSearchParamsKey,
  buildSearchParamsFromFilters,
  buildSearchParamsKey,
  buildSuggestedDatesParamsKey,
  getOccupancyFromSearchStrings, isSocketSafeToDisconnect,
  updateLeBrandSpecificFilters,
} from 'lib/search/searchUtils'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { convertBedbankOfferToSummary } from './BedbankOfferActions'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { timeNowInSecond } from 'lib/datetime/time'
import getObjectKey from 'lib/object/getObjectKey'
import invariant from 'tiny-invariant'
import { saveHighIntentOffersToLocal } from 'components/Recommendations/utils/highIntentLocal'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { isPaidSession } from 'selectors/offerSelectors'
import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { AppAction } from './ActionTypes'
import { disconnectSearchSocket, getSearchSocket } from 'search/initialiseSearchSocket'
import { mapSearchResultToOfferListMetaData } from 'api/mappers/hotelOfferMap'
import { paths, SearchResultEntry, SearchNotFoundError, SearchValidationError } from '@luxuryescapes/contract-search'
import { isSpoofed } from 'selectors/featuresSelectors'
import { batchFetchSurchargeMargin } from 'api/reservation'
import uuidV4 from 'lib/string/uuidV4Utils'
import { getChannelMarkup } from 'selectors/channelMarkupSelector'
import { objectEntries } from 'lib/object/objectUtils'

export function offerShareButtonClick(pageId: string, pageType: string): AppAction {
  return {
    type: OFFER_SHARE_START,
    data: {
      pageId,
      pageType,
    },
  }
}

export function offerShareComplete(pageId: string, pageType: string, shareMethod: string): AppAction {
  return {
    type: OFFER_SHARE_COMPLETE,
    data: {
      pageId,
      pageType,
      shareMethod,
    },
  }
}

export function fetchAccumulatedOfferById(
  offerId: string,
  regionCode?: string,
  flightOrigin?: string,
): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    if (state.offer.offerErrors[offerId] || state.offer.offersLoading[offerId] || state.offer.offers[offerId]) {
      return
    }
    const channelMarkup = getChannelMarkup(state)

    const isSpoofed = state?.auth?.account.isSpoofed

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER,
      request: async() => {
        const offer = await OfferService.getAccumulatedOfferById(offerId, regionCode || state.geo.currentRegionCode, flightOrigin || getDefaultAirportCode(state), state.geo.currentCurrency, isSpoofed, channelMarkup)

        if (!offer) {
          return Promise.reject(`Offer not found ${offerId}`)
        }

        return offer
      },
      offerId,
    })
  }
}

interface FetchOneOfferOptions { regionCode?: string, flightOrigin?: string, allPackages?: boolean, privateRequestKey?: string }

export function fetchOfferById(offerId: string, {
  regionCode,
  flightOrigin,
  allPackages = false,
  privateRequestKey,
}: FetchOneOfferOptions = {}): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const isSpoofed = state?.auth?.account.isSpoofed

    const offerKey = privateRequestKey ? `${offerId}-${privateRequestKey}` : offerId

    if (state.offer.offerErrors[offerKey] || state.offer.offersLoading[offerKey] || state.offer.offers[offerKey]) {
      return
    }
    const channelMarkup = getChannelMarkup(state)

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER,
      request: () => OfferService.getOfferById(offerId, {
        region: regionCode || state.geo.currentRegionCode,
        flightOrigin: flightOrigin || getDefaultAirportCode(state),
        allPackages,
        privateRequestKey,
        currentCurrency: state.geo.currentCurrency,
        isSpoofed,
        channelMarkup,
      }),
      offerId: offerKey,
    })
  }
}

export function fetchOfferFlightPrice(offerId: string, flightOrigin: string): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    const existingOffer = state.offer.offers[offerId]
    if (existingOffer?.flightPrices[flightOrigin] || !existingOffer) {
      // already have price, no need to re-load
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_FLIGHT_PRICE,
      request: () => OfferService.getOfferFlightPrice({
        flightOrigin,
        flightDestination: existingOffer.flightDestinationPort,
        currency: state.geo.currentCurrency,
        region: state.geo.currentRegionCode,
        duration: existingOffer.lowestPricePackage?.duration ?? 1,
        forceBundleId: existingOffer.forceBundleId,
        travelToDate: existingOffer.travelToDate,
      }),
      offerId,
      flightOrigin,
    })
  }
}

function convertLEOfferToSummary(offer: App.Offer): App.OfferSummary {
  const {
    packages,
    highlights,
    whatWeLike,
    facilities,
    finePrint,
    gettingThere,
    ...offerSummary
  } = offer
  return {
    ...offerSummary,
    // offer summaries have the field present, so it can work in tandem with an actual offer
    // but the packages will always be empty to save space
    packages: [],
  }
}

export function convertOfferToSummary(
  offer: App.Offer | App.BedbankOffer,
): App.OfferSummary | App.BedbankOfferSummary {
  if (isBedbankOffer(offer)) {
    return convertBedbankOfferToSummary(offer)
  } else if (isBundleOffer(offer) || isCruiseOffer(offer)) {
    // some offer type doesn't know how to summary yet
    return offer
  } else {
    return convertLEOfferToSummary(offer)
  }
}

type FetchOfferOptions = {
  regionCode?: string;
  includePackages?: boolean;
}

/**
 * Bulk fetch of offers
 *
 * @remarks
 * Before requesting the fetch,
 * Checks are made against offers currently being loaded and offers that are missing data
 *
 * This ensures we only fetch when absolutely necessary
 */
export function fetchOffersById(
  offerIds: Array<string> = [],
  options: FetchOfferOptions = {},
): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const { includePackages, regionCode } = options
    const missingOfferIds = unique(offerIds.filter(function(id) {
      if (
        // don't have the offer at all and it's not currently being loaded
        (!state.offer.offers[id] && !state.offer.offersLoading[id] && !state.offer.offerErrors[id]) ||
        // needs packages but is missing them, we have to refetch
        (includePackages && state.offer.offers[id]?.packages.length === 0)
      ) {
        return true
      }
    }))

    if (missingOfferIds.length === 0) {
      // already have all the offers
      return
    }

    const authState: App.AuthState = state.auth
    const channelMarkup = getChannelMarkup(state)

    const fetchOptions = {
      ...options,
      flightOrigin: getDefaultAirportCode(state),
      currentCurrency: state.geo.currentCurrency,
      isSpoofed: authState.account.isSpoofed,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFERS,
      request: () => OfferService.getOffersById(missingOfferIds, regionCode || state.geo.currentRegionCode, fetchOptions, channelMarkup),
      offerIds: missingOfferIds,
    })
  }
}

interface Options {
  clientCheckAvailability?: boolean;
  evVersion?: 'current' | 'next';
  leaveSearchSocketOpen?: boolean;
}

export function fetchOfferList(filters: App.OfferListFilters = {}, options: Options = {}): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const isPaidSessionEnabled = isPaidSession(state)
    const channelMarkup = getChannelMarkup(state)

    const offerListKey = getOfferListKey(filters)

    if (state.offer.offerLists[offerListKey]) {
      // already have the results for this set of params
      return
    }

    if (filters.checkIn && !filters.checkOut) {
      // This request is only valid if both checking and checkout dates are provided
      return
    }

    const domainUserId = getDomainUserId()
    const memberId = state.auth.account.memberId

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_LIST,
      request: () => OfferService.getOfferList({
        region: state.geo.currentRegionCode,
        filters,
        canViewLuxPlusBenefits,
        isLuxPlusEnabled: luxPlusEnabled,
        clientCheckAvailability: options.clientCheckAvailability,
        evVersion: options.evVersion,
        isPaidSession: isPaidSessionEnabled,
        memberId,
        domainUserId,
        isSpoofed: isSpoofed(state),
        accessToken: state.auth.accessToken,
        channelMarkup,
      }).then((results) => {
        let metaData: Array<App.OfferListMetaData> = results.metaData || []
        const offerIds: Array<string> = results.offerIds
        // Finding all bundle offers and creating a map object
        const mapBundledOffers = arrayToObject(
          metaData.filter(m => m.bundledOfferId && m.available),
          r => r.bundledOfferId!,
          r => r.offerId,
        )

        // Adding for single offers property bundleOfferId
        metaData = metaData.map(r => mapBundledOffers[r.offerId] ? {
          ...r,
          bundleOfferId: mapBundledOffers[r.offerId],
        } : r)

        if (metaData.length > 0 && (filters.landmarkId || filters.destinationId || filters.propertyId || filters.bounds)) {
          dispatch(setSearchResultMetadata(metaData, filters))
        }

        const tourMetadata = results.tourMetadata || []
        if ((tourMetadata.length > 0) && (filters.destinationId)) {
          dispatch(setTourSearchResultMetadata(tourMetadata, filters))
        }

        if (results.filters) {
          const filters = updateLeBrandSpecificFilters(results.filters) as App.OfferListAvailableFilters
          const filterOrder = updateLeBrandSpecificFilters(results.filterOrder) ?? { amenities: {}, holidayTypes: {}, locations: {} }
          const offerListFilters: Partial<App.OfferListFilterOptions> = {
            filters,
            filterOrder,
            orderedFilters: {
              holidayTypes: sortBy(
                objectEntries(filters.holidayTypes),
                ([_, count]) => count,
                'desc',
              ).map(([value, count]) => ({ value, count })),
              amenities: sortBy(
                objectEntries(filters.amenities),
                ([_, count]) => count,
                'desc',
              ).map(([value, count]) => ({ value, count })),
            },
          }
          dispatch({
            type: API_CALL_SUCCESS,
            api: FETCH_OFFER_LIST_FILTERS,
            data: offerListFilters,
            key: offerListKey,
          })
        }

        return {
          searchId: results.searchId,
          mainOfferList: unique(filters.limit ? take(offerIds, filters.limit) : offerIds),
          offerCount: results.offerCount,
          searchVertical: results.searchVertical,
        }
      }),
      key: offerListKey,
    })
  }
}

export function fetchOfferListFilters(filters: App.OfferListFilters): AppAction {
  // filters come with the offer list in search now
  return fetchOfferList(filters)
}

export function fetchTourSearchFacets(filters: App.OfferListFilters = {}): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const destinationId = filters.destinationId
    const excludeIds = filters.offerIdsToExclude?.join(',')

    const params = {
      placeId: destinationId,
      region: state.geo.currentRegionCode,
      category: filters.categories?.[0],
      luxPlusExclusive: filters.luxPlusFeatures,
      priceGte: filters.priceGte ? parseFloat(filters.priceGte) : undefined,
      priceLte: filters.priceLte ? parseFloat(filters.priceLte) : undefined,
      onSale: filters.onSale,
      tourLengthGte: filters.tourLengthGte ? parseFloat(filters.tourLengthGte) : undefined,
      tourLengthLte: filters.tourLengthLte ? parseFloat(filters.tourLengthLte) : undefined,
    }

    const key = getObjectKey(params)
    if (state.tour.tourFacets[key] && !excludeIds) {
      // already have the results for this set of params
      return
    }
    if ((filters.offerTypes?.includes(OFFER_TYPE_TOUR) || filters.offerTypes?.includes(OFFER_TYPE_TOUR_V2)) && destinationId) {
      dispatch({
        type: API_CALL,
        api: FETCH_TOUR_SEARCH_FACETS,
        request: () => SearchService.getTourFacets({ ...params, placeId: destinationId, excludeIds }),
        key,
      })
    }
  }
}

interface BestPriceParams {
  checkIn: string;
  checkOut: string;
  occupants: Array<App.Occupants>;
  bundledOfferId?: string;
}

export function fetchBestPriceForOffer(offer: App.Offer | App.OfferSummary, params: BestPriceParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = buildSearchParamsKey(params.checkIn, params.checkOut, params.occupants, params.bundledOfferId)
    const offerId = offer.id
    const channelMarkup = getChannelMarkup(state)

    if (
      state.offer.offerBestPrices[offerId]?.[key] ||
      state.offer.offerPricesLoading[offerId]?.[key] ||
      state.offer.offerPricesErrors[offerId]?.[key]
    ) {
      // Already have details, or are loading them, or encountered an error while loading them
      return
    }

    // occupants we're given don't transform children/infant categories
    // based on property, so we need to do it here
    const rooms = params.occupants.map(occupants =>
      getOccupancyFromSearchStrings(
        occupants.adults,
        occupants.childrenAge,
        offer.property?.maxChildAge,
        offer.property?.maxInfantAge,
      ),
    )

    dispatch({
      key,
      offerId,
      type: API_CALL,
      api: FETCH_BEST_PRICE_FOR_OFFER,
      request: () => CalendarV2Service.getLowestPriceForOffer({
        offerType: offer.type,
        offerIds: [offerId],
        regionCode: state.geo.currentRegionCode,
        currencyCode: state.geo.currentCurrency,
        timezone: offer.property?.timezone ?? 'UTC',
        checkIn: params.checkIn,
        checkOut: params.checkOut,
        rooms,
        bundledOfferId: params.bundledOfferId,
        saleUnit: offer.saleUnit,
        channelMarkup,
      }),
    })
  }
}

export function fetchBestOfferForProperty(propertyId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const [propertyType, id] = propertyId.split(':')

    if (state.offer.bestPropertyOffer[propertyId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEST_OFFER_FOR_PROPERTY,
      request: () => SearchService.getAlternativeOffers(
        id,
        propertyType === 'le' ? 'le' : 'bedbank',
        state.geo.currentRegionCode,
      ).then(result => {
        const leResultsByType = groupBy(result.leOfferDetails, res => res.type)

        // priority list of flash -> TAO -> LME -> bedbank
        const best = (
          leResultsByType.get(OFFER_TYPE_HOTEL) ??
          leResultsByType.get(OFFER_TYPE_ALWAYS_ON) ??
          leResultsByType.get(OFFER_TYPE_LAST_MINUTE)
        )
        return { offerId: best?.[0].offerId ?? result.bedbank[0], type: best?.[0].offerId ? 'le' : 'bedbank' }
      }),
      propertyId,
    })
  }
}

function setSearchResultMetadata(
  metaData: Array<App.OfferListMetaData>,
  filters: App.OfferListFilters,
): AppAction {
  const searchTargetId = filters.landmarkId ?? filters.destinationId ?? filters.propertyId ?? ''
  const availabilityKey = buildDestinationSearchParamsKey(searchTargetId, filters.checkIn, filters.checkOut, filters.rooms)
  const suggestedDatesKey = buildSuggestedDatesParamsKey(filters.flexibleMonths, filters.flexibleNights, filters.rooms)
  const offerMetaDataKey = getOfferListKey(filters)

  return {
    type: OFFER_SET_SEARCH_RESULT_METADATA,
    data: {
      distanceFromSearchTarget: {
        [searchTargetId]: arrayToObject(metaData, r => r.offerId, r => r.distance),
      },
      // all offers include unavailable ones
      offerAvailabilityFromSearchTarget: {
        [availabilityKey]: arrayToObject(metaData, r => r.offerId, r => r.available),
      },
      offerSuggestedDates: {
        [suggestedDatesKey]: arrayToObject(metaData, r => r.offerId, r => r.suggestedTravelDates),
      },
      offerMetaData: {
        [offerMetaDataKey]: arrayToObject(metaData, r => r.offerId, r => r),
      },
    },
    searchTargetId,
    availabilityKey,
    suggestedDatesKey,
    offerMetaDataKey,
  }
}

function setTourSearchResultMetadata(
  tourMetadata: Array<App.TourListMetadata>,
  filters: App.OfferListFilters,
): AppAction {
  const tourMetadataKey = getOfferListKey(filters)

  return {
    type: OFFER_SET_TOUR_SEARCH_RESULT_METADATA,
    data: {
      tourMetadata: {
        [tourMetadataKey]: arrayToObject(tourMetadata, r => r.tourId, r => r),
      },
    },
    tourMetadataKey,
  }
}

export interface AvailableRatesParams {
  checkIn: string;
  checkOut: string;
  occupants: Array<App.Occupants>;
  currencyCode?: string;
  flightOrigin?: string;
  bundleOfferId?: string;
}

export function fetchAvailableRatesForEachRoom(offer: App.Offer, params: AvailableRatesParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const offerId = offer.id
    const offerAvailableRatesByOccupancy = state.offer.offerAvailableRatesByOccupancy[offerId]
    const fetchedKeys = new Set(Object.keys(offerAvailableRatesByOccupancy || {}))

    params.occupants.map(occupants => {
      const key = buildAvailableRateKey(params.checkIn, params.checkOut, [occupants])
      if (!fetchedKeys.has(key)) {
        fetchedKeys.add(key)
        dispatch(fetchAvailableRates(offer, {
          ...params,
          occupants: [occupants],
        }))
      }
    })
  }
}

function updateAvailableRatesForOffer(offerId: string, key: string): AppAction {
  return {
    type: OFFER_UPDATE_AVAILABLE_RATES,
    offerId,
    key,
  }
}

export function fetchAvailableRatesForOffer(offer: App.Offer | App.OfferSummary | App.BundledOffer, params: AvailableRatesParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const offerId = offer.id
    const offerAvailableRates = state.offer.offerAvailableRatesByOccupancy[offerId]
    const currentOfferAvailableRates = state.offer.offerAvailableRates[offerId]

    const key = buildAvailableRateKey(params.checkIn, params.checkOut, params.occupants)

    if (!offerAvailableRates || !(key in offerAvailableRates)) {
      // we have not fetched data with this key before, call the API
      dispatch(fetchAvailableRates(offer, params))
    } else if (!!currentOfferAvailableRates && !(key in currentOfferAvailableRates)) {
      // we did fetched it before but offerAvailableRates is not using data with that key
      dispatch(updateAvailableRatesForOffer(offerId, key))
    }
  }
}

function fetchAvailableRates(offer: App.Offer | App.OfferSummary | App.BundledOffer, params: AvailableRatesParams): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const offerId = offer.id
    const key = buildAvailableRateKey(params.checkIn, params.checkOut, params.occupants)
    const channelMarkup = getChannelMarkup(state)

    // occupants we're given don't transform children/infant categories
    // based on property, so we need to do it here
    const rooms = params.occupants.map(occupants =>
      getOccupancyFromSearchStrings(
        occupants.adults,
        occupants.childrenAge,
        offer.property?.maxChildAge,
        offer.property?.maxInfantAge,
      ),
    )

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABILITY_RATES_FOR_OFFER,
      request: () => CalendarV2Service.getAvailableRatesForOffer({
        offerType: offer.type,
        offerIds: [params.bundleOfferId ?? offerId],
        regionCode: state.geo.currentRegionCode,
        currencyCode: params.currencyCode || state.geo.currentCurrency,
        timezone: offer.property?.timezone ?? 'UTC',
        checkIn: params.checkIn,
        checkOut: params.checkOut,
        rooms,
        dynamic: true,
        flightOrigin: params.flightOrigin,
        bundledOfferId: params.bundleOfferId ? offerId : undefined,
        channelMarkup,
      }),
      offerId,
      key,
    })
  }
}

function searchForLocation(location: string, place: App.Place): Array<App.Place> | undefined {
  if (place.name === location || place.canonicalName === location) {
    return [place]
  }
  if (place.children) {
    for (const child of place.children) {
      const result = searchForLocation(location, child)
      if (result !== undefined) {
        return [place, ...result]
      }
    }
  }
  return undefined
}

function getDeepestLocation(locations: Array<string>): Array<string> {
  let bestLocationPaths = [undefined] as Array<App.Place | undefined>
  for (const location of locations) {
    const locationPath = searchForLocation(location, { id: '', name: 'root', children: placesTree })
    if (locationPath !== undefined && locationPath.length > bestLocationPaths.length) {
      bestLocationPaths = locationPath
    }
  }

  return bestLocationPaths.length > 1 ? skip(bestLocationPaths, 1).map(path => path!.name) : take(locations, 1)
}

export function getRelatedTravelItemsForTour(offer: App.TourOffer): Array<App.BreadcrumbItem> {
  const locations = getDeepestLocation(offer.locations)
  const breadcrumbs: Array<App.BreadcrumbItem> = [
    { text: 'Tours', url: `/${OFFER_TYPE_TOUR_SLUG}` },
    ...locations.map(location => ({ text: location, url: `/${urlTransform(`${location}.Tours & Cruises`)}`.toLowerCase() })),
    { text: offer.lowestPricePackage.tour.name },
  ]
  return breadcrumbs
}

function breadcrumbURLConstructor(
  destination: Array<SearchService.Destination>,
  checkIn?: string,
  checkOut?: string,
  rooms?: Array<App.Occupants>,
) {
  const searchParams = buildSearchParamsFromFilters({
    checkIn,
    checkOut,
    rooms,
  })
  return `/search?destinationName=${nameToSearchFriendlyQueryParamsTransform(destination[0].name)}&destinationId=${destination[0].place_id}&${searchParams}`
}

export async function breadcrumbsFetcher(offer: App.Offer | App.BedbankOffer, state?: App.State, checkIn?: string, checkOut?: string, rooms?: Array<App.RoomOccupants>): Promise<Array<App.BreadcrumbItem>> {
  invariant(offer.property, 'Missing property on offer ' + offer.id)
  const leOffer = isLEOffer(offer)

  let firstItem = { text: 'Hotels', url: `/${OFFER_TYPE_HOTEL_SLUG}` }
  if (leOffer && offer.property.isUltraLux) {
    firstItem = { text: 'Ultra Lux', url: `/${PRODUCT_TYPE_ULTRALUX_SLUG}` }
  }

  let destinations: Array<SearchService.Destination>
  if (state?.offer.relatedTravelItems[offer.id]) {
    destinations = state.offer.relatedTravelItems[offer.id].map((destination) => {
      if (destination.placeId) {
        return { name: destination.text, place_id: destination.placeId }
      }
    }).filter((breadcrumb) => breadcrumb !== undefined) as Array<SearchService.Destination>
  } else {
    destinations = await SearchService.getBreadcrumbs(offer.property.id, (leOffer ? 'le' : 'bedbank'), false)
    destinations = destinations.reverse()
  }
  const locationPathDeduplicated = uniqueBy(destinations, d => d.name + '|' + d.place_id)
  const breadcrumbs = locationPathDeduplicated
    .map((location, index) => [location, ...locationPathDeduplicated.slice(0, index).reverse()])
    .map((locationPath) => ({
      text: locationPath[0].name,
      url: breadcrumbURLConstructor(locationPath, checkIn, checkOut, rooms),
      placeId: locationPath[0].place_id,
    }))

  let lastItem: App.BreadcrumbItem
  if (leOffer) {
    lastItem = {
      text: offer.property.name,
      url: `/offer/${offer.slug}/${offer.id}`,
    }
  } else {
    lastItem = {
      text: offer.name,
      url: `/partner/${offer.slug}/${offer.id}`,
    }
  }

  return [
    firstItem,
    ...breadcrumbs,
    lastItem,
  ]
}

export function fetchRelatedTravelItems(offer: App.Offer | App.BedbankOffer, checkIn?: string, checkOut?: string, rooms?: Array<App.RoomOccupants>): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (isTourV1Offer(offer)) {
      if (state.offer.relatedTravelItems[offer.id] !== undefined) {
        return
      }
      dispatch({
        type: API_CALL_SUCCESS,
        api: FETCH_RELATED_TRAVEL_ITEMS,
        offerId: offer.id,
        data: getRelatedTravelItemsForTour(offer),
      })
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_RELATED_TRAVEL_ITEMS,
      request: () => breadcrumbsFetcher(offer, state, checkIn, checkOut, rooms),
      offerId: offer.id,
    })
  }
}

export function fetchAlternativeOffers(propertyId: string, type: 'le' | 'bedbank', additionalKey: string = ''): AppAction {
  const key = [propertyId, additionalKey].join('')
  return (dispatch, getState) => {
    const state: App.State = getState()

    dispatch({
      type: API_CALL,
      api: FETCH_ALTERNATIVE_OFFERS,
      request: () => SearchService.getAlternativeOffers(propertyId, type, state.geo.currentRegionCode),
      key,
    })
  }
}

export function clearOffers(
  ids: {
    offerIds?: Array<string>,
    bedbankOfferIds?: Array<string>,
    tourV2OfferIds?: Array<string>,
  },
) {
  return {
    type: OFFERS_CLEAR,
    ids,
  }
}

export function fetchTraderInformation(
  offerId: string,
  regionCode?: string,
): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.offer.traderInformation[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TRADER_INFORMATION,
      request: () =>
        OfferService.getTraderInformation(
          offerId,
          regionCode || state.geo.currentRegionCode,
        ),
      offerId,
    })
  }
}

export function offerViewed(offerId: string, offerType: App.OfferType, isPriceMissing: boolean = false): AppAction {
  return (dispatch, getState) => {
    const newView: App.RecentlyViewedOffer = {
      offerId,
      offerType,
      creationTime: timeNowInSecond(), // store view time as sec
      category: 'recently_viewed',
      lereVersion: 'recently_viewed_local',
      isPriceMissing,
    }

    saveHighIntentOffersToLocal([newView])

    dispatch({
      type: OFFER_VIEWED,
      view: newView,
    })

    const state: App.State = getState()
    const userId = getCurrentUserId(state)
    const domainUserId = state.auth.domainUserId

    const isLuxPlusMember = checkCanViewLuxPlusBenefits(state)
    if (userId || domainUserId) {
      RecommendationService.saveRecentlyViewedOffersWithDebounce([newView], state.geo.currentRegionCode, isLuxPlusMember, domainUserId, userId)
    }
  }
}

export function fetchOfferLocationBreadcrumbs(offerId: string, type: 'le' | 'bedbank', showAllLocations: boolean): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.offer.locationBreadcrumbs[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_LOCATION_BREADCRUMBS,
      request: () => SearchService.getBreadcrumbs(offerId, type, showAllLocations),
      offerId,
    })
  }
}

export interface PricingData {
  price: number;
  value: number;
  taxesAndFees: number;
  propertyFees: number;
}
export function updateHotelPackagePricingData(
  offerId: string,
  uniqueKey: string,
  data: Partial<PricingData>,
): AppAction {
  return {
    type: OFFER_UPDATE_PRICING_DATA,
    offerId,
    uniqueKey,
    data,
  }
}

export function fetchPopularHotelFilters(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: API_CALL,
      api: FETCH_POPULAR_FILTERS,
      request: () => SearchService.getPopularHotelFilters(state.geo.currentRegionCode),
    })
  }
}

export function pushOfferToListBottom(offerId: string, listKey: string, reason: string): AppAction {
  return {
    type: OFFER_PUSH_TO_LIST_BOTTOM,
    offerId,
    listKey,
    reason,
  }
}

function addOfferListExtra(offerListKey: string, extra: App.OfferListExtra): AppAction {
  return {
    type: ADD_OFFER_LIST_EXTRA,
    key: offerListKey,
    extra,
  }
}

function endOfferListStream(action: {offerListKey: string, searchId: string}) {
  const { offerListKey, searchId } = action

  return {
    type: END_OFFER_LIST_STREAM,
    key: offerListKey,
    searchId,
  }
}

export type StreamingHotelSearchOffersResponse = { result: Array<SearchResultEntry>, searchId: string }

// Temporary : A specific type will be made for this
export type StreamingHotelSearchFilterResponse = Omit<paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json'], 'results'> & {
  searchId: string
}

export enum SEPARATOR_TYPE {
  NEARBY_OFFERS = 'NEARBY_OFFERS',
}

export function offerListScroll(filters: App.OfferListFilters = {}, limit: number): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const offerListKey = getOfferListKey(filters)
    const searchId = state.offer.offerLists[offerListKey]?.searchId
    const fetching = state.offer.offerLists[offerListKey]?.fetching
    const error = state.offer.offerLists[offerListKey]?.error
    const streamingEnded = state.offer.offerLists[offerListKey]?.streamEnded

    if (fetching || !searchId || streamingEnded || error) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_LIST_STREAM_SCROLL,
      key: offerListKey,
      request: () =>
        OfferService.streamOfferListScroll(searchId, limit),
    })
  }
}

export function streamOfferList(
  filters: App.OfferListFilters = {},
  offset?: number,
  newSearchId?: string,
  options: Options = {},
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { clientCheckAvailability, evVersion, leaveSearchSocketOpen } = options
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const isPaidSessionEnabled = isPaidSession(state)
    const domainUserId = getDomainUserId()
    const memberId = state.auth.account.memberId
    const personalise = filters.shouldUsePersonalizedSearch
    const channelMarkup = getChannelMarkup(state)

    const offerListKey = getOfferListKey(filters)

    // If the stream has ended then we do not want to retry
    if (state.offer.offerLists[offerListKey]?.streamEnded) {
      // already have the results for this set of params
      return
    }

    if (filters.checkIn && !filters.checkOut) {
      // This request is only valid if both checking and checkout dates are provided
      return
    }

    const searchId = state.offer.offerLists[offerListKey]?.searchId ?? newSearchId ?? uuidV4()

    // We only want to dispatch API_CALL_REQUEST when we are making a request for the first time
    if (!state.offer.offerLists[offerListKey]) {
      dispatch({
        type: API_CALL_REQUEST,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        searchId,
      })
    }

    const handleFiltersResponse = (response: StreamingHotelSearchFilterResponse) => {
      const offerListFilterResults = mapOfferListFiltersResponse(response)

      // If the received filters' searchId doesn't match the current searchId, ignore the response
      if (response.searchId !== searchId) {
        return
      }

      if (offerListFilterResults.filters) {
        dispatch({
          type: API_CALL_SUCCESS,
          api: FETCH_OFFER_LIST_FILTERS,
          data: {
            ...getOfferListFilters(offerListFilterResults),
            offerCount: offerListFilterResults.offerCount,
          },
          key: offerListKey,
        })
      }
    }

    const handleOfferResponse = (response: StreamingHotelSearchOffersResponse) => {
      const offerListOfferResponse = mapOfferListResponse(response, channelMarkup)

      // If the searchId of the offers does not match the searchId of the offer list, do nothing
      if (offerListOfferResponse.searchId !== searchId) {
        return
      }

      const metaData = mapOfferListMetaData(offerListOfferResponse)

      if (metaData.length > 0 && (filters.landmarkId || filters.destinationId || filters.propertyId || filters.bounds)) {
        dispatch(setSearchResultMetadata(metaData, filters))
      }

      const offerIds = unique(filters?.limit ? take(offerListOfferResponse.offerIds, filters.limit) : offerListOfferResponse.offerIds)
      dispatch({
        type: API_CALL_SUCCESS,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        data: {
          streamedOfferIds: offerIds,
        },
      })
    }

    const handleSeparatorResponse = (response: {
      type: SEPARATOR_TYPE;
      position: number;
      searchId: string;
    }) => {
      const extra = mapSeparatorToExtra(response)

      if (!extra || searchId !== response.searchId) return

      dispatch(addOfferListExtra(offerListKey, extra))
    }

    const handleEndResponse = (response: { searchId: string}) => {
      const responseSearchId = response?.searchId

      if (responseSearchId !== searchId) return

      dispatch(endOfferListStream({ offerListKey, searchId }))

      if (leaveSearchSocketOpen) {
        searchSocket.off('search:response:offers', handleOfferResponse)
        searchSocket.off('search:response:filters', handleFiltersResponse)
        searchSocket.off('search:response:separator', handleSeparatorResponse)
        searchSocket.off('search:response:end', handleEndResponse)
        searchSocket.off('search:error', handleError)
        searchSocket.off('disconnect', handleDisconnection)
        searchSocket.off('connect', handleConnection)

        if (isSocketSafeToDisconnect(searchSocket)) {
          disconnectSearchSocket()
        }
      } else {
        disconnectSearchSocket()
      }
    }

    const handleError = (errorResponse: SearchNotFoundError | SearchValidationError) => {
      let error = 'streaming error'

      if (errorResponse.type === 'searchNotFound') {
        if (searchId !== errorResponse.searchId) return
        handleEndResponse({ searchId })
        error = 'searchNotFound'
      } else if (errorResponse.type === 'validationError') {
        error = 'validationError'
      }

      dispatch({
        type: API_CALL_FAILURE,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        error,
      })
    }

    const handleDisconnection = () => {
      dispatch({
        type: SET_OFFER_LIST_ERROR,
        key: offerListKey,
        error: 'disconnected',
      })
    }

    const handleConnection = () => {
      dispatch({
        type: SET_OFFER_LIST_ERROR,
        key: offerListKey,
        error: undefined,
      })
    }

    const searchSocket = getSearchSocket()
    // If we are not closing the socket on the end event we do not want to remove existing listeners
    if (!leaveSearchSocketOpen) {
      // Remove any existing listeners on the search socket
      searchSocket.removeAllListeners()
    }

    searchSocket.on('search:response:offers', handleOfferResponse)
    searchSocket.on('search:response:filters', handleFiltersResponse)
    searchSocket.on('search:response:separator', handleSeparatorResponse)
    searchSocket.on('search:response:end', handleEndResponse)
    searchSocket.on('search:error', handleError)
    searchSocket.on('disconnect', handleDisconnection)
    searchSocket.on('connect', handleConnection)

    streamRequest({
      region: state.geo.currentRegionCode,
      filters,
      canViewLuxPlusBenefits,
      isLuxPlusEnabled: luxPlusEnabled,
      clientCheckAvailability,
      evVersion,
      isPaidSession: isPaidSessionEnabled,
      memberId,
      domainUserId,
      searchId,
      offset,
      personalise,
      include: filters.include,
    })
  }
}

function mapOfferListResponse(
  response: StreamingHotelSearchOffersResponse,
  channelMarkup?: App.ChannelMarkup,
): { offerIds: Array<string>; metaData?: Array<App.OfferListMetaData>, searchId?: string} {
  const { result, searchId } = response
  return {
    offerIds: result.map(res => res.id),
    metaData: result.map((item, index) => mapSearchResultToOfferListMetaData(item, index, channelMarkup)),
    searchId,
  }
}

function mapSeparatorToExtra(
  response: {
    type: SEPARATOR_TYPE;
    position: number;
    searchId: string
  },
): App.OfferListExtra | undefined {
  let type: App.OfferListExtraType

  switch (response.type) {
    case SEPARATOR_TYPE.NEARBY_OFFERS:
      type = 'nearby'
      break
    default:
      return
  }

  return {
    type,
    position: response.position,
  }
}

function mapOfferListFiltersResponse(response: StreamingHotelSearchFilterResponse): Omit<OfferListResult, 'offerIds' | 'metaData'> {
  return {
    filters: {
      amenities: response.filters.amenities,
      bedrooms: response.filters.bedrooms,
      campaigns: response.filters.campaigns,
      customerRatings: response.filters.customerRatings,
      holidayTypes: response.filters.holidayTypes,
      inclusions: response.filters.inclusions,
      propertyTypes: response.filters.propertyTypes,
      offerTypes: response.filters.type,
      luxPlusFeatures: response.filters.luxPlusExclusive ?? {},
      total: response.total,
    },
    filterOrder: response.filterOrder,
    offerCount: response.total,
  }
}

function mapOfferListMetaData(results: Omit<OfferListResult, 'offerIds'>): Array<App.OfferListMetaData> {
  let metaDataList: Array<App.OfferListMetaData> = results.metaData || []
  const mapBundledOffers = arrayToObject(
    metaDataList.filter(m => m.bundledOfferId && m.available),
    r => r.bundledOfferId!,
    r => r.offerId,
  )

  // Adding for single offers property bundleOfferId
  metaDataList = metaDataList.map(metaData => mapBundledOffers[metaData.offerId] ? {
    ...metaData,
    bundleOfferId: mapBundledOffers[metaData.offerId],
  } : metaData)

  return metaDataList
}

function getOfferListFilters(results: Omit<OfferListResult, 'offerIds' | 'metaData'>): Omit<App.OfferListFilterOptions, 'error' | 'fetching' | 'searchId'> {
  const filters = updateLeBrandSpecificFilters(results.filters) as App.OfferListAvailableFilters
  const filterOrder = updateLeBrandSpecificFilters(results.filterOrder) ?? { amenities: {}, holidayTypes: {}, locations: {} }
  return {
    filters,
    filterOrder,
    orderedFilters: {
      holidayTypes: sortBy(
        objectEntries(filters.holidayTypes),
        ([_, count]) => count,
        'desc',
      ).map(([value, count]) => ({
        value,
        count,
      })),
      amenities: sortBy(
        objectEntries(filters.amenities),
        ([_, count]) => count,
        'desc',
      ).map(([value, count]) => ({
        value,
        count,
      })),
    },
  }
}

export function removeOffer(offerId: string): AppAction {
  return {
    type: REMOVE_OFFER,
    offerId,
  }
}

export function fetchCalculatedSurchargeMargin(
  roomRateId: string,
  surchargeMarginItem: App.ToBeCalculatedSurchargeMarginItem,
  surchargeMarginKey: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const currentSurchargeMargin = state.offer.surchargeMargins[surchargeMarginKey]

    if (!currentSurchargeMargin) {
      dispatch({
        type: API_CALL,
        api: FETCH_SURCHARGE_MARGIN,
        surchargeMarginKey,
        request: () => batchFetchSurchargeMargin([roomRateId, surchargeMarginItem], undefined),
      })
    }
  }
}
export function fetchPropertyOfferMap(propertyId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.offer.propertyOfferMappings[propertyId]) {
      // record exists, already attempted to get it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_PROPERTY_OFFER_MAPPING,
      request: () => OfferService.getPropertyOfferMapping(propertyId),
      propertyId,
    })
  }
}
