import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { SearchMenuStates } from 'components/Search/type'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import React, { ChangeEventHandler, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import Group from 'components/utils/Group'
import TextInputList from 'components/Common/Form/Input/TextInputList'
import DropdownListHeader from 'components/Luxkit/Dropdown/List/DropdownListHeader'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'
import { pluralizeToString } from 'lib/string/pluralize'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import InputText from 'components/Luxkit/Typography/InputText'
import Counter from 'components/Luxkit/Counter'
import { getMaxOccupancies } from 'lib/offer/occupancyUtils'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { sum } from 'lib/array/arrayUtils'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  className?: string;
  activeMenu: SearchMenuStates;
  onRoomChange: (rooms: Array<App.Occupants>) => void;
  openTravellerMenu: (e: React.MouseEvent<HTMLButtonElement>) => void
  closeMenu: () => void
}

function BusinessTravellerSelectHotelsDesktop(props: Props & MappedStateProps) {
  const {
    className,
    activeMenu,
    travellerEmployees,
    onRoomChange,
    openTravellerMenu,
  } = props

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { occupancies } = useContext(GlobalSearchStateContext)

  const maxCapacities = useMemo(() => {
    return getMaxOccupancies(undefined, occupancies[0])
  }, [occupancies])

  // Exclude the primary traveller from the count. Always at least 1 adult.
  const adultsCount = (occupancies[0]?.adults ?? 1) - 1
  const maxAdults = maxCapacities.adults - 1

  const setActiveMenu = useCallback((menu: SearchMenuStates) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu })
  }, [globalSearchDispatch])

  const handleSelectTravellerClose = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)
  }, [setActiveMenu])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const handleOccupancyChange = useCallback((value: number) => {
    onRoomChange([{ ...occupancies[0], adults: value + 1 }])
  }, [onRoomChange, occupancies])

  const fieldValue = useMemo(() => {
    if (travellerEmployees.length === 0) {
      return undefined
    }
    if (occupancies[0].adults === 1) {
      return `${travellerEmployees[0].firstName} ${travellerEmployees[0].lastName}`
    }
    const adultsCount = pluralizeToString('adult', sum(occupancies, room => room.adults))
    const roomCount = pluralizeToString('room', occupancies.length)
    return `${adultsCount} (${roomCount})`
  }, [occupancies, travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  const [selectedRoom, setSelectedRoom] = useState(false)

  const selectedTravellerString = travellerEmployees[0] ? `${travellerEmployees[0].firstName} ${travellerEmployees[0].lastName}` : undefined

  return (
    <SearchFormFieldGroup className={className}>
      <SearchFormField
        label="Travellers"
        required
        requiredErrorMessage="Please select traveller"
        value={fieldValue}
        displayValue={fieldValue}
        placeholder="Select Traveller"
        onClick={openTravellerMenu}
        ref={localInputRef}
      />
      <DropdownList
        size="M"
        open={activeMenu === SearchMenuStates.Rooms}
        onClose={handleSelectTravellerClose}
        triggerRef={localInputRef}
        anchorRef={localInputRef}
        headerExtension={selectedRoom && <TextInputList
          value={searchTerm}
          type="text"
          placeholder="Search traveller name"
          onChange={handleSearchTermChange}
          endIcon={<LineSearchIcon />}
          noValidationSpacing
          autoFocus
          displayValue={selectedTravellerString}
        />}
      >
        {selectedRoom &&
          <BusinessTravellerSelectContent
            searchTerm={searchTerm}
            onTravellerSelect={() => setSelectedRoom(false)}
          />}
        {!selectedRoom &&
          <Group direction="vertical" gap={12}>
            <DropdownListHeader
              size="S"
              extensionSlot={<Group direction="vertical" gap={24}>
                <TextInputList
                  type="text"
                  placeholder="Search traveller name"
                  label="Primary traveller"
                  endIcon={<DropdownChevron />}
                  noValidationSpacing
                  contentEditable={false}
                  onFocus={() => setSelectedRoom(true)}
                  required
                  displayValue={selectedTravellerString}
                />
                <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
                  <label htmlFor="occupancy-item-adults">
                    <InputText variant="label">Other travellers in room</InputText>
                  </label>
                  <Counter
                    data-testid="occupancy-item-adults"
                    value={adultsCount}
                    onChange={handleOccupancyChange}
                    max={maxAdults}
                    name="room.adults"
                  />
                </Group>
              </Group>}
            />
          </Group>
        }
      </DropdownList>
    </SearchFormFieldGroup>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectHotelsDesktop)
