import { UserCartAddonItemType, USER_CART_ADDON_TYPES, USER_CART_ACCOMMODATION_TYPES, UserCartAccommodationItemType } from 'constants/userCart'
import { isBNBLLEHotelItem, isFlightItem } from './checkoutUtils'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_VILLA,
} from 'constants/checkout'

const SUPPORTED_USER_CART_ITEMS = new Set([
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE,
  CHECKOUT_ITEM_TYPE_VILLA,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
])

export function isUserCartAddonItem(item: App.Checkout.AnyItem): item is Extract<App.Checkout.AnyItem, { itemType: UserCartAddonItemType} > {
  return USER_CART_ADDON_TYPES.includes(item.itemType as UserCartAddonItemType)
}

export function isUserCartAccommodationItem(item: App.Checkout.AnyItem): item is Extract<App.Checkout.AnyItem, { itemType: UserCartAccommodationItemType} > {
  return USER_CART_ACCOMMODATION_TYPES.includes(item.itemType as UserCartAccommodationItemType)
}

export function isUserCartSupportedItem(item: App.Checkout.AnyItem): boolean {
  if (!SUPPORTED_USER_CART_ITEMS.has(item.itemType)) return false
  if (isFlightItem(item) && !item.hasFinalFare) return false
  if (isBNBLLEHotelItem(item)) return false
  return true
}
