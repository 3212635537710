export const USER_CART_ADDON_TYPES = [
  'insurance',
  'subscription-join',
  'booking-protection',
  'luxury-plus-subscription',
] satisfies Array<App.Checkout.AnyItemType>

export type UserCartAddonItemType = typeof USER_CART_ADDON_TYPES[number]

export const USER_CART_ACCOMMODATION_TYPES = [
  'bedbankHotel',
  'bundleAndSave',
  'hotel',
  'villa',
] satisfies Array<App.Checkout.AnyItemType>

export type UserCartAccommodationItemType = typeof USER_CART_ACCOMMODATION_TYPES[number]
