import request from 'api/requestUtils'
import config from 'constants/config'
import qs from 'qs'

interface BlogPostOptions {
  categoryIds?: Array<number>;
  tagIds?: Array<number>;
  limit?: number;
  popular?: boolean;
  location?: string;
}

function mapServerBlogPost(serverPost: any): App.DreamSiteBlogPost {
  return {
    id: serverPost.lux_id,
    categories: serverPost.lux_categories.map((tag: { label: string, link: string }) => tag.label),
    tags: serverPost.lux_tags.map((tag: { label: string, link: string }) => tag.label),
    image: {
      url: serverPost.lux_image_url,
      title: serverPost.lux_title,
    },
    shortDescription: serverPost.lux_excerpt_2,
    title: serverPost.lux_title_2,
    url: serverPost.lux_link,
  }
}

export function getDreamSiteBlogPosts(options: BlogPostOptions) {
  const query = qs.stringify({
    categories: options.categoryIds,
    tags: options.tagIds,
    per_page: options.limit,
    ...(options.popular && { popular: true }),
    ...(options.location && { location: options.location }),
  }, { arrayFormat: 'comma' })

  return request.get(`/posts?${query}`, {}, config.DREAM_SITE_API_URL).then(response => {
    // dream site returns response directly, doesn't match our API
    return (response as Array<any>).map(mapServerBlogPost)
  })
}
