import { AgentHubGetOffersCommissionBody } from 'agentHub/api/agentHubCommission'
import config from 'constants/config'
import { isBedbankOffer, isLpcHotelOffer, isTourV2Offer } from 'lib/offer/offerTypes'
import { createSelector } from 'reselect'

export const isAgentHubEnabled = config.agentHub?.isEnabled
export const isAgentHubMultiCartItemsEnabled = isAgentHubEnabled && config.UNIVERSAL_CHECKOUT_MULTI_CART_ITEMS_ENABLED

const getAgentHubOfferLocation = createSelector(
  (state: App.State, offerId: string) => state.offer.offers[offerId],
  (offer) => {
    if (isTourV2Offer(offer)) {
      const tourV2Offer = offer as App.Tours.TourV2Offer
      const variation = Object.keys(tourV2Offer.variations)[0]
      return tourV2Offer.variations[variation]?.startLocation ?? ''
    }

    return offer?.location ?? ''
  },
)

const getBedbankOfferRates = createSelector(
  (state: App.State, offerId: string) => state.offer.bedbankOfferRates[offerId],
  (offerRates) => {
    return offerRates
  },
)

const getAgentHubOfferPackages = createSelector(
  (state: App.State, offerId: string) => state.offer.offers[offerId],
  (state: App.State, offerId: string) => state.offer.bedbankOffers[offerId],
  getBedbankOfferRates,
  (offer, bedbankOffer, offerRates) => {
    if (bedbankOffer || isBedbankOffer(offer)) {
      if (!offerRates) {
        return []
      }

      return offerRates[Object.keys(offerRates)[0]].map(rate => {
        return {
          packageId: rate.id,
          mx: rate.mx || '',
        }
      })
    }

    if (!offer?.packages) {
      return []
    }

    if (isLpcHotelOffer(offer)) {
      return offer.packages.filter(pkg => pkg.roomRate?.mx).map(pkg => {
        return {
          packageId: pkg.id,
          mx: pkg.roomRate!.mx || '',
        }
      })
    }

    return offer.packages.filter(pkg => pkg.mx).map(pkg => ({
      packageId: pkg.id,
      mx: pkg.mx || '',
    }))
  },
)

export const getAgentHubOfferCommissionBody = createSelector(
  (state: App.State, offerId: string) => state.offer.offers[offerId],
  (state: App.State, offerId: string) => state.offer.bedbankOffers[offerId],
  getAgentHubOfferLocation,
  getAgentHubOfferPackages,
  (offer, bedbankOffer, location, packages): AgentHubGetOffersCommissionBody | null => {
    const offerData = offer || bedbankOffer

    if (!offerData) {
      return null
    }

    let productType = offerData.productType
    const newTourProductTypes = ['deluxe_tour', 'premium_tour', 'ultra_lux_tour', 'signature_series_tour']
    if (newTourProductTypes.includes(productType)) {
      productType = 'direct_tour'
    }

    const offerBody = {
      offerId: offerData.id,
      price: offer?.lowestPricePackage?.price ?? bedbankOffer?.sell?.price ?? 0,
      location: [location],
      type: productType,
      vendor: offer?.vendorName || '',
      packages,
    }

    return offerBody
  },
)
