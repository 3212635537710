import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_VILLA } from 'constants/offer'

const supportedHolidayTypes = [
  'All-inclusive',
  'Family Friendly',
  'Ski',
  'Theme Park',
  'Honeymoon',
  'Adults-only',
  'Beach',
  undefined,
]

export function getDescription({ destinationLandingPageType, placeName, holidayType }: { destinationLandingPageType: DestinationLandingPageType, placeName: string, holidayType: string }) {
  const year = new Date().getFullYear()
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    return (placeName ? placeName + ' ' : '') + (holidayType ? holidayType + ' ' : '') + 'Holiday Package deals for travel in ' + year + '/' + (year + 1) + '. Save on luxury hotel + flight bundles. Get exclusive inclusions.'
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    return 'Luxury ' + (placeName ? placeName + ' ' : '') + 'Resorts with exclusive inclusions. Book now for ' + year + '/' + (year + 1) + ' travel.'
  } else {
    throw new Error('getDescription:Invalid destinationLandingPageType')
  }
}

export function capitalizeWords(input: string) {
  return input
    .split(' ') // Split by spaces
    .map(word =>
      word
        .split('-') // Split hyphenated words
        .map((part, index) =>
          index === 0 ?
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : // Capitalize only the first part
            part.toLowerCase(), // Keep subsequent parts lowercase
        )
        .join('-'), // Rejoin hyphenated words
    )
    .join(' ') // Rejoin the entire string
}

// e.g. `all+inclusive` -> `All-inclusive`
// '_' is used to represent '-' in the url
// '+' is used to represent ' ' in the url
export function decodeFromUrlString(urlString: string) {
  return capitalizeWords(urlString.replace(/_/g, '-').replace(/\+/g, ' '))
}

export function validateHolidayType(holidayType: string) {
  if (supportedHolidayTypes.includes(holidayType)) {
    return true
  }
  return false
}

export enum DestinationLandingPageType {
  HolidayPackages = 'holiday-packages',
  Resorts = 'resorts',
}

export function getDestinationLandingPageType(path: string): DestinationLandingPageType {
  if (path.startsWith('/:regionCode/holiday-packages-')) {
    return DestinationLandingPageType.HolidayPackages
  } else if (path.startsWith('/:regionCode/resorts-')) {
    return DestinationLandingPageType.Resorts
  } else {
    throw new Error('getDestinationLandingPageType:Invalid path')
  }
}

interface getFiltersProps {
  destinationLandingPageType: DestinationLandingPageType,
  hasHolidayType: boolean,
  urlHolidayType: string,
  place: { id?: string }
}

export function getFilters({
  destinationLandingPageType,
  hasHolidayType,
  urlHolidayType,
  place,
}: getFiltersProps): App.OfferListFilters {
  const holidayTypes = hasHolidayType ? [decodeFromUrlString(urlHolidayType)] : []
  const destinationId = place?.id ?? ''
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    return {
      holidayTypes,
      destinationId,
      offerTypes: [OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_VILLA, OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK],
    }
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    return {
      destinationId,
      offerTypes: [OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_VILLA, OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK],
      inclusions: ['Resort credit'],
    }
  } else {
    throw new Error('getFilters:Invalid destinationLandingPageType')
  }
}

interface getTitleAndSubTitleProps {
  destinationLandingPageType: DestinationLandingPageType,
  placeName: string,
  holidayType: string
}
export function getTitleAndSubTitle({ destinationLandingPageType, placeName, holidayType }: getTitleAndSubTitleProps) {
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    const title = `${placeName} ${holidayType} Holiday Packages`
    const subtitle = `Explore our Holiday Package deals in ${placeName}`
    return { title, subtitle }
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    const title = `${placeName} Resorts`
    const subtitle = `Explore our Resorts in ${placeName}`
    return { title, subtitle }
  } else {
    throw new Error('getTitleAndSubTitle:Invalid destinationLandingPageType')
  }
}
