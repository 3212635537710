import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { createSelector } from 'reselect'

/** Gets the default / lowest ranked tier from program config */
export const getLuxLoyaltyDefaultTier = createSelector(
  (state: App.State) => state.luxLoyalty.programConfig.data,
  (programConfig): App.LuxLoyaltyTier => {
    if (!programConfig) return LUX_LOYALTY.DEFAULT_TIER
    return programConfig.tiersOrder[0]
  },
)

const DEFAULT_PROGRAM_CONFIG_DETAILS: App.LuxLoyaltyProgramConfig = {
  currencyName: 'point',
  currencyNameAbbreviation: 'pt',
  statusCreditsName: 'status credit',
  programName: 'société',
  tierLabel: 'traveller status',
  tiersOrder: ['silver', 'gold', 'platinum'],
  tiersConfigs: {
    silver: {
      id: 'silver',
    },
    gold: {
      id: 'gold',
    },
    platinum: {
      id: 'platinum',
    },
  },
}

/**
 * Use this selector to get the LuxLoyalty program config details.
 * It selects the dynamic config details if available, otherwise falls back to the default config details.
 */
export const getLuxLoyaltyProgramConfigDetails = createSelector(
  (state: App.State) => state.luxLoyalty.programConfig,
  (programConfig): App.LuxLoyaltyProgramConfig => {
    return programConfig.data ?? DEFAULT_PROGRAM_CONFIG_DETAILS
  },
)

export const getNextLuxLoyaltyTierConfig = createSelector(
  (state: App.State) => getLuxLoyaltyProgramConfigDetails(state),
  (state: App.State) => state.luxLoyalty.account.data?.tier,
  (state: App.State) => state.luxLoyalty.eligibilityTier.data,
  (
    programConfigDetails,
    accountTier,
    eligibilityTier,
  ): App.LuxLoyaltyTier | undefined => {
    const tier: App.LuxLoyaltyTier = accountTier ?? eligibilityTier ?? 'silver'
    const currentTierIndex = programConfigDetails.tiersOrder.findIndex((t) => t === tier)
    return programConfigDetails.tiersOrder[currentTierIndex + 1]
  },
)
