import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import React from 'react'
import { Route, Switch } from 'react-router'
import ContactOptionsBanner from '../Header/HeaderBanners/ContactOptionsBanner'
import HeaderTopBanners from '../Header/HeaderTopBanners'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'

const NO_BANNER_EXACT_ROUTES: Array<string> = [
  '/:regionCode/support',
]

const NO_BANNER_INEXACT_ROUTES: Array<string> = [
  '/:regionCode/trip-planner/trip/:tripId',
  '/:regionCode/trip-planner/public-trip/:tripId',
  '/:regionCode/trip-planner/curated/:tripId',
]

function BannerRoutes() {
  const isDsNavBarEnabled = !!useOptimizelyExperiment(OptimizelyExperiments.dsNewNavBar) && config.NAVKIT_NAVBAR_ENABLED
  return <>
    {config.SHOW_CONTACT_BANNER && !isDsNavBarEnabled && <CSSBreakpoint max="mobile">
      <ContactOptionsBanner />
    </CSSBreakpoint>}
    <Switch>
      <Route exact path={NO_BANNER_EXACT_ROUTES} />
      <Route path={NO_BANNER_INEXACT_ROUTES} />
      <Route component={HeaderTopBanners} />
    </Switch>
  </>
}

export default BannerRoutes
