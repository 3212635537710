import {
  GetSecurePaymentInfoResponse,
  MerchantFeeConfigResponse,
  MerchantFeeDetailsResponse,
  PayToSupportedBanksResponse,
  SecurePaymentInfo,
  StripeSavedCardsV2Response,
  PaymentScheduleResponse,
  PaymentScheduleResponseData,
  PaymentScheduleDetailsResponse,
  PaymentDetailsForOrdersResponse,
} from 'api/payment'
import { arrayToObject } from 'lib/array/arrayUtils'

export function instalmentDetailsMap(instalmentDetails): App.InstalmentDetails {
  return instalmentDetails ? {
    instalmentPaymentDetails: instalmentDetails.instalmentPaymentDetails.map(i => instalmentPaymentDetailsMap(i)),
    instalment_status: instalmentDetails.instalment_status,
    total_paid_amount: parseFloat(instalmentDetails.total_paid_amount),
    balance_amount: parseFloat(instalmentDetails.balance_amount),
    currency: instalmentDetails.currency,
    total_amount: parseFloat(instalmentDetails.total_amount),
    fk_orders: instalmentDetails.fk_orders,
    instalment_frequency: instalmentDetails.instalment_frequency,
    total_instalments_count: instalmentDetails.total_instalments_count,
    instalment_frequency_days: instalmentDetails.instalment_frequency_days,
    per_instalment_amount: parseFloat(instalmentDetails.per_instalment_amount),
    initial_payment_date: instalmentDetails.initial_payment_date,
    initial_payment_amount: parseFloat(instalmentDetails.initial_payment_amount),
    scheduled_instalment_dates: instalmentDetails.scheduled_instalment_dates,
    total_due_balance_auto_debit_failed_count: instalmentDetails.total_due_balance_auto_debit_failed_count,
    paid_instalments_count: instalmentDetails.paid_instalments_count,
    items: instalmentDetails.items,
    is_active: instalmentDetails.is_active,
    metadata: {
      external_ref: instalmentDetails.metadata.external_ref,
    },
    last_instalment_amount: parseFloat(instalmentDetails.last_instalment_amount),
    fk_customer: instalmentDetails.fk_customer,
  } : null
}

function instalmentPaymentDetailsMap(instalmentPaymentDetails): App.Instalment {
  return {
    id: instalmentPaymentDetails.id,
    instalments_number: instalmentPaymentDetails.instalments_number,
    instalment_amount: parseFloat(instalmentPaymentDetails.instalment_amount),
    instalment_due_date: instalmentPaymentDetails.instalment_due_date,
    instalment_paid_date: instalmentPaymentDetails.instalment_paid_date,
    currency: instalmentPaymentDetails.currency,
    instalment_status: instalmentPaymentDetails.instalment_status,
    due_balance_auto_debit_failed_count: instalmentPaymentDetails.due_balance_auto_debit_failed_count,
    created_at: instalmentPaymentDetails.created_at,
    metadata: {
      is_credit: instalmentPaymentDetails.metadata.is_credit,
      is_promo: instalmentPaymentDetails.metadata.is_promo,
    },
  }
}

export function merchantFeeConfigMap(result: MerchantFeeConfigResponse['result']): Array<App.MerchantFeeConfig> {
  return result.merchantFeeConfigs.map(merchantFeeConfig => ({
    id: merchantFeeConfig.id,
    name: merchantFeeConfig.name,
    percentage: merchantFeeConfig.percentage,
  }))
}

export function merchantFeeDetailsMap(result: MerchantFeeDetailsResponse['result']): Array<App.MerchantFeeDetails> {
  return result.merchantFeeDetails.filter(item => item.intent === 'sale').map(merchantFeeDetail => ({
    fkItem: merchantFeeDetail.fk_item,
    amount: merchantFeeDetail.amount,
    metadata: {
      data: {
        shouldAutoRefund: merchantFeeDetail.metadata.data.shouldAutoRefund,
      },
    },
  }))
}

export function payToSupportedBanksMap(result: PayToSupportedBanksResponse['result']): Array<App.PayToSupportedBank> {
  return result.map(payToSupportedBank => ({
    bankName: payToSupportedBank.bankName,
    bankCode: payToSupportedBank.bankCode,
    bsbPrefixes: payToSupportedBank.bsbPrefixes,
    bankLogo: payToSupportedBank.bankLogo,
    bankAppName: payToSupportedBank.bankAppName,
    bankOnlineBankingName: payToSupportedBank.bankOnlineBankingName,
    faqLink: payToSupportedBank.faqLink,
  }))
}

export function savedStripeCardsMap(result: StripeSavedCardsV2Response['result']): Array<App.StripePaymentCardV2> {
  return result.map(savedCard => ({
    last4: savedCard.last4,
    maskedPan: savedCard.maskedPan,
    expMonth: savedCard.expMonth,
    expYear: savedCard.expYear,
    expiry: savedCard.expiry,
    brand: savedCard.brand,
    logo: savedCard.logo,
    country: savedCard.country,
    paymentMethodId: savedCard.paymentMethodId,
    fingerprint: savedCard.fingerprint,
    funding: savedCard.funding,
  }))
}

export function securePaymentInfoMap(result: GetSecurePaymentInfoResponse['result']): SecurePaymentInfo {
  return {
    amount: result.amount,
    currency: result.currency,
    orderId: result.orderId,
    isDeposit: result.depositInfo.depositPaymentType === 'initial',
    isDepositBalance: result.depositInfo.depositPaymentType === 'final',
    isPaid: result.isPaid,
    isExpired: result.isExpired,
    hasLuxPlus: result.hasLuxPlus,
    items: result.paymentPlanItems.map(paymentPlanItem => ({
      total: paymentPlanItem.totalAmount,
      type: paymentPlanItem.type,
    })),
    creditAmount: result.creditAmount,
    promoAmount: result.promoAmount,
    balanceAmount: result.depositInfo.balanceAmount,
    balanceDueDate: result.depositInfo.balanceDueDate,
  }
}

export function paymentScheduleDataMap(result: PaymentScheduleResponse['result']): PaymentScheduleResponseData {
  return {
    eligible: result.eligible,
    payment_schedules: result.payment_schedules?.map((schedule) => ({
      paymentType: schedule.payment_type,
      dueDate: schedule.due_date,
      baseAmount: schedule.base_amount,
      feeAmount: schedule.fee_amount,
      totalAmount: schedule.total_amount,
      creditApplied: schedule.credit_applied,
      expectedAmount: schedule.expected_amount,
      amountType: schedule.amount_type,
      retry: schedule.retry && { maxChargeAttempts: schedule.retry.max_charge_attempts },
      unitOfSale: schedule.unit_of_sale,
      fee: schedule.fee,
      eligibleItem: schedule.eligible_item && {
        totalAmount: schedule.eligible_item?.total_amount,
      },
      itemsBreakdown: schedule.items_breakdown?.map(item => ({
        itemId: item.id_items,
        totalAmount: item.total_amount,
        type: item.type,
      })),
    })),
  }
}

export function paymentScheduleDetailsMap(result: PaymentScheduleDetailsResponse['result']): App.PaymentScheduleDetails | undefined {
  if (!result) {
    return undefined
  }
  return {
    totalAmount: result.total_amount,
    totalPaid: result.total_paid,
    totalRefunded: result.total_refunded,
    balanceAmount: result.balance_amount,
    status: result.status,
    paymentSchedules: result.payment_schedules.map((paymentSchedule) => ({
      paymentType: paymentSchedule.payment_type,
      dueDate: paymentSchedule.due_date,
      baseAmount: paymentSchedule.base_amount,
      feeAmount: paymentSchedule.fee_amount,
      totalAmount: paymentSchedule.total_amount,
      status: paymentSchedule.status,
      amountType: paymentSchedule.amount_type,
      fee: paymentSchedule.fee,
      nextChargeDate: paymentSchedule.next_charge_date,
    })),
  }
}

interface PaymentDetailsForOrder {
  payments: Array<App.OrderPayment>;
  depositDetails?: App.Deposit;
  instalmentDetails?: App.InstalmentDetails;
  deferredPaymentDetails?: App.ReserveForZeroDetails;
  paymentScheduleDetails?: App.PaymentScheduleDetails;
}

function mapInstalmentPaymentDetails(instalmentPaymentDetails: NonNullable<PaymentDetailsForOrdersResponse['result'][number]['instalment_details']>['instalment_payments'][number]): App.Instalment {
  return {
    id: instalmentPaymentDetails.id,
    instalments_number: instalmentPaymentDetails.instalments_number,
    instalment_amount: instalmentPaymentDetails.instalment_amount,
    instalment_due_date: instalmentPaymentDetails.instalment_due_date,
    instalment_paid_date: instalmentPaymentDetails.instalment_paid_date,
    currency: instalmentPaymentDetails.currency,
    instalment_status: instalmentPaymentDetails.instalment_status,
    due_balance_auto_debit_failed_count: instalmentPaymentDetails.due_balance_auto_debit_failed_count,
    created_at: instalmentPaymentDetails.created_at,
    metadata: {
      is_credit: instalmentPaymentDetails.metadata.is_credit,
      is_promo: instalmentPaymentDetails.metadata.is_promo,
    },
  }
}

function mapInstalmentDetails(instalmentDetails: PaymentDetailsForOrdersResponse['result'][number]['instalment_details']): App.InstalmentDetails | undefined {
  if (!instalmentDetails) {
    return undefined
  }

  return {
    instalment_status: instalmentDetails.instalment_status,
    total_paid_amount: instalmentDetails.total_paid_amount,
    balance_amount: instalmentDetails.balance_amount,
    currency: instalmentDetails.currency,
    total_amount: instalmentDetails.total_amount,
    fk_orders: instalmentDetails.fk_orders,
    instalment_frequency: instalmentDetails.instalment_frequency,
    total_instalments_count: instalmentDetails.total_instalments_count,
    instalment_frequency_days: Number(instalmentDetails.instalment_frequency_days),
    per_instalment_amount: instalmentDetails.per_instalment_amount,
    initial_payment_date: instalmentDetails.initial_payment_date,
    initial_payment_amount: instalmentDetails.initial_payment_amount,
    scheduled_instalment_dates: instalmentDetails.scheduled_instalment_dates,
    total_due_balance_auto_debit_failed_count: instalmentDetails.total_due_balance_auto_debit_failed_count,
    paid_instalments_count: instalmentDetails.paid_instalments_count,
    items: instalmentDetails.items,
    is_active: instalmentDetails.is_active,
    metadata: {
      external_ref: instalmentDetails.metadata.external_ref,
    },
    last_instalment_amount: instalmentDetails.last_instalment_amount,
    fk_customer: instalmentDetails.fk_customer,
    instalmentPaymentDetails: instalmentDetails.instalment_payments.map(i => mapInstalmentPaymentDetails(i)),
  }
}

function mapDepositDetails(depositDetails: PaymentDetailsForOrdersResponse['result'][number]['deposit_details']): App.Deposit | undefined {
  if (!depositDetails) {
    return undefined
  }
  return {
    deposit_status: depositDetails.deposit_status,
    paid_amount: depositDetails.paid_amount,
    currency: depositDetails.currency,
    balance_amount: depositDetails.balance_amount,
    balance_due_date: depositDetails.balance_due_date,
    balance_paid_date: depositDetails.balance_paid_date,
    fk_orders: depositDetails.fk_orders,
    deposit_metadata: depositDetails.deposit_metadata,
    due_balance_auto_debit_failed_count: depositDetails.due_balance_auto_debit_failed_count,
    total_amount: depositDetails.total_amount,
    createdAt: depositDetails.created_at,
    deposit_paid_date: depositDetails.deposit_paid_date,
    fk_customer: depositDetails.fk_customer,
    is_active: depositDetails.is_active,
  }
}

function mapDeferredPaymentDetails(deferredPaymentDetails: PaymentDetailsForOrdersResponse['result'][number]['deferred_payment_details']): App.ReserveForZeroDetails | undefined {
  if (!deferredPaymentDetails) {
    return undefined
  }
  return {
    orderIds: deferredPaymentDetails.fk_orders,
    payableAmount: deferredPaymentDetails.payable_amount,
    currency: deferredPaymentDetails.currency,
    isActive: deferredPaymentDetails.is_active,
    schedulePaymentDate: deferredPaymentDetails.scheduled_payment_date,
    bookingStatus: deferredPaymentDetails.booking_status,
    actualPaymentDate: deferredPaymentDetails.actual_payment_date,
  }
}

function mapPaymentScheduleDetails(paymentScheduleDetails: PaymentDetailsForOrdersResponse['result'][number]['payment_schedule_details']): App.PaymentScheduleDetails | undefined {
  if (!paymentScheduleDetails) {
    return undefined
  }
  return {
    totalAmount: paymentScheduleDetails.total_amount,
    totalPaid: paymentScheduleDetails.total_paid,
    totalRefunded: paymentScheduleDetails.total_refunded,
    balanceAmount: paymentScheduleDetails.balance_amount,
    status: paymentScheduleDetails.status,
    paymentSchedules: paymentScheduleDetails.payment_schedules.map(paymentSchedule => ({
      paymentType: paymentSchedule.payment_type,
      dueDate: paymentSchedule.due_date,
      baseAmount: paymentSchedule.base_amount,
      feeAmount: paymentSchedule.fee_amount,
      totalAmount: paymentSchedule.total_amount,
      status: paymentSchedule.status,
      amountType: paymentSchedule.amount_type,
      fee: paymentSchedule.fee,
      nextChargeDate: paymentSchedule.next_charge_date,
    })),
  }
}

export function getPaymentDetailsForOrdersMap(result: PaymentDetailsForOrdersResponse['result']): Record<string, PaymentDetailsForOrder> {
  return arrayToObject(result,
    (item) => item.order_id,
    (item) => ({
      payments: item.payments.map(payment => ({
        id: payment.id_payment,
        type: payment.type,
        currencyCode: payment.currency,
        amount: payment.amount,
        status: payment.status,
        createdAt: payment.created_at,
        depositDetails: mapDepositDetails(item.deposit_details),
        instalmentDetails: mapInstalmentDetails(item.instalment_details),
        isDeferred: !!item.deferred_payment_details,
        transaction_key: payment.transaction_key,
        pspName: payment.psp_name,
        id_promo_code: payment.response_json?.id_promo,
        code_name: payment.response_json?.code_name,
      })),
      depositDetails: mapDepositDetails(item.deposit_details),
      instalmentDetails: mapInstalmentDetails(item.instalment_details),
      deferredPaymentDetails: mapDeferredPaymentDetails(item.deferred_payment_details),
      paymentScheduleDetails: mapPaymentScheduleDetails(item.payment_schedule_details),
    }))
}
