import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import { HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES } from 'components/OfferList/OfferListTiles/constants'
import BedbankCancellationPolicy from 'components/OfferPage/Bedbank/BedbankCancellationPolicy'
import { useBedbankRoomRatePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useMapSearchOfferUrl from 'hooks/Search/useMapSearchOfferUrl'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { buildBedbankCancellationPolicy } from 'lib/search/hotels'
import { isSearchStreamingSupported } from 'lib/search/searchUtils'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile from '../SearchTile'
import SearchTileHighlightBanner from '../shared/SearchTileHighlightBanner'
import SearchTileRowOverlineLocation from '../shared/SearchTileRowOverlineLocation'
import BedbankLTSSearchTileFeatures from './BedbankLTSSearchTileFeatures'
import BedbankSearchTileAction from './BedbankSearchTileAction'
import BedbankSearchTileFeatures from './BedbankSearchTileFeatures'
import BedbankSearchTilePriceStack from './BedbankSearchTilePriceStack'
import BedbankSearchTileUnavailabilityBanner from './BedbankSearchTileUnavailabilityBanner'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import useBedbankOfferSearchPricing from 'hooks/useBedbankOfferSearchPricing'
import { generateLuxLoyaltyPointsCalculatorBedbankOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTile({ offer, filters }: Props) {
  const locations = useMemo<Array<string>>(() => {
    if (offer.property.locationTitleOverride) return [offer.property.locationTitleOverride]

    return [
      [offer.property.address.city, offer.property.address.countryName].filter(Boolean).join(', '),
    ]
  }, [offer])

  const mapUrl = useMapSearchOfferUrl(offer)

  const searchStreamingEnabled = isSearchStreamingSupported(filters)

  const offerMetaData = useOfferMetaData(offer.id, filters)
  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !filters?.flexibleNights
  const promotions = useBedbankRoomRatePromotions(offer.promotions, filters?.checkIn, filters?.checkOut, isAnytimeSearch)
  const isLTSOffer = offerMetaData?.hasPromotions || !!Object.values(promotions).length

  const {
    offerPrice,
    offerMemberPrice,
    shownRate,
    fetchingRates,
  } = useBedbankOfferSearchPricing(offer, filters)

  const cancellationPolicy = useMemo(() => {
    return buildBedbankCancellationPolicy(
      offerMetaData?.cancellationPolicyInfo,
      filters,
      offer.property.timezone,
    )
  }, [offerMetaData?.cancellationPolicyInfo, filters, offer.property.timezone])

  const hasDates = !!(filters?.checkIn && filters.checkOut)
  const isSoldOut = searchStreamingEnabled ? !offerMetaData?.available : (!shownRate && hasDates && !fetchingRates)

  const highlightMessage = useMemo<string | undefined>(() => {
    if (
      filters?.propertyId &&
      !filters?.searchNearby &&
      offer.property?.id === filters.propertyId.split(':')[1]
    ) { // This is a property search result
      if (filters?.checkIn && filters?.checkOut) {
        if (isSoldOut) {
          return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
        }
        return 'We found a great price for your hotel. Book now to secure this rate!'
      } else {
        return 'We found the property you were interested in. Select your dates to book now.'
      }
    }
  }, [filters, offer, isSoldOut])

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => [
    generateLuxLoyaltyPointsCalculatorBedbankOptions(shownRate, {
      price: offerPrice,
      memberPrice: offerMemberPrice,
    }),
  ], [shownRate, offerPrice, offerMemberPrice])

  return <SearchTile
    className={BedbankSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    state={highlightMessage ? 'highlighted' : 'default'}
    action={!isSoldOut && <BedbankSearchTileAction offer={offer} filters={filters} />}
    banner={!!highlightMessage && <SearchTileHighlightBanner description={highlightMessage} />}
    bookmarkAction={<BookmarkButton offer={offer} />}
    cancellationPolicyLabel={offer.productType === 'limited_time_special' && (shownRate || cancellationPolicy) && <BedbankCancellationPolicy
      rate={shownRate ?? cancellationPolicy}
      offer={offer}
      showNonCancellation
    />}
    extension={isSoldOut && <BedbankSearchTileUnavailabilityBanner offer={offer} filters={filters} />}
    features={<>
      {!isLTSOffer && <BedbankSearchTileFeatures offer={offer} filters={filters} />}
      {isLTSOffer && <BedbankLTSSearchTileFeatures offer={offer} />}
    </>}
    meta={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
    images={offer.images}
    overline={<SearchTileRowOverlineLocation locations={locations} mapUrl={mapUrl} />}
    pricePoint={!isSoldOut && <BedbankSearchTilePriceStack offer={offer} filters={filters} />}
    productLabel={isLTSOffer && <ProductTypeLabel productType={offer.productType} />}
    rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
    title={offer.name}
  />
}

export default BedbankSearchTile
