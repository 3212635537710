import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  ACCOUNT_INIT_COMPLETE,
  ACCOUNT_ACCESS_SHOW_LOGIN,
  ACCOUNT_ACCESS_SHOW_EMAIL_JOIN,
  ACCOUNT_ACCESS_SHOW_REGISTER,
  ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD,
  SET_RECENTLY_USED_AIRPORT_CODE,
  SAVE_CUSTOMER_SIGNATURE,
  DELETE_CUSTOMER_SIGNATURE,
  SYNC_USER_DETAILS,
  GSI_SCRIPT_LOADED,
  SET_CUSTOMER_VALUE,
  CLEAR_AUTH_ERROR,
  REMOVE_USER_NOTIFICATION,
  VIEW_USER_NOTIFICATIONS,
  TOGGLE_SHOP_AS_MEMBER,
  SUBSCRIPTION_USER_NOTIFICATIONS_UPDATE,
  RESET_CUSTOMER_TRAVELLER_SUBMISSION,
  ADD_USER_CART_ITEM,
  ADD_USER_CART_CONNECTED_ROOM,
  CLEAR_PURCHASED_USER_CART_ITEMS,
  FETCH_RECENT_SEARCHES,
  SET_RECENT_SEARCHES,
  SYNC_USER_CART,
  DELETE_USER_CART_ITEM,
  SAVE_USER_CART_ITEM_FOR_LATER,
  UNDO_USER_CART_ITEM_DELETE,
  DELETE_ALL_USER_CART_ITEMS,
  REMOVE_USER_CART_CONNECTED_ROOM,
  UPDATE_USER_CART_ITEM,
} from 'actions/actionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  FETCH_PAYMENT_CARDS,
  FETCH_STRIPE_PAYMENT_CARDS,
  FETCH_AVAILABLE_CREDIT,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTRATION,
  UPDATE_USER_DETAILS,
  UPDATE_ACCOUNT_PARTNERSHIP,
  USER_FORGOT_PASSWORD,
  LINK_PARTNERSHIP_ACCOUNT,
  CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
  CHECK_USER_EXISTS,
  USER_RESET_PASSWORD,
  FETCH_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS,
  SEND_VERIFICATION_CODE,
  CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW,
  FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION,
  FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD,
  UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION,
  UPDATE_ADD_PHONE_PROMPTED,
  FETCH_USER_NOTIFICATIONS,
  FETCH_CUSTOMER_TRAVELLERS,
  DELETE_CUSTOMER_TRAVELLER,
  SUBMIT_CUSTOMER_TRAVELLER,
  FETCH_USER_CART,
} from 'actions/apiActionConstants'
import config from 'constants/config'

import { extractTokenFields } from 'lib/jwtToken/extractTokenFields'
import { AnyAction } from 'redux'
import { EmptyArray } from 'lib/array/arrayUtils'
import { omitKeys } from 'lib/object/objectUtils'
import getObjectKey from 'lib/object/getObjectKey'
import { AuthAction } from 'actions/AuthActions'

export const initialAuthState: App.AuthState = {
  account: {
    addressDetails: {},
    gender: undefined,
    memberId: undefined,
    creditsByCurrency: {},
    balance: 0,
    balanceFetched: false,
    balanceError: false,
    paymentCards: [],
    paymentCardsFetched: false,
    paymentCardsError: {},
    stripePaymentCards: [],
    stripePaymentCardsFetched: false,
    stripePaymentCardsError: {},
    referralProgramEnabled: true,
    isSpoofed: false,
    givenName: '',
    surname: '',
    fullName: '',
    title: undefined,
    email: '',
    dob: '',
    legacyId: undefined,
    legacyPlatform: undefined,
    status: undefined,
    roles: [],
    vendors: [],
    countryCode: undefined,
    postcode: undefined,
    phonePrefix: undefined,
    phone: undefined,
    signupDomain: undefined,
    partnerships: {},
    personContactId: undefined,
    emailVerified: true,
    toggles: {},
    lastLogin: {},
    creditsLedger: [],
    numberOfPurchases: 0,
    customerSupportCode: undefined,
    recentlyUsedAirportCode: undefined,
    subscriberTier: null,
    addPhonePromptedAt: undefined,
    luxPlus: {
      member: {
        /** This is used so spoofed customers can be toggled between buying as a member or as a non member */
        disableMembership: false,
        subscription: {
          item: undefined,
          fetching: false,
          error: undefined,
          preferredPaymentMethod: undefined,
        },
      },
    },
  },
  fetchingNotifications: false,
  notifications: [],
  notificationsError: undefined,
  users: {},
  accessToken: undefined,
  error: undefined,
  processing: false,
  source: null,
  travellerDetailsProcessing: false,
  travellerDetailsFetched: false,
  travellerDetailsError: false,
  accountProcessing: false,
  partnershipProcessing: false,
  partnershipError: undefined,
  ledLegacyOrders: {
    hasAny: false,
    legacyCutoffDate: undefined,
    fetchLegacyOrdersFailed: false,
    hasFetched: false,
  },
  initComplete: false,
  signature: undefined,
  gsiLoaded: false,
  completeAccountBannerDismissed: false,
  domainUserId: undefined,
  recentSearches: [],
  customerTravellers: {
    travellers: EmptyArray,
    fetching: false,
    error: false,
    submissionState: {
      submissionProcessing: undefined,
      error: false,
      deletionProcessing: {},
    },
  },
  userCart: {
    items: {},
    itemStates: {},
    roomsToBeConnected: [],
    synced: false,
    fetching: false,
  },
}

function authSuccess(state: App.AuthState, action: AnyAction): Partial<App.AuthState> {
  const accessToken = action.data.accessToken ?? state.accessToken
  const { agentId } = extractTokenFields(accessToken)

  const accountData: Partial<App.AuthState> = {
    accessToken,
    account: {
      ...state.account,
      ...action.data.account,
      agentId,
    },
    users: {
      ...state.users,
      // they just logged in...we know the account exists!
      [action.data.account.email]: {
        ...state.users[action.data.account.email],
        exists: true,
      },
    },
    processing: false,
    initComplete: true,
  }
  // WL-Mobile login flow
  if (config.MOBILE_APP_CUSTOMISATIONS) {
    const persistentAccountData = {
      account: {
        ...accountData.account,
        accessToken,
      },
    }

    // Persist account data in iphone/capacitor "local storage"
    try {
      import(/* webpackChunkName: "CapacitorPreferences" */ '@capacitor/preferences')
        .then(capacitorPreferencesModule => {
          const { Preferences } = capacitorPreferencesModule
          Preferences.set({
            key: 'account',
            value: JSON.stringify(persistentAccountData),
          })
        })
    } catch {
      console.error('Failed to persist account data')
    }
  }

  return ({
    ...accountData,
  })
}

function authClearError(): Partial<App.AuthState> {
  return ({
    error: undefined,
  })
}

function authStart(state: App.AuthState, action: AnyAction): Partial<App.AuthState> {
  return ({
    ...authClearError(),
    processing: true,
    source: action.source,
  })
}

function authProcessing(): Partial<App.AuthState> {
  return ({
    ...authClearError(),
    processing: true,
  })
}

function authError(state: App.AuthState, action: AnyAction): Partial<App.AuthState> {
  return ({
    error: action.error ?? action.data,
    processing: false,
    accountProcessing: false,
  })
}

function userCartBase(state: App.AuthState): App.UserCartState {
  return {
    ...state.userCart,
    synced: false,
  }
}

function filterConnectedRooms(items: App.UserCartState['items'], connectedRooms: Array<App.ConnectedRooms>) {
  const itemIds = new Set(Object.keys(items))
  const uniqueConnectedRooms = new Map<string, App.ConnectedRooms>()
  const result: Array<App.ConnectedRooms> = []
  connectedRooms.forEach(room => {
    const roomKey = getObjectKey(room)
    if (!uniqueConnectedRooms.has(roomKey)) {
      uniqueConnectedRooms.set(roomKey, room)
      if (room.every(itemId => itemIds.has(itemId))) {
        result.push(room)
      }
    }
  })
  return result
}

const apiRequests = reducerSwitch<App.AuthState>({
  [CHECK_USER_EXISTS]: authProcessing,
  [USER_LOGIN]: authStart,
  [USER_REGISTRATION]: authStart,
  [USER_RESET_PASSWORD]: authProcessing,
  [USER_FORGOT_PASSWORD]: authProcessing,
  [LINK_PARTNERSHIP_ACCOUNT]: authProcessing,
  [SEND_VERIFICATION_CODE]: authProcessing,
  [UPDATE_USER_DETAILS]: () => ({
    accountProcessing: true,
  }),
  [UPDATE_ACCOUNT_PARTNERSHIP]: () => ({
    partnershipProcessing: true,
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      creditsByCurrency: {
        ...state.account.creditsByCurrency,
        [action.currencyCode]: {
          balance: 0,
          nextExpiringCredit: null,
          loading: true,
          error: null,
        },
      },
    },
  }),
  [FETCH_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: true,
    travellerDetailsFetched: false,
    travellerDetailsError: false,
  }),
  [DELETE_CUSTOMER_DETAILS]: (state) => ({
    account: {
      ...state.account,
      addressDetails: undefined,
    },
    travellerDetailsProcessing: true,
    travellerDetailsFetched: false,
  }),
  [UPDATE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: true,
  }),
  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: true,
            error: undefined,
          },
        },
      },
    },
  }),
  [FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD]: (state) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            ...state.account.luxPlus.member.subscription,
            preferredPaymentMethod: undefined,
          },
        },
      },
    },
  }),
  [FETCH_USER_NOTIFICATIONS]: () => ({
    fetchingNotifications: true,
  }),
  [FETCH_CUSTOMER_TRAVELLERS]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      fetching: true,
      error: false,
    },
  }),
  [DELETE_CUSTOMER_TRAVELLER]: (state, action) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        deletionProcessing: {
          ...state.customerTravellers.submissionState.deletionProcessing,
          [action.id]: true,
        },
      },
    },
  }),

  [SUBMIT_CUSTOMER_TRAVELLER]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        error: false,
        submissionProcessing: 'processing',
      },
    },
  }),

  [FETCH_USER_CART]: (state) => ({
    userCart: {
      ...userCartBase(state),
      fetching: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.AuthState>({
  [FETCH_USER_NOTIFICATIONS]: (state, action) => ({
    notifications: action.data,
    fetchingNotifications: false,
  }),
  [CHECK_USER_EXISTS]: (state, action) => ({
    processing: false,
    users: {
      ...state.users,
      [action.key]: {
        id: action.key,
        exists: action.data.isExist,
        maskedPhone: action.data.maskedPhone,
        email: action.email,
        phoneNumber: action.phoneNumber,
      },
    },
  }),
  [USER_LOGIN]: authSuccess,
  [USER_REGISTRATION]: authSuccess,
  [SEND_VERIFICATION_CODE]: () => ({
    processing: false,
  }),
  [USER_RESET_PASSWORD]: () => ({
    processing: false,
  }),
  [USER_FORGOT_PASSWORD]: () => ({
    processing: false,
  }),
  [CHECK_LEGACY_ORDERS_FOR_CURRENT_USER]: (state, action) => {
    return ({
      ledLegacyOrders: {
        ...state.ledLegacyOrders,
        hasAny: action.data.orders.length > 0,
        legacyCutoffDate: action.data.legacyCutoffDate,
        hasFetched: true,
        fetchLegacyOrdersFailed: false,
      },
    })
  },
  [FETCH_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      paymentCards: action.data,
      paymentCardsFetched: true,
      paymentCardsError: {},
    },
  }),
  [FETCH_STRIPE_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      stripePaymentCards: action.data,
      stripePaymentCardsFetched: true,
      stripePaymentCardsError: {},
    },
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      balance: action.data.balance ?? 0,
      balanceFetched: true,
      balanceError: false,
      creditsLedger: action.data.credits,
      creditsByCurrency: {
        ...state.account.creditsByCurrency,
        [action.currencyCode]: {
          balance: action.data.balance,
          nextExpiringCredit: action.data.nextExpiringCredit,
          loading: false,
          error: null,
        },
      },
    },
  }),
  [UPDATE_USER_DETAILS]: (state, action) => ({
    ...authClearError(),
    processing: false,
    accountProcessing: false,
    account: {
      ...state.account,
      ...action.data,
    },
  }),
  [USER_LOGOUT]: (state) => {
    // WL-Mobile: remove Persist account data from iphone/capacitor "local storage"
    if (config.MOBILE_APP_CUSTOMISATIONS) {
      try {
        import(/* webpackChunkName: "Capacitor" */ '@capacitor/preferences')
          .then(capacitorPreferencesModule => {
            const { Preferences } = capacitorPreferencesModule
            Preferences.remove({ key: 'account' })
          })
      } catch {
        console.error('Failed to delete account data')
      }
    }
    return ({ ...initialAuthState, gsiLoaded: state.gsiLoaded, initComplete: true })
  },
  [UPDATE_ACCOUNT_PARTNERSHIP]: (state, action) => ({
    partnershipProcessing: false,
    account: {
      ...state.account,
      partnerships: {
        ...state.account.partnerships,
        [action.partnershipPrefix]: {
          ...state.account.partnerships[action.partnershipPrefix as keyof App.PartnershipsMap],
          ...action.data,
        },
      },
    },
  }),
  [LINK_PARTNERSHIP_ACCOUNT]: (state, action) => ({
    processing: false,
    account: {
      ...state.account,
      partnerships: {
        ...state.account.partnerships,
        [action.prefix]: action.details,
      },
    },
  }),
  [SET_RECENTLY_USED_AIRPORT_CODE]: (state, action) => ({
    account: {
      ...state.account,
      recentlyUsedAirportCode: action.airportCode,
    },
  }),
  [UPDATE_ADD_PHONE_PROMPTED]: (state, action) => ({
    account: {
      ...state.account,
      addPhonePromptedAt: action.addPhonePromptedAt,
    },
  }),
  [FETCH_CUSTOMER_DETAILS]: (state, action) => ({
    account: {
      ...state.account,
      addressDetails: action.data.addressDetails,
      gender: action.data.gender,
      title: action.data.title,
      middleName: action.data.middleName,
    },
    travellerDetailsProcessing: false,
    travellerDetailsFetched: true,
    travellerDetailsError: false,
  }),
  [DELETE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: false,
    travellerDetailsFetched: false,
  }),
  [UPDATE_CUSTOMER_DETAILS]: (state, action) => ({
    account: {
      ...state.account,
      addressDetails: action.data.addressDetails,
      gender: action.data.gender,
      title: action.data.title,
      middleName: action.data.middleName,
    },
    travellerDetailsProcessing: false,
  }),

  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            item: action.data,
            fetching: false,
            error: undefined,
          },
        },
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            item: action.data,
            fetching: false,
            error: undefined,
          },
        },
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            item: action.data,
            fetching: false,
            error: undefined,
          },
        },
      },
    },
  }),
  [FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            ...state.account.luxPlus.member.subscription,
            preferredPaymentMethod: action.data.paymentMethod,
          },
        },
      },
    },
  }),
  [FETCH_CUSTOMER_TRAVELLERS]: (state, action) => ({
    customerTravellers: {
      ...state.customerTravellers,
      travellers: action.data,
      fetching: false,
      error: false,
    },
  }),
  [DELETE_CUSTOMER_TRAVELLER]: (state, action) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        deletionProcessing: {
          ...state.customerTravellers.submissionState.deletionProcessing,
          [action.id]: false,
        },
      },
    },
  }),
  [SUBMIT_CUSTOMER_TRAVELLER]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        submissionProcessing: 'processed',
        error: false,
      },
    },
  }),
  [FETCH_USER_CART]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      items: action.data.items,
      roomsToBeConnected: filterConnectedRooms(action.data.items, action.data.roomsToBeConnected),
      itemStates: action.data.itemStates,
      fetching: false,
      synced: true,
    },
  }),
})

const apiFailures = reducerSwitch<App.AuthState>({
  [CHECK_USER_EXISTS]: authError,
  [USER_LOGIN]: authError,
  [USER_REGISTRATION]: authError,
  [UPDATE_USER_DETAILS]: authError,
  [USER_RESET_PASSWORD]: authError,
  [USER_FORGOT_PASSWORD]: authError,
  [LINK_PARTNERSHIP_ACCOUNT]: authError,
  [SEND_VERIFICATION_CODE]: authError,
  [FETCH_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      paymentCardsError: action.error,
    },
  }),
  [FETCH_USER_NOTIFICATIONS]: (state, action) => ({
    notificationsError: action.error,
    fetchingNotifications: false,
  }),
  [FETCH_STRIPE_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      stripePaymentCardsError: action.error,
    },
  }),
  [CHECK_LEGACY_ORDERS_FOR_CURRENT_USER]: (state) => ({
    ledLegacyOrders: {
      ...state.ledLegacyOrders,
      fetchLegacyOrdersFailed: true,
      hasFetched: false,
    },
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      balance: 0,
      balanceFetched: false,
      balanceError: true,
    },
    creditsByCurrency: {
      ...state.account.creditsByCurrency,
      [action.currencyCode]: {
        balance: 0,
        nextExpiringCredit: null,
        loading: false,
        error: true,
      },
    },
  }),
  [UPDATE_ACCOUNT_PARTNERSHIP]: (state, action) => ({
    partnershipProcessing: false,
    partnershipError: action.error,
  }),
  [FETCH_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: false,
    travellerDetailsFetched: false,
    travellerDetailsError: true,
  }),
  [DELETE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: false,
  }),

  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: false,
            error: action.error,
          },
        },
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: false,
            error: action.error,
          },
        },
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          subscription: {
            fetching: false,
            error: action.error,
          },
        },
      },
    },
  }),
  [FETCH_CUSTOMER_TRAVELLERS]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      fetching: false,
      error: true,
    },
  }),
  [DELETE_CUSTOMER_TRAVELLER]: (state, action) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        deletionProcessing: {
          ...state.customerTravellers.submissionState.deletionProcessing,
          [action.id]: false,
        },
      },
    },
  }),
  [SUBMIT_CUSTOMER_TRAVELLER]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        error: true,
        submissionProcessing: 'failed',
      },
    },
  }),
  [FETCH_USER_CART]: (state) => ({
    userCart: {
      ...userCartBase(state),
      fetching: false,
    },
  }),
})

export default createReducer<App.AuthState, AuthAction>(initialAuthState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [ACCOUNT_INIT_COMPLETE]: () => ({ initComplete: true }),
  [ACCOUNT_ACCESS_SHOW_LOGIN]: authClearError,
  [ACCOUNT_ACCESS_SHOW_EMAIL_JOIN]: authClearError,
  [ACCOUNT_ACCESS_SHOW_REGISTER]: authClearError,
  [ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD]: authClearError,
  [CLEAR_AUTH_ERROR]: authClearError,
  [SAVE_CUSTOMER_SIGNATURE]: (state, action) => ({
    signature: action.signature,
  }),
  [DELETE_CUSTOMER_SIGNATURE]: () => ({
    signature: undefined,
  }),
  [SYNC_USER_DETAILS]: (state, action) => ({
    account: {
      ...state.account,
      ...action.userDetails,
    },
  }),
  [GSI_SCRIPT_LOADED]: () => ({
    gsiLoaded: true,
  }),
  [SET_CUSTOMER_VALUE]: (state, action) => ({
    customerValue: action.value,
  }),
  [SUBSCRIPTION_USER_NOTIFICATIONS_UPDATE]: (state, action) => ({
    notifications: action.notifications,
  }),
  [REMOVE_USER_NOTIFICATION]: (state, action) => ({
    notifications: state.notifications.filter(item => item.id !== action.notification.id),
  }),
  [VIEW_USER_NOTIFICATIONS]: (state) => ({
    notifications: state.notifications.map(item => ({ ...item, viewed: true })),
  }),
  [FETCH_RECENT_SEARCHES]: (state, action) => ({
    recentSearches: action.data,
  }),
  [SET_RECENT_SEARCHES]: (state, action) => ({
    recentSearches: action.data,
  }),
  [TOGGLE_SHOP_AS_MEMBER]: (state, action) => ({
    account: {
      ...state.account,
      luxPlus: {
        ...state.account.luxPlus,
        member: {
          ...state.account.luxPlus.member,
          disableMembership: action.disableMembership,
        },
      },
    },
  }),
  [RESET_CUSTOMER_TRAVELLER_SUBMISSION]: (state) => ({
    customerTravellers: {
      ...state.customerTravellers,
      submissionState: {
        ...state.customerTravellers.submissionState,
        submissionProcessing: undefined,
        error: false,
      },
    },
  }),
  [ADD_USER_CART_ITEM]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      items: {
        ...state.userCart.items,
        [action.item.itemId]: action.item,
      },
      itemStates: {
        ...state.userCart.itemStates,
        [action.item.itemId]: {
          saveForLater: false,
          deleted: false,
        },
      },
    },
  }),
  [ADD_USER_CART_CONNECTED_ROOM]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      roomsToBeConnected: filterConnectedRooms(state.userCart.items, [...state.userCart.roomsToBeConnected, action.connectedRooms]),
    },
  }),
  [REMOVE_USER_CART_CONNECTED_ROOM]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      roomsToBeConnected: filterConnectedRooms(state.userCart.items, state.userCart.roomsToBeConnected.filter(room => !room.some(itemId => action.connectedRoom.includes(itemId)))),
    },
  }),
  [CLEAR_PURCHASED_USER_CART_ITEMS]: (state, action) => {
    const items = omitKeys(Array.from<string>(action.purchasedItemIds))(state.userCart.items)
    const roomsToBeConnected = filterConnectedRooms(items, state.userCart.roomsToBeConnected)
    return {
      userCart: {
        ...userCartBase(state),
        items,
        roomsToBeConnected,
      },
    }
  },
  [SYNC_USER_CART]: (_, action) => ({
    userCart: {
      ...action.userCartState,
      roomsToBeConnected: filterConnectedRooms(action.userCartState.items, action.userCartState.roomsToBeConnected),
      synced: true,
    },
  }),
  [SAVE_USER_CART_ITEM_FOR_LATER]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      items: { ...state.userCart.items },
      itemStates: {
        ...state.userCart.itemStates,
        [action.itemId]: {
          saveForLater: !state.userCart.itemStates?.[action.itemId]?.saveForLater,
          deleted: false,
        },
      },
    },
  }),
  [UPDATE_USER_CART_ITEM]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      items: {
        ...state.userCart.items,
        [action.item.itemId]: action.item,
      },
    },
  }),
  [DELETE_USER_CART_ITEM]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      itemStates: {
        ...state.userCart.itemStates,
        [action.itemId]: {
          deleted: !state.userCart.itemStates?.[action.itemId]?.deleted,
          saveForLater: false,
        },
      },
    },
  }),
  // this does pretty much the same as the above but makes the intent much clearer in code
  [UNDO_USER_CART_ITEM_DELETE]: (state, action) => ({
    userCart: {
      ...userCartBase(state),
      itemStates: {
        ...state.userCart.itemStates,
        [action.itemId]: {
          deleted: false,
          saveForLater: false,
        },
      },
    },
  }),
  [DELETE_ALL_USER_CART_ITEMS]: () => ({
    userCart: initialAuthState.userCart,
  }),
})
