import { CHECKOUT_ITEM_TYPE_FLIGHT } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'
import { FlightSegment, FlightViewTypes } from 'constants/flight'
import { last } from 'lib/array/arrayUtils'

function getJourneyFields(
  journey: App.JourneyV2,
) {
  const isV2ReturnFare = journey.fareType === FlightViewTypes.RETURN

  return {
    journeyId: journey.id,
    journeyKey: journey.bookingInfo.journeyKey,
    provider: journey.provider,
    providerSearchId: journey.bookingInfo.providerSearchId,
    cost: isV2ReturnFare ? journey.price.all.totalRoundTripPrice : journey.price.all.totalFare,
    carrierImage: {
      url: journey.carrierLogo,
      title: journey.carrierName,
    },
  }
}

function getViewType(
  departing?: App.AnyJourney,
  returning?: App.AnyJourney,
  viewType?: string,
) {
  const { RETURN, TWO_ONE_WAYS_AND_RETURN } = FlightViewTypes
  const isReturnCombinedFlight = departing?.fareType === RETURN && returning?.fareType === RETURN
  return isReturnCombinedFlight ? TWO_ONE_WAYS_AND_RETURN : viewType
}

interface FlightItemUpdate {
  originAirportCode?: string;
  destinationAirportCode?: string;
  searchId?: string;
  forceBundleId?: string;
}

const EmptyBaggage: App.Checkout.FlightItemPaxBaggageMap = { baggage: {}, carryOnBaggage: {} }

export function setFlightCheckoutItemJourney(
  checkoutItem: App.Checkout.FlightItem,
  journey: App.JourneyV2,
  segment: 'departing' | 'returning',
  expiry: number,
  options: {
    fareFamily?: App.FlightFareFamily;
    baggage?: App.Checkout.FlightItemPaxBaggageMap;
    forceBundleId?: string;
  },
) {
  const journeyFields = getJourneyFields(journey)

  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    expiry,
    // our flight is changing, reset the fare fetching behaviour
    hasFinalFare: false,
    fareFetchError: undefined,
    journeyType: journey.itemType,
    forceBundleId: options.forceBundleId ?? checkoutItem.forceBundleId,
    totalFare: 0,
    searchId: checkoutItem.searchId,
    viewType: journey.fareType === FlightViewTypes.RETURN ? FlightViewTypes.TWO_ONE_WAYS_AND_RETURN : checkoutItem.viewType,
    flights: [FlightSegment.DEPARTING, FlightSegment.RETURNING].map(currentSegment => {
      const flight = currentSegment === FlightSegment.DEPARTING ? checkoutItem.flights[0] : checkoutItem.flights[1]

      if (segment === currentSegment) {
        return {
          ...journeyFields,
          extras: options.baggage ?? EmptyBaggage,
          fareFamily: options.fareFamily,
          departingAirportCode: flight?.departingAirportCode,
          arrivalAirportCode: flight?.arrivalAirportCode,
          departingDate: flight?.departingDate,
          arrivalAirportName: flight?.arrivalAirportName,
          departingAirportName: flight?.departingAirportName,
        }
      }

      return flight
    }).filter(Boolean),
  }

  return updatedItem
}

export function resetFlightCheckoutItem(
  checkoutItem: App.Checkout.FlightItem,
) {
  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    flights: [],
  }

  return updatedItem
}

export function setFlightCheckoutItemJourneyFareFamily(
  checkoutItem: App.Checkout.FlightItem,
  flightIndex: number,
  options: {
    fareFamily?: App.FlightFareFamily;
  },
) {
  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    flights: checkoutItem.flights.map((flight, index) => {
      if (index === flightIndex) {
        return {
          ...flight,
          fareFamily: options.fareFamily,
        }
      }

      return flight
    }),
  }

  return updatedItem
}

export function updateFlightCheckoutItem(
  checkoutItem: App.Checkout.FlightItem,
  updates: FlightItemUpdate,
): App.Checkout.FlightItem {
  const {
    originAirportCode,
    destinationAirportCode,
    searchId,
    forceBundleId,
  } = updates

  const flights = searchId !== checkoutItem.searchId ? [] : checkoutItem.flights

  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    originAirportCode: originAirportCode ?? checkoutItem.originAirportCode,
    destinationAirportCode: destinationAirportCode ?? checkoutItem.destinationAirportCode,
    searchId,
    forceBundleId,
    // we just updated the item, fare may have changed
    hasFinalFare: false,
    fareFetchError: undefined,
    flights,
  }

  return updatedItem
}

interface GenerateItemFields {
  originAirportCode: string,
  destinationAirportCode?: string,
  occupants: App.Occupants,
  passengers: Array<App.Checkout.FlightPassenger>,
  fareType: 'return' | 'oneWay' | 'multiCity',
  viewType: string,
  searchId?: string,
  isFlightCredit?: boolean,
  itemId?: string,
  fareFamilies?: Array<App.FlightFareFamily | undefined>,
  baggage?: Array<App.Checkout.FlightItemPaxBaggageMap>,
  flights?: Array<App.JourneyV2>,
  bundledItemIds?: Array<string>,
  quotedFare?: number;
  expiry?: number
}

export function generateFlightCheckoutItem(fields: GenerateItemFields): App.Checkout.FlightItem {
  const {
    searchId,
    flights = [],
    baggage = [],
    fareFamilies = [],
    occupants,
    passengers,
    fareType,
    viewType,
    isFlightCredit,
    itemId,
    bundledItemIds,
    originAirportCode,
    destinationAirportCode,
    quotedFare,
    expiry,
  } = fields
  return {
    itemId: itemId ?? uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_FLIGHT,
    originAirportCode,
    destinationAirportCode: destinationAirportCode!,
    passengers,
    occupants,
    fareType,
    viewType: getViewType(flights[0], flights[1], viewType),
    quotedFare,
    journeyType: flights[0]?.itemType,
    isFlightCredit: !!isFlightCredit,
    searchId,
    bundledItemIds,
    expiry,
    flights: flights.map((flight, index) => {
      const journey = getJourneyFields(flight)

      return {
        journeyId: journey.journeyId,
        journeyKey: journey.journeyKey,
        provider: journey.provider,
        providerSearchId: journey.providerSearchId,
        fareFamily: fareFamilies[index],
        extras: baggage[index] ?? EmptyBaggage,
        cost: journey.cost,
        carrierImage: journey.carrierImage,
        departingAirportCode: flight.flightGroup.flights[0].departureAirport,
        arrivalAirportCode: last(flight.flightGroup.flights).arrivalAirport,
        departingDate: flight.flightGroup.flights[0].departingDate,
        arrivalAirportName: last(flight.flightGroup.flights).arrivalAirportName,
        departingAirportName: flight.flightGroup.flights[0].departureAirportName,
      }
    }),
    hasFinalFare: false,
  }
}
