import React, { useMemo } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import styled from 'styled-components'
import { rem } from 'polished'
import ItineraryMap from 'components/Cruises/CruiseOfferPage/content/Itinerary/ItineraryMap'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import useCruiseUniqueItineraryList from 'hooks/Cruise/useCruiseUniqueItineraryList'
import offerPageURL from 'lib/offer/offerPageURL'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'
import cn from 'clsx'
import { isCruiseV1Offer } from 'lib/offer/offerTypes'
import { EmptyArray } from 'lib/array/arrayUtils'

const Itinerary = styled.div`
  order: 4;
  padding: ${rem(24)} ${rem(24)} 0 ${rem(24)};
`

const ImageItinerary = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`

const Sticky = styled.div`
  ${mediaQueryUp.desktop} {
    position: sticky;
    top: 0;
    box-shadow: ${props => props.theme.shadow.bottom.small};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${rem(60)} ${rem(65)} auto;
  gap: ${rem(10)};

  .colSpan {
    grid-column: span 2;
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: ${rem(56)} ${rem(60)} auto;
  }
`

interface Props {
  offerTitle: string;
  itinerary?: Array<App.CruiseItineraryItem>;
  itineraryFlash?: string;
  offer: App.CruiseOffer | App.Offer | App.OfferSummary | App.Cruises.CruiseOfferSnapshot;
}

function CruiseModalItineraryDetails({
  itinerary = EmptyArray,
  offerTitle,
  itineraryFlash,
  offer,
}: Props) {
  const isOfferSnapshot = !('type' in offer)
  const isFlashOffer = !isOfferSnapshot && isCruiseV1Offer(offer)
  const uniqueItineraryList = useCruiseUniqueItineraryList({
    itinerary,
    isFlashOffer,
  })

  const shortItinerartFlashOffer = useMemo(() => {
    const pattern = /Day\s\d+(?:\s&\s\d+)?:\s([^*]+)/g
    const matches = itineraryFlash?.match(pattern)
    const mappedMatchs = matches?.map(match => match.replace(':', '&nbsp;&nbsp;&nbsp;'))!
    return mappedMatchs?.join('\n\n') ?? ''
  }, [itineraryFlash])

  const hasPort = useMemo(() => !!itinerary?.length && itinerary.some(item => !!item.port), [itinerary])

  return <ModalBase height="auto">
    <ModalHeader
      title={`Itinerary for ${offerTitle}`}/>
    <ModalBody>

      {!isFlashOffer && hasPort && <Sticky>
        <ItineraryMap
          isModal
          itinerary={itinerary}
        />
      </Sticky>}
      {isFlashOffer && !!offer.images[1] && <Sticky>
        <ImageItinerary>
          <Image
            data-testid="cruise-modal-itinerary-image"
            quality="eco"
            dpr={2}
            enhancedFormats={false}
            image={offer.images[1]}
          />
        </ImageItinerary>
      </Sticky>}

      <Itinerary>
        <Grid>
          {!isFlashOffer && uniqueItineraryList.map((itinerary) => <>
            <BodyText variant="medium">
              Day {itinerary.item.startDay}
            </BodyText>
            {(itinerary.isStart || itinerary.isEnd || itinerary.addPortCircle) && <BodyText variant="medium" weight="bold">
              {itinerary.isStart && 'Start: '}
              {itinerary.isEnd && 'End: '}
              {itinerary.addPortCircle && !itinerary.isStart && !itinerary.isEnd && `Port ${itinerary.portNumber}: `}
            </BodyText>}

            <BodyText variant="medium" format="titlecase" className={cn({ colSpan: !itinerary.isStart && !itinerary.isEnd && !itinerary.addPortCircle })}>
              {!!itinerary.item.title && itinerary.item.title.toLowerCase()}
              {!itinerary.item.title && 'At Sea'}
            </BodyText>
          </>)}
        </Grid>

        {isFlashOffer && shortItinerartFlashOffer && <MarkdownRender type="compact" fontSize="large" content={shortItinerartFlashOffer} />}
      </Itinerary>
    </ModalBody>
    <ModalFooter
      isPrimaryActionHidden={isOfferSnapshot}
      primaryActionProps={{
        children: 'View offer',
        href: offerPageURL(isOfferSnapshot ? undefined : offer),
        target: '_blank',
      }}
    />
  </ModalBase>
}

export default CruiseModalItineraryDetails
