import { ANYWHERE_NAME, ANYWHERE_PLACE_ID } from 'constants/search'
import { arrayToObject } from 'lib/array/arrayUtils'
import config from './config'

// Magic to enforce types on the places list
const asPlaceRecord = <T extends string>(record: { [K in T]: App.Place }) => record

/**
 * A list of known places with their place/place id for the search service
 */
const Places = asPlaceRecord({
  Anywhere: { id: ANYWHERE_PLACE_ID, name: ANYWHERE_NAME },
  Auckland: { id: 'le_464adf7b911508701f4c4e70eab19a6e', name: 'Auckland' },
  Sydney: { id: 'le_678c61ac5b4f91ca86e112fc50e63766', name: 'Sydney' },
  Canberra: { id: 'le_5f169380fd43eadac8a9f1423aaa9ac8', name: 'Canberra' },
  Brisbane: { id: 'le_b96d145f6c31e35755f4a6841574ba37', name: 'Brisbane' },
  Darwin: { id: 'le_3f08f0e6992e8d712984a799e6681209', name: 'Darwin' },
  Adelaide: { id: 'le_f4c2c73d7fe5eb38fd83d32a61604247', name: 'Adelaide' },
  Perth: { id: 'le_7c27cf9f8fe09724bb754e474610eb0e', name: 'Perth' },
  Hobart: { id: 'le_b573c52d599a979ba8c16e9789bb8014', name: 'Hobart' },
  Melbourne: { id: 'le_15fa44474c92da27cf2125373356db4c', name: 'Melbourne' },
  Australia: { id: 'le_d3d9446802a44259755d38e6d163e820', name: 'Australia' },
  Canada: { id: 'le_c16a5320fa475530d9583c34fd356ef5', name: 'Canada' },
  Iceland: { id: 'le_d1fe173d08e959397adf34b1d77e88d7', name: 'Iceland' },
  India: { id: 'le_f033ab37c30201f73f142449d037028d', name: 'India' },
  Japan: { id: 'le_7647966b7343c29048673252e490f736', name: 'Japan' },
  Malaysia: { id: 'le_a3c65c2974270fd093ee8a9bf8ae7d0b', name: 'Malaysia' },
  NewZealand: { id: 'le_9fc3d7152ba9336a670e36d0ed79bc43', name: 'New Zealand' },
  Singapore: { id: 'le_bd4c9ab730f5513206b999ec0d90d1fb', name: 'Singapore' },
  Thailand: { id: 'le_8f85517967795eeef66c225f7883bdcb', name: 'Thailand' },
  UnitedKingdom: { id: 'le_cfecdb276f634854f3ef915e2e980c31', name: 'United Kingdom' },
  UnitedStates: { id: 'le_757b505cfd34c64c85ca5b5690ee5293', name: 'United States' },
  Vietnam: { id: 'le_a597e50502f5ff68e3e25b9114205d4a', name: 'Vietnam' },
  Bali: { id: 'le_64930b7151b6bc3e289f37d5da62ac9f', name: 'Bali' },
  Dubai: { id: 'le_f9736cf40a30e0fe71c53004951da2ed', name: 'Dubai' },
  France: { id: 'le_093f65e080a295f8076b1c5722a46aa2', name: 'France' },
  Germany: { id: 'le_ea5d2f1c4608232e07d3aa3d998e5135', name: 'Germany' },
  Ireland: { id: 'le_68d30a9594728bc39aa24be94b319d21', name: 'Ireland' },
  Netherlands: { id: 'le_d1f491a404d6854880943e5c3cd9ca25', name: 'Netherlands' },
  HongKong: { id: 'le_f5b2c7d542d5cc6eecba73128445ef53', name: 'Hong Kong' },
  Italy: { id: 'le_93db85ed909c13838ff95ccfa94cebd9', name: 'Italy' },
  SouthAfrica: { id: 'le_9766527f2b5d3e95d4a733fcfb77bd7e', name: 'South Africa' },
  Rajasthan: { id: 'le_2c8fb83bd80f4e8ff450a08e094f625d', name: 'Rajasthan' },
  Queensland: { id: 'le_3d3621cee6f49e93a34f3f0f654ab41a', name: 'Queensland' },
  Cancun: { id: 'le_8701b6eda0e8f334b30b4488214bbf1e', name: 'Cancun' },
  Spain: { id: 'le_7e7757b1e12abcb736ab9a754ffb617a', name: 'Spain' },
  China: { id: 'le_a5bfc9e07964f8dddeb95fc584cd965d', name: 'China' },
  Indonesia: { id: 'le_43ec517d68b6edd3015b3edc9a11367b', name: 'Indonesia' },
  Israel: { id: 'le_3ef815416f775098fe977004015c6193', name: 'Israel' },
  Korea: { id: 'le_f4b9ec30ad9f68f89b29639786cb62ef', name: 'Korea' },
  Macau: { id: 'le_c9e1074f5b3f9fc8ea15d152add07294', name: 'Macau' },
  Philippines: { id: 'le_2b24d495052a8ce66358eb576b8912c8', name: 'Philippines' },
  Qatar: { id: 'le_f2217062e9a397a1dca429e7d70bc6ca', name: 'Qatar' },
  SaudiArabia: { id: 'le_6c4b761a28b734fe93831e3fb400ce87', name: 'Saudi Arabia' },
  Taiwan: { id: 'le_38af86134b65d0f10fe33d30dd76442e', name: 'Taiwan' },
  UnitedArabEmirates: { id: 'le_a2557a7b2e94197ff767970b67041697', name: 'United Arab Emirates' },
  Wellington: { id: 'le_fd87f0d4bde24eadae6f324b7d4c31a6', name: 'Wellington' },
  Christchurch: { id: 'le_1e15efc3fac4ba31daa6f91082c97e97', name: 'Christchurch' },
  Hamilton: { id: 'le_735fae61e2b0f29cae18117c99c2fa59', name: 'Hamilton' },
  Queenstown: { id: 'le_5a4773830c5a9191a6bd6c301d060ad2', name: 'Queenstown' },
  GoldCoast: { id: 'le_bac732346917b0a1231354ef55faf00a', name: 'Gold Coast' },
  BarossaValley: { id: 'le_05c690755465e8723c8d9ee8d5b6f486', name: 'Barossa Valley' },
  HunterValley: { id: 'le_864d8b8a2daebb7269e0882b0bfb6232', name: 'Hunter Valley' },
  YarraValley: { id: 'le_e6fd3b368ff273183a74d471a141d7c3', name: 'Yarra Valley' },
  BlueMountains: { id: 'le_4371d2566cbf4eb16e1cda1ce268c63c', name: 'Blue Mountains' },
  Fiji: { id: 'le_72b32a1f754ba1c09b3695e0cb6cde7f', name: 'Fiji' },
  Whitsundays: { id: 'le_02785edd54b40735ce22cea83094508a', name: 'Whitsundays' },
  Mexico: { id: 'le_eb160de1de89d9058fcb0b968dbbbd68', name: 'Mexico' },
  Jamaica: { id: 'le_2a38a4a9316c49e5a833517c45d31070', name: 'Jamaica' },
  Maldives: { id: 'le_2723d092b63885e0d7c260cc007e8b9d', name: 'Maldives' },
  Victoria: { id: 'le_5781a2637b476d781eb3134581b32044', name: 'Victoria' },
  Cairns: { id: 'le_b7218568a1335addb2966d47e1fe939c', name: 'Cairns' },
  Greece: { id: 'le_735b90b4568125ed6c3f678819b6e058', name: 'Greece' },
  Croatia: { id: 'le_17e62166fc8586dfa4d1bc0e1742c08b', name: 'Croatia' },
  Cyprus: { id: 'le_6c8349cc7260ae62e3b1396831a8398f', name: 'Cyprus' },
})

export const PlacesByPlaceId = arrayToObject(Object.values(Places), place => place.id)

export const placesByRegionCode: Record<string, App.Place> = {
  cn: Places.China,
  hk: Places.HongKong,
  id: Places.Indonesia,
  il: Places.Israel,
  jp: Places.Japan,
  kr: Places.Korea,
  mo: Places.Macau,
  my: Places.Malaysia,
  ph: Places.Philippines,
  qa: Places.Qatar,
  sa: Places.SaudiArabia,
  sg: Places.Singapore,
  tw: Places.Taiwan,
  th: Places.Thailand,
  ae: Places.UnitedArabEmirates,
  vn: Places.Vietnam,
  au: Places.Australia,
  fr: Places.France,
  de: Places.Germany,
  ie: Places.Ireland,
  it: Places.Italy,
  nl: Places.Netherlands,
  es: Places.Spain,
  za: Places.SouthAfrica,
  in: Places.India,
  ca: Places.Canada,
  us: Places.UnitedStates,
  gb: Places.UnitedKingdom,
  nz: Places.NewZealand,
}

export const DEFAULT_PLACE = Places.Melbourne

export function getPlaceByRegionCode(regionCode: string = '') {
  return placesByRegionCode[regionCode.toLowerCase()] ?? DEFAULT_PLACE
}

export function getDefaultRegionPlace(regionCode: string) {
  switch (config.BRAND) {
    case 'luxuryescapes':
      return getPlaceByRegionCode(regionCode)
    case 'treatmetravel':
      // treatmetravel is NZ-only and should fallback to Auckland
      // other non-le brands define 'melbourne' as their region place as
      // they are AU only and their regions are really major cities in AU
      return Places.Auckland
    default:
      return DEFAULT_PLACE
  }
}

export default Places
