import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import BodyText from '../Typography/BodyText'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

type AssistChipSize = 'small' | 'medium' | 'large'

export const ASSIST_CHIP_HEIGHTS: Record<AssistChipSize, number> = {
  small: 28,
  medium: 32,
  large: 36,
}

const Chip = styled(Clickable)`
  display: inline-flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.round};
  box-shadow: inset 0 0 0 var(--chip-stroke-width) var(--chip-stroke-color);
  position: relative;
  z-index: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  gap: ${rem(4)};
  white-space: nowrap;

  &:disabled > * {
    pointer-events: none;
  }

  &.icon-only {
    justify-content: center;
  }

  &.size-small {
    height: ${rem(ASSIST_CHIP_HEIGHTS.small)};
    padding: 0 ${rem(8)};

    &.icon-only {
      padding: 0;
      width: ${rem(ASSIST_CHIP_HEIGHTS.small)};
    }
  }

  &.size-medium {
    height: ${rem(ASSIST_CHIP_HEIGHTS.medium)};
    padding: 0 ${rem(12)};

    &.icon-only {
      padding: 0;
      width: ${rem(ASSIST_CHIP_HEIGHTS.medium)};
    }
  }

  &.size-large {
    height: ${rem(ASSIST_CHIP_HEIGHTS.large)};
    padding: 0 ${rem(12)};

    &.icon-only {
      padding: 0;
      width: ${rem(ASSIST_CHIP_HEIGHTS.large)};
    }
  }

  &.variant-outline {
    --chip-stroke-width: 1px;
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.four};
    background-color: transparent;
    color: ${props => props.theme.palette.neutral.default.one};

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
          background-color: ${props => props.theme.palette.neutral.default.eight};
        }
      }

      &.error {
        --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};
      }

      &:active {
        --chip-stroke-width: 2px;
        --chip-stroke-color: ${props => props.theme.palette.neutral.default.one};
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }
    }

    &:disabled {
      --chip-stroke-color: ${props => props.theme.palette.neutral.default.five};
      color: ${props => props.theme.palette.neutral.default.four};
    }
  }

  &.variant-filled {
    --chip-stroke-width: 1px;
    --chip-stroke-color: ${props => props.theme.palette.neutral.default.four};
    background-color: ${props => props.theme.palette.neutral.default.eight};
    color: ${props => props.theme.palette.neutral.default.one};

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          --chip-stroke-color: ${props => props.theme.palette.neutral.default.two};
        }
      }

      &.error {
        --chip-stroke-color: ${props => props.theme.palette.messaging.critical.normalForeground};
      }

      &:active {
        --chip-stroke-color: ${props => props.theme.palette.neutral.default.one};
        background-color: ${props => props.theme.palette.neutral.default.one};
        color: ${props => props.theme.palette.neutral.default.eight};
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }
    }

    &:disabled {
      --chip-stroke-color: ${props => props.theme.palette.neutral.default.five};
      color: ${props => props.theme.palette.neutral.default.four};
    }
  }

  > svg, img {
    height: ${rem(16)};
    width: ${rem(16)};
  }
`

interface Props extends React.ComponentProps<typeof Clickable> {
  /** Determines the core visual representation of the chip */
  variant: 'filled' | 'outline';
  /** Determines factors like the height/padding of the chip */
  size: AssistChipSize;
  /** Whether or not the chip should show itself visually in an error state */
  error?: boolean;
  /** An icon element that will go at the start (often the 'left' side) of the chip */
  startIcon?: React.ReactElement;
  /** An icon element that will go at the end (often the 'right' side) of the chip */
  endIcon?: React.ReactElement;
}

/**
 * - Assist chips provide the user with the ability to perform an action related to visible content.
 * - They are used when the user has multiple options for optional actions, and need to be displayed together.
 * - An assist chip could be used when shown in a card below primary content, allowing the user to know there are optional actions that they may perform. Typically assist chips can trigger an action or show progress and confirmation. Icons and copy may adjust in the chip depending on the action performed (e.g. a loading indicator, an icon becoming a filled variant).
 * - For example, on a My Escapes card we may use chips to show to the customer that adding an experiences, flights or insurance is optional. An assist chip here would not distract the customer from the main goal of viewing their escape, but still provide helpful context that this is an optional action they may perform. Another example could be in trip planner itinerary, for a card which does not have dates set then a chip could be shown here prompting the user to add dates to this specific item. Using a button here would prove to make the page quite busy when taking into account other screen elements.
 */
const AssistChip = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(function AssistChip(props, ref) {
  const { children, className, variant, size, error, startIcon, endIcon, ...buttonProps } = props

  const iconOnly = React.Children.toArray(children).filter(Boolean).length === 0 && ((startIcon && !endIcon) || (endIcon && !startIcon))

  return (<Chip
    {...buttonProps}
    ref={ref}
    className={cn(
      className,
      `variant-${variant}`,
      `size-${size}`,
      iconOnly ? 'icon-only' : undefined,
      { error },
    )}
  >
    {startIcon}
    {!iconOnly && <BodyText variant={size}>{children}</BodyText>}
    {endIcon}
  </Chip>)
})

AssistChip.displayName = 'AssistChip'

export default AssistChip
