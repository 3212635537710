import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import {
  MODAL_OPEN,
  BOOKING_DATE_WARNING_MODAL_OPEN, JOURNEY_SOLD_OUT_MODAL_OPEN,
  CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN, FLIGHTS_SESSION_EXPIRED_MODAL_OPEN, GENERIC_ORDER_ERROR_MODAL_OPEN,
  AUTH_MODAL_OPEN,
  QUOTE_EMAIL_MODAL_OPEN,
  MODAL_CLOSE,
  ACCOUNT_ACCESS_SHOW_LOGIN,
  ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD,
  ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK,
  SHOW_BRIDGER_PAY_PAYMENT_MODAL,
  ADD_PAYMENT_PROVIDER, SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL, SHOW_CUSTOMER_SIGNATURE_MODAL, API_CALL,
  TOUR_V2_PRICE_CHANGE_MODAL_OPEN,
  JOURNEY_PRICE_CHANGE_MODAL_OPEN, HOTEL_PRICE_CHANGE_MODAL_OPEN,
  BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN,
  BUNDLE_PRICE_CHANGE_MODAL_OPEN,
  SHOW_CHECKOUT_CART_MODE_MODAL,
  ACCOUNT_ACCESS_SET_MODE,
  SHOW_PAY_TO_AUTHORISATION_MODAL,
  SHOW_PAY_ID_AUTHORISATION_MODAL,
  CONFIRMATION_EMAIL_MODAL_OPEN,
  BEDBANK_SOLD_OUT_MODAL_OPEN,
  SHOW_EXPERIENCE_CANCELLATION_MODAL,
  CART_LINK_MODAL_OPEN,
  SHOW_SECURE_PAYMENT_MODAL,
  TERMS_AND_CONDITIONS_MODAL_OPEN,
} from './actionConstants'
import { USER_LOGIN } from './apiActionConstants'
import { webViewAccountAccess } from 'api/auth'
import { useMobileNativeAppAuth } from 'selectors/configSelectors'
import { AppAction } from './ActionTypes'

export function modalOpen(heading: string, message: string): AppAction {
  return {
    type: MODAL_OPEN,
    data: {
      heading,
      message,
    },
  }
}

export function bookingDateWarningModalOpen(): AppAction {
  return {
    type: BOOKING_DATE_WARNING_MODAL_OPEN,
  }
}

export function hotelPriceChangeModalOpen(priceChange: App.UiPriceChangeModalState): AppAction {
  return {
    type: HOTEL_PRICE_CHANGE_MODAL_OPEN,
    data: {
      priceChange,
    },
  }
}

export function bundlePriceChangeModalOpen(priceChange: App.UiPriceChangeModalState): AppAction {
  return {
    type: BUNDLE_PRICE_CHANGE_MODAL_OPEN,
    data: {
      priceChange,
    },
  }
}

export function bedbankHotelPriceChangeModalOpen(priceChange: App.UiPriceChangeModalState): AppAction {
  return {
    type: BEDBANK_HOTEL_PRICE_CHANGE_MODAL_OPEN,
    data: {
      priceChange,
    },
  }
}

export function tourV2PriceChangeModalOpen(priceChange: App.UiPriceChangeModalState): AppAction {
  return {
    type: TOUR_V2_PRICE_CHANGE_MODAL_OPEN,
    data: {
      priceChange,
    },
  }
}

export function journeyPriceChangeModalOpen(priceChange: App.UiPriceChangeModalState): AppAction {
  return {
    type: JOURNEY_PRICE_CHANGE_MODAL_OPEN,
    data: {
      priceChange,
    },
  }
}

export function journeySoldOutModalOpen(): AppAction {
  return {
    type: JOURNEY_SOLD_OUT_MODAL_OPEN,
  }
}

export function bedbankSoldOutModalOpen(): AppAction {
  return {
    type: BEDBANK_SOLD_OUT_MODAL_OPEN,
  }
}

export function cantProcessOrderOnlineModalOpen(refCode: string): AppAction {
  return {
    type: CANT_PROCESS_ORDER_ONLINE_MODAL_OPEN,
    data: {
      refCode,
    },
  }
}

export function flightsSessionExpiredModalOpen(): AppAction {
  return {
    type: FLIGHTS_SESSION_EXPIRED_MODAL_OPEN,
  }
}

export function genericOrderErrorModalOpen(errors: Array<any>): AppAction {
  return {
    type: GENERIC_ORDER_ERROR_MODAL_OPEN,
    data: {
      errors,
    },
  }
}

export function authModalOpen(heading?: string, message?: string): AppAction {
  return {
    type: AUTH_MODAL_OPEN,
    data: {
      heading,
      message,
    },
  }
}

export function quoteEmailModalOpen(): AppAction {
  return {
    type: QUOTE_EMAIL_MODAL_OPEN,
  }
}

export function cartLinkModalOpen(): AppAction {
  return {
    type: CART_LINK_MODAL_OPEN,
  }
}

export function termsAndConditionsModalOpen() {
  return {
    type: TERMS_AND_CONDITIONS_MODAL_OPEN,
  }
}

export function skyCheckModalOpen(): AppAction {
  return {
    type: CONFIRMATION_EMAIL_MODAL_OPEN,
  }
}

export function modalClose() {
  return {
    type: MODAL_CLOSE,
  }
}

/** @deprecated Please use `showModal(<AccountAccessModal />)` now instead of the redux version */
export function accountAccessShowLogin(mode?: App.UiAccountModalMode, dismissable: boolean = true): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    if (useMobileNativeAppAuth(state) && mode !== 'promptAddPhone') {
      dispatch({
        type: API_CALL,
        api: USER_LOGIN,
        request: () => webViewAccountAccess(state.geo.currentRegionCode, luxPlusEnabled).catch((error) => {
          // login failed/closed it, close the auth modal
          dispatch({
            type: MODAL_CLOSE,
          })
          throw error
        }),
      })
    }

    // even if we're in an app environment still open the auth modal
    // there is code out there that waits for the modal itself to close to know login failed
    // e.g. the AuthRoute component
    dispatch({
      type: ACCOUNT_ACCESS_SHOW_LOGIN,
      mode,
      dismissable,
    })
  }
}

export function accountAccessShowForgotPassword(): AppAction {
  return {
    type: ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD,
  }
}

export function accountAccessShowPartnershipLink(): AppAction {
  return {
    type: ACCOUNT_ACCESS_SHOW_PARTNERSHIP_LINK,
  }
}

export function showBridgerPayPaymentModal(): AppAction {
  return {
    type: SHOW_BRIDGER_PAY_PAYMENT_MODAL,
  }
}

export function addPaymentProvider(paymentProvider: string): AppAction {
  return {
    type: ADD_PAYMENT_PROVIDER,
    paymentProvider,
  }
}

export function showBridgerPayPaymentFailedModal(paymentProvider?: string): AppAction {
  return {
    type: SHOW_BRIDGERPAY_PAYMENT_FAILED_MODAL,
    data: {
      paymentProvider,
    },
  }
}

export function showCustomerSignatureModal(showConfirmationOptions = false): AppAction {
  return {
    type: SHOW_CUSTOMER_SIGNATURE_MODAL,
    showConfirmationOptions,
  }
}

export function showCheckoutCartModeModal(): AppAction {
  return {
    type: SHOW_CHECKOUT_CART_MODE_MODAL,
  }
}

export function setAccountAccessMode(mode: App.UiAccountModalMode): AppAction {
  return {
    type: ACCOUNT_ACCESS_SET_MODE,
    mode,
  }
}

export function showPayToAuthorisationModal(): AppAction {
  return {
    type: SHOW_PAY_TO_AUTHORISATION_MODAL,
  }
}

export function showPayIdAuthorisationModal(price: number): AppAction {
  return {
    type: SHOW_PAY_ID_AUTHORISATION_MODAL,
    data: {
      price,
    },
  }
}
export function showExperienceCancellationModal(): AppAction {
  return {
    type: SHOW_EXPERIENCE_CANCELLATION_MODAL,
  }
}

export function showSecurePaymentModal(): AppAction {
  return {
    type: SHOW_SECURE_PAYMENT_MODAL,
  }
}
