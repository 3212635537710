import React, {
  PropsWithChildren,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'

import noop from 'lib/function/noop'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { useInView } from 'react-intersection-observer'

const defaultHeights = {
  businessTravellerBudgetBar: 0,
  site: 0,
  siteStickyBanner: 0,
  checkout: 0,
  tripPlanner: 0,
  tripPlannerImmersive: 0,
  trip: 0,
  tripPlannerFooter: 0,
  tripPlannerDateCarousel: 0,
  bedbankOfferHeader: 0,
  villasOfferHeader: 0,
}

type HeaderKey = keyof typeof defaultHeights

const defaultInViews: Record<HeaderKey, boolean> = {
  businessTravellerBudgetBar: false,
  site: false,
  siteStickyBanner: false,
  checkout: false,
  tripPlanner: false,
  tripPlannerImmersive: false,
  trip: false,
  tripPlannerFooter: false,
  tripPlannerDateCarousel: false,
  bedbankOfferHeader: false,
  villasOfferHeader: false,
}

export interface HeaderHeightContextData {
  heights: { [key in HeaderKey]: number };
  setHeaderHeight: (key: HeaderKey, height: number) => void;
}

/**
 * A context for tracking the heights of header elements, so that other
 * elements can be correctly positioned below them.
 */
export const HeaderHeightContext = React.createContext<HeaderHeightContextData>({
  heights: defaultHeights,
  setHeaderHeight: noop,
})

export interface HeaderInViewContextData {
  inViews: { [key in HeaderKey]: boolean };
  setInView: (key: HeaderKey, inView: boolean) => void;
}

export const HeaderInViewContext = React.createContext<HeaderInViewContextData>({
  inViews: defaultInViews,
  setInView: noop,
})

export function HeaderStateContextProvider(props: PropsWithChildren) {
  const [heights, setHeights] = useState<HeaderHeightContextData['heights']>(defaultHeights)
  const [inViews, setInViews] = useState<HeaderInViewContextData['inViews']>(defaultInViews)

  const setHeaderHeight = useCallback(
    (key: HeaderKey, height: number) => {
      setHeights((heights) => {
        if (heights[key] === height) { return heights } // no change
        return { ...heights, [key]: height }
      })
    },
    [],
  )

  const setInView = useCallback((key: HeaderKey, inView: boolean) => {
    setInViews((inViews) => {
      if (inViews[key] === inView) { return inViews } // no change
      return { ...inViews, [key]: inView }
    })
  }, [])

  const heightState = useMemo(
    () => ({ heights, setHeaderHeight }),
    [heights, setHeaderHeight],
  )

  const inViewState = useMemo(
    () => ({ inViews, setInView }),
    [inViews, setInView],
  )

  return (
    <HeaderHeightContext.Provider value={heightState}>
      <HeaderInViewContext.Provider value={inViewState}>
        {props.children}
      </HeaderInViewContext.Provider>
    </HeaderHeightContext.Provider>
  )
}

/**
 * Watches a header for changes to its height and in view, and updates the `HeaderStateContext` accordingly
 *
 * @param key A string key for the header
 * @param ref A ref for the header element
 *
 * @deprecated use `useAppHeaderObserver` instead
 **/
export function useHeaderStateObserver(
  key: HeaderKey,
  ref: React.RefObject<HTMLElement | null>,
  isEnabled = true,
) {
  const { setHeaderHeight } = useContext(HeaderHeightContext)
  const { setInView } = useContext(HeaderInViewContext)
  const [inViewRef, inView] = useInView()

  useEffect(() => {
    inViewRef(ref.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  useEffect(() => {
    if (isEnabled) {
      setInView(key, inView)
    }
  }, [inView, isEnabled, key, setInView])

  useIsomorphicLayoutEffect(() => {
    const element = ref.current
    if (element && isEnabled) {
      startTransition(() => {
        setHeaderHeight(key, element.getBoundingClientRect().height)
      })
    }
  }, [key, ref, setHeaderHeight, isEnabled])

  useResizeObserver(ref, entry => setHeaderHeight(key, entry.target.getBoundingClientRect().height))
}
