import Group from 'components/utils/Group'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextButton from 'components/Luxkit/Button/TextButton'
import { matchPath, useRouteMatch } from 'react-router'
import { useAppSelector } from 'hooks/reduxHooks'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import { encodeSearchParams } from 'lib/search/searchUtils'
import config from 'constants/config'
import SiteTakeoverBackgroundImage from './SiteTakeoverBackgroundImage'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import { themeClassName } from 'lib/theme/themeUtils'
import useOffer from 'hooks/Offers/useOffer'

const Background = styled(SiteTakeoverBackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`

const Content = styled(Group)`
  transform: translateZ(0);
`

const Banner = styled.div`
  padding: ${rem(12)} ${rem(16)};
  position: relative;
`

const offerPageRoutes: Array<string> = [
  '/:regionCode/offer/:slug/:offerId',
  '/:regionCode/cruises/:slug/:offerId',
  '/:regionCode/tour/:offerId',
]

const noBannerRoutes: Array<string> = [
  '/:regionCode/search',
  '/:regionCode/checkout',
]

function SiteTakeoverPageBanner() {
  const match = useRouteMatch<{offerId: string}>(offerPageRoutes)
  const [offer] = useOffer(match?.params.offerId)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const siteTakeover = useCurrentSiteTakeover()
  const showingOfferBanner = !!(offer && siteTakeover?.offerPage.offerTypes.has(offer.type))
  const hide = useMemo((): boolean => {
    // hide on:
    // - The home page (has the carousel)
    // - Search page results (has it's own banner)
    // - Would show the offer page banner  (has it's own banner)
    // - Any of the other no banner routes (shouldn't show banners)
    return showingOfferBanner ||
     !!matchPath(currentPath, { path: '/:regionCode', exact: true }) ||
    !!matchPath(currentPath, { path: noBannerRoutes })
  }, [currentPath, showingOfferBanner])

  const searchQuery = useMemo(() => encodeSearchParams({
    searchItem: ANYWHERE_SEARCH_ITEM,
    rooms: [config.search.defaultOccupants],
  }), [])

  if (!siteTakeover || hide) {
    return null
  }

  return <Banner >
    <Background background="default"/>
    <Content
      direction="horizontal"
      gap={12}
      verticalAlign="center"
      horizontalAlign="center"
      className={themeClassName(siteTakeover.palette)}
    >
      <BodyText variant="medium">
        {siteTakeover.pageBannerContent}
      </BodyText>
      <TextButton
        kind="primary"
        variant="ghost"
        size="small"
        to={`/search?${searchQuery.toString()}`}
      >
        View offers
      </TextButton>
    </Content>
  </Banner>
}

export default SiteTakeoverPageBanner
