import {
  API_CALL, DISMISS_NOTIFICATION_BANNER,
} from './actionConstants'
import {
  FETCH_LANDING_PAGE,
  FETCH_MAGAZINE_ARTICLES,
  FETCH_NOTIFICATION_BANNER,
  FETCH_BANNERS,
  FETCH_FAQ,
  FETCH_LIST_BANNERS,
  FETCH_DREAM_SITE_BLOG_POSTS,
  FETCH_LINK_BOX,
} from './apiActionConstants'
import { TYPE_OF_PAGE_CONTENT_HUB } from 'constants/landingPage'
import { getLandingPage, getLinkBox, getMagazineArticlesByTags, getNotificationBanner, getDestinationProductPage, getLegalPage } from 'api/content'
import { getBanners, getListBanners } from 'api/banners'
import { setNotificationBannerCookie, getNotificationBannerHashFromCookie } from 'cookies/notificationBannerCookie'
import { encode } from 'lib/encoding/isomorphicBase64'
import { getContentPageKey } from 'lib/url/contentUtils'
import { getDreamSiteBlogPosts } from 'api/dreamSite'
import { removeRegionFromPath } from 'lib/url/searchUrlUtils'
import { AppAction } from './ActionTypes'

export function fetchLandingPage(
  slug: string,
  type: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const key = getContentPageKey(slug, type)
    if (state.content.landingPages[key]) {
      // already have loaded the page
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LANDING_PAGE,
      request: () => getLandingPage(slug, type, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchHubPage(slug: string): AppAction {
  return (dispatch) => {
    dispatch(fetchLandingPage(slug, TYPE_OF_PAGE_CONTENT_HUB))
  }
}

export function fetchMagazineArticles(tags: Array<string>, numberOfPosts: number = 3): AppAction {
  return {
    type: API_CALL,
    api: FETCH_MAGAZINE_ARTICLES,
    request: () => getMagazineArticlesByTags(tags, numberOfPosts),
  }
}

export function fetchNotificationsForSlug(slug: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const existingNotification = state.content.notifications[slug]
    if (existingNotification || existingNotification === null) {
      // already fetched it, no need to fetch again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_NOTIFICATION_BANNER,
      request: () => getNotificationBanner(slug, state.geo.currentRegionCode).then(notification => {
        if (notification) {
          const existingBannerHash = getNotificationBannerHashFromCookie(notification.slug)
          if (existingBannerHash && notification.show) {
            const hash = encode(notification.bannerText)
            notification.show = existingBannerHash !== hash
          }
        }

        return notification
      }),
      slug,
    })
  }
}

export function fetchBanners(location: App.BannerLocation, tags: Array<string> = []): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.content.banners[location] && !tags.length) {
      // already fetched it, no need to fetch again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BANNERS,
      request: () => getBanners({ regionCode: state.geo.currentRegionCode, tags, location }),
      location,
    })
  }
}

export function fetchListBanners(location: App.BannerLocation, tags: Array<string> = []): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.content.listBanners?.[location] && !tags.length) {
      // already fetched it, no need to fetch again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LIST_BANNERS,
      request: () => getListBanners({ regionCode: state.geo.currentRegionCode, tags, location }),
      location,
    })
  }
}

export function dismissNotificationBanner(slug: string, text: string): AppAction {
  setNotificationBannerCookie(slug, encode(text) ?? '')

  return {
    type: DISMISS_NOTIFICATION_BANNER,
    slug,
  }
}

export function fetchFaq(type: 'destinationProduct' | 'legal', slug: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.content.faqs[type][slug] !== undefined) {
      // we already tried this, don't refetch.
      return
    }

    const fetcher =
      type === 'destinationProduct' ?
        getDestinationProductPage :
        getLegalPage

    dispatch({
      type: API_CALL,
      api: FETCH_FAQ,
      request: () => fetcher(slug),
      faqType: type,
      slug,
    })
  }
}

export function fetchDreamSiteBlogPosts(tagId: number, limit?: number, popular?: boolean, location?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.content.dreamSiteBlogPosts[tagId]) {
      // already fetched or in process of doing so, no need to again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_DREAM_SITE_BLOG_POSTS,
      request: () => getDreamSiteBlogPosts({ tagIds: [tagId], limit, popular, location }),
      key: tagId,
    })
  }
}

/**
 * NB: this is just loaded on 'Hotels' destination search pages at the moment
 */
export function fetchLinkBox(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const pathWithoutRegion = removeRegionFromPath(state.router.location.pathname)

    if (state?.content?.searchFooterLinkBox && pathWithoutRegion in state.content.searchFooterLinkBox) {
      // already fetched or in process of doing so, no need to again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LINK_BOX,
      request: () => getLinkBox({ region: state.geo.currentRegionCode, slug: pathWithoutRegion }),
      key: pathWithoutRegion,
    })
  }
}
