import { createReducer } from 'lib/redux/reducerUtils'
import { fareTypes, fareCabins, DEFAULT_ADULT_OCCUPANTS, FlightsClassTypes } from 'constants/flight'
import { FlightDropdownOption } from 'components/Flights/types'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import uuidV4 from 'lib/string/uuidV4Utils'

export enum FlightSearchWidgetActions {
  RESET_STATE = 'RESET_STATE',
  SET_DEPARTURE_AIRPORT = 'SET_DEPARTURE_AIRPORT',
  SET_ARRIVAL_AIRPORT = 'SET_ARRIVAL_AIRPORT',
  SET_CHECKIN_DATE = 'SET_CHECKIN_DATE',
  SET_CHECKOUT_DATE = 'SET_CHECKOUT_DATE',
  SET_OCCUPANTS = 'SET_OCCUPANTS',
  SET_FARE_TYPE = 'SET_FARE_TYPE',
  SET_FARE_CABIN = 'SET_FARE_CABIN',
  SET_ACTIVE_MENU = 'SET_ACTIVE_MENU',
  SHOW_DEPARTURE_ERROR = 'SHOW_DEPARTURE_ERROR',
  SHOW_ARRIVAL_ERROR = 'SHOW_ARRIVAL_ERROR',
  SHOW_TRAVEL_DATES_ERROR = 'SHOW_TRAVEL_DATES_ERROR',
  SHOW_SAME_AIRPORT_ERROR = 'SHOW_SAME_AIRPORT_ERROR',
  UPDATE_FLIGHT_SEARCH_WIDGET_DATA = 'UPDATE_FLIGHT_SEARCH_WIDGET_DATA',
  ADD_FLIGHT = 'ADD_FLIGHT',
  REMOVE_FLIGHT = 'REMOVE_FLIGHT',
}

export interface FlightSearchWidgetFlightItem {
  id: string,
  departureAirport?: App.AirportLocation;
  arrivalAirport?: App.AirportLocation;
  checkinDate?: moment.Moment;
  checkoutDate?: moment.Moment;
  checkinQuotedPrice?: number;
  checkoutQuotedPrice?: number;
  showDepartureError?: boolean;
  showDestinationError?: boolean;
  showTravelDatesError?: boolean;
  showSameAirportError?: boolean;
}

export interface FlightSearchWidgetState {
  fareType: FlightDropdownOption;
  fareCabin: FlightDropdownOption<FlightsClassTypes>;
  occupants: App.Occupants;
  activeMenu?: StandaloneFlightMenu;
  flightDealId?: string;
  flights: Array<FlightSearchWidgetFlightItem>;
  selectedFlightId?: string;
  filterAirlines?: Array<string>;
  disableCalendarPricing?: boolean;
}

export interface FlightSearchWidgetDispatchAction {
  type: FlightSearchWidgetActions,
  id?: string,
  [key: string]: any,
}

export const initialFlightStateOccupants: App.Occupants = {
  adults: DEFAULT_ADULT_OCCUPANTS,
  children: 0,
  childrenAge: [],
  infants: 0,
}

const flightItem: Omit<FlightSearchWidgetFlightItem, 'id'> = {
  departureAirport: undefined,
  arrivalAirport: undefined,
  checkinDate: undefined,
  checkoutDate: undefined,
  showDepartureError: false,
  showDestinationError: false,
  showTravelDatesError: false,
  showSameAirportError: false,
}

export function createEmptyFlightSearchWidgetFlightItem(data: Partial<FlightSearchWidgetFlightItem> = {}): FlightSearchWidgetFlightItem {
  return {
    id: uuidV4(),
    ...flightItem,
    ...data,
  }
}

export const initialState: FlightSearchWidgetState = {
  fareType: fareTypes[0],
  fareCabin: fareCabins[0],
  occupants: initialFlightStateOccupants,
  activeMenu: undefined,
  flights: [createEmptyFlightSearchWidgetFlightItem()],
  selectedFlightId: undefined,
}

const flightSearchWidgetStateReducer = createReducer<FlightSearchWidgetState, FlightSearchWidgetDispatchAction>(initialState, {
  [FlightSearchWidgetActions.RESET_STATE]: () => (initialState),
  [FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { departureAirport: action.airport }),
  }),
  [FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { arrivalAirport: action.airport }),
  }),
  [FlightSearchWidgetActions.SET_CHECKIN_DATE]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { checkinDate: action.date, checkinQuotedPrice: action.quotedPrice }),
  }),
  [FlightSearchWidgetActions.SET_CHECKOUT_DATE]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { checkoutDate: action.date, checkoutQuotedPrice: action.quotedPrice }),
  }),
  [FlightSearchWidgetActions.SET_OCCUPANTS]: (state, action) => ({
    occupants: action.occupants,
  }),
  [FlightSearchWidgetActions.SET_FARE_TYPE]: (state, action) => ({
    fareType: action.fareType,
  }),
  [FlightSearchWidgetActions.SET_FARE_CABIN]: (state, action) => ({
    fareCabin: action.fareCabin,
  }),
  [FlightSearchWidgetActions.SET_ACTIVE_MENU]: (state, action) => ({
    activeMenu: action.activeMenu,
    selectedFlightId: action.id,
  }),
  [FlightSearchWidgetActions.SHOW_DEPARTURE_ERROR]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { showDepartureError: action.error }),
  }),
  [FlightSearchWidgetActions.SHOW_ARRIVAL_ERROR]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { showDestinationError: action.error }),

  }),
  [FlightSearchWidgetActions.SHOW_TRAVEL_DATES_ERROR]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { showTravelDatesError: action.error }),

  }),
  [FlightSearchWidgetActions.SHOW_SAME_AIRPORT_ERROR]: (state, action) => ({
    flights: updateFlightProperty(state.flights, action.id!, { showSameAirportError: action.error }),
  }),
  [FlightSearchWidgetActions.UPDATE_FLIGHT_SEARCH_WIDGET_DATA]: (state, { payload }) => ({
    occupants: payload.occupants,
    fareType: payload.fareType,
    fareCabin: payload.fareCabin,
    activeMenu: payload.activeMenu,
    flights: payload.flights,
  }),
  [FlightSearchWidgetActions.ADD_FLIGHT]: (state, action) => ({
    flights: [...state.flights, action.payload],
  }),
  [FlightSearchWidgetActions.REMOVE_FLIGHT]: (state, action) => ({
    flights: state.flights.filter(flight => flight.id !== action.payload),
  }),
})

export default flightSearchWidgetStateReducer

const updateFlightProperty = (flights: Array<FlightSearchWidgetFlightItem>, id: string, data: Partial<FlightSearchWidgetFlightItem>) => {
  return flights.reduce((acc: Array<FlightSearchWidgetFlightItem>, flight) => {
    if (flight.id === id) {
      return [...acc, {
        ...flight,
        ...data,
      }]
    }

    return [...acc, flight]
  }, [])
}
