import { getHasLuxLoyaltyAccount, getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import {
  enrolLuxLoyaltyAccountRequest,
  fetchLuxLoyaltyAccountEligibilityRequest,
  fetchLuxLoyaltyAccountRequest,
  fetchLuxLoyaltyProgramConfigRequest,
} from 'api/luxLoyalty'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { API_CALL } from './actionConstants'
import { AppAction } from './ActionTypes'
import {
  ENROL_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
  FETCH_LUX_LOYALTY_PROGRAM_CONFIG,
} from './apiActionConstants'

interface EnrolLuxLoyaltyAccount {
  accountMemberId?: string;
  token?: string;
  enableSnackbar?: boolean;
}

export function enrolLuxLoyaltyAccount({ accountMemberId, token, enableSnackbar }: EnrolLuxLoyaltyAccount): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = accountMemberId ?? state.auth.account.memberId
    const givenName = state.auth.account.givenName
    const headlessMode = state.system.headlessMode
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    let enrolled = false

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) return

    dispatch({
      type: API_CALL,
      api: ENROL_LUX_LOYALTY_ACCOUNT,
      request: () => enrolLuxLoyaltyAccountRequest({
        customerId: memberId,
      }, token)
        .then((result) => {
          enrolled = true

          if (enableSnackbar) {
            showSnackbar(
              `${givenName ? `${givenName}, you` : 'You'} have successfully joined ${LUX_LOYALTY.PROGRAM_NAME}!`,
              'success',
              {
                action: {
                  label: 'Learn more',
                  to: `/${LUX_LOYALTY.LANDING_PAGE}`,
                },
              },
            )
          }

          return result
        })
        .catch((err) => {
          if (enableSnackbar) {
            showSnackbar(
              'Please try again later or contact us for assistance.',
              'critical',
              { heading: 'Oops! Something went wrong' },
            )
          }

          throw err
        })
        .finally(() => {
          // Send message to mobile WebView
          if (headlessMode) {
            appViewMessageHandler('luxLoyalty', { enrolled })
          }
        }),
    })
  }
}

export function fetchLuxLoyaltyAccount(token?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT,
      request: () => fetchLuxLoyaltyAccountRequest(token),
    })
  }
}

export function fetchLuxLoyaltyAccountEligibility(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyEligibility = state.luxLoyalty.eligibilityTier

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyEligibility.fetching ||
      luxLoyaltyEligibility.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
      request: () => fetchLuxLoyaltyAccountEligibilityRequest(memberId),
    })
  }
}

export function fetchLuxLoyaltyProgramConfig(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxLoyaltyProgramConfig = state.luxLoyalty.programConfig

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      !!luxLoyaltyProgramConfig.data ||
      luxLoyaltyProgramConfig.fetching ||
      luxLoyaltyProgramConfig.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_PROGRAM_CONFIG,
      request: () => fetchLuxLoyaltyProgramConfigRequest(),
    })
  }
}
