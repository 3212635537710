import { Dispatch } from 'redux'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { API_CALL } from './actionConstants'
import {
  FETCH_LE_SUBSCRIPTIONS,
  FETCH_LE_SUBSCRIPTIONS_V2,
  FETCH_RESUBSCRIPTION_DISMISSED_DATE,
  FETCH_TRAVEL_PREFERENCES_DISMISSED,
  FIRE_RESUBSCRIBE_PROMO_CODE,
  UPDATE_LE_SUBSCRIPTIONS,
  UPDATE_LE_SUBSCRIPTIONS_V2,
  UPDATE_RESUBSCRIPTION_DISMISSED_DATE,
  UPDATE_TRAVEL_PREFERENCES_DISMISSED,
} from './apiActionConstants'
import * as leSubscriptionsService from 'api/LESubscriptions'
import { AppAction } from './ActionTypes'
import config from 'constants/config'

const isLeSubscriptionsEnabled = config.LE_SUBSCRIPTIONS_ENABLED

export function fetchLESubscriptions(userId: string) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_LE_SUBSCRIPTIONS,
      request: () => leSubscriptionsService.fetchLEUserSubscriptions(userId),
    })
  }
}

export function fetchLESubscriptionsV2(userId: string) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_LE_SUBSCRIPTIONS_V2,
      request: () => leSubscriptionsService.fetchLEUserSubscriptionsV2(userId),
    })
  }
}

export function updateLESubscriptions(
  data: any,
) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: UPDATE_LE_SUBSCRIPTIONS,
      request: () => leSubscriptionsService.updateLEUserSubscriptions(data),
    })
  }
}

export function updateLESubscriptionsV2(
  data: any,
) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: UPDATE_LE_SUBSCRIPTIONS_V2,
      request: () => leSubscriptionsService.updateLEUserSubscriptionsV2(data),
    })
  }
}

export function fetchResubscribeModalDismissedAt(userId: string) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_RESUBSCRIPTION_DISMISSED_DATE,
      request: () => leSubscriptionsService.fetchResubscribeModalDismissedDate(userId),
    })
  }
}

export function updateResubscribeModalDismissedAt(userId: string) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: UPDATE_RESUBSCRIPTION_DISMISSED_DATE,
      request: () => leSubscriptionsService.updateResubscribeModalDismissedDate(userId),
    })
  }
}

export function fireEmailResubscribePromoCode(userId: string) {
  return (dispatch: Dispatch) => {
    if (!isLeSubscriptionsEnabled) { return }

    dispatch({
      type: API_CALL,
      api: FIRE_RESUBSCRIBE_PROMO_CODE,
      request: () => leSubscriptionsService.sendEmailResubscribePromoCode(userId),
    })
  }
}

export function fetchTravelPreferencesModalDismissed(): AppAction {
  return (dispatch, getState) => {
    if (!isLeSubscriptionsEnabled) { return }

    const state = getState()
    const userId = getCurrentUserId(state)
    if (!userId) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_TRAVEL_PREFERENCES_DISMISSED,
      request: () => leSubscriptionsService.fetchTravelPreferencesModalDismissedDate(userId),
    })
  }
}

export function setTravelPreferencesModalViewed(): AppAction {
  return (dispatch, getState) => {
    if (!isLeSubscriptionsEnabled) { return }

    const state = getState()
    const userId = getCurrentUserId(state)
    if (!userId) { return }

    dispatch({
      type: API_CALL,
      api: UPDATE_TRAVEL_PREFERENCES_DISMISSED,
      request: () => leSubscriptionsService.updateTravelPreferencesModalDismissedDate(userId, true),
    })
  }
}
