import config from 'constants/config'
import { ITEM_STATUS_AWAITING_DATES, ITEM_STATUS_CANCELLED, ITEM_STATUS_CREATED, ITEM_STATUS_COMPLETED } from 'constants/cart'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import moment from 'moment'
import { pluralizeToString } from 'lib/string/pluralize'
import { getLatestCompletedReservation } from 'lib/order/orderUtils'
import { isItemUpcoming } from 'lib/order/isUpcoming'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { hasApprovedBNBLRefundRequest } from 'lib/order/refundRequestUtils'

export interface OrderHotelChangeDatesConfig {
  canDateChange: boolean;
  /**
   * exact date when daysBeforeCheckInChangesDisallowed is reached
   */
  policyDeadline: string;
  /**
   * Whether or not the user can request a date change through outsidePolicy DatesRequest
   * when offer's daysBeforeCheckInChangesDisallowed date is reached
   */
  canRequestDateChange: boolean;
}

export const canAddFlights = (order: App.Order, geo: App.GeoState, offerFlightsEnabled: boolean) => {
  if (!config.BNBL_FLIGHT_BOOKING_ENABLED) return false
  if (!offerFlightsEnabled) return false
  if (!geo.currentRegionHasFlights) return false

  const alreadyHasFlights = order.flightItems.some(
    ({ status }) => status !== ITEM_STATUS_CANCELLED,
  )
  if (alreadyHasFlights) return false

  const latestReservation = getLatestCompletedReservation(order)
  if (latestReservation && !isItemUpcoming(latestReservation)) return false

  if (order.items?.some(i => i.canBookDates)) return false

  const validStatuses = [
    ITEM_STATUS_AWAITING_DATES,
    ITEM_STATUS_CREATED,
    ITEM_STATUS_COMPLETED,
  ]
  const validAccomItems = order.items.filter(({ status }) => validStatuses.includes(status))
  return (validAccomItems.length === 1)
}

export const changeDatesUntilStr = (checkInDate: string, changeDatesLimit: number) => {
  const formattedDate = moment.utc(checkInDate).subtract(changeDatesLimit, 'day').format('ll')
  return `${formattedDate} (${pluralizeToString('day', changeDatesLimit)} before check-in).`
}

export const canShowBookByDates = (canBookDates: boolean, bookByDate: string) => {
  if (!canBookDates) return false
  return moment.utc(bookByDate).subtract(2, 'month').isAfter(moment.utc('2021-01-01'))
}

export const getCapacityPath = (item: App.OrderItem, order: App.Order, originAirportCode?: string) => ({
  orderItemId: item.id,
  orderId: order.id,
  originAirportCode,
})

export const hasActiveReservation = (item: App.OrderItem) => {
  if (item.reservationError) return false
  if (!item.reservation) return false
  if (item.status === 'cancelled') return false
  return true
}

const allowedDateChangeTypes = new Set<App.OfferType>(['hotel', 'tactical_ao_hotel'])
const allowedInstallmentDateChangeTypes = new Set<App.OfferType>(['hotel'])
const allowedDepositDateChangeTypes = new Set<App.OfferType>(['hotel', 'tour'])
export function isDateChangeAllowed(
  confirmation: boolean | undefined,
  item: App.OrderItem | undefined,
  numberOfDaysBeforeBookingIsLockedIn: number,
  changeDatesLimitReached: boolean,
  order: App.Order,
  cancellationConfig: App.OrderItemHotelCancellationConfig,
): boolean {
  if (!item) return false
  if (item.isCompedPackage) return false
  if (!allowedDateChangeTypes.has(item.offer.type)) return false
  if (confirmation) return false
  if (!item.reservation) return false
  if (item.canBookDates) return false
  if (item.status === 'cancelled') return false
  if (order.hasFlight) return false
  if (changeDatesLimitReached) return false
  if (order.depositDetails && !allowedDepositDateChangeTypes.has(item.offer.type)) return false
  if (order.instalmentDetails && !allowedInstallmentDateChangeTypes.has(item.offer.type)) return false
  if (item.offer.type === OFFER_TYPE_ALWAYS_ON && !cancellationConfig.canRefund) return false
  if (order.reserveForZeroDetails?.isActive) return false
  if (!config.accommodation.allowDateChangeOnOrder) return false
  if (!numberOfDaysBeforeBookingIsLockedIn || !item.reservation) return false

  return true
}

export function getHotelChangeDatesConfig(
  confirmation: boolean | undefined,
  item: App.OrderItem | undefined,
  order: App.Order,
  offer: App.Offer,
) {
  const configs = {
    canDateChange: false,
    policyDeadline: '',
    canRequestDateChange: false,
  }

  if (!item) return configs

  const numberOfDaysBeforeBookingIsLockedIn = offer?.daysBeforeCheckInChangesDisallowed || 0
  const changeDatesLimitReached = offer?.numberOfDateChangesAllowed !== 'Unlimited' && parseInt(offer.numberOfDateChangesAllowed || '') <= item.numberOfDateChanges

  const dateChangeAllowed = isDateChangeAllowed(confirmation, item, numberOfDaysBeforeBookingIsLockedIn, changeDatesLimitReached, order, item.cancellationConfig)
  const hasOutsidePolicyDatesRequests = order.outsidePolicyDatesRequests[item.id] || order.outsidePolicyDatesRequests[item.downgradedFromId ?? '']

  if (item.reservation && dateChangeAllowed) {
    const finalDateAllowed = moment(item.reservation.startDate).subtract(numberOfDaysBeforeBookingIsLockedIn, 'days')
    const isWithinChangeDatesPolicyDeadline = moment().startOf('day').isSameOrBefore(finalDateAllowed)
    configs.policyDeadline = finalDateAllowed.format(ISO_DATE_FORMAT)
    configs.canDateChange = isWithinChangeDatesPolicyDeadline && !hasOutsidePolicyDatesRequests && !hasApprovedBNBLRefundRequest(order, item)
    configs.canRequestDateChange = !isWithinChangeDatesPolicyDeadline && !hasOutsidePolicyDatesRequests && !hasApprovedBNBLRefundRequest(order, item)
  }

  return configs
}

const allowedExtendDateTypes = new Set<App.OfferType>(['hotel', 'tactical_ao_hotel'])
export function isExtendDatesAllowed(
  item: App.OrderItem,
  offer: App.Offer,
): boolean {
  if (!allowedExtendDateTypes.has(offer.type)) return false
  if (!offer.packageUpgradesAllowed) return false
  if (offer.type === 'hotel' && item.reservation?.channelManager !== 'self-managed') return false
  return true
}

export function canPutOnHold(
  item: App.OrderItem,
  order: App.Order,
  offerType: App.OfferType | undefined,
  canDateChange: boolean,
) {
  return config.CONVERT_ORDER_TO_BNBL && canDateChange && !item.isCompedPackage && order.bookingProtectionItems.length === 0 && offerType === 'hotel'
}
