import { getCalenderDaysByCheckIn } from 'lib/offer/offerCalendarUtils'
import { getItemUniqueKey, getPackageId } from './cart'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import { isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import config from 'constants/config'
import moment from 'moment'
import { generateCheckoutItemViewOfferCreditKey } from '../businessTraveller/cart'
import { extractPkgInclusions } from 'lib/offer/inclusionUtils'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import getHotelEncryptedMargin from 'luxLoyalty/lib/margin/getHotelEncryptedMargin'

export function dummyItemTotalsView(): App.Checkout.ItemViewTotals {
  return {
    price: 0,
    memberPrice: 0,
    value: 0,
    surcharge: 0,
    taxesAndFees: 0,
    extraGuestSurcharge: 0,
    memberValue: 0,
  }
}

function dummyAccommodationItemView(
  item: App.Checkout.LEAccommodationItem, kind: 'le', offer?: App.Offer,
): App.Checkout.LEAccommodationItemView {
  const dummy: App.Checkout.LEAccommodationItemView = {
    ...dummyItemTotalsView(),
    kind,
    packageId: getPackageId(item),
    uniqueKey: getItemUniqueKey(item),
    roomTypeId: '',
    item,
    mainLabel: '',
    mainSummaryLabel: '',
    image: { id: '' },
    occupancy: { adults: 0, children: 0, infants: 0 },
    removeLabel: 'Remove',
    partnership: { bonusPoints: 0, bonusDescription: '', localRewardConversionRate: 0 },
    totals: {
      extraGuestSurcharge: 0,
      price: 0,
      memberPrice: 0,
      taxesAndFees: 0,
      value: 0,
      memberValue: 0,
      surcharge: 0,
    },
    amenities: [],
    policies: '',
    hasTactical: false,
    inclusions: [],
    bonusInclusions: [],
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
  }

  return dummy
}

export function getLEPackageItemTotals(
  offer: App.Offer | App.BundledOffer,
  pkg: App.Package,
  checkIn: string,
  checkOut: string,
  occupancy: App.Occupants,
  calendarsByPackageKey: App.CalendarState['calendarsByPackageKey'] | undefined,
  calendarsByOccupancy: App.CalendarState['calendarsByOccupancy'] | undefined,
  availableRatesByOccupancy: { [offerId: string]: App.OfferAvailableRatesByOccupancy },
): App.Checkout.ItemViewTotals | undefined {
  let surcharge = 0
  let extraGuestSurcharge = 0
  let price = 0
  let memberPrice = 0
  let value = 0
  let memberValue = 0
  let propertyFees = 0
  let taxesAndFees = 0
  let extraGuestSurchargesPayableAtProperty = false

  if (
    (offer.type === OFFER_TYPE_ALWAYS_ON || offer.type === 'rental') &&
    offer.property?.useDynamicOccupancy || offer.property?.useDynamicCancellationPolicies
  ) {
    const key = buildAvailableRateKey(checkIn, checkOut, [occupancy])
    const availableRates = availableRatesByOccupancy[offer.id]?.[key]?.rates
    const availableRate = availableRates?.find(rate => rate.packageUniqueKey === pkg.uniqueKey)

    if (!availableRate) {
      return undefined
    }
    extraGuestSurcharge = availableRate.extraGuestSurcharge
    surcharge += availableRate.surcharge + extraGuestSurcharge
    price = availableRate.hotelPrice
    memberPrice = availableRate.hotelMemberPrice
    value = availableRate.hotelValue
    memberValue = availableRate.hotelMemberValue
    propertyFees = availableRate.propertyFees
    taxesAndFees = availableRate.taxesAndFees
  } else {
    extraGuestSurchargesPayableAtProperty = !!pkg.roomRate?.extraGuestSurcharges.length && pkg.roomRate?.extraGuestSurcharges.some(surcharge => surcharge?.payableAtProperty)
    const occupancyCalendar = calendarsByOccupancy?.[generateOccupancyStringByRoom(occupancy)]
    const pkgCalendar = occupancyCalendar?.[pkg.uniqueKey] ?? calendarsByPackageKey?.[pkg.uniqueKey]
    const pkgByDay = pkgCalendar && getCalenderDaysByCheckIn(pkgCalendar)[checkIn]

    if (!pkgByDay) {
      return undefined
    }

    extraGuestSurcharge = pkgByDay.extraGuestSurcharge ?? 0
    surcharge += pkgByDay.surcharge + (!extraGuestSurchargesPayableAtProperty ? extraGuestSurcharge : 0)
    price = pkgByDay.hotelPrice
    memberPrice = pkgByDay.hotelMemberPrice
    value = pkgByDay.hotelValue
    memberValue = pkgByDay.hotelMemberValue
    propertyFees = pkgByDay.propertyFees ?? 0
    taxesAndFees = pkgByDay.taxesAndFees
  }

  return {
    surcharge,
    price,
    memberPrice,
    value,
    memberValue,
    taxesAndFees,
    extraGuestSurcharge,
    otherFees: {
      propertyFees,
      extraGuestSurcharge: extraGuestSurchargesPayableAtProperty ? extraGuestSurcharge : 0,
    },
  }
}

export function getLEPackageItemView(
  item: App.Checkout.LEAccommodationItem,
  calendarsByOccupancy: App.CalendarState['calendarsByOccupancy'],
  availableRatesByOccupancy: { [offerId: string]: App.OfferAvailableRatesByOccupancy },
  checkoutWithMemberPrice: boolean,
  offer?: App.Offer,
  offerCredits?: Record<string, App.BusinessTraveller.StatefulData<App.BusinessTraveller.OfferCredit>>,
  postPurchase?: App.CheckoutCartMode,
  existingOrder?: App.Order,
  luxPlusTier?: App.MembershipSubscriptionTier,
): App.WithDataStatus<App.Checkout.LEAccommodationItemView> {
  if (!offer) {
    return {
      hasRequiredData: false,
      data: dummyAccommodationItemView(item, 'le'),
    }
  }

  const uniqueKey = getItemUniqueKey(item)
  const pkg = offer.packages.find(pkg => pkg.uniqueKey === uniqueKey)

  let itemTotals: App.Checkout.ItemViewTotals | undefined
  if (isInstantBookingLEHotelItem(item)) {
    if (offer && pkg) {
      itemTotals = getLEPackageItemTotals(
        offer,
        pkg,
        item.checkIn,
        item.checkOut,
        item.occupancy,
        undefined,
        calendarsByOccupancy,
        availableRatesByOccupancy,
      )
    }
  } else {
    itemTotals = {
      price: pkg?.price ?? 0,
      memberPrice: pkg?.memberPrice ?? 0,
      value: pkg?.value ?? 0,
      taxesAndFees: pkg?.taxesAndFees ?? 0,
      otherFees: {
        propertyFees: pkg?.propertyFees ?? 0,
      },
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
    }
  }

  if (!itemTotals) {
    return {
      hasRequiredData: false,
      data: dummyAccommodationItemView(item, 'le'),
    }
  }

  // If the item has a new price, use that instead
  if (item.newPrice) {
    itemTotals.price = item.newPrice
  }
  if (item.newSurcharge) {
    itemTotals.surcharge = item.newSurcharge + (itemTotals.extraGuestSurcharge ?? 0)
  }
  if (item.newExtraGuestSurcharge) {
    itemTotals.extraGuestSurcharge = item.newExtraGuestSurcharge
  }

  if (
    (postPurchase === 'change-package' ||
      postPurchase === 'select-date' ||
      postPurchase === 'change-dates'
    ) && 'orderItemId' in item && existingOrder) {
    const orderItem = existingOrder.items.find(orderItem => orderItem.id === item.orderItemId)
    if (orderItem) {
      itemTotals.paidPrice = orderItem.total

      if (offer.type === 'hotel' && ['select-date', 'change-dates'].includes(postPurchase)) {
        // If we are changing the package at the same time as changing the dates
        const newPackageSelected = orderItem.package.duration !== item.duration

        let priceToUse = newPackageSelected ? itemTotals.price : orderItem.packagePrice
        if (orderItem.channelMarkupId) {
          priceToUse = itemTotals.price
        }
        const memberPriceToUse = newPackageSelected ? itemTotals.memberPrice : orderItem.packagePrice

        const nextPrice = checkoutWithMemberPrice && memberPriceToUse > 0 ? memberPriceToUse : priceToUse
        itemTotals.newPrice = nextPrice + (itemTotals.surcharge || 0)
        itemTotals.price = itemTotals.newPrice - orderItem.total
        itemTotals.memberPrice = itemTotals.newPrice - orderItem.total
      } else if (item.isUpdated) {
        const nextPrice = checkoutWithMemberPrice && itemTotals.memberPrice > 0 ? itemTotals.memberPrice : itemTotals.price
        itemTotals.newPrice = nextPrice + (itemTotals.surcharge || 0)
        itemTotals.price = itemTotals.newPrice - orderItem.total
        itemTotals.memberPrice = itemTotals.newPrice - orderItem.total
      } else {
        itemTotals.memberPrice = 0
        itemTotals.newPrice = 0
        itemTotals.price = 0
      }
      // clear those prices that we don't want to display
      itemTotals.value = 0
      itemTotals.surcharge = 0
      itemTotals.taxesAndFees = 0
      itemTotals.extraGuestSurcharge = 0
      itemTotals.otherFees!.propertyFees = 0
    }
  }

  const checkIn = 'checkIn' in item ? moment(item.checkIn) : undefined
  const checkOut = 'checkOut' in item ? moment(item.checkOut) : undefined

  const [inclusions, bonusInclusions, luxPlusInclusions] = extractPkgInclusions(pkg, { checkIn, checkOut, luxPlusTier })

  const data: App.Checkout.LEAccommodationItemView = {
    totals: {
      ...itemTotals,
      isReservableForZero: pkg?.roomRate?.isReservableForZero,
    },
    kind: 'le',
    pkg,
    packageId: item.packageId,
    uniqueKey,
    roomTypeId: pkg?.roomType?.id ?? '',
    item,
    mainLabel: pkg?.roomType?.name ?? '',
    mainSummaryLabel: pkg?.name ?? '',
    image: pkg?.roomType?.images[0] ?? offer.image,
    amenities: pkg?.roomType?.amenities ?? [],
    policies: pkg?.roomPolicyDescription ?? '',
    occupancy: item.occupancy,
    inclusions,
    bonusInclusions,
    luxPlusInclusions,
    removeLabel: item.itemType === 'tourV1' ? 'Remove tour' : `Remove ${offer?.saleUnitLong ?? 'room'}`,
    partnership: pkg?.partnership,
    hasTactical: pkg?.hasTactical ?? false,
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
    mx: getHotelEncryptedMargin(offer?.type, pkg),
  }

  let hasRequiredData = !!itemTotals.price || !!postPurchase

  if (config.businessTraveller.currentAccountMode === 'business' && offerCredits) {
    const offerCreditKey = generateCheckoutItemViewOfferCreditKey(data)
    const offerCredit = offerCredits[offerCreditKey]
    if (offerCredit?.status === 'success') {
      data.totals.businessTravellerCredits = offerCredit.creditValue
    } else {
      hasRequiredData = false
    }
  }

  return {
    hasRequiredData,
    data,
  }
}
