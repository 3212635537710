import { arrayRange, groupBy, max, sortBy, uniqueBy } from 'lib/array/arrayUtils'
import {
  PACKAGE_UPGRADE_VALUES,
  TOUR_V2_FEMALE_TRAVELLER_TITLES,
  TOUR_V2_MALE_TRAVELLER_TITLES,
  getOfferPackageUpgradeDisplayTextMap,
  ALL_TOUR_PACKAGE_TYPE,
} from 'constants/tours'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import switchFunc from 'lib/function/switchFunc'
import { addDays, isSameDay } from 'lib/datetime/dateUtils'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'

export function deduceGenderFromTitle(title: App.Title): App.Gender {
  if (TOUR_V2_FEMALE_TRAVELLER_TITLES.includes(title)) {
    return 'Female'
  }

  if (TOUR_V2_MALE_TRAVELLER_TITLES.includes(title)) {
    return 'Male'
  }

  return 'Other'
}

export function depositAmountStringBuilder(defaultDepositAmountPercentage: number, depositType?: App.Tours.V2OfferDepositType, depositAmount?: number) {
  const baseText = 'Secure with a'

  if (depositType === 'flat') {
    return { full: `${baseText} $${depositAmount}/person deposit`, short: `$${depositAmount}/person` }
  }

  if (depositType === 'percentage') {
    return { full: `${baseText} ${depositAmount}% deposit`, short: `${depositAmount}%` }
  }

  return { full: `${baseText} ${defaultDepositAmountPercentage}% deposit`, short: `${defaultDepositAmountPercentage}%` }
}

const roomTypeUpgrade = new Set([
  'twinShare',
  'tripleShare',
  'quadShare',
  'multiShare',
])
export function hasRoomTypeUpgrade(roomType: App.Tours.V2OfferRoomType | undefined) {
  return roomTypeUpgrade.has(roomType ?? '')
}

const roomTypePackageUpgrade = new Set([
  'single_regular',
  'single_vip',
  'single_platinum',
  'twin_regular',
  'twin_vip',
  'twin_platinum',
])
export function hasRoomTypePackageUpgrade(roomType: App.Tours.V2OfferRoomType | undefined) {
  return roomTypePackageUpgrade.has(roomType ?? '')
}

// Basically if the tag is "Offer ends in X days" it should be critical variant, otherwise positive variant
export function getUrgencyTagVariant(urgencyTag: string) {
  if (urgencyTag.toLocaleLowerCase().includes('ends in')) {
    return 'critical2'
  }
  return 'positive'
}

export function getTourV2VariationDuration(variation: App.Tours.TourV2OfferVariation) {
  const lastDay = max(variation.itinerary ?? [], (item) => item.startDay)! // guaranteed to exist
  const daysCount = lastDay.startDay + lastDay.duration - 1

  return daysCount
}

export function getRoomTypeDisplayText(roomType: string, offerId: string, packageUpgradeOptionDisplayName?: string) {
  const [room, packageOption] = roomType.split('_')
  if (packageUpgradeOptionDisplayName) {
    return `${room} ${packageUpgradeOptionDisplayName.toLocaleLowerCase()}`
  }
  return packageOption ? `${room} ${getOfferPackageUpgradeDisplayTextMap(offerId).get(packageOption as PACKAGE_UPGRADE_VALUES)?.toLocaleLowerCase()}` : roomType
}

const TOUR_V2_ROOM_TYPE_CAPACITIES: Record<App.Tours.V2OfferRoomType, number> = {
  single: 1,
  twin: 2,
  twinShare: 1,
  triple: 3,
  tripleShare: 1,
  quad: 4,
  quadShare: 1,
  multiShare: 1,
  single_regular: 1,
  single_vip: 1,
  single_platinum: 1,
  twin_regular: 2,
  twin_vip: 2,
  twin_platinum: 2,
}
const roomTypeCapacitySwitchFunc = switchFunc(TOUR_V2_ROOM_TYPE_CAPACITIES, 1)
export function getRoomTypeCapacity(roomType: App.Tours.V2OfferRoomType | undefined): number {
  return roomTypeCapacitySwitchFunc(roomType ?? '')
}

const TOUR_V2_ROOM_TYPE: Partial<Record<App.Tours.V2OfferRoomType, string>> = {
  single_regular: 'regular',
  single_vip: 'vip',
  single_platinum: 'platinum',
  twin_regular: 'regular',
  twin_vip: 'vip',
  twin_platinum: 'platinum',
}
export const getPackageOptionType = switchFunc(TOUR_V2_ROOM_TYPE, '')

export function getPackageOptionDisplayText(packageOption: string, offerId: string) {
  const lowerCasedPackageOption = packageOption.toLowerCase()

  return getOfferPackageUpgradeDisplayTextMap(offerId).get(lowerCasedPackageOption as PACKAGE_UPGRADE_VALUES) ?? packageOption
}

export function getTourPackageListForm(departures: Array<App.Tours.TourV2OfferDeparture>, tourId: string): Array<{ type: string, name: string }> {
  const optionsAvailables = departures.flatMap(departure => departure.options).map(option => ({
    type: option.packageType!,
    name: getPackageOptionDisplayText(option.packageType!, tourId),
  }))
  const uniquePackageTypes = uniqueBy(optionsAvailables, (option) => option.type)
  const allPackageType = { type: ALL_TOUR_PACKAGE_TYPE, name: 'All packages' }
  return [allPackageType, ...uniquePackageTypes]
}

export function findCheapestTourV2Variation(offer: App.Tours.TourV2Offer): App.Tours.TourV2OfferVariation | undefined {
  const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)
  const cheapestDeparture = cheapestPurchasableOption && offer.departures[cheapestPurchasableOption.fkDepartureId]
  const cheapestVariation = cheapestDeparture && offer.variations[cheapestDeparture.fkVariationId]

  return cheapestVariation
}

const TOUR_V2_ROOM_TYPE_COPY: Record<App.Tours.V2OfferRoomType, string> = {
  single: 'Single room',
  twin: 'Twin room',
  twinShare: 'Twin share',
  triple: 'Triple room',
  tripleShare: 'Triple share',
  quad: 'Quad room',
  quadShare: 'Quad share',
  multiShare: 'Multi share',
  single_regular: 'Single room',
  single_vip: 'Single room',
  single_platinum: 'Single room',
  twin_regular: 'Twin room',
  twin_vip: 'Twin room',
  twin_platinum: 'Twin room',
}

export const formatRoomType = switchFunc(TOUR_V2_ROOM_TYPE_COPY, undefined)

export const formatPackageOptionType = (tourId: string) => {
  const PACKAGE_UPGRADE_TEXT = getOfferPackageUpgradeDisplayTextMap(tourId)
  const TOUR_V2_UPGRADE_ROOM: Partial<Record<App.Tours.V2OfferRoomType, string>> = {
    single_regular: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.REGULAR),
    twin_regular: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.REGULAR),
    single_vip: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.VIP),
    twin_vip: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.VIP),
    single_platinum: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.PLATINUM),
    twin_platinum: PACKAGE_UPGRADE_TEXT.get(PACKAGE_UPGRADE_VALUES.PLATINUM),
  }
  return switchFunc(TOUR_V2_UPGRADE_ROOM, '')
}

interface CheapestOfTourV2Offer {
  cheapestDeparture?: App.Tours.TourV2OfferDeparture
  cheapestPurchasableOption?: App.Tours.TourV2OfferPurchasableOption
  cheapestVariation?: App.Tours.TourV2OfferVariation
  hasSomeDiscounts?: boolean
  isDiscountOnTour?: boolean
}
export function findCheapestOfTourV2Offer(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary,
  variationId?: App.Tours.TourV2OfferVariation['id'],
  isLuxPlusToursEnabled = false,
): CheapestOfTourV2Offer {
  const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer, variationId, isLuxPlusToursEnabled)

  if (!cheapestPurchasableOption) {
    return {
      cheapestDeparture: undefined,
      cheapestPurchasableOption: undefined,
      cheapestVariation: undefined,
      hasSomeDiscounts: false,
      isDiscountOnTour: false,
    }
  }

  const cheapestDeparture = offer.departures[cheapestPurchasableOption.fkDepartureId]
  const cheapestVariation = offer.variations[cheapestDeparture.fkVariationId]

  const discountOnPurchasableOption = offer.purchasableOptions.filter((purchasableOption) => {
    return purchasableOption.fullPrice - purchasableOption.price > 0
  })
  const hasSomeDiscounts = discountOnPurchasableOption.length > 0 && offer.purchasableOptions.length !== discountOnPurchasableOption.length
  const isDiscountOnTour = discountOnPurchasableOption.length === offer.purchasableOptions.length

  return {
    cheapestDeparture,
    cheapestPurchasableOption,
    cheapestVariation,
    hasSomeDiscounts,
    isDiscountOnTour,
  }
}

export function findCheapestTourExperience(purchasableExperienceOptions: Array<App.Tours.TourV2OfferPurchasableOption>): App.Tours.TourV2OfferPurchasableOption | undefined {
  const cheapestPurchasableExperienceOptions = sortBy(purchasableExperienceOptions, (purchasableExperienceOption) => purchasableExperienceOption.price, 'asc')
  return cheapestPurchasableExperienceOptions.length > 0 ? cheapestPurchasableExperienceOptions[0] : undefined
}

export interface TourDiscount {
  hasSomeDiscount: boolean
  allIsDiscounted: boolean
}
export function findDiscountOnTour(offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary, variationId?: string, departureIds?: Array<string>): TourDiscount {
  let purchasableOptions = offer.purchasableOptions
  if (variationId) {
    purchasableOptions = purchasableOptions.filter(purchaseOption => purchaseOption.fkVariationId === variationId)
  } else if (departureIds) {
    purchasableOptions = purchasableOptions.filter(purchaseOption => departureIds.includes(purchaseOption.fkDepartureId))
  }
  const discountOnPurchasableOption = purchasableOptions.filter((purchasableOption) => {
    return purchasableOption.fullPrice - purchasableOption.price > 0
  })
  const hasSomeDiscount = discountOnPurchasableOption.length > 0 && purchasableOptions.length !== discountOnPurchasableOption.length
  const allIsDiscounted = discountOnPurchasableOption.length === purchasableOptions.length

  return {
    hasSomeDiscount,
    allIsDiscounted,
  }
}

export type LuxPlusLabelTagOptions = 'all' | 'price-only' | 'access-only' | 'none'

export function getTourTags(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary,
  isLuxPlusToursAvailable?: boolean,
  luxPlusLabels: LuxPlusLabelTagOptions = 'all',
  departureId?: string,
): Array<App.OfferUrgencyLabel> {
  if (departureId) {
    const departure = offer.departures[departureId]
    return departure.urgencyTags
  }
  const { hasSomeDiscount, allIsDiscounted } = findDiscountOnTour(offer)

  const tags: Array<App.OfferUrgencyLabel> = []

  if (allIsDiscounted) {
    tags.push({ type: 'sale' })
  } else if (hasSomeDiscount) {
    tags.push({ type: 'departuresale' })
  }

  if (isLuxPlusToursAvailable) {
    const purchasableOptionWithLuxPlus = offer.purchasableOptions.find((purchasableOption) => purchasableOption.memberPrice)
    if (
      offer.luxPlus.access === 'earlyAccess' &&
      offer.schedule &&
      (luxPlusLabels === 'all' || luxPlusLabels === 'access-only')
    ) {
      tags.push({ type: 'lux_plus_early_access' })
      if (new Date(offer.schedule.start) > new Date()) {
        tags.push({ type: 'lux_plus_general_release', end: offer.schedule.start })
      }
    } else if (purchasableOptionWithLuxPlus && (luxPlusLabels === 'all' || luxPlusLabels === 'price-only')) {
      tags.push({ type: 'lux_plus_member_price' })
    }
  }

  if (offer.isAgentHubExclusive) {
    tags.push({ type: 'agenthub_exclusive' })
  }
  return [...tags, ...offer.urgencyTags]
}

export interface TourV2PricePerDayDetails {
  isDefaultPriceView: boolean
}

export function checkPurchasableOptionHasPackageUpgradeOrRoomType(
  purchasableOptions: Array<App.Tours.TourV2OfferPurchasableOption>,
): { hasPackageUpgrade: boolean, hasRoomUpgrade: boolean } {
  const hasPackageUpgrade = groupBy(purchasableOptions, purchasableOption => purchasableOption.packageType).size > 1
  const hasRoomUpgrade = purchasableOptions.some(purchasableOption => hasRoomTypeUpgrade(purchasableOption.roomType))
  return { hasPackageUpgrade, hasRoomUpgrade }
}

export function checkInventoryAvailable(roomInventory: Map<string, number>, singleRoomsCount: number, twinRoomsCount: number, packageOption: string): boolean {
  const singleRoomInventory = roomInventory.get(`single_${packageOption.toLowerCase()}`) ?? 0
  const twinRoomInventory = roomInventory.get(`twin_${packageOption.toLowerCase()}`) ?? 0
  return singleRoomInventory >= singleRoomsCount && twinRoomInventory >= twinRoomsCount * 2
}

function getDateStringRange(startDate: Date, endDate: Date) {
  if (isSameDay(startDate, endDate)) return undefined
  const firstDate = startDate.getMonth() === endDate.getMonth() ?
    startDate.toLocaleString('en-AU', { day: '2-digit' }) :
    startDate.toLocaleString('en-AU', { day: '2-digit', month: 'short' })
  return `${firstDate} - ${endDate.toLocaleString('en-AU', { day: '2-digit', month: 'short' })}`
}

function consolidateEmptyTourExperience(activityDay: App.Tours.TourExperienceByDay, endDay: number, locations: Array<string>, itineraryEndDate: Date) {
  const rangeDay = [activityDay.day, endDay]
  const locationsConsolidate = [...activityDay.locations, ...locations]
  activityDay.title = `Day ${rangeDay?.join(' - ')}: ${locationsConsolidate.join(' - ')}`
  activityDay.rangeDay = rangeDay
  activityDay.rangeDate = getDateStringRange(activityDay.date, itineraryEndDate)
  activityDay.locations = locationsConsolidate
  return activityDay
}

export function getTourExperiencesByItineraryDay(tour: App.Tours.TourV2Offer| App.Tours.TourV2OfferSummary | undefined, variationId: string, departureId: string): Array<App.Tours.TourExperienceByDay> {
  if (!tour) {
    return []
  }
  const departure = tour.departures[departureId]
  if (!departure) {
    return []
  }
  const itineraries = variationId ? tour.variations[variationId].itinerary : []
  let indexToKeepForNoExperiences = 0
  return itineraries.reduce<Array<App.Tours.TourExperienceByDay>>((acc, itineraryItem) => {
    const itineraryId = itineraryItem.id
    const departureDate = new Date(departure.startDate)
    const itineraryDate = addDays(departureDate, itineraryItem.startDay - 1)
    const itineraryEndDate = addDays(itineraryDate, itineraryItem.duration - 1)
    const endDay = itineraryItem.startDay + itineraryItem.duration - 1
    const rangeDay = itineraryItem.duration > 1 ? [itineraryItem.startDay, endDay] : undefined
    const rangeDate = getDateStringRange(itineraryDate, itineraryEndDate) ?? undefined
    const locations = itineraryItem.locationsVisited
    const titleConsolidate = `Day ${rangeDay?.join(' - ') ?? itineraryItem.startDay}: ${itineraryItem.locationsVisited.join(' - ')}`
    if (itineraryItem.experiences.length > 0) {
      // only skip going to next index if we're the first itinerary with experiences
      if ((indexToKeepForNoExperiences === 0 && acc[indexToKeepForNoExperiences]) || indexToKeepForNoExperiences > 0) indexToKeepForNoExperiences++
      if (rangeDay) {
        const [startDay, endDay] = rangeDay
        arrayRange(startDay, endDay).forEach(day => {
          const experiencesByDay = itineraryItem.experiences.filter(experience => experience.dayNumbers.includes(day))
          const date = addDays(departureDate, day - 1)
          const title = `Day ${day}: ${itineraryItem.locationsVisited.join(' - ')}`
          if (experiencesByDay.length > 0) {
            acc[indexToKeepForNoExperiences] = { title, day, date, locations, experiences: experiencesByDay, itineraryId }
            indexToKeepForNoExperiences++
          } else {
            // keep index for no experiences on consecutive day
            if (acc[indexToKeepForNoExperiences]) {
              const experienceDay = acc[indexToKeepForNoExperiences]
              acc[indexToKeepForNoExperiences] = consolidateEmptyTourExperience(experienceDay, endDay, locations, itineraryEndDate)
            } else {
              acc[indexToKeepForNoExperiences] = { title, day, date, locations, itineraryId }
            }
          }
        })
      } else {
        acc[indexToKeepForNoExperiences] = {
          title: titleConsolidate,
          day: itineraryItem.startDay,
          date: itineraryDate,
          experiences: itineraryItem.experiences,
          locations,
          itineraryId,
        }
        indexToKeepForNoExperiences++
      }
    } else {
      // keep index for no experiences on consecutive day
      if (acc[indexToKeepForNoExperiences]) {
        const experienceDay = acc[indexToKeepForNoExperiences]
        acc[indexToKeepForNoExperiences] = consolidateEmptyTourExperience(experienceDay, endDay, locations, itineraryEndDate)
      } else {
        acc[indexToKeepForNoExperiences] = {
          itineraryId,
          title: titleConsolidate,
          day: itineraryItem.startDay,
          date: itineraryDate,
          locations,
          rangeDay,
          rangeDate,
        }
      }
    }
    return acc
  }, [])
}

export function checkTourExperienceTimeSlot(tourExperienceItems: Array<App.Checkout.TourV2ExperienceItem>, timeSlots: Array<string>, tourExperienceDate: Date) {
  const dateTourExperience = moment(tourExperienceDate).format(ISO_DATE_FORMAT)
  if (timeSlots.length === 0) return true
  return tourExperienceItems.every((tourExperienceItem) => {
    if (!tourExperienceItem?.purchasableOption?.timeSlot) return true
    const [startTime, endTime] = tourExperienceItem?.purchasableOption?.timeSlot?.split(' - ') ?? []
    const startDate = Date.parse(`${tourExperienceItem.date}T${startTime}`)
    const closeDate = Date.parse(`${tourExperienceItem.date}T${endTime}`)
    return timeSlots.some((timeSlot) => {
      const [startTime, endTime] = timeSlot.split(' - ')
      const tourExperienceStartDate = Date.parse(`${dateTourExperience}T${startTime}`)
      const tourExperienceEndDate = Date.parse(`${dateTourExperience}T${endTime}`)
      return !(tourExperienceStartDate >= startDate && tourExperienceStartDate <= closeDate) &&
        !(tourExperienceEndDate >= startDate && tourExperienceEndDate <= closeDate) &&
        !(tourExperienceStartDate <= closeDate && tourExperienceEndDate >= startDate) &&
        !(tourExperienceStartDate <= startDate && tourExperienceEndDate >= closeDate)
    })
  })
}

export function getTourV2SavingsWithLuxPlus(purchasableOption: App.Tours.TourV2OfferPurchasableOption | undefined): number | undefined {
  const { price: pricePerPerson, memberPrice } = purchasableOption ?? {}
  const savingsWithLuxPlus = (!pricePerPerson || !memberPrice) ? undefined : Number(pricePerPerson.toFixed()) - Number(memberPrice.toFixed())
  return savingsWithLuxPlus
}

export function getTourItemKey(tourItem: App.Checkout.TourV2Item): string {
  return tourItem.privateRequestKey ? `${tourItem.offerId}-${tourItem.privateRequestKey}` : tourItem.offerId
}

export function isCurrentDepartureEligibleForDateChange(tour: App.Tours.TourV2Offer, tourOrderItem: App.Tours.TourV2OrderItem): boolean {
  const handbackDate = tour.departures[tourOrderItem.departureId]?.handbackDate
  return !!handbackDate && new Date(handbackDate) > new Date()
}

export function isTourProductTypeStyled(productType: App.Tours.V2ProductType): productType is App.Tours.V2ProductTypeStyled {
  return productType === 'deluxe_tour' || productType === 'premium_tour' || productType === 'ultra_lux_tour' || productType === 'signature_series_tour'
}

export function isTourLimitedTimeOffer(tour: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary): boolean {
  return tour.name?.includes('Limited Time Offer') ?? false
}
