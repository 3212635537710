import { rem } from 'polished'
import React, { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import BodyText from './Typography/BodyText'
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom'
import zIndex from 'styles/tools/z-index'

/** in ms */
const TOOLTIP_DELAY = 300
/** in ms */
const TOOLTIP_TRANSITION = 200

const TooltipSheet = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.one};
  box-shadow: ${props => props.theme.shadow.bottom.medium};
  border-radius: ${props => props.theme.borderRadius.S};
  padding: ${rem(8)};
  width: max-content;
  max-width: min(${rem(335)}, calc(100vw - ${rem(32)}));
  min-width: ${rem(80)};
  z-index: ${zIndex.tooltip};
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity ${TOOLTIP_TRANSITION}ms, visibility 0s ${TOOLTIP_TRANSITION}ms;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
    transition: opacity ${TOOLTIP_TRANSITION}ms, visibility 0s;
  }

  /* Tooltip doesn't need to be painted unless it has been opened at least once. */
  &.not-opened-once {
    display: none;
  }
`

interface Props {
  /**
   * Short description to provide additional snippet of information about what the element it is attached to it is all about.
   * Textual content of the tooltip, w/wo markup.
   */
  description: ReactNode
  /**
   * Determines where the tooltip sits around the anchor.
   *
   * @default bottom
   */
  placement?: 'top' | 'top-start' | 'top-end' | 'right' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left'
  /**
   * Reference to the element that would trigger the tooltip
   */
  triggerRef: RefObject<HTMLElement>
  /**
   * Reference to the element to which the tooltip would attach
   *
   * @default triggerRef
   */
  anchorRef?: RefObject<HTMLElement>
  /**
   * Reference to the element within which the tooltip is to be contained.
   */
  boundaryRef?: RefObject<HTMLElement>
  /**
   * [Uncontrolled] Initial state of the tooltip
   * @default false
  */
  defaultOpen?: boolean
  /**
   * [Controlled] Current state of the tooltip
   */
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

function FloatingTooltip(props: Props) {
  const {
    description,
    placement = 'bottom',
    triggerRef,
    anchorRef,
    boundaryRef,
    defaultOpen = false,
    open,
    onOpenChange,
  } = props

  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>(defaultOpen)
  const isOpen = open ?? uncontrolledOpen
  const [hasOpenedOnce, setHasOpenedOnce] = useState<boolean>(isOpen)
  const isControlled = open !== undefined
  const resetTimeout = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const float = useFloating<HTMLElement>({
    placement,
    strategy: 'absolute',
    middleware: [
      offset(4),
      shift({
        padding: 4,
        boundary: boundaryRef?.current ?? undefined,
      }),
      flip({
        crossAxis: false,
        flipAlignment: true,
        padding: 16,
        boundary: boundaryRef?.current ?? undefined,
      }),
    ],
  })

  useEffect(() => {
    if (isOpen && triggerRef.current && tooltipRef.current) {
      if (!hasOpenedOnce) setHasOpenedOnce(true)
      return autoUpdate(triggerRef.current, tooltipRef.current, float.update)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (anchorRef?.current ?? triggerRef.current) {
      float.refs.setReference(anchorRef?.current ?? triggerRef.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [float.refs.setReference, triggerRef, anchorRef])

  const showTooltip = useCallback(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      if (!isControlled) {
        setUncontrolledOpen(true)
      }
      onOpenChange?.(true)
    }, TOOLTIP_DELAY)
  }, [isControlled, onOpenChange, resetTimeout])

  const hideTooltip = useCallback(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      if (!isControlled) {
        setUncontrolledOpen(false)
      }
      onOpenChange?.(false)
    }, TOOLTIP_DELAY)
  }, [isControlled, onOpenChange, resetTimeout])

  useEffect(() => {
    const trigger = triggerRef.current
    if (trigger) {
      trigger.addEventListener('mouseenter', showTooltip)
      trigger.addEventListener('focusin', showTooltip)
      trigger.addEventListener('mouseleave', hideTooltip)
      trigger.addEventListener('focusout', hideTooltip)

      return () => {
        trigger.removeEventListener('mouseenter', showTooltip)
        trigger.removeEventListener('focusin', showTooltip)
        trigger.removeEventListener('mouseleave', hideTooltip)
        trigger.removeEventListener('focusout', hideTooltip)
        resetTimeout()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TooltipSheet
    role="tooltip"
    ref={(element) => {
      tooltipRef.current = element
      float.refs.setFloating(element)
    }}
    data-open={isOpen}
    className={cn({
      visible: isOpen,
      'not-opened-once': !hasOpenedOnce,
    })}
    style={{
      position: float.strategy,
      top: float.y ?? 0,
      left: float.x ?? 0,
    }}
    onMouseEnter={resetTimeout}
    onMouseLeave={hideTooltip}
  >
    <BodyText
      variant="small"
      weight="normal"
      colour="neutral-eight"
      align="start"
    >
      {description}
    </BodyText>
  </TooltipSheet>
}

export default FloatingTooltip
