import { createSelector } from 'reselect'
import { sum } from 'lib/array/arrayUtils'
import { isLuxPlusSubscriptionItem, isSubscriptionJoinItem } from 'lib/checkout/checkoutUtils'
import { addYears, intlFormatter } from 'lib/datetime/dateUtils'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { checkCanRedeemLuxPlusBenefits, isEnabledLuxPlusDiscountedBookingProtection, isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

export const getLuxPlusSubscriptionItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.LuxPlusSubscriptionItem> => items.filter(isLuxPlusSubscriptionItem),
)

export const isStandaloneLuxPlusSubscription = createSelector(
  (state : App.State) => state.checkout.cart.items,
  (items): boolean => !!items.length && items.every(item => isLuxPlusSubscriptionItem(item) || isSubscriptionJoinItem(item)),
)

export const isLuxPlusSubscriptionInCart = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (items): boolean => items.length > 0,
)

export const getHasLuxPlusOrSubscriptionInCart = createSelector(
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (isLuxPlusSubscriptionInCart, canRedeemLuxPlusBenefits): boolean => !!(isLuxPlusSubscriptionInCart || canRedeemLuxPlusBenefits),
)

export const eligibleLuxPlusCheckoutNotGift = createSelector(
  (state: App.State) => getHasLuxPlusOrSubscriptionInCart(state),
  (state: App.State) => state.checkout.cart.isGift,
  (hasLuxPlusOrSubscriptionInCart, isGift): boolean => hasLuxPlusOrSubscriptionInCart && !isGift,
)

export const checkoutWithMemberPrice = createSelector(
  (state: App.State) => eligibleLuxPlusCheckoutNotGift(state),
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (eligibleLuxPlusCheckoutNotGift, isStandaloneLuxPlusSubscription): boolean => {
    return eligibleLuxPlusCheckoutNotGift && !isStandaloneLuxPlusSubscription
  },
)

export const shouldUseInsuranceMemberPrice = createSelector(
  (state: App.State) => isEnabledLuxPlusDiscountedInsurance(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (isEnabledLuxPlusDiscountedInsurance, checkoutWithMemberPrice): boolean =>
    isEnabledLuxPlusDiscountedInsurance && checkoutWithMemberPrice,
)

export const shouldUseBookingProtectionMemberPrice = createSelector(
  (state: App.State) => isEnabledLuxPlusDiscountedBookingProtection(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (isEnabledLuxPlusDiscountedBookingProtection, checkoutWithMemberPrice): boolean =>
    isEnabledLuxPlusDiscountedBookingProtection && checkoutWithMemberPrice,
)

export const getLuxPlusSubscriptionItemView = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (luxPlusItems): App.WithDataStatus<Array<App.Checkout.LuxPlusSubscriptionItemView>> => {
    return {
      hasRequiredData: true,
      data: luxPlusItems.map((item): App.Checkout.LuxPlusSubscriptionItemView => {
        return {
          item,
          luxLoyaltyProductType: 'lux_plus',
          totals: {
            price: item.amount,
            taxesAndFees: 0,
            memberPrice: 0,
            value: 0,
            memberValue: 0,
            surcharge: 0,
            extraGuestSurcharge: 0,
          },
        }
      }),
    }
  },
)

export const getLuxPlusSubscriptionsTotalPrice = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (items): number => sum(items, item => item.amount || 0),
)

export const getSubscriptionJoinItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => items.filter(isSubscriptionJoinItem),
)

export const getSubscriptionJoinItemView = createSelector(
  (state: App.State) => getSubscriptionJoinItems(state),
  (items): App.WithDataStatus<Array<App.Checkout.SubscriptionJoinItemView>> => {
    return {
      hasRequiredData: true,
      data: items.map((item): App.Checkout.SubscriptionJoinItemView => {
        return {
          item,
          luxLoyaltyProductType: 'lux_plus',
          totals: {
            price: item.waived ? 0 : item.amount,
            value: item.amount,
            taxesAndFees: 0,
            memberPrice: 0,
            memberValue: 0,
            surcharge: 0,
            extraGuestSurcharge: 0,
          },
        }
      }),
    }
  },
)

export const getLuxPlusBreakdownView = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItemView(state),
  (state: App.State) => getLuxPlusSubscriptionsTotalPrice(state),
  (state: App.State) => getSubscriptionJoinItems(state),
  (subscriptionViews, totalPrice, joinViews): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (subscriptionViews.data.length === 0) {
      return { hasRequiredData: subscriptionViews.hasRequiredData, data: [] }
    }

    const breakdownViews: Array<App.Checkout.PriceBreakdownView> = subscriptionViews.data.map(view => {
      const joinFeeItem = joinViews.find(join => join.subscriptionItemId === view.item.itemId)
      const breakdown: App.Checkout.PriceBreakdownView = {
        title: `${LUXURY_PLUS.PROGRAM_NAME} Membership`,
        price: totalPrice,
        memberPrice: 0,
        additionalInfoText: [],
        alwaysShowItemPrice: true,
        itemsAlwaysVisible: true,
        items: [{
          itemType: 'lux-plus-subscription',
          title: '',
          itemId: view.item.itemId,
          additionalInfoText: [
            'Yearly membership',
            `Renews ${intlFormatter(addYears(new Date(), 1), INTL_DMY_CASUAL_SHORT_FORMAT)}.`,
          ],
          additionalElements: joinFeeItem?.amount ? [{
            id: 'policy',
            type: 'cancellation-policy',
            left: {
              type: 'text',
              value: 'Cancel renewal anytime',
            },
            right: {
              type: 'price',
              value: 0,
            },
          }] : [],
        }],
      }

      if (joinFeeItem) {
        if (!joinFeeItem.waived) {
          breakdown.items[0].additionalElements.push({
            id: 'joinFee',
            type: 'price-line',
            left: {
              type: 'text',
              value: 'One-off joining fee',
            },
            right: {
              type: 'price',
              value: joinFeeItem.amount,
            },
          })
        }
        if (joinFeeItem.waived && joinFeeItem.amount) {
          breakdown.items[0].additionalElements.push({
            id: 'joinFeeWaiver',
            variant: 'lux-plus',
            type: 'price-line',
            left: {
              type: 'text',
              value: 'Special offer: No joining fee',
            },
            right: {
              type: 'price',
              value: joinFeeItem.amount,
              strikethrough: true,
            },
          })
        }
      }

      return breakdown
    })

    return {
      hasRequiredData: true,
      data: breakdownViews,
    }
  },
)
