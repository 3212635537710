import React, { useCallback, useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Pill from 'components/Luxkit/Pill'
import Group from 'components/utils/Group'
import TextLink from 'components/Luxkit/TextLink'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { pushWithRegion } from 'actions/NavigationActions'
import { fetchProductSubscription } from 'actions/LuxPlusActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { set as setLocalStorage } from 'lib/storage/isomorphicLocalStorage'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import LuxPlusProductPlaceholder from 'luxPlus/components/LuxPlusProductPlaceholder'
import { useScreenSizeOnly } from 'hooks/useScreenSize'

const LuxPlusHeader = styled(Group)`
  padding: ${rem(20)};
  background-color: ${(props) => props.theme.palette.product.luxPlus.background};
  text-align: center;
`

function LuxPlusFreePreviewAutoModalShortVariant() {
  const dispatch = useAppDispatch()
  const givenName = useAppSelector(state => state.auth.account.givenName)

  const isMobile = useScreenSizeOnly('mobile')
  const modalContext = useModalElementContext()

  const onGetFreePreview = useCallback(() => {
    modalContext.resolve()
    dispatch(pushWithRegion(`/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}?${LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM}=true`))
  }, [modalContext, dispatch])

  const onDismiss = useCallback(() => {
    setLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY, 1)
    modalContext.resolve()
  }, [modalContext])

  useEffect(() => {
    dispatch(fetchProductSubscription())
  }, [dispatch])

  return (
    <ModalBase>
      <ModalBody>
        <LuxPlusHeader direction="vertical" gap={12} horizontalAlign="center">
          <div><Pill kind="primary" variant="success">{LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview</Pill></div>
          <Group direction="vertical" gap={8} horizontalAlign="center">
            {givenName && <Heading variant="heading3" colour="neutral-eight">{givenName},<br/>you’re invited to experience</Heading>}
            {!givenName && <Heading variant="heading3" colour="neutral-eight">You’re invited to experience</Heading>}
            <LuxPlusProductPlaceholder />
          </Group>
        </LuxPlusHeader>
        <ModalContent>
          <Group direction="vertical" gap={8} desktopGap={24}>
            <BodyText variant="medium">
              We’re giving you the chance to experience a <strong>{LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview</strong> of our brand-new VIP Travel Club.
            </BodyText>
            <BodyText variant="medium">Discover hidden offers and enjoy a first-hand look at the exclusive year-round member benefits.</BodyText>
            <BodyText variant="medium" weight="bold">
              No Credit card required. <TextLink target="_blank" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply</TextLink>.
            </BodyText>
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter actionsLayout={isMobile ? 'stacked' : 'auto'} primaryActionProps={{ children: `Start ${LUXURY_PLUS.FREE_PREVIEW_DURATION} day-free preview`, onClick: onGetFreePreview }} secondaryActionProps={{ children: 'Maybe later', onClick: onDismiss }} />
    </ModalBase>
  )
}

export default LuxPlusFreePreviewAutoModalShortVariant
