import { fetchDestinationImageId } from 'actions/PlaceActions'
import { getListName } from 'analytics/enhanced-ecommerce'
import OfferList, { OfferListV2Item } from 'components/OfferList/OfferList'
import { OfferListEventHandler, OfferListEvents, OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'
import useOfferListTracking from 'hooks/Offers/useOfferListTracking'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'
import getOfferListKey from 'lib/offer/offerListKey'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useRouteMatch } from 'react-router'
import * as Analytics from 'analytics/analytics'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SearchForm, { SearchFormRef } from 'components/Search/SearchForm'
import { HOTEL_SEARCH_TYPEAHEAD_TYPES } from 'constants/search'
import LayoutContainer from 'components/Common/LayoutContainer'
import HotelSearchNearbyPropertyOfferList from '../HotelSearchPage/HotelSearchNearbyPropertyOfferList'
import { getImageUrl } from 'lib/image/imageUtils'
import { decodeFromUrlString, DestinationLandingPageType, getDescription, getDestinationLandingPageType, getFilters, getTitleAndSubTitle, validateHolidayType } from './DestinationLandingPageUtils'
import HeadData from 'components/Common/HeadData'

const Banner = styled.div<{imageUrl?: string}>`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${rem(48)};

  & > h1, & > h2 {
    margin: 0 auto;
    padding: 0;
  }

  ${mediaQueryUp.tablet} {
    margin-bottom: ${rem(16)};
  }

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${props => props.imageUrl}) lightgray 50% / cover no-repeat;
`

const SearchFormContainer = styled.div`
${mediaQueryUp.tablet} {
  padding: 0 ${rem(10)} ${rem(16)} ${rem(10)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};
}
`

interface MatchProps {
  holidayType: string;
  location: string;
  regionCode: string;
}

const SearchResultContainer = styled(LayoutContainer)`
  padding-top: ${rem(16)};  
  &.filterPane {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    display: grid;
    padding: ${rem(24)} 0;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: ${rem(32)};

    ${mediaQueryUp.desktop} {
      grid-template: "offer-filters offer-list" auto / ${rem(278)} minmax(0, 2fr);
      padding-top: ${rem(12)};
    }
  }
`

interface Props extends RouteComponentProps<MatchProps> {
  regionCode: string;
  isSearchEnabled: boolean;
  locationToPlace: { [location: string]: App.Place }
  placesLoading: { [location: string]: boolean }
  customHeadings?: App.ContentHeadings;
  isDynamicSearchFooterBoxContent: boolean
  windowSearch: string,
  filtersFromUrl: App.OfferListFilters,
  placesLookUp: { [id: string]: App.Place }
}

function DestinationLandingPage(props: Props) {
  const { locationToPlace } = props
  const match = useRouteMatch<MatchProps>()
  const { holidayType: urlHolidayType, location: urlLocation } = match.params
  const holidayType = urlHolidayType ? decodeFromUrlString(urlHolidayType) : ''
  const hasHolidayType = validateHolidayType(holidayType) && holidayType !== ''
  const location = decodeFromUrlString(urlLocation)
  const destinationLandingPageType = getDestinationLandingPageType(match.path)
  const dispatch = useAppDispatch()
  const formRef = useRef<SearchFormRef>(null)
  const queryParams = useQueryParams()
  const place = locationToPlace[location]
  const { title, subtitle } = getTitleAndSubTitle({ destinationLandingPageType, placeName: place?.name ?? '', holidayType })

  const imageId = useAppSelector(state => state.destination.places[place?.id]?.imageId)
  const imageUrl = getImageUrl(imageId ?? '')

  const { globalHotelsSearchDispatch, globalHotelsSearchState } = useGlobalHotelsSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    searchType: 'destination',
    searchItem: { searchType: 'destination', value: place?.id, format: { mainText: place?.name, secondaryText: '' } },
    searchTargetLabel: place?.name,
  }, queryParams)

  useEffect(() => {
    dispatch(fetchDestinationImageId(place?.id))
  }, [place, dispatch, location])

  const filters = useMemo((): App.OfferListFilters => getFilters(
    {
      destinationLandingPageType,
      hasHolidayType,
      urlHolidayType,
      place,
    }), [destinationLandingPageType, hasHolidayType, place, urlHolidayType])

  const nearbyFilters = useMemo(() => {
    const { holidayTypes, limit, ...restFilters } = filters
    return {
      ...restFilters,
      limit: 20,
      searchNearby: true,
    }
  }, [filters])

  const trackingName = destinationLandingPageType === DestinationLandingPageType.HolidayPackages ? 'HolidayPackage Page' : 'Resort Page'
  const tracking = useOfferListTracking(
    getListName('OfferList', trackingName, 'Main Results'),
    {
      ga: {
        category: 'OfferList',
        label: 'Main Results',
      },
      key: getOfferListKey(filters),
    },
  )

  const onListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    if (dispatchAction.type === OfferListEvents.productClick) {
      Analytics.trackClientEvent({
        subject: 'search-click-position',
        action: `ab-value: ${dispatchAction.position + 1}`,
        category: 'logging',
        type: 'operational',
        optimizelyEventId: '29214570602',
        optimizelyEventKey: 'search-click-position',
      })
    }
  }, [])

  const extras: Array<OfferListV2Item> = [{
    id: 'nearby-property-offers',
    position: 'end',
    element: <HotelSearchNearbyPropertyOfferList filters={nearbyFilters} />,
  }]

  return (
    <GlobalSearchContextProvider
      state={globalHotelsSearchState}
      dispatch={globalHotelsSearchDispatch}
    >
      <HeadData
        title={title}
        description={getDescription({ destinationLandingPageType, placeName: place?.name ?? '', holidayType })}
      />
      <Banner imageUrl={imageUrl}>
        <Heading variant="heading2" align="center" as="h1" colour="neutral-eight">{title}</Heading>
        <BodyText variant="large" align="center" as="h2" colour="neutral-eight">{subtitle}</BodyText>
      </Banner>
      <SearchFormContainer>
        <SearchForm
          submitOnApply
          filters={filters}
          ref={formRef}
          showDateFieldOnMobile={false}
          typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
        />
      </SearchFormContainer>
      <SearchResultContainer>
        <OfferListEventsProvider tracking={tracking} onListEvent={onListEvent}>
          <OfferList
            filters={filters}
            tileStyle="search"
            interstitials={extras}
            streamResults
          />
        </OfferListEventsProvider>
      </SearchResultContainer>
    </GlobalSearchContextProvider>
  )
}
const mapStateToProps = (state: App.State) => ({
  placesLookUp: state.destination.places,
  locationToPlace: state.destination.locationToPlace,
  placesLoading: state.destination.placesLoading,
  regionCode: state.geo.currentRegionCode,
  customHeadings: state.content?.secureHeadingParams?.landing,
  windowSearch: state.router.location.search,
})

export default connect(mapStateToProps)(DestinationLandingPage)
