import type { Card, ClassicCard, ContentCards } from '@braze/web-sdk'
import config from 'constants/config'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'

let _braze: any = undefined

async function getBraze() {
  if (config.BRAZE_API_KEY && !_braze) {
    _braze = await import(/* webpackChunkName: "Braze-SDK" */ '@braze/web-sdk').then(brazeSdk => {
      brazeSdk.initialize(config.BRAZE_API_KEY!, {
        baseUrl: 'sdk.iad-05.braze.com',
        enableLogging: process.env.NODE_ENV !== 'production',
      })
      // Something's causing people to be opted out of Braze even though they didn't do anything to be opted out.
      // We don't even offer a way to opt out of Braze. Might be adblockers? In any case, `enableSDK()` clears the opt-out cookie.
      brazeSdk.enableSDK()
      return brazeSdk
    })
    window.__DEBUG_BRAZE__ = _braze
  }
  return Promise.resolve(_braze)
}

function mapBrazeNotification(card: ClassicCard): App.UserNotification {
  const url = card.url ? addQueryParamsToPath(card.url, { utm_medium: 'notification_centre_web' }) : undefined

  return {
    id: card.id,
    created: card.created ?? undefined,
    description: card.description,
    title: card.title,
    imageUrl: card.imageUrl,
    viewed: card.viewed,
    url,
    originalCard: card,
  }
}

function filterInvalidNotifications(notifications: Array<Card>) {
  return notifications.filter(notification => {
    // Extra key value pair set in braze to filter by device/platform
    if (!notification.extras.allowed_devices) return true
    return notification.extras.allowed_devices.includes('web')
  })
}

export async function changeBrazeUser(memberId?: string) {
  const sdk = await getBraze()
  if (sdk) {
    const brazeUserId = _braze.getUser()?.getUserId()
    if (brazeUserId !== memberId) {
      sdk.changeUser(memberId)
      sdk.openSession()
    }
  }
}

export async function getUserNotifications() {
  const sdk = await getBraze()

  if (sdk) {
    return new Promise(resolve => {
      const subscriptionId = sdk.subscribeToContentCardsUpdates((contentCards: ContentCards) => {
        const cards = filterInvalidNotifications(contentCards.cards).map(mapBrazeNotification)
        resolve(cards)

        // done with our single update, remove this subscription
        sdk.removeSubscription(subscriptionId)
      })
      sdk.requestContentCardsRefresh()
    })
  }
}

// Stores the current content cards subscription ID so we can remove it later if need be.
let currentSubscriptionId: string | undefined = undefined
export async function subscribeToUserNotifications(
  onCardUpdate: (cards: Array<App.UserNotification>) => void,
) {
  const sdk = await getBraze()

  if (sdk) {
    if (currentSubscriptionId) {
      sdk.removeSubscription(currentSubscriptionId)
      currentSubscriptionId = undefined
    }

    currentSubscriptionId = sdk.subscribeToContentCardsUpdates((contentCards: ContentCards) => {
      const cards = filterInvalidNotifications(contentCards.cards).map(mapBrazeNotification)
      onCardUpdate(cards)
    })
  }
}

export async function unsubscribeToUserNotifications() {
  const sdk = await getBraze()

  if (sdk && currentSubscriptionId) {
    sdk.removeSubscription(currentSubscriptionId)
    currentSubscriptionId = undefined
  }
}

export async function logNotificationClick(notification: App.UserNotification) {
  const sdk = await getBraze()
  if (sdk) {
    sdk.logCardClick(notification.originalCard, true)
  }
}

export async function logNotificationImpression(notification: App.UserNotification) {
  const sdk = await getBraze()
  if (sdk) {
    sdk.logCardImpressions([notification.originalCard], true)
  }
}
