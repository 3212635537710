import { useEffect, useMemo } from 'react'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import { getIsFetchingToursPopularDestinations, getToursPopularDestinations } from 'selectors/destinationSelectors'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { fetchToursPopularDestinations } from 'actions/GlobalSearch/GlobalSearchToursActions'
import { DATE_SEARCH_OPTION_IDS, SEARCH_VERTICALS, TOUR_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { useGlobalSearchResultsTracking } from '../useGlobalSearchTracking'
import { SearchResult, SearchResultBucket } from 'api/interactionStudio'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import {
  TOUR_V2_SEARCH_TYPES,
  TOURS_GROUP_TYPES_LIST,
} from 'constants/tours'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useOfferList from 'hooks/Offers/useOfferList'
import {
  getFlexibleMonthsFromURLSearchParams,
  getFlexibleNightsFromURLSearchParams,
  getSearchItemFromURLSearchParams,
} from 'lib/url/searchUrlUtils'
import { OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'

export function useGlobalToursSearchContext(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const queryParams = useMemo(() => {
    if (syncWithURLSearchParams) {
      return {
        searchItem: getSearchItemFromURLSearchParams(syncWithURLSearchParams),
        flexibleNights: getFlexibleNightsFromURLSearchParams(syncWithURLSearchParams),
        flexibleMonths: getFlexibleMonthsFromURLSearchParams(syncWithURLSearchParams),
        tourGroupTypes: getTourGroupTypeFromURLSearchParams(syncWithURLSearchParams),
      }
    }
    return undefined
  }, [syncWithURLSearchParams])

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext({
    ...initialState,
    dateSearchOptionId: DATE_SEARCH_OPTION_IDS.FLEXIBLE,
    searchVerticals: new Set([SEARCH_VERTICALS.TOURS]),
  })

  // sync search item
  useEffect(() => {
    if (queryParams) {
      const { searchItem, flexibleNights, flexibleMonths, tourGroupTypes } = queryParams

      if (TOUR_V2_SEARCH_TYPES.includes(searchItem?.searchType)) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_SEARCH_ITEM })
      }

      if (flexibleNights) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights: flexibleNights as TOUR_FLEXIBLE_DURATION_RANGE })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION })
      }

      if (flexibleMonths) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths })
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS, userSelectedFlexibleMonths: true })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE })
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS })
      }

      if (tourGroupTypes) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_TOUR_GROUP_TYPE, tourGroupTypes: tourGroupTypes as Array<App.Tours.TourGroupType> })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.SELECT_ALL_TOUR_GROUP_TYPE })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const dispatch = useAppDispatch()

  const isFetchingToursPopularDestinations = useAppSelector(getIsFetchingToursPopularDestinations)
  const toursPopularDestinations = useAppSelector(getToursPopularDestinations)

  useEffect(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS })
    dispatch(fetchToursPopularDestinations())
  }, [dispatch, globalSearchDispatch])

  useEffect(() => {
    if (!isFetchingToursPopularDestinations) {
      globalSearchDispatch({
        type: GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS,
        popularDestinations: toursPopularDestinations,
      })
    }
  }, [isFetchingToursPopularDestinations, toursPopularDestinations, globalSearchDispatch])

  return {
    globalToursSearchDispatch: globalSearchDispatch,
    globalToursSearchState: globalSearchState,
  }
}

export function useGlobalToursSearchResultsTracking(globalToursSearchState: GlobalSearchState) {
  const filters = useAppSelector(getUrlOfferListFilters)

  const tourSearchParams: App.OfferListFilters = { ...filters, offerTypes: [OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2] }

  const tourOfferList = useOfferList(tourSearchParams)
  const areTourResultsFetching = tourOfferList.fetching

  const trackableResults = useMemo<Array<SearchResult>>(() => {
    const results: Array<SearchResult> = []
    tourOfferList?.offerIds?.forEach((id) => { results.push({ id, kind: SearchResultBucket.TTC_TOUR }) })
    return results
  }, [tourOfferList])

  useGlobalSearchResultsTracking(
    { ...globalToursSearchState, occupancies: [], isFlexibleDateSelected: false },
    tourOfferList.key,
    trackableResults,
    areTourResultsFetching,
  )
}

function getTourGroupTypeFromURLSearchParams(urlSearchParams: URLSearchParams): Array<string> {
  const tourGroupTypeQueryParamsKeys = TOURS_GROUP_TYPES_LIST
  return tourGroupTypeQueryParamsKeys.reduce((acc, key) => {
    if (urlSearchParams.has(key)) acc.push(key)
    return acc
  }, [])
}

export function getTourGroupTypeSearchMap(tourGroupTypes: Array<App.Tours.TourGroupType> = []): App.Tours.TourGroupTypeMap {
  return tourGroupTypes.reduce((acc, value) => {
    return { ...acc, [value]: true }
  }, {})
}
