import React, { useContext, useEffect, useMemo } from 'react'
import { Route, matchPath } from 'react-router-dom'
import classnames from 'clsx'
import FooterRoutes from './FooterRoutes'
import App from 'components/App/App'
import SpoofingWarning from '../SpoofWarning'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import GlobalStyles from 'styles/GlobalStyles'
import styled from 'styled-components'
import BannerRoutes from './BannerRoutes'
import NotificationBannerRoutes from './NotificationBannerRoutes'
import HeaderRoutes from './HeaderRoutes'
import PageRoutes from './PageRoutes'
import { connect } from 'react-redux'
import GeoContext from 'contexts/geoContext'
import { fetchGeoAirports, fetchGeoState } from 'actions/GeoActions'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ErrorPage from 'components/Pages/ErrorPage'
import { useAppDispatch } from 'hooks/reduxHooks'
import GlobalSearchV2 from 'components/SearchV2/GlobalSearchV2'
import { fetchDepositConfig } from 'actions/SystemActions'
import SupportAssistantChatWidgetRoutes from 'supportAssistant/routes/SupportAssistantChatWidgetRoutes'
import AccountAccessPrompt from 'components/Account/AccountAccessPrompt/AccountAccessPrompt'
import UnhandledErrorPage from 'components/Pages/ErrorPage/UnhandledErrorPage'
import config from 'constants/config'
import { useRestoreCartState } from 'checkout/hooks/useRestoreCartState'
import PromoDisplayPrompt from 'marketing/components/PromoDisplayPrompt'
import EnablePushNotificationsPrompt from 'components/Account/EnablePushNotificationsPrompt'

const fixedHeightRoutes = [
  '/:regionCode/search/map',
  '/:regionCode/search/homes-and-villas/map',
  '/:regionCode/support',
]

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow-y: auto;
  }

  &.fixed-height {
    /*
      For pages such as map views where there's no page scrolling.
      Using position: absolute and height: 100%, rather than e.g. height: 100vh
      helps it work better with mobile browsers that cover part of the viewport
      with menu bars. I'm looking at YOU, iOS Safari and iOS Chrome >:(
    */
    position: absolute;
    min-height: 1px;
    height: 100%;
    width: 100%;
  }
`

interface Props {
  pathname: string;
  headlessMode: boolean;
}

function Routes(props: Props) {
  const geo = useContext(GeoContext)

  const dispatch = useAppDispatch()
  const isFixedHeightRoute = useMemo(() => {
    return fixedHeightRoutes.some(route => matchPath(props.pathname, route))
  }, [props.pathname])

  useRestoreCartState()

  useEffect(() => {
    dispatch(fetchGeoState(geo.currentRegionCode, true))
    dispatch(fetchGeoAirports())
    dispatch(fetchDepositConfig())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorBoundary>
      <AppWrapper className={classnames({ 'fixed-height': isFixedHeightRoute })}>
        {!geo.initialised && <LoadingIndicator />}
        {geo.initialised && <>
          {geo.fetchRegionError && <ErrorPage error={geo.fetchRegionError} />}
          {!geo.fetchRegionError && <>
            <SpoofingWarning />
            <App>
              {!props.headlessMode && <Route path="/:regionCode" component={BannerRoutes} />}
              {(config.notification && !props.headlessMode) && <Route path="/:regionCode" component={NotificationBannerRoutes} />}
              {!props.headlessMode && <Route path="/:regionCode" component={HeaderRoutes} />}
              {!props.headlessMode && config.ENABLE_PROMO_DISPLAY && <PromoDisplayPrompt />}
              {!props.headlessMode && config.ENABLE_PUSH_NOTIFICATIONS_PROMPT && <EnablePushNotificationsPrompt />}
              {!props.headlessMode && <AccountAccessPrompt />}
              <ErrorBoundary fallback={() => <UnhandledErrorPage />}>
                <Route path="/:regionCode" component={PageRoutes} />
              </ErrorBoundary>
            </App>
            {!props.headlessMode && <Route path="/:regionCode" component={FooterRoutes} />}
            <GlobalSearchV2 />
            <Route path="/:regionCode" component={SupportAssistantChatWidgetRoutes} />
          </>}
        </>}
        <GlobalStyles />
      </AppWrapper>
    </ErrorBoundary>
  )
}

function mapStateToProps(state: App.State) {
  return {
    pathname: state.router.location.pathname,
    headlessMode: state.system.headlessMode,
  }
}

export default connect(mapStateToProps)(Routes)
